import type { SYNTHETIC_TABS } from "../types/synthetic.types";

type RouteType = Record<
  string,
  {
    path: string;
    name: string;
    children: Record<
      string,
      {
        path: string;
        name: string;
        children?: Record<
          string,
          {
            path: string;
            name: string;
            children?: any;
          }
        >;
      }
    >;
  }
>;

export const Routes: RouteType = {
  //homepage
  HOME: { name: "home", path: "/", children: {} },
  CONTACT: { name: "contact", path: "/contact", children: {} },

  //payments & account
  ACCOUNT: { name: "account", path: "/account", children: {} },
  APIKEYS: { name: "apikeys", path: "/api", children: {} },
  BILLING: {
    name: "billing",
    path: "/billing",
    children: {
      SUCCESS: {
        name: "billingSuccess",
        path: "/billing/success",
        children: {},
      },
      CANCELLED: {
        name: "billingCancelled",
        path: "/billing/cancelled",
        children: {},
      },
    },
  },
  SHOP: { name: "shop", path: "/shop", children: {} },

  // login & register
  LOGIN: { name: "login", path: "/login", children: {} },
  LOGOUT: { name: "logout", path: "/logout", children: {} },
  RESTORE: { name: "restore", path: "/restore", children: {} },

  //voices
  VOICES: {
    name: "voices",
    path: "/voices",
    children: {
      CLONED: { name: "voicesCloned", path: "/voices/cloned", children: {} },
      SYSTEM: { name: "voicesSystem", path: "/voices/system", children: {} },
    },
  },

  //playgrounds
  VOICE_CLONING: {
    name: "voiceCloning",
    path: "/voice-cloning",
    children: {
      UPLOAD: { name: "upload", path: "/voice-cloning/upload", children: {} },
      RECORD: { name: "record", path: "/voice-cloning/record", children: {} },
      SUCCESS: { name: "success", path: "/voice-cloning/success", children: {} },
    },
  },
  REQUEST_VIDEO_TWIN_PRO: {
    name: "requestVideoTwinPro",
    path: "/request-video-twin-pro",
    children: {},
  },
  SPEECH_TO_SPEECH: {
    name: "speechToSpeech",
    path: "/speech-to-speech",
    children: {},
  },
  TEXT_TO_SPEECH: {
    name: "textToSpeech",
    path: "/text-to-speech",
    children: {},
  },
  VIDEO_TO_VIDEO: {
    name: "videoToVideo",
    path: "/video-to-video",
    children: {
      CREATE: {
        name: "videoToVideoCreate",
        path: "/video-to-video/create",
        children: {},
      },
      PREVIEW: {
        name: "videoToVideoPreview",
        path: "/video-to-video/preview/:id",
        children: {},
      },
    },
  },
  VIDEO_SYNTHESIS: {
    name: "gemeloVideo",
    path: "/video-synthesis",
    children: {
      CREATE_TEXT: {
        name: "gemeloVideoCreateText",
        path: "/video-synthesis/text",
        children: {},
      },
      CREATE_AUDIO: {
        name: "gemeloVideoCreateAudio",
        path: "/video-synthesis/audio",
        children: {},
      },
      PREVIEW: {
        name: "gemeloVideoPreview",
        path: "/video-synthesis/preview/:id",
        children: {},
      },
    },
  },
  VIDEO_CREATOR: {
    name: "videoCreator",
    path: "/video-creator",
    children: {
      MAIN: {
        name: "videoCreatorMain",
        path: "/video-creator/main",
        children: {
          VIDEO: { name: "videoCreatorVideo", path: "video", children: {} },
          AUDIO: { name: "videoCreatorAudio", path: "audio", children: {} },
          TEXT: { name: "videoCreatorText", path: "text", children: {} },
          MULTI_TEXT: {
            name: "videoCreatorMultiText",
            path: "multi-text",
            children: {},
          },
          DUBBING: {
            name: "videoCreatorDubbing",
            path: "dubbing",
            children: {},
          },
          RECORDING: {
            name: "videoCreatorRecording",
            path: "recording",
            children: {},
          },
        },
      },
      VISUAL: {
        name: "videoCreatorVisual",
        path: "/video-creator/visual",
        children: {},
      },
      VOICE: {
        name: "videoCreatorVoice",
        path: "/video-creator/voice",
        children: {},
      },
    },
  },

  // synthetics
  SYNTHETICS: {
    name: "syntheticsCreator",
    path: "/synthetics",
    children: {
      FORM: { name: "syntheticsForm", path: "form", children: {} },
      LOOK_PICKER: {
        name: "synLookPicker",
        path: "look-picker/:id",
        children: {},
      },
      ERROR: { name: "synError", path: "error/:id", children: {} },
      UPLOAD_PHOTO: {
        name: "syntethicsUploadPhoto",
        path: "/synthetics/upload",
        children: {},
      },
      GALLERY: {
        name: "syntethicsGallery",
        path: "/synthetics/gallery",
        children: {},
      },
      GALLERY_ID: {
        name: "syntethicsGalleryId",
        path: "/synthetics/gallery/:id",
        children: {},
      },
    },
  },

  //video twin creator
  VIDEO_TWIN_CREATOR: {
    name: "videoTwinCreator",
    path: "/video-twin-creator",
    children: {
      SELECT_METHOD: {
        name: "videoTwinCreatorSelectMethod",
        path: "select",
        children: {},
      },
      UPLOAD: {
        name: "videoTwinCreatorUpload",
        path: "upload/:type",
        children: {},
      },
      RECORD: { name: "videoTwinCreatorRecord", path: "record", children: {} },
      VIDEO_SUBMITTED: {
        name: "videoSubmitted",
        path: "upload-success/:id/:type",
        children: {},
      },
    },
  },

  //Video Twin Studio
  AI_TWIN_STUDIO: {
    name: "twinStudio",
    path: "/twin-studio",
    children: {
      LIPSYNC_MODELS: {
        name: "lipsyncModels",
        path: "/twin-studio/high-quality",
        children: {},
      },
      ZERO_SHOT: {
        name: "zeroShot",
        path: "/twin-studio/zero-shot",
        children: {},
      },
      SYNTHETICS: {
        name: "/twin-studio/synthetics",
        path: "/twin-studio/synthetics",
        children: {},
      },
      LIPSYNC_MODEL: {
        name: "lipsyncModel",
        path: "/twin-studio/model/:id",
        children: {
          VIDEO_CREATOR: {
            name: "lipsyncModelVideoCreator",
            path: "video-creator",
            children: {
              AUDIO: {
                name: "lipsyncVideoCreatorAudio",
                path: "audio",
                children: {},
              },
              TEXT: {
                name: "lipsyncVideoCreatorText",
                path: "text",
                children: {},
              },
            },
          },
        },
      },
      SYNTHETIC_PORTRAIT: {
        name: "syntheticPortrait",
        path: "/twin-studio/portrait/:id",
        children: {
          VIDEO_CREATOR: {
            name: "syntheticPortraitVideoCreator",
            path: "video-creator",
            children: {
              VIDEO: {
                name: "syntheticPortraitVideoCreatorVideo",
                path: "video",
                children: {},
              },
              AUDIO: {
                name: "syntheticPortraitVideoCreatorAudio",
                path: "audio",
                children: {},
              },
              TEXT: {
                name: "syntheticPortraitVideoCreatorText",
                path: "text",
                children: {},
              },
              DUBBING: {
                name: "syntheticPortraitVideoCreatorDubbing",
                path: "dubbing",
                children: {},
              },
            },
          },
        },
      },
    },
  },

  PHOTO_TWIN_STUDIO: {
    name: "photoTwinStudio",
    path: "/photo-twin-studio",
    children: {
      PROMPT_GALLERY: {
        name: "photoTwinStudioPromptGallery",
        path: "/photo-twin-studio/prompt-gallery",
      },
      MODELS_GALLERY: {
        name: "photoTwinStudioModelsGallery",
        path: "/photo-twin-studio/models-gallery/:openDialog?",
      },
      MODEL: {
        name: "photoModel",
        path: "model/:id/:openDialog?",
        children: {},
      },
    },
  },

  //others
  RECORDINGS_HISTORY: {
    path: "/recordings-history",
    name: "recordingsHistory",
    children: {
      AUDIO: {
        name: "recordingsHistoryAudio",
        path: "/recordings-history/audio",
        children: {},
      },
      VIDEO: {
        name: "recordingsHistoryVideo",
        path: "/recordings-history/video",
        children: {},
      },
    },
  },
  SHARE: {
    name: "share",
    path: "/share/:id",
    children: {
      SETTINGS: {
        name: "sharedVideoSettings",
        path: "/share/:id",
        children: {},
      },
      PREVIEW: {
        name: "sharedVideoPreview",
        path: "/preview/:id",
        children: {},
      },
    },
  },
  TRAINING: { name: "training", path: "/training", children: {} },
  OFFERS: {
    name: "offers",
    path: "/offers",
    children: {
      CREDITS: { name: "credits", path: "/offers/credits", children: {} },
      EARLY_ACCESS: { name: "access", path: "/offers/access", children: {} },
    },
  },
  STATISTICS: { name: "statistics", path: "/stats", children: {} },
  PRIVACY_POLICY: { name: "privacyPolicy", path: "/privacy", children: {} },
  TERMS_OF_SERVICE: {
    name: "termsOfService",
    path: "/terms-of-service",
    children: {},
  },
  CONTACT_US: {
    name: "contact",
    path: "https://gemelo.ai/contact",
    children: {},
  },
};

<template>
  <div class="login">
    <div class="login__header login__slot">
      <slot name="header" />
    </div>
    <div class="login__content login__slot">
      <slot name="content" />
    </div>
    <div class="login__footer login__slot text-center">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { useSnackbarStore } from "../store/useSnackbarStore";
  import { watch } from "vue";

  const props = defineProps({
    error: {
      type: String,
      default: "",
    },
  });

  const { showSnackbar } = useSnackbarStore();

  watch(
    () => props.error,
    (newVal) => {
      if (newVal) {
        showSnackbar(newVal, AlertModes.ERROR);
      }
    }
  );
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 400px;

  &__content {
    display: block;
    flex-direction: column;
  }

  &__slot {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__error {
    color: var(--vt-c-red);
    text-align: center;
  }

  :deep(.v-container) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}
</style>

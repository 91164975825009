import { defineStore } from "pinia";

import {
  PLAYGROUND_FEATURES,
} from "@/core/data/playgroundFeatures";

export const usePlaygroundStore = defineStore({
  id: "playground",
  state: () => ({
    consent: {
      [PLAYGROUND_FEATURES.VTV.name]: false,
      [PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]: false,
      [PLAYGROUND_FEATURES.VIDEO_CREATOR.name]: true,
      [PLAYGROUND_FEATURES.STS.name]: false,
      twinCreation: false,
    },

  }),
});

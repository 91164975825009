import { defineStore } from "pinia";
import type { SyntheticVideo } from "../types/video.types";
import { computed, ref } from "vue";
import type { LoadedVideoFile, LocalRecording } from "../types/recording.types";
import type {
  SyntheticModel,
  SyntheticPortrait,
} from "../types/synthetic.types";
import {
  PLAYGROUND_FEATURES,
  type PlaygroundFeature,
} from "../data/playgroundFeatures";
import { VoiceInputSource } from "../types/playground.types";

export const useVideoCreatorStore = defineStore("videoCreatorStore", () => {
  const isCreatingVideoFromAudio = ref(false);
  const isCreatingVideoFromRecording = ref(false);
  const isCreatingDubbingVideo = ref(false);
  const isCreatingVideoFromText = ref(false);
  const isCreatingVideoFromVideo = ref(false);
  const isCreatingMultipleVideosFromMultiText = ref(false);
  const openVideoSelector = ref(false);
  const videoCreatorCurrentlySelectedAudioRecording = ref(null as any);
  const videoSynthesisInputAudio = ref(null as LocalRecording | null);
  const videoSynthesisInputText = ref("");
  const selectedDubbingVideo = ref(null as LoadedVideoFile | null);
  const selectedVideo = ref(null as LoadedVideoFile | null);
  const selectedZeroShotVideo = ref(null as LoadedVideoFile | null);
  const videoPreview = ref(null as SyntheticVideo | null);
  const isLoadingVideoPreview = ref(false);
  const videoErrorStatus = ref(false);
  const userSelectedModel = ref(null as SyntheticModel | null);
  const skipVoiceConversion = ref(false);
  const voiceSelectionDialogOpened = ref(false);
  const isCreateVideoButtonClicked = ref(false);
  const isGenerateAudioButtonClicked = ref(false);
  const isCheckingMicrophoneAccess = ref(true);
  const noMicAccess = ref(false);
  const audioInputMode = ref(VoiceInputSource.MIC);
  const audioIsRecordingPanelVisible = ref(false);

  const userSelectedPicture = ref({
    [PLAYGROUND_FEATURES.VTV.name]: null as null | SyntheticPortrait,
    [PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]:
      null as null | SyntheticPortrait,
    [PLAYGROUND_FEATURES.VIDEO_CREATOR.name]: null as null | SyntheticPortrait,
  });

  const multiText = ref({
    fileName: "",
    data: [] as Array<any>,
    numOfAllItems: 0,
    numOfGeneratedVideos: 0,
  });

  const stopAllLoaders = () => {
    isCreatingVideoFromText.value = false;
    isCreatingVideoFromAudio.value = false;
    isCreatingVideoFromRecording.value = false;
    isCreatingDubbingVideo.value = false;
    isCreatingVideoFromVideo.value = false;
    isCreatingMultipleVideosFromMultiText.value = false;
  };

  const isCreatingVideo = computed(() => {
    return (
      isCreatingVideoFromText.value ||
      isCreatingVideoFromAudio.value ||
      isCreatingVideoFromRecording.value ||
      isCreatingVideoFromVideo.value ||
      isCreatingDubbingVideo.value ||
      isCreatingMultipleVideosFromMultiText.value
    );
  });

  const updateMultiTextData = ({
    id,
    videoId,
  }: {
    id: string | number;
    videoId: number;
  }) => {
    const index = multiText.value.data.findIndex((el) => el[0] === id);

    if (index !== -1) {
      multiText.value.data[index][2] = videoId;
    }
  };

  const updatePicture = (
    picture: SyntheticPortrait,
    variant: PlaygroundFeature
  ) => {
    userSelectedPicture.value[variant.name] = picture;
    userSelectedModel.value = null;
  };

  const updateModel = (model: SyntheticModel) => {
    userSelectedPicture.value[PLAYGROUND_FEATURES.VIDEO_CREATOR.name] = null;
    userSelectedPicture.value[PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name] = null;
    userSelectedModel.value = model;
  };

  const clearMultiTextData = () => {
    multiText.value.fileName = "";
    multiText.value.data = [];
    multiText.value.numOfAllItems = 0;
    multiText.value.numOfGeneratedVideos = 0;
  };

  const isDownloadUpdatedCSVActive = computed(() => {
    const readyArr = multiText.value.data.filter((el) => el["2"] ?? false);

    return readyArr.length === multiText.value.numOfAllItems;
  });

  return {
    isCreatingVideoFromAudio,
    isCreatingVideoFromRecording,
    isCreatingDubbingVideo,
    isCreatingVideoFromText,
    isCreatingVideoFromVideo,
    isCreatingMultipleVideosFromMultiText,
    multiText,
    openVideoSelector,
    selectedVideo,
    selectedDubbingVideo,
    selectedZeroShotVideo,
    videoCreatorCurrentlySelectedAudioRecording,
    videoSynthesisInputAudio,
    videoSynthesisInputText,
    videoPreview,
    isLoadingVideoPreview,
    videoErrorStatus,
    userSelectedModel,
    userSelectedPicture,
    isCheckingMicrophoneAccess,
    noMicAccess,
    audioInputMode,
    audioIsRecordingPanelVisible,
    skipVoiceConversion,
    stopAllLoaders,
    isCreatingVideo,
    updateMultiTextData,
    clearMultiTextData,
    isDownloadUpdatedCSVActive,
    updateModel,
    updatePicture,
    voiceSelectionDialogOpened,
    isGenerateAudioButtonClicked,
    isCreateVideoButtonClicked,
  };
});

//Removed dynamic import as this is a initial page from route
import ApiKeys from "../pages/ApiKeys.vue";

import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.APIKEYS.name,
    path: Routes.APIKEYS.path,
    component: ApiKeys,
    meta: { requiresAuth: true },
  },
];

import axios from "axios";

export const headcountToHubspotMap = {
  Individual: "1-5",
  "2-10": "5-25",
  "11-50": "25-50",
  "51-200": "50-100",
  "201-500": "100-500",
  "501-2000": "500-1000",
  "2000+": "1000+",
};

type FormDataType = {
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  company?: string;
  message?: string;
  jobtitle?: string;
  industry?: string;
  numemployees?: keyof typeof headcountToHubspotMap;
  website?: string;
  who_referred?: string;
  requestid?: string;
};

type PayloadType = {
  formData: FormDataType;
  urlId: string;
  pageName: string;
};

type ContextDataType = {
  pageUri: string;
  pageName: string;
  hutk?: string;
  ipAddress?: string
};

export const sendFormDataToHubSpot = (data: PayloadType): void => {
  const hutkCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("hubspotutk="));
  const hutk = hutkCookie ? hutkCookie.split("=")[1] : null;

  const contextData: ContextDataType = {
    pageUri: window.location.href,
    pageName: data.pageName,
  };

  if (hutk) {
    contextData.hutk = hutk;
  }

  // For contacts object, the objectTypeId field is set to 0-1.
  // Docs: https://developers.hubspot.com/docs/api/crm/understanding-the-crm

  const formattedData = {
    submittedAt: Date.now().toString(),
    fields: [
      ...Object.entries(data.formData).map(([key, value]) => ({
        objectTypeId: "0-1",
        name: key,
        value: key === "numemployees" ? headcountToHubspotMap[value as keyof typeof headcountToHubspotMap] || "" : value,
      })),
      {
        objectTypeId: "0-1",
        name: "requestid",
        value: Date.now().toString(36) + Math.random().toString(36).substr(2),
      },
    ],
    context: contextData,
  };

  axios
    .post(
      `${import.meta.env.VITE_HUBSPOT_PORTAL_ID}/${data.urlId}`,
      formattedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((resp) => {
      console.log(resp.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

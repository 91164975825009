<template>
  <c-dialog
    :close-option="true"
    confirm-variant="primary"
    icon="ph:warning-circle-fill"
    is-error-icon
    :model-value="modelValue"
    variant="secondary"
    width="424"
    @cancel="$emit('update:modelValue', false)"
    @confirm="buyTokens"
    @keydown.enter="buyTokens"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title> You need more tokens </template>
    <template #content>
      <c-typography
        class="d-block text-center"
        color-class="copy-secondary"
        variant="body-2-400"
      >
        To continue enjoying all the benefits Gemelo has to offer, you’ll need
        to top up your token balance.
      </c-typography>
    </template>
    <template #confirm-label>Buy more tokens</template>
  </c-dialog>
</template>

<script lang="ts" setup>
// @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const router = useRouter();

  const buyTokens = () => {
    router.push({ name: Routes.SHOP.name });
    emit("update:modelValue", false);
  };
</script>

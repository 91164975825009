<template>
  <template>
    <c-dialog
      icon="mdi:timer-off-outline"
      :model-value="modelValue"
      variant="secondary"
      width="424"
      @confirm="buyMinutes"
      @keydown.enter="buyMinutes"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #title>Insufficient minutes</template>
      <template #content>
        <div class="text-center">
          <c-typography color-class="copy-secondary" variant="body-2-400">
            You don’t have enough minutes to generate this file. Time to purchase more and keep the creativity flowing!
          </c-typography>
        </div>
      </template>
      <template #confirm-label>Buy more</template>
    </c-dialog>
  </template>
</template>

<script lang="ts" setup>
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const router = useRouter();

  const buyMinutes = () => {
    router.push({ name: Routes.BILLING.name });
  };
</script>

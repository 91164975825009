<template>
  <div>
    <div
      :class="[
        'align-center d-flex flex-column gap-24 text-center',
        { 'pa-4': !isMobile },
      ]"
    >
      <div class="d-flex justify-start w-100">
        <c-button
          mode="highlight-plain"
          no-padding
          prepend-icon="ph:caret-left"
          size="x-small"
          @click="navigateToGallery"
        >
          Video Twin Studio
        </c-button>
      </div>
      <div class="mt-4">
        <img class="submit-card__image" src="/images/check.webp" />
      </div>
      <div>
        <c-typography color-class="copy-primary" variant="body-2-600"
        >Your video has been submitted!</c-typography
        >
      </div>
      <div class="submit-card__text">
        <c-typography color-class="copy-secondary" variant="body-3-400">
          {{
            isZeroShot
              ? "Your AI Twin is currently in training and will be ready to use very soon. We will notify you by email when it is ready."
              : "Great job! Your AI Twin is now in training. This usually takes 12-24 hours, but it might take longer for the best results. We'll email you when it's ready."
          }}
        </c-typography>
        <c-typography
          class="d-block mt-4"
          color-class="copy-secondary"
          variant="body-3-400"
        >
          {{ isZeroShot ? "" : "Need something quick?" }}
        </c-typography>
        <c-typography
          class="cursor-pointer"
          color-class="highlight-color"
          variant="body-3-600"
          @click="goToExpressTwins"
        >{{
          isZeroShot ? "Go to Video Twin Studio" : "Create Express Twin"
        }}</c-typography
        >
        <c-typography color-class="copy-secondary" variant="body-3-400">
          {{
            isZeroShot
              ? " and try creating videos with available twins!"
              : "— it's ultra-fast and perfect for instant exploration!"
          }}
        </c-typography>
      </div>
      <c-typography
        class="d-block"
        color-class="copy-secondary"
        variant="body-3-400"
      >
        Got questions?
        <a :href="Routes.CONTACT.path">
          <c-typography color-class="highlight-color" variant="body-3-600">
            Contact us</c-typography
          >
        </a>
      </c-typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from "vue";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  //@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  const router = useRouter();
  const twinNewId = computed(() => router.currentRoute.value.params.id);

  const isZeroShot = computed(() => {
    return router.currentRoute.value.params.type === "zero-shot";
  });

  const navigateToGallery = () => {
    router.push({
      name: isZeroShot.value
        ? Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name
        : Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
      params: { id: twinNewId.value },
    });
  };

  const goToExpressTwins = () => {
    router.push({
      name: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
    });
  };
</script>

<style lang="scss" scoped>
.submit-card {
  &__text {
    max-width: 450px;
  }

  &__image {
    height: 160px;
    width: 160px;
  }
}

.gap-24 {
  gap: 24px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>

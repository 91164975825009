import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

const Statistics = (): Promise<RouteComponent> =>  import("../pages/Statistics.vue");

export default [
  {
    name: Routes.STATISTICS.name,
    path: Routes.STATISTICS.path,
    component: Statistics,
    meta: { requiresAuth: true },
  },
];

<template>
  <div class="align-center d-flex frame justify-space-between mt-3 pa-2 pl-4">
    <c-typography color-class="copy-primary" variant="body-2-400"
    >Unsure about which video to upload? Read and follow our video guide &#8594; </c-typography
    >
    <c-button
      mode="highlight-plain"
      prepend-icon="ph:circle-wavy-check-fill"
      size="small"
      @click="openVideoGuide"
    >Video Guide</c-button
    >
  </div>

  <video-to-video-guide v-model="showVideoGuideDialog" />
</template>

<script lang="ts" setup>
  import { ref } from "vue";

  import VideoToVideoGuide from "@/core/components/Dialogs/VideoToVideoGuide.vue";

  //@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  import { analytics, VIDEO_CREATOR } from "@/core/utils/analytics";

  const showVideoGuideDialog = ref(false);

  const openVideoGuide = () => {
    analytics.sendEvent("video-creator", VIDEO_CREATOR.actions.OPEN_VIDEO_GUIDE);
    showVideoGuideDialog.value = true;
  };
</script>

<style scoped lang="scss">
.frame {
  background: rgb(var(--v-theme-player-bg));
  border-radius: 12px;
  outline: 1px solid rgb(var(--v-theme-light-frame));

}
</style>

<template>
  <view-layout>
    <template #content>
      <Container
        class="px-6 py-4 section"
        outlined
      >
        <template #title>
          <c-typography variant="subtitle-2-600">Client key&nbsp;</c-typography>
          <c-typography variant="subtitle-2-400">(Client ID)</c-typography>
        </template>

        <template #title-action> </template>
        <div :class="['align-center d-flex justify-start api-key-info']">
          <c-typography
            v-if="clientKey"
            :class="['client-key', { 'client-key--min-width': !isMobile }]"
            data-testid="clientKey"
            variant="body-1-400"
          >
            {{ getClientKey }}
          </c-typography>
          <c-tooltip
            :content="
              data.showClientKey ? 'Hide Client key' : 'Show Client key'
            "
            placement="top"
          >
            <c-button
              v-if="clientKey"
              id="clientShowHideBtn"
              :icon="data.showClientKey ? 'mdi:eye' : 'mdi:eye-off'"
              mode="secondary"
              size="small"
              @click="data.showClientKey = !data.showClientKey"
            />
          </c-tooltip>
          <c-tooltip content="Copy" placement="top">
            <c-button
              v-if="clientKey"
              icon="ph:copy-simple-fill"
              mode="secondary"

              @click="copyClientKey"
            ></c-button>
          </c-tooltip>
        </div>
      </Container>

      <c-data-table
        id="apiKeysTable"
        :headers="headers"
        :loading="data.isRefreshingList"
        :mobile="isMobile"
        no-data-message="No API keys"
        :rows="data.rows"
        table-name="API keys"
      >
        <template #right-action>
          <c-button
            id="addApiKey"
            :disabled="data.isRefreshingList"
            elevation="0"
            :loading="data.isAddingApiKey"
            size="default"
            @click="createNewApiKey"
          >Create API key</c-button
          >
        </template>
        <template #[`item.apiKey`]="{ item }">
          <div class="d-flex">
            <div
              :class="['api-key', { 'api-key-mobile': isMobile }]"
              :data-testid="`apiKey${item.id}`"
            >
              {{
                item.encrypt ? encryptKey(item.apiKey) : item.apiKey
              }}
            </div>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="actions-wrapper d-flex">
            <c-tooltip
              :content="data.showClientKey ? 'Hide API key' : 'Show API key'"
              placement="top"
            >
              <c-button
                :id="`apiShowHideBtn${item.id}`"
                class="table-icon-button"
                :icon="item.encrypt ? 'mdi:eye-off' : 'mdi:eye'"
                mode="secondary"
                @click="item.encrypt = !item.encrypt"
              />
            </c-tooltip>
            <c-tooltip content="Copy"     placement="top">
              <c-button
                :id="`copy${item.id}`"
                class="table-icon-button"
                icon="ph:copy-simple-fill"
                mode="secondary"
                @click="copyApiKey(item.apiKey)"
              />
            </c-tooltip>
          </div>
        </template>

        <template #[`item.delete`]="{ item }">
          <c-tooltip content="Delete"     placement="top">
            <c-button
              class="table-icon-button"
              :data-testid="`delete${item.id}`"
              height="20"
              icon="ph:trash-simple"
              mode="secondary"
              @click="showConfirmDialog(item)"
            />
          </c-tooltip>
        </template>
      </c-data-table>
      <delete-api-key-dialog
        v-model="data.confirmDialog"
        :key-to-delete="data.keyToDelete"
        @load-api-keys="loadApiKeys"
      />
    </template>
  </view-layout>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import type { ApiKeyData } from "@/core/types/apikey.types";
  import coreClient from "@/core/api/core.api";
  import Container from "@/core/components/Container/Container.vue";
  import DeleteApiKeyDialog from "../components/DeleteApiKeyDialog.vue";
  import { encryptKey } from "@/core/utils/encrypt";
  import { formatDate } from "@/core/utils/formatters";
  import { isMobile } from "@/core/utils/mobile";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import ViewLayout from "@/core/layouts/ViewLayout.vue";
  import { analytics, API_KEYS } from "@/core/utils/analytics";
  import { computed, onMounted, reactive, ref } from "vue";
  import { createApiKey, getApiKeys } from "@/core/services/apikeys.service";

  // @ts-ignore
  import { CDataTable } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();
  const clientKey = ref(userStore.userKey);

  const copyApiKey = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey);
    showSnackbar("API key copied.", AlertModes.SUCCESS);
    analytics.sendEvent("api_keys", API_KEYS.actions.COPY_API_KEY);
  };

  const data = reactive({
    showClientKey: false,
    confirmDialog: false,
    keyToDelete: 0,
    isRefreshingList: false,
    isAddingApiKey: false,
    rows: [] as Array<ApiKeyData>,
  });

  const headers = [
    { title: "API key", key: "apiKey" },
    { title: "Actions", key: "actions", align: "left", sortable: false },
    { title: "Creation date", key: "createdAt" },
    { title: "Delete", key: "delete", align: "center", sortable: false },
  ];

  async function loadApiKeys() {
    data.isRefreshingList = true;
    try {
      const rows = await getApiKeys(true);

      data.rows = rows.map((row) => convertRowDateColumns(row));
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.isRefreshingList = false;
    }
  }

  const showConfirmDialog = (item: any) => {

    data.keyToDelete = item.id as number;
    data.confirmDialog = true;
  };

  const createNewApiKey = async () => {
    try {
      data.isAddingApiKey = true;
      addRow(await createApiKey());
      showSnackbar("API key created.", AlertModes.SUCCESS);
      analytics.sendEvent("api_keys", API_KEYS.actions.CREATE_API_KEY);
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.isAddingApiKey = false;
    }
  };

  const addRow = (row: ApiKeyData) => {
    const rowToAdd = convertRowDateColumns(row);

    data.rows = [...data.rows, rowToAdd];
  };

  const convertRowDateColumns = (row: ApiKeyData) => {
    const updatedRow = Object.assign({}, row);

    updatedRow.createdAt = formatDate(row.createdAt);
    updatedRow.encrypt = true;
    return updatedRow;
  };

  const getClientKey = computed(() => clientKey.value
    ? data.showClientKey
      ? clientKey?.value
      : encryptKey(clientKey?.value)
    : ""
  );

  const copyClientKey = () => {
    navigator.clipboard.writeText(clientKey?.value);
    showSnackbar("API key copied", AlertModes.SUCCESS);
    analytics.sendEvent("api_keys", API_KEYS.actions.COPY_CLIENT_KEY);
  };

  onMounted(async () => {
    try {
      await loadApiKeys();
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    }
  });
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.table-icon-button {
  height: 20px !important;
  width: auto !important;
}

.section {
  background-color: rgb(var(--v-theme-aphla-bg)) !important;
  border-radius: 16px;
}

.text-red {
  color: var(--vt-c-red) !important;
}

.client-key {
  overflow: hidden;
  text-overflow: ellipsis;

  &--min-width {
    min-width: 350px;
  }
}

.api-key {
  min-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  &-mobile {
    min-width: 120px;
    width: 120px;
  }

  &-column {
    padding-left: 0;
    width: 200px;
  }

  &-info {
    color: rgb(var(--v-theme-copy-primary)) !important;
  }
}

:deep(.api-key-column) {
  overflow: hidden;
}

:deep(.v-toolbar__content) {
  .flex-grow-1 {
    display: none;
  }

  .v-toolbar-title {
    min-width: min-content;
  }
}

.mobile-margin {
  margin-left: -16px;
}

.actions-wrapper {
  gap: 16px;
}

:deep(.v-table__wrapper) {
  overflow-y: hidden !important;
}
</style>

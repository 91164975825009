<template>
  <div class="align-center d-flex h-100 justify-center pa-10">
    <div
      class="align-center configure-error d-flex flex-column justify-center text-center"
    >
      <div class="align-center d-flex icon-wrapper justify-center">
        <c-icon
          color="rgb(var(--v-theme-copy-primary))"
          height="32"
          icon="ph:warning"
        />
      </div>

      <c-typography
        class="mb-3 mt-6 text-center"
        color-class="copy-primary"
        variant="subtitle-2-600"
      >Oops, something went wrong!</c-typography
      >
      <c-typography
        class="mb-6 text-center"
        color-class="copy-secondary"
        variant="body-2-400"
      >Please try again
      </c-typography>

      <c-button block mode="secondary" @click="tryAgain">Try again</c-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  // @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";
  import { storeToRefs } from "pinia";

  const { videoErrorStatus } = storeToRefs(useVideoCreatorStore());

  const tryAgain = () => {
    videoErrorStatus.value = false;
  };
</script>

<style lang="scss" scoped>
.configure-error {
  width: 300px;
}

.icon-wrapper {
  background: rgb(var(--v-theme-dark-frame));
  border-radius: 26px;
  height: 52px;
  justify-self: end;
  width: 52px;
}
</style>

<template>
  <div class="d-flex flex-column frame pa-3">
    <c-typography color-class="highlight-color" variant="body-2-600">{{
      record ? "Record a Short Video" : "Ensure Optimal Video Quality"
    }}</c-typography>
    <c-typography
      class="pt-1"
      color-class="highlight-color"
      variant="body-2-400"
    >
      {{
        record
          ? "Speak to the camera for 4 to 60 seconds. You can introduce yourself, talk about your business, or read a script. For the best results, make pauses during your speech, ensure good lighting, and avoid covering your face."
          : `For best results, upload a high-resolution video, ${lipsync ? '1-3 minutes' : '4-60 seconds'} in length. Make sure to pause and close your mouth during speech, and avoid covering your face. Good lighting is key.`
      }}
      For more tips see
      <span class="cursor-pointer" @click="openShootingTips">
        <b> Detailed Instructions</b></span
      >.
    </c-typography>
  </div>
  <shooting-tips v-model="shootingTips" />
</template>

<script lang="ts" setup>
  import ShootingTips from "./ShootingTips.vue";
  import { onMounted, ref } from "vue";

  //@ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  import { useUserStore } from "@/core/store/userStore";

  const props = defineProps({
    record: {
      type: Boolean,
      default: false,
    },
    lipsync: {
      type: Boolean,
      default: false,
    },
  });

  const shootingTips = ref(false);
  const userStore = useUserStore();

  const openShootingTips = () => {
    shootingTips.value = true;
  };

  onMounted(() => {
    if (!userStore.ui_local_data.dontShowShootingTips) {
      shootingTips.value = true;
    }
  });
</script>

<style scoped lang="scss">
.frame {
  background-color: rgb(var(--v-theme-button-secondary));
  border: 2px solid rgb(var(--v-theme-button-primary));
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>

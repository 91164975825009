<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <c-dialog
    :cancel-option="false"
    :confirm-disabled="data.images.length < data.minPhotos || !data.name"
    :confirm-loading="data.loading"
    :model-value="modelValue"
    :price="!(data.images.length < data.minPhotos || !data.name) ? photoTwinModelCost : ''"
    variant="primary"
    width="600"
    @cancel="emit('update:modelValue', false)"
    @confirm="confirm"
    @keydown.enter="confirm"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Create new Photo Twin</template>

    <template #content>
      <div class="mb-2 mt-3">
        <c-typography
          :color-class="data.loading ? 'copy-secondary-lock' : 'copy-secondary'"
          variant="body-2-600"
        >Type name</c-typography
        >
      </div>
      <c-input
        v-model="data.name"
        density="compact"
        :disabled="data.loading"
        maxLength="100"
        mode="outlined"
        placeholder="Type Photo Twin name"
      />
      <div class="mb-2 mt-3">
        <c-typography
          class="d-block mb-05"
          :color-class="data.loading ? 'copy-secondary-lock' : 'copy-secondary'"
          variant="body-2-600"
        >Select gender</c-typography
        >
      </div>
      <div class="d-flex gap-12">
        <select-image-card
          v-for="el in data.genderOptions"
          :key="el.title"
          :checked="el.title.toLocaleLowerCase() === data.gender"
          :disabled="data.loading"
          :img="el.img"
          :title="el.title"
          @check-gender="checkGender"
        />
      </div>
      <div class="align-center d-flex justify-space-between mt-3">
        <div style="max-width: 378px">
          <c-typography
            :color-class="
              data.loading ? 'copy-secondary-lock' : 'copy-secondary'
            "
            variant="body-2-600"
          >Upload photos</c-typography
          >
          <c-typography
            class="d-block mt-1"
            :color-class="
              data.loading ? 'copy-secondary-lock' : 'copy-secondary'
            "
            variant="body-3-400"
          >Select
            <b>min. {{ data.minPhotos }} to max. {{ data.maxPhotos }} photos</b>
            (maximum
            <b>100 MB</b> total)</c-typography
          >
        </div>
        <c-button
          :append-icon="
            data.showPhotoGuide ? 'mdi:chevron-up' : 'mdi:chevron-down'
          "
          mode="highlight-plain"
          size="x-small"
          @click="data.showPhotoGuide = !data.showPhotoGuide"
        >Photo guide</c-button
        >
      </div>
      <div
        :class="[
          'd-flex flex-column wrapper',
          { 'wrapper--open': data.showPhotoGuide },
        ]"
      >
        <div
          ref="photoGuideContainer"
          class="slide-content"
          :class="{ 'slide-content--show': data.showPhotoGuide }"
        >
          <c-typography
            class="mt-4"
            color-class="copy-secondary"
            variant="body-3-400"
          >To get the best results, please follow these rules:</c-typography
          >
          <div class="d-flex flex-column mt-2 wrapper">
            <div v-for="el in tips" :key="el.title" class="d-flex flex-column">
              <div class="d-flex mb-1">
                <c-icon
                  class="mr-2"
                  color="rgb(var(--v-theme-universal-green))"
                  icon="ph:check"
                  width="16"
                />
                <c-typography variant="body-2-600">{{ el.title }}</c-typography>
              </div>
              <c-typography color-class="copy-secondary" variant="body-3-400">
                {{ el.desc }}
              </c-typography>
            </div>
          </div>
        </div>
      </div>

      <drag-and-drop-multiple
        v-model:photos="data.images"
        class="mt-4"
        :disabled="data.loading"
        :max-images="data.maxPhotos"
        :min-images="data.minPhotos"
      />
      <c-typography
        class="d-block mt-2"
        color-class="copy-secondary"
        pointer
        variant="body-3-400"
      >
        By submitting to this form I accept Gemelo AI
        <router-link class="link" :to="Routes.PRIVACY_POLICY.path" @click.stop>
          Privacy policy</router-link
        >.
      </c-typography>
    </template>
    <template #confirm-label> Create </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import DragAndDropMultiple from "@/core/components/DragAndDropMultiple.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { createNewPhotoTwinModel } from "@/core/services/photoTwins.service";
  import SelectImageCard from "./SelectImageCard.vue";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "../../../core/store/userStore";
  import { nextTick, reactive, ref, watch } from "vue";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CIcon, CInput, CTypography } from "@charactr/wooper-ui/atoms";
  import { storeToRefs } from "pinia";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const tips = [
    {
      title: "High Quality Pictures",
      desc: "Try to ensure that the photos are of the best possible quality, preferably high definition",
    },
    {
      title: "Number of people",
      desc: "Photos should only show one person",
    },
    {
      title: "Color Preference",
      desc: "Maintain your photo in its natural colors; avoid black & white or sepia tones, and refrain from using intense filters or frames",
    },
  ];

  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();
  const { photoTwinModelCost } = storeToRefs(userStore);

  const data = reactive({
    loading: false,
    name: "",
    minPhotos: 10,
    maxPhotos: 20,
    images: [],
    gender: "",
    showPhotoGuide: false,
    genderOptions: [
      {
        title: "Female",
        img: "/images/synthetics/female.webp",
      },
      {
        title: "Male",
        img: "/images/synthetics/male.webp",
      },
    ],
  });

  const photoGuideContainer = ref<HTMLElement | null>(null);

  const checkGender = (val: string) => {
    data.gender = val.toLocaleLowerCase();
  };

  const base64ToBlob = (base64Data: string, contentType: string) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const confirm = async () => {
    const files = data.images.map((item: { src: string }) => {
      const [header, base64] = item.src.split(",");
      const matchResult = header.match(/:(.*?);/);

      const contentType = matchResult
        ? matchResult[1]
        : "application/octet-stream";
      const base64Data = base64;

      return base64ToBlob(base64Data, contentType);
    });

    data.loading = true;

    try {
      const response = await createNewPhotoTwinModel({
        name: data.name,
        files: files,
        gender: data.gender,
      });

      userStore.addPhotoModel(response);
      emit("update:modelValue", false);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.loading = false;
    }
  };
</script>

<style lang="scss" scoped>
.gap-12 {
  gap: 12px;
}

.wrapper {
  gap: 16px;

  &--open {
    height: auto;
  }
}

.slide-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
}

.slide-content--show {
  max-height: 226px;
}
</style>

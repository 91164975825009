<template>
  <div :class="['d-flex justify-space-between w-100', { 'text-wrapper': isMobile }]">
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-space-between">
        <c-typography data-testid="get-started-dashboard-title" variant="subtitle-1-600">Templates</c-typography>
        <div v-if="showArrows">
          <c-button
            data-testid="home_go_prev"
            height="24"
            icon="ph:arrow-left"
            icon-color="rgb(var(--v-theme-copy-secondary))"
            icon-height="24"
            plain
            width="24"
            @click="goPrev"
          ></c-button>
          <c-button
            class="ml-5"
            data-testid="home_go_next"
            height="24"
            icon="ph:arrow-right"
            icon-color="rgb(var(--v-theme-copy-secondary))"
            icon-height="24"
            plain
            width="24"
            @click="goNext"
          ></c-button>
        </div>
      </div>
      <c-typography
        class="mt-1"
        color-class="copy-secondary"
        variant="body-2-400"
      >Check out our ready-made AI Twins
      </c-typography>
    </div>
  </div>
  <div :class="['d-flex flex-wrap pt-3', { 'title--mobile': isMobile }]">
    <v-slide-group ref="slideGroup" :show-arrows="false">
      <v-slide-group-item
        v-for="titleFeature in features.filter((el) => !el.hidden)"
        :key="titleFeature.name"
      >
        <template-card
          :feature="titleFeature"
        />
      </v-slide-group-item>
    </v-slide-group>
  </div>

</template>

  <script lang="ts" setup>
  import TemplateCard from "./cards/TemplateCard.vue";

  import { isMobile } from "@/core/utils/mobile";
  import { computed, type PropType, ref } from "vue";

  import type { TitleFeature } from "@/core/types/other.types";

  //@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  import { useMutationObserver } from "@vueuse/core";

  const props = defineProps({
    features: {
      type: Array as PropType<Array<TitleFeature>>,
      default: () => [],
    },
  });

  const slideGroup = ref();
  const showSliderBtns = ref(false);

  const showArrows = computed(() => isMobile.value || showSliderBtns.value);

  const goNext = () => {
    slideGroup.value.scrollTo("last");
  };

  const goPrev = () => {
    slideGroup.value.scrollTo("prev");
  };

  useMutationObserver(
    slideGroup,
    (mutations) => {
      if (mutations[0]) {
        const isClassMutation = mutations[0].attributeName === "class";

        if (isClassMutation) {
          const classList = (mutations[0].target as HTMLElement).classList;

          showSliderBtns.value = Array.from(classList).includes(
            "v-slide-group--is-overflowing"
          );

          //in case when screen was resized and scroll buttons dissaperared, then scroll to first element
          if (!showSliderBtns.value) {
            slideGroup.value.scrollTo("first");
          }
        }
      }
    },
    {
      attributes: true,
    }
  );
  </script>

  <style lang="scss" scoped>
  :deep(.v-slide-group__content) {
    gap: 16px;
    margin: 2px;
  }
  </style>

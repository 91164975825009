import axios from "axios";
import router from "@/router";
import { Routes } from "@/core/routes/core.guard";
import { useStore } from "@/core/store";
import { useUserStore } from "../store/userStore";
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import jwt_decode, { type JwtPayload } from "jwt-decode";
import coreClient from "@/core/api/core.api";
import {
  CLIENT_ID,
  REFRESH_TOKEN,
  TERMS_CONSENT,
  TOKEN,
} from "../types/login.types";
import { useDialogsStore } from "../store/useDialogsStore";
import { toRefs } from "vue";
import { apiUrl, apiV2Url, apiV3Url } from "../utils/api";

export type ErrorData = {
  text: string;
  message: string;
  code: number;
};

interface ExtendedJwtPayload extends JwtPayload {
  sid: string;
}

const client = axios.create();
const refreshTokenClientInstance = axios.create();

export const isLogged = (): boolean => {
  const clientId = tokenClientId();

  return (
    localStorage.getItem(TOKEN) !== null &&
    localStorage.getItem(`${TERMS_CONSENT}_${clientId}`) === "true"
  );
};

export const tokenClientId = (): number => {
  const clientId = localStorage.getItem(CLIENT_ID);

  if (clientId !== null) {
    return Number(clientId);
  }
  return 0;
};

export const sessionId = (): number => {
  const token = localStorage.getItem(TOKEN);

  if (token !== null) {
    const decoded = jwt_decode<ExtendedJwtPayload>(token);

    return Number(decoded.sid);
  }
  return 0;
};

export const isTokenExpired = (localStorageKey: string): boolean => {
  try {
    const apiToken = localStorage.getItem(localStorageKey);

    if (!apiToken) {
      return true;
    }

    return (
      JSON.parse(atob(apiToken.split(".")[1])).exp * 1000 < new Date().getTime()
    );
  } catch (e) {
    return true;
  }
};

const isSlowpokeApiRequest = (url: string) => {
  return url.startsWith(apiUrl("")) || url.startsWith(apiV2Url("")) || url.startsWith(apiV3Url(""));
};

const refreshApiToken = async (): Promise<string> => {
  const { logout: storeLogout } = useStore();
  const userStore = useUserStore();

  try {
    const newToken = await refreshTokenClientInstance.post(
      apiV2Url("auth/refresh"),
      {
        refreshToken: localStorage.getItem(REFRESH_TOKEN),
      }
    );

    userStore.setJwtSession(newToken.data);
    updateUserData(newToken.data.token);

    return newToken.data.token;
  } catch (error) {
    console.error("Error refreshing token:", error);

    storeLogout();
    coreClient.abort();
    router.push({ name: Routes.LOGIN.name });

    throw new Error("Failed to refresh token");
  }
};

const updateUserData = async (token: string): Promise<void> => {
  const userStore = useUserStore();
  const clientId = tokenClientId();

  const url = apiV2Url("auth/data");

  console.log(localStorage.getItem("TOKEN"));
    const response = await coreClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    userStore.setUserFeatures(response.data, clientId);

};

let refreshPromise: Promise<string> | null = null;

const mixpanelInterceptor = async (request: AxiosRequestConfig) => {
  if (!isSlowpokeApiRequest(String(request.url))) {
    return request;
  }

  if (request.headers === undefined) {
    request.headers = {};
  }

  request.headers["X-Gemelo-UI"] = "true";

  return request;
};

const authInterceptor = async (request: AxiosRequestConfig) => {
  if (!isSlowpokeApiRequest(String(request.url))) {
    return request;
  }

  if (request.headers === undefined) {
    request.headers = {};
  }

  if (isLogged() && isTokenExpired(TOKEN)) {
    // if there are multiple auth requests on a page, they will all wait for the first `refreshPromise` to resolve,
    // so we don't duplicate refresh requests
    if (refreshPromise) {
      await refreshPromise;
    } else {
      try {
        refreshPromise = refreshApiToken();
        await refreshPromise;
        refreshPromise = null;
      } catch (e) {
        console.error(e);
      }
    }
  }

  if (localStorage.getItem(TOKEN)) {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(TOKEN)}`;
  }

  return request;
};

let abortController = new AbortController();

const abortInterceptor = (request: AxiosRequestConfig) => {
  if (!isSlowpokeApiRequest(String(request.url))) {
    return request;
  }

  request.signal = abortController.signal;

  return request;
};

client.interceptors.request.use(mixpanelInterceptor);
client.interceptors.request.use(authInterceptor);
client.interceptors.request.use(abortInterceptor);
client.interceptors.response.use(
  (r) => r,
  async (err) => {
    if (err.code === "ERR_CANCELED" && err.name === "CanceledError") {
      return {
        data: {},
        message: "",
      };
    }
    if (!isSlowpokeApiRequest(err.config.url)) {
      return Promise.reject(err);
    }

    // don't intercept login request
    if (err.request.responseURL.endsWith("auth/login")) {
      return Promise.reject(err);
    }

    if (err.request.responseURL.endsWith("auth/register")) {
      return Promise.reject(err);
    }

    if (import.meta.env.DEV) {
      console.error(err);
    }

    const { logout: storeLogout } = useStore();
    const { newTermsDialog, notEnoughTokensDialog } = toRefs(useDialogsStore());

    if (err.response?.status === 403 && err.response.data.consentError) {
      newTermsDialog.value = true;
    }

    if (err.response?.status === 402) {
      notEnoughTokensDialog.value = true;
    }

    // should never happen in normal circumstances
    if (err.response?.status === 401) {
      console.log("inside 401 condition logout");
      storeLogout();
      await router.push({ name: Routes.LOGIN.name });

      return Promise.reject(err);
    }

    return Promise.reject(err);
  }
);

interface CustomAxiosInstance extends AxiosInstance {
  abort(): void;
}

(client as CustomAxiosInstance).abort = () => {
  if (abortController) {
    abortController.abort();

    setTimeout(() => {
      abortController = new AbortController();
    }, 0);
  }
};

export default client as CustomAxiosInstance;

<template>
  <c-dialog
    cancel-option
    class="scrollable"
    :close-option="false"
    :model-value="modelValue"
    persistent
    :width="isMobile ? '100%' : 580"
    @cancel="decline"
    @confirm="accept"
    @keydown.enter="accept"
  >
    <template #title>Terms and Conditions</template>
    <template #content>
      <terms-content />
    </template>
    <template #confirm-label> Yes, I agree </template>
    <template #cancel-label> No, decline</template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { acceptNewTerms } from "@/core/api/slowpoke.auth.api";
  import { AlertModes } from "@/core/types/other.types";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import TermsContent from "../TermsContent.vue";
  import { useRouter } from "vue-router";

  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useStore } from "@/core/store";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  import { toRefs } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
  });

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const { logout: storeLogout } = useStore();
  const dialogsStore= useDialogsStore();
  const { newTermsDialog } = toRefs(dialogsStore);
  const { newTermsAccepted } = dialogsStore;

  //ROUTER
  const router = useRouter();

  const accept = async () => {
    try {
      await acceptNewTerms();
      showSnackbar(
        "New terms and conditions accepted.",
        AlertModes.SUCCESS
      );
      newTermsAccepted();
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    }
  };

  const decline = async () => {
    console.log("inside decline new terms dialog");
    storeLogout();
    router.push({ name: Routes.LOGIN.name });
    newTermsDialog.value = false;
  };
</script>

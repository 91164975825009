import ModelPage from "../components/ModelPage.vue";
import ModelsGallery from "../components/ModelsGallery.vue";
import PhotoTwinStudio from "../pages/PhotoTwinStudio.vue";
import PromptGallery from "../components/PromptGallery.vue";
import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.PHOTO_TWIN_STUDIO.name,
    path: Routes.PHOTO_TWIN_STUDIO.path,
    component: PhotoTwinStudio,
    meta: { requiresAuth: true },
    redirect: { name: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name },
    children: [
      {
        name: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
        path: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.path,
        component: ModelsGallery,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
      },
      {
        name: Routes.PHOTO_TWIN_STUDIO.children.PROMPT_GALLERY.name,
        path: Routes.PHOTO_TWIN_STUDIO.children.PROMPT_GALLERY.path,
        component: PromptGallery,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
          lighterBg: true,
        },
      },
      {
        name: Routes.PHOTO_TWIN_STUDIO.children.MODEL.name,
        path: Routes.PHOTO_TWIN_STUDIO.children.MODEL.path,
        component: ModelPage,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
          lighterBg: true,
        },
      },
    ],
  },
];

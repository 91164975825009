import type { CharactrAPISDK } from "@charactr/api-sdk";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useSdkStore = defineStore("sdkStore", () => {
  const noApiKey = ref(false);
  const sdk = ref({} as CharactrAPISDK);
  const sdkInitialized = ref(false);

  const $reset = () => {
    sdk.value = {} as CharactrAPISDK;
    sdkInitialized.value = false;
  };

  return {
    noApiKey,
    sdk,
    sdkInitialized,

    $reset,
  };
});

export const TOKEN = "slowpoke-web-panel-token";
export const REFRESH_TOKEN = "slowpoke-web-panel-refresh-token";
export const TERMS_CONSENT = "slowpoke-web-panel-terms-consent";
export const CLIENT_ID = "slowpoke-web-panel-client-id";

  export class LoginResponse {
    client: Client;

    token: string;

    refreshToken: string;

    mainConsentValid: boolean;

    constructor(obj: LoginResponse) {
      this.client = obj.client;
      this.token = obj.token;
      this.refreshToken = obj.refreshToken;
      this.mainConsentValid = obj.mainConsentValid;
    }
  }

  export class Client {
    id: number;

    email: string;

    avatarUrl?: string;

    key: string;

    featureFlags: string[];

    subscriptionTier?: string;

    constructor(obj: Client) {
      this.id = obj.id;
      this.email = obj.email;
      this.avatarUrl = obj.avatarUrl;
      this.key = obj.key;
      this.featureFlags = obj.featureFlags;
      this.subscriptionTier = obj.subscriptionTier;
    }
  }

<template>
  <media-selector
    :confirm-disabled="!selectedVoiceId"
    :model-value="modelValue"
    @cancel="cancelVoiceSelection"
    @select="confirmVoiceSelection"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <voices
      :mode="mode"
      :selected-voice-id="selectedVoiceId"
      @check-voice="voiceSelectionChanged"
    />
  </media-selector>
</template>

<script setup lang="ts">
  import MediaSelector from "./MediaSelector.vue";
  import type { PlaygroundFeature } from "@/core/data/playgroundFeatures";
  import { storeToRefs } from "pinia";
  import { updateSyntheticVoice } from "@/core/services/synthetics.service";
  import { updateLipsyncVoice } from "@/core/services/lipsync.service";
  import { useRouter } from "vue-router";
  import { useUserStore } from "../../store/userStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import type { Voice } from "@/core/types/voice.types";
  import Voices from "@/core/components/Voice/Voices.vue";
  import { computed, type PropType, reactive, toRefs } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: "",
    },
  });

  const emit = defineEmits(["update:modelValue"]);
  const userVoices = useVoicesStore();
  const userStore = useUserStore();
  const { listOfSyntheticModels, listOfGalleryPictures } = storeToRefs(userStore);
  const { updateVoice } = userVoices;
  const { userSelectedVoice } = toRefs(userVoices);

  const router = useRouter();

  const data = reactive({
    newSelectedVoice: null as Voice | null,
  });

  const routePath = computed(() => {
    return router.currentRoute.value.path;
  });

  const twinId = computed(() => router.currentRoute.value.params.id);

  const selectedVoiceId = computed(() => {
    if (data.newSelectedVoice) {
      return data.newSelectedVoice.id;
    }
    const variantSelectedVoice = userSelectedVoice.value[props.mode];

    return variantSelectedVoice ? variantSelectedVoice.id : 0;
  });

  const voiceSelectionChanged = (voice: Voice) => {
    data.newSelectedVoice = voice;
  };

  const cancelVoiceSelection = () => {
    data.newSelectedVoice = null;
  };

  const confirmVoiceSelection = () => {
    const isTwinPage =
      routePath.value.includes("twin-studio") &&
      routePath.value.includes("video-creator");

    const isSynthPage = routePath.value.includes("portrait");

    if (isTwinPage) {
      const el = !isSynthPage
        ? listOfSyntheticModels.value.find(
          (el) => String(el.id) === String(twinId.value)
        )
        : listOfGalleryPictures.value.find(
          (el) => String(el.id) === String(twinId.value)
        );

      if (!el?.isBuiltin) {
        if (isSynthPage) {
          updateSyntheticVoice({
            lipsyncId: Number(twinId.value),
            cloned: Boolean(data.newSelectedVoice?.createdAt),
            voiceId: data.newSelectedVoice?.id as string | number,
          });
        } else {
          updateLipsyncVoice({
            lipsyncId: Number(twinId.value),
            cloned: Boolean(data.newSelectedVoice?.createdAt),
            voiceId: data.newSelectedVoice?.id as string | number,
          });
        }

      }
    }

    if (data.newSelectedVoice) {
      updateVoice(data.newSelectedVoice, props.mode);
    }

    emit("update:modelValue", false);
  };
</script>

<style lang="scss" scoped>
.cloning-container {
  background: rgb(var(--v-theme-player-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-bottom: none !important;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
}
</style>

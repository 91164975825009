<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <c-dialog
    :model-value="modelValue"
    variant="secondary"
    :width="isMobile ? 368 : 847"
    @confirm="emit('update:modelValue', false)"
    @keydown.enter="emit('update:modelValue', false)"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #confirm-label>OK</template>
    <template #title> Video Guide </template>
    <template #content>
      <div class="d-flex my-2">
        <c-icon
          class="mr-1"
          color="rgb(var(--v-theme-universal-green))"
          icon="ph:check-circle-fill"
          width="16"
        />
        <c-typography variant="body-3-600"> Good examples</c-typography>
      </div>

      <div
        :class="[
          'dialog-content d-flex',
          { 'dialog-content--mobile': isMobile },
        ]"
      >
        <div class="d-flex flex-column wrapper-photos">
          <div
            :class="[
              'd-flex img-wrapper justify-center',
              isMobile ? 'flex-row' : 'flex-column',
            ]"
          >
            <img
              v-for="el in videoGuide.photos"
              :key="el"
              :class="['img', { 'img--mobile': isMobile }]"
              :src="el"
            />
          </div>
        </div>
        <div class="d-flex flex-column wrapper">
          <div v-for="el in tips" :key="el.title" class="d-flex flex-column">
            <div class="d-flex mb-1">
              <c-icon
                class="mr-2"
                color="rgb(var(--v-theme-universal-green))"
                icon="ph:check"
                width="16"
              /><c-typography variant="body-2-600">{{ el.title }}</c-typography>
            </div>
            <c-typography color-class="copy-secondary" variant="body-3-400">{{
              el.desc
            }}</c-typography>
          </div>
        </div>
      </div>
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const videoGuide = {
    title: "Good examples",
    icon: "ph:check-circle-fill",
    iconColor: "rgb(var(text--green))",
    photos: ["/images/vtv/vtv_guide1.png", "/images/vtv/vtv_guide2.png"],
  };

  const tips = [
    {
      title: "Proper Head Position",
      desc: "Keep your head upright and facing forward as if you’re taking a professional portrait",
    },
    {
      title: "Minimal Head Movement",
      desc: "Limit head movement when recording, and aim for a straight-on view",
    },
    {
      title: "Facial Expressions",
      desc: "Your natural expressions are key — we’ll capture nuances like smiles and blinks",
    },
    {
      title: "Eye Contact",
      desc: "Gaze directly into the camera lens (you may also use eye-contact tracking tools)",
    },
    {
      title: "Good Lighting",
      desc: "Bright and even lighting is crucial. Use front or side lighting to illuminate your face without harsh shadows",
    },
    {
      title: "Background",
      desc: "Please choose a plain or soft-focus background to ensure you stand out",
    },
    {
      title: "Video Aspect Ratio",
      desc: "Record in a horizontal or square format for the best display across devices",
    },
  ];
</script>

<style lang="scss" scoped>
.outlined {
  border: 1px solid rgb(var(--v-theme-light-frame)) !important;
}

:deep(.v-card-actions) {
  color: rgb(var(--v-theme-copy-secondary)) !important;
  min-height: 20px !important;
}

:deep(.v-card-text) {
  line-height: 18px !important;
}

.img-wrapper {
  gap: 16px;
}

.img {
  border-radius: 8px;
  box-sizing: border-box;
  height: 202px;
  width: 320px;

  &--mobile {
    height: 100px;
    width: 150px;
  }
}

.wrapper {
  gap: 16px;

  &-photos {
    gap: 16px;
  }
}

.dialog-content {
  gap: 16px;

  &--mobile {
    flex-flow: column;
  }
}
</style>

import { REFRESH_TOKEN } from "../types/login.types";
import { apiUrl, apiV2Url } from "@/core/utils/api";
import coreClient, { sessionId, tokenClientId } from "@/core/api/core.api";

export async function logout(): Promise<void> {
  const token = localStorage.getItem(REFRESH_TOKEN);

  if (token) {
    const sid = sessionId();
    const url = apiV2Url("auth/logout");

    const response = await coreClient.post(url, {
      sessionID: sid,
    });

    return response.data;
  } else return;
}

export async function logoutAll(password: string): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/logout/all`);

  const response = await coreClient.post(url, {
    password: password,
  });

  return response.data;
}

import RecordVideo from "../components/RecordVideo.vue";
import type { RouteLocationNormalized } from "vue-router";
import { Routes } from "@/core/routes/core.guard";
import TwinVideoCreator from "../pages/TwinVideoCreator.vue";
import UploadVideo from "../components/UploadVideo.vue";
import VideoSubmitted from "../components/VideoSubmitted.vue";

export default [
  {
    name: Routes.VIDEO_TWIN_CREATOR.name,
    path: Routes.VIDEO_TWIN_CREATOR.path,
    redirect: (): any => {
      return {
        name: Routes.VIDEO_TWIN_CREATOR.children.UPLOAD.name,
        params: { type: "zero-shot" },
      };
    },
    component: TwinVideoCreator,
    meta: { requiresAuth: true },
    children: [
      {
        name: Routes.VIDEO_TWIN_CREATOR.children.UPLOAD.name,
        path: Routes.VIDEO_TWIN_CREATOR.children.UPLOAD.path,
        component: UploadVideo,
        meta: { requiresAuth: true },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: (to?: RouteLocationNormalized) => void
        ): void => {
          if (!to.params.type) {
            next({ ...to, params: { ...to.params, type: "zero-shot" } });
          } else {
            next();
          }
        },
      },
      {
        name: Routes.VIDEO_TWIN_CREATOR.children.RECORD.name,
        path: Routes.VIDEO_TWIN_CREATOR.children.RECORD.path,
        component: RecordVideo,
        meta: { requiresAuth: true },
      },
      {
        name: Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.name,
        path: Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.path,
        component: VideoSubmitted,
        meta: { requiresAuth: true },
      },
    ],
  },
];

<template>
  <div class="align-center grid ma-0 pa-0 w-100">
    <div class="align-center d-flex justify-center" @click="clickBackBtn">
      <c-button
        class="d-flex"
        color="rgba(var(--v-theme-copy-primary))"
        height="20"
        icon="ph:caret-left"
      />
    </div>

    <div class="align-center d-flex justify-center w-100">
      <c-typography pointer variant="subtitle-2-600">
        {{ PLAYGROUND_FEATURES.VIDEO_CREATOR.label }}
      </c-typography>

      <c-button
        class="ma-0 ml-2 pa-0"
        height="24"
        icon="ph:info"
        icon-height="24"
        plain
        size="x-small"
        width="24"
        @click="openInfoModal"
      />
    </div>
  </div>
  <c-typography
    class="d-block pt-4"
    color-class="copy-primary"
    variant="body-2-600"
  >Set up twin and choose your way</c-typography
  >
  <c-typography
    class="d-block pt-1"
    color-class="copy-secondary"
    variant="body-3-400"
  >Select a visual look and match perfect voice</c-typography
  >
  <c-dialog
    v-model="infoModal"
    variant="secondary"
    width="510"
    @confirm="startGuideTour"
    @keydown.enter="startGuideTour"
  >
    <template #title>
      {{ PLAYGROUND_FEATURES.VIDEO_CREATOR.dialog.title }}
    </template>
    <template #content>
      <c-typography color-class="copy-secondary" variant="body-3-400">
        {{ PLAYGROUND_FEATURES.VIDEO_CREATOR.dialog.description }}
      </c-typography>
    </template>
    <template #confirm-label>Start Guide Tour</template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { ref, toRefs } from "vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useRouter } from "vue-router";

  //@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  //@ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  //STORE
  const dialogStore = useDialogsStore();
  const { videoCreatorDesktopGuide } = toRefs(dialogStore);

  const router = useRouter();

  const infoModal = ref(false);

  const clickBackBtn = () => {
    router.go(-1);
  };

  const openInfoModal = () => {
    infoModal.value = true;
  };

  const startGuideTour = () => {
    if (router.currentRoute.value.name !== Routes.VIDEO_CREATOR.name) {
      router.push({ name: Routes.VIDEO_CREATOR.name });
    }
    infoModal.value = false;
    videoCreatorDesktopGuide.value = true;
  };
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 20px auto;
}
</style>

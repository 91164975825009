<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <wrapper
    ref="wrapperRef"
    :mobile="isMobile"
    :variant="PLAYGROUND_FEATURES.TTS"
  >
    <template #streaming>
      <div
        v-if="noApiKey"
        :class="['text-center w-100 py-2', { 'px-4': isMobile }]"
      >
        <c-typography color-class="copy-secondary" variant="body-2-400">
          To fully utilize our TTS Streaming feature, an active API key is
          required. Don't worry, we've got you covered! <br />
          Click the button below, and we'll generate a personalized API key for
          you.
        </c-typography>
        <c-button class="mt-4 w-100" size="large" @click="streaming.createApiKeyWithSDKInit"
        >Create API key
        </c-button>
      </div>
    </template>
    <template #playground>
      <div :class="['pt-2 w-100', { 'px-4': isMobile }]">
        <c-textarea
          v-model="data.userText"
          :disabled="isConverting || noApiKey || isRecordingsLimitReached"
          :full-height="isMobile"
          :height="isMobile ? '5000px' : textAreaHeight"
          :loading="isConverting"
          :max="userStore.ttsCharactersLimit"
          :max-height="maxTextAreaHeight"
          mode="outlined"
          placeholder="Type your text"
          :show-action-c-button="true"
          test-id="text-to-speech"
        />
      </div>
      <audio-limit-dialog v-model="data.limitDialog" />
    </template>
    <template #action>
      <c-tooltip
        action="Upgrade"
        class="w-100"
        content="You've reached the limit of audio recordings available to free accounts. Upgrade now to expand your audio library and enjoy unlimited text-to-speech conversions."
        manual
        placement="top"
        :show="userStore.allLimitsDataLoaded && isRecordingsLimitReached"
        title="Audio Text-to-Speech Limit Reached"
        width="300"
        @handle-action-click="handleActionClick"
      >
        <c-button
          block
          class="mt-4"
          :disabled="
            !data.userText.length ||
              !userSelectedVoice[PLAYGROUND_FEATURES.TTS.name] ||
              noApiKey ||
              isRecordingsLimitReached ||
              data.userText.length > userStore.ttsCharactersLimit
          "
          :loading="isConverting"
          :price="( !data.userText.length ||
            !userSelectedVoice[PLAYGROUND_FEATURES.TTS.name] ||
            noApiKey ||
            isRecordingsLimitReached ||
            data.userText.length > userStore.ttsCharactersLimit) ? '' : Math.ceil((data.userText.length / 450) * 2)"
          size="large"
          @click="convertTextToRecording"
        >Convert Now</c-button
        >
      </c-tooltip>
    </template>
  </wrapper>
</template>

<script setup lang="ts">
  import AudioLimitDialog from "../components/dialogs/AudioLimitDialog.vue";
  import { getRecordings } from "@/core/services/playground.service";
  import { isWeb } from "@/core/utils/capacitor";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import useStreaming from "../composables/useStreaming";
  import { VoiceType } from "@/core/types/voice.types";
  import Wrapper from "@/core/components/Wrapper/Wrapper.vue";

  import { AlertModes, RightPanelTabs } from "@/core/types/other.types";
  import { analytics, TTS } from "@/core/utils/analytics";
  import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from "vue";
  import { isIOS, isMobile } from "@/core/utils/mobile";

  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useMobileRecentRecordingStore } from "@/core/store/useMobileRecentRecordingStore";
  import { useUserStore } from "@/core/store/userStore";

  // @ts-ignore
  import { CButton, CTextarea, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSdkStore } from "@/core/store/useSdkStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab, closeRightDrawerTab } = appDrawersState;
  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);
  const streaming = useStreaming();
  const router = useRouter();
  const progressStore = useProgressStore();
  const { isConverting } = toRefs(progressStore);
  const sdkStore = useSdkStore();
  const { noApiKey } = toRefs(sdkStore);

  const mobileRecentRecording = useMobileRecentRecordingStore();
  const { mobileRecentAudioRecording } = toRefs(mobileRecentRecording);
  const { $reset } = mobileRecentRecording;

  const data = reactive({
    limitDialog: false,
    userText: "",
  });

  onBeforeMount(async () => {
    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.RECORDINGS);
    }

    if (!drawerStore.vcRecordings.length) {
      await drawerStore.loadRecordings(PLAYGROUND_FEATURES.STS.name);
    }

  });

  onMounted(() => {
    $reset();
    if (!userStore.ttsCharactersMaxLength) {
      userStore.getSubscriptionLimits();
    }
  });
  const wrapperRef = ref();

  const textAreaHeight = computed(() => {
    return isMobile.value ? window.innerHeight + "px" : 180 + "px";
  });

  const isRecordingsLimitReached = computed(() => {

    if (userStore.isUnlimitedAudioAccess) return false;

    const totalRecordings = drawerStore.ttsRecordings.length + drawerStore.vcRecordings.length;

    return totalRecordings >= userStore.audioRecordingsLimit;
  });

  const maxTextAreaHeight = computed(() => {
    const mobileWrapper = wrapperRef.value?.childRef;

    if (!isMobile.value || !mobileWrapper) {
      return "calc(100vh - 350px)";
    }

    const { headerHeight, bottomPlayerHeight } = mobileWrapper;

    const minusMobile = isMobile.value ? 61 : 0;
    const minusIOS = isIOS.value ? 60 : 0;

    //61 is app toolbar height
    //16 is extra padding for textarea (with characters count)
    return `calc(100vh - ${bottomPlayerHeight}px - 61px - 16px - ${minusMobile}px - ${minusIOS}px - ${headerHeight}px)`;
  });

  const convertTextToRecording = async () => {
    if (data.userText.length > userStore.ttsCharactersMaxLength && !userStore.subscriptionOption) {
      data.limitDialog = true;
      return;
    }

    try {
      const ttsVoice = userSelectedVoice.value[PLAYGROUND_FEATURES.TTS.name];

      if (data.userText.length && ttsVoice) {
        isConverting.value = true;

        analytics.sendEvent("tts", TTS.actions.START);

        await streaming.convertStream(
          Number(ttsVoice.id),
          ttsVoice.createdAt
            ? VoiceType.CLONED
            : VoiceType.SYSTEM,
          data.userText,
          "wav",
          true
        );

        drawerStore.prependAudio(PLAYGROUND_FEATURES.TTS.name);

        if (isMobile.value || !isWeb()) {

          const recentRecordings = await getRecordings(PLAYGROUND_FEATURES.TTS.name, 1);
          if (recentRecordings.length > 0 && recentRecordings[0])
            mobileRecentAudioRecording.value = recentRecordings[0];
        } else {
          showSnackbar("File added to the Recordings", AlertModes.SUCCESS);
        }

        userStore.updateBallance();
        analytics.sendEvent("tts", TTS.actions.SUCCESS);
      }
    } catch (e: any) {
      if (e.response?.status === 402) {
        showSnackbar(
          "Your account balance is too small to use Text To Speech",
          AlertModes.ERROR
        );
        analytics.sendEvent("tts", TTS.actions.NO_BALANCE_ERROR);
        return;
      }
      if (e.message) {
        //sdk error pattern Error(`${response.status}: ${response.statusText}`);
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [status, statusText] = e.message.split(":");

        analytics.sendEvent("tts", TTS.actions.ERROR);

        if (statusText) {
          const trimmedStatusText = statusText.trim();

          const capitalizedStatusText =
            trimmedStatusText.charAt(0).toUpperCase() +
            trimmedStatusText.slice(1);

          showSnackbar(capitalizedStatusText, AlertModes.ERROR);
        } else {
          showSnackbar(e, AlertModes.ERROR);
        }
      } else {
        showSnackbar(e, AlertModes.ERROR);
      }
    } finally {
      isConverting.value = false;
    }
  };

  const handleActionClick = () => {
    router.push({ name: Routes.SHOP.name });
  };

</script>

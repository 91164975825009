<template>
  <div>
    <c-typography
      class="d-block"
      color-class="copy-secondary"
      variant="body-2-500"
    >Twin name*</c-typography
    >
    <c-input
      v-model="name"
      class="mb-4 mt-2"
      density="compact"
      :disabled="isSubmittingVideo"
      :max-length="75"
      mode="outlined"
      placeholder="Type twin name"
    />
  </div>
  <div id="recording-info">
    <highlighted-info-panel record/>
  </div>
  <div
    id="recording-panel"
    :class="[
      'align-center d-flex justify-center mt-3 recording-panel',
      { 'recording-panel--min-height flex-column': !hasPermissions },
      { 'recording-panel--mobile': !hasPermissions && isMobile },
    ]"
  >
    <div
      v-if="!hasPermissions"
      class="align-center d-flex icon-wrapper justify-center mb-4"
    >
      <c-icon
        color="rgb(var(--v-theme-highlight-color))"
        height="32"
        icon="ph:camera-light"
      ></c-icon>
    </div>
    <c-typography
      v-if="!hasPermissions"
      class="d-block text-center"
      color-class="copy-secondary"
      :style="{ maxWidth: '360px' }"
      variant="body-2-500"
    >
      Your browser may ask for permission to use camera and microphone
    </c-typography>
    <c-tag
      v-if="(hasPermissions && !recordedBlobUrl) || isRecording"
      class="tag"
      icon="ph:record-fill"
      mode="camera"
    >
      {{ isRecording ? "REC" : "PREVIEW" }}
    </c-tag>
    <video
      v-show="!hasRecordedVideo && hasPermissions"
      ref="videoElement"
      autoplay
      muted
      playsinline
    ></video>
    <div
      v-if="recordedBlobUrl"
      id="video-flex-container"
      ref="videoContainer"
      class="align-center d-flex flex-grow-1 justify-center video-flex-container"
    >
      <circle-loader
        v-if="!isVideoLoaded"
        :size="60"
        title="Loading video data..."
      />
      <div
        :class="[
          'video-box ',
          {
            'd-none': !isVideoLoaded,
            'video-box--fullscreen': isFullscreen,
          },
        ]"
      >
        <video-js-player
          ref="videoPlayer"
          :hide-background="true"
          :options="{
            controls: true,
            preload: `auto`,
            fill: true,
            sources: [
              {
                src: recordedBlobUrl,
                type: 'video/mp4',
              },
            ],
          }"
          @loaded="videoLoaded"
        >
        </video-js-player>
      </div>
    </div>
  </div>

  <c-progress-linear
    v-if="isRecording"
    v-model="progress"
    bg-color="button-secondary"
    class="mt-3 recording-progress-bar"
    :color="!showSaveButton ? 'alert-red' : 'button-primary'"
    height="24"
  >
    <template #default="{ value }">
      <c-typography color-class="highlight-color" variant="body-2-600"
      >{{ Math.ceil(value) }}%</c-typography
      >
    </template>
  </c-progress-linear>

  <div
    v-if="hasPermissions && !hasRecordedVideo && !isRecording"
    class="device-select mt-3"
  >
    <c-dropdown
      v-model="selectedCameraTitle"
      :clearable="false"
      density="compact"
      :items="cameraOptions.map((el) => el.title)"
      label="Choose Camera"
      :mobile="isMobile"
      mode="outlined"
      prepend-inner-icon="ph:video-camera"
    />

    <c-dropdown
      v-model="selectedMicrophoneTitle"
      :clearable="false"
      density="compact"
      :items="microphoneOptions.map((el) => el.title)"
      label="Choose Microphone"
      :mobile="isMobile"
      mode="outlined"
      prepend-inner-icon="ph:microphone"
    />
  </div>
  <playground-consent-checkbox
    v-if="recordedBlobUrl"
    v-model="playgroundStore.consent.twinCreation"
    class="mt-2"
  />
  <div v-if="recordedBlobUrl" class="d-flex gap-8 justify-space-between mt-4">
    <c-button
      class="flex-grow-1"
      :disabled="isSubmittingVideo"
      mode="secondary"
      @click="recordAgain"
    >Record Again</c-button
    >
    <c-button
      class="flex-grow-1"
      :disabled="!playgroundStore.consent.twinCreation || !name.length"
      :loading="isSubmittingVideo"
      @click="submitVideo"
    >Submit Video</c-button
    >
  </div>
  <c-button
    v-else-if="!isRecording"
    block
    class="mt-3"
    mode="secondary"
    @click="toggleRecording"
  >{{ buttonLabel }}</c-button
  >
  <div v-else class="d-flex gap-16 mt-3">
    <c-button
      class="flex-grow-1"
      error
      warning
      @click="cancelRecording"
    >
      Cancel
    </c-button>
    <c-button
      v-if="showSaveButton && isRecording"
      class="flex-grow-1"
      mode="secondary"
      @click="stopRecording"
    >
      Save
    </c-button>
  </div>
</template>

<script lang="ts" setup>
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import HighlightedInfoPanel from "./HighlightedInfoPanel.vue";
  import { isMobile } from "@/core/utils/mobile";
  import PlaygroundConsentCheckbox from "@/modules/playground/components/PlaygroundConsentCheckbox.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { submitZeroShotVideo } from "../services/videoTwinCreator.service";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useRouter } from "vue-router";
  import { useUserStore } from "@/core/store/userStore";
  import useVideoResize from "@/modules/share/composables/useVideoResize";
  import VideoJsPlayer from "@/core/components/RightDrawer/components/Players/VideoJsPlayer.vue";
  import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
  import { useElementSize, useFullscreen, useWindowSize } from "@vueuse/core";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CDropdown, CIcon, CInput, CProgressLinear, CTag, CTypography } from "@charactr/wooper-ui/atoms";

  let recordingInterval: any = null;
  const recordingDuration = 60;
  const time = ref(0);
  const router = useRouter();
  const userStore = useUserStore();
  const playgroundStore = usePlaygroundStore();

  const progress = computed(() => {
    return (time.value / recordingDuration) * 100;
  });

  const videoElement = ref<HTMLVideoElement | null>(null);
  const mediaRecorder = ref<MediaRecorder | null>(null);
  const recordedChunks = ref<Blob[]>([]);
  const buttonLabel = ref("Turn on Camera & Mic");
  const isSubmittingVideo = ref(false);

  const videoDevices = ref<MediaDeviceInfo[]>([]);
  const audioDevices = ref<MediaDeviceInfo[]>([]);
  const selectedCameraTitle = ref("");
  const selectedMicrophoneTitle = ref("");

  const selectedCamera = computed(() => {
    const camera = cameraOptions.value.find(
      (camera) => camera.title === selectedCameraTitle.value
    );

    return camera ? camera.value : "";
  });

  const selectedMicrophone = computed(() => {
    const microphone = microphoneOptions.value.find(
      (microphone) => microphone.title === selectedMicrophoneTitle.value
    );

    return microphone ? microphone.value : "";
  });

  const stream = ref<MediaStream | null>(null);

  const isRecording = ref(false);
  const hasPermissions = ref(false);
  const hasRecordedVideo = ref(false);

  const recordedBlobUrl = ref("");
  const recordedBlob = ref<Blob | null>(null);

  const videoDesignMaxWidth = 568;
  const videoContainer = ref();
  const videoPlayer = ref();
  const isVideoLoaded = ref(false);
  const isHeightMax = ref(false);
  const showSaveButton = ref(false);
  const maxHeightForVideo = ref(0);
  const name = ref("");
  const { width: videoContainerWidth } = useElementSize(videoContainer);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const maxSize = ref(videoDesignMaxWidth);
  const { isFullscreen } = useFullscreen(videoPlayer);

  const { calcVideoSize, loadVideo, videoHeightWithPx, videoWidthWithPx } =
    useVideoResize({
      maxVideoSize: maxSize,
      containerWidthRef: videoContainerWidth,
      isHeightMax: isHeightMax,
      maxVideoHeight: maxHeightForVideo,
    });

  watch(
    () => videoContainerWidth.value,
    () => {
      calcVideoSize();
    }
  );

  watch(
    () => windowWidth.value,
    (newVal) => {
      // 32 is card padding (16 x 2), 239 is nav drawer width
      const spaceForElement = newVal - 32 - (!isMobile.value ? 239 : 0);

      if (newVal < 1100 && spaceForElement > 0) {
        isHeightMax.value = false;
        maxSize.value = spaceForElement;
      } else {
        maxSize.value = videoDesignMaxWidth;
      }
    },
    {
      immediate: true,
    }
  );

  watch(
    () => windowHeight.value,
    (newVal) => {
      const divElement = document.querySelector("#recording-info") as HTMLElement;
      const bottomPosition = divElement?.getBoundingClientRect()?.bottom || 400;

      const maxSpaceForVideo = newVal - 186 - bottomPosition;

      maxHeightForVideo.value = maxSpaceForVideo;

      if (maxSpaceForVideo < 450) {
        maxSize.value = maxSpaceForVideo;
        isHeightMax.value = true;
        calcVideoSize();
      } else {
        isHeightMax.value = false;
        maxSize.value = videoDesignMaxWidth;
        calcVideoSize();
      }
    },
    {
      immediate: true,
    }
  );

  const cameraOptions = computed(() => {
    return uniqueDevices(videoDevices.value).map((device) => ({
      title: device.label || `Camera ${videoDevices.value.indexOf(device) + 1}`,
      value: device.deviceId,
    }));
  });

  const microphoneOptions = computed(() => {
    return uniqueDevices(audioDevices.value).map((device) => ({
      title:
        device.label || `Microphone ${audioDevices.value.indexOf(device) + 1}`,
      value: device.deviceId,
    }));
  });

  onMounted(async () => {
    await getMediaDevices();
  });

  watch(
    () => selectedMicrophoneTitle.value,
    async () => {
      if (stream.value) {
        await startStream();
      }
    }
  );

  watch(
    () => selectedCameraTitle.value,
    async () => {
      if (stream.value) {
        await startStream();
      }
    }
  );

  onBeforeUnmount(() => {
    stopStream();
  });

  const videoLoaded = (videoMetadata: { width: number; height: number }) => {
    isVideoLoaded.value = true;
    loadVideo(videoMetadata);
  };

  const getMediaDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    videoDevices.value = [
      ...devices.filter((device) => device.kind === "videoinput"),
    ];

    audioDevices.value = devices.filter((device) => device.kind === "audioinput");

    if (videoDevices.value.length > 0) {
      selectedCameraTitle.value = videoDevices.value[0].label || "Camera 1";
    }
    if (audioDevices.value.length > 0) {
      selectedMicrophoneTitle.value =
        audioDevices.value[0].label || "Microphone 1";
    }
  };

  const startStream = async () => {
    await stopStream();

    try {
      stream.value = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCamera.value
            ? { exact: selectedCamera.value }
            : undefined,
          width: { ideal: 568 },
        },
        audio: {
          deviceId: selectedMicrophone.value
            ? { exact: selectedMicrophone.value }
            : undefined,
        },
      });
    } catch (error) {
      console.error("Error accessing media devices.", error);
      alert(
        "Unable to access camera or microphone. Please check permissions and try again."
      );
    }

    if (videoElement.value) {
      videoElement.value.srcObject = stream.value;
    }

    buttonLabel.value = "Start Recording";
    hasRecordedVideo.value = false;
    hasPermissions.value = true;
  };

  const stopStream = () => {
    if (stream.value) {
      stream.value.getTracks().forEach((track) => track.stop());
      stream.value = null;
    }
  };

  const calculateVideoDurationFromBlob = (videoBlob: Blob): Promise<number> => {
    return new Promise((resolve, reject) => {
      const tempVideo = document.createElement("video");

      tempVideo.preload = "metadata";

      const url = URL.createObjectURL(videoBlob);

      tempVideo.src = url;

      tempVideo.onloadedmetadata = () => {
        if (tempVideo.duration === Infinity) {
          tempVideo.currentTime = Number.MAX_SAFE_INTEGER;
          tempVideo.ontimeupdate = () => {
            tempVideo.ontimeupdate = null;
            URL.revokeObjectURL(url);
            resolve(tempVideo.duration);
          };
        } else {
          URL.revokeObjectURL(url);
          resolve(tempVideo.duration);
        }
      };

      tempVideo.onerror = (error) => {
        reject(new Error("Failed to load video metadata"));
      };
    });
  };

  const startRecording = () => {
    if (stream.value) {
      const mimeType = MediaRecorder.isTypeSupported(
        "video/mp4; codecs=avc1.42E01E, mp4a.40.2"
      )
        ? "video/mp4; codecs=avc1.42E01E, mp4a.40.2"
        : "video/webm; codecs=vp9";

      const options = {
        mimeType,
        videoBitsPerSecond: 2500000,
      };

      mediaRecorder.value = new MediaRecorder(stream.value, options);
      recordedChunks.value = [];

      mediaRecorder.value.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.value.push(event.data);
        }
      };

      mediaRecorder.value.onstop = () => {
        recordedBlob.value = new Blob(recordedChunks.value, { type: mimeType });

        recordedBlobUrl.value = URL.createObjectURL(recordedBlob.value);

        hasRecordedVideo.value = true;

        resetTimer();
        stopStream();
      };

      mediaRecorder.value.start(1000);

      buttonLabel.value = "Stop Recording";
      isRecording.value = true;

      time.value = 0;
      recordingInterval = setInterval(() => {
        time.value++;
        if (time.value >= 4) {
          showSaveButton.value = true;
        }
        if (time.value > recordingDuration) {
          stopRecording();
        }
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.value) {
      mediaRecorder.value.stop();
      buttonLabel.value = "Turn on Camera & Mic";
      isRecording.value = false;
      showSaveButton.value = false;
    }
  };

  const cancelRecording = () => {
    if (isRecording.value && mediaRecorder.value) {
      mediaRecorder.value.stop();
      recordedChunks.value = [];
    }

    resetRecording();
  };

  const resetRecording = () => {
    if (mediaRecorder.value) {
      mediaRecorder.value.ondataavailable = null;
      mediaRecorder.value.onstop = null;
      if (mediaRecorder.value.state !== "inactive") {
        mediaRecorder.value.stop();
      }
      mediaRecorder.value = null;
    }
    resetTimer();
    buttonLabel.value = "Start Recording";
    isRecording.value = false;
    hasRecordedVideo.value = false;
    recordedBlobUrl.value = "";
    showSaveButton.value = false;
  };

  const recordAgain = () => {
    stopStream();
    recordedBlobUrl.value = "";
    resetRecording();
    startStream();
  };

  const resetTimer = () => {
    if (recordingInterval !== null) {
      clearInterval(recordingInterval);
      recordingInterval = null;
    }
    time.value = 0;
  };

  const toggleRecording = () => {
    if (buttonLabel.value === "Turn on Camera & Mic") {
      startStream();
    } else if (buttonLabel.value === "Start Recording") {
      startRecording();
    } else if (buttonLabel.value === "Stop Recording") {
      stopRecording();
    }
  };

  const uniqueDevices = (devices: MediaDeviceInfo[]) => {
    const uniqueIds = new Set();

    return devices.filter((device) => {
      const id = device.groupId || device.deviceId;

      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        return true;
      }
      return false;
    });
  };

  const submitVideo = async () => {
    const videoDuration = await calculateVideoDurationFromBlob(
      recordedBlob.value as Blob
    );

    try {
      isSubmittingVideo.value = true;
      const payload = {
        video: recordedBlob.value,
        videoDurationMs: videoDuration * 1000 || 0,
        name: name.value,
      };
      const resp = await submitZeroShotVideo(payload);

      userStore.addLipsyncModel(resp);

      router.push({
        name: Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.name,
        params: { id: resp.id, type: "zero-shot" },
      });
    } catch (e) {
      console.error("e", e);
    } finally {
      isSubmittingVideo.value = false;
    }
  };
</script>

<style lang="scss" scoped>

.gap-8 {
  gap: 8px;
}

.recording-progress-bar {
  border-radius: 8px;
}

.recording-panel {
  align-items: center;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;

  &--min-height {
    background: rgba(var(--v-theme-dark-bg));
    min-height: 320px;
  }

  &--mobile {
    max-height: calc(100vh - 100px);
    min-height: 245px;
  }

  video {
    border-radius: 8px;
    height: 100%;
    max-height: calc(100vh - 450px);
    max-width: 100%;
    object-fit: contain;
  }

  .tag {
    left: 50%;
    position: absolute;
    top: 8px;
    transform: translateX(-50%);
    z-index: 10;
  }
}

.device-select {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

:deep(.video-container) {
  background: transparent !important;
}

:deep(.video-container) {
  height: 100%;
}

:deep(.vjs-tech) {
  height: auto !important;
}

.icon-wrapper {
  background: rgba(var(--v-theme-aphla-bg));
  border-radius: 50%;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%);
  height: 38px;
  width: 38px;
}

.video-box {
  height: v-bind("videoHeightWithPx");
  width: v-bind("videoWidthWithPx");

  &--fullscreen {
    :deep(.vjs-tech) {
      height: 100% !important;
      max-height: unset !important;
    }
  }
}

.gap-16 {
  gap: 16px;
}
</style>

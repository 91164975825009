
export enum sortingIds {
  NAME_ASCENDING = "nameAscending",
  NAME_DESCENDING = "nameDescending",
  NEWEST_FIRST = "newestFirst",
  OLDEST_FIRST = "oldestFirst",
  LONGEST_FIRST = "longestFirst",
  SHORTEST_FIRST = "shortestFirst",
}


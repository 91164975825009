<template>
  <c-typography
    class="d-block mb-3 text-center"
    data-testid="photo-twin-studio-prompt-gallery-title"
    variant="subtitle-2-600"
  >Prompt Gallery
  </c-typography>
  <c-typography
    class="d-block mb-1 text-center"
    color-class="copy-secondary"
    data-testid="photo-twin-studio-prompt-gallery-subtitle"
    variant="body-2-400"
  >No idea for now? Don't worry, because here you can find a ready-to-use
    prompt library prepared by us.
  </c-typography>
  <c-typography
    class="d-block mb-6 text-center"
    color-class="copy-secondary"
    data-testid="photo-twin-studio-prompt-gallery-subtitle"
    variant="body-2-400"
  >See yourself as a superhero, a medieval warrior, a pirate ship captain and
    many others!
  </c-typography>
  <circle-loader v-if="isLoadingPromptGallery" title="Loading prompt gallery..." />
  <div v-else :class="['gallery gap-16 w-100', { 'gallery--mobile': isMobile }]">
    <c-image-card
      v-for="item in promptItems"
      :key="item.id"
      bottom-full
      :class="['gallery-image-card cursor-pointer']"
      color="button-secondary"
      gap="8"
      :gradient="data.hoveredId === item.id"
      :image-height="isMobile ? '114' : '282'"
      :image-src="item.thumbnailUrl"
      :width="isMobile ? '160' : '390'"
      @click="() => openPhotoPreview(item)"
      @mouseleave="data.hoveredId = 0"
      @mouseover="data.hoveredId = item.id"
    >
      <template #image-bottom>
        <div
          v-if="data.hoveredId === item.id"
          class="align-center d-flex flex-column pa-3"
        >
          <c-typography class="prompt" color-class="cta" variant="body-3-400"
          >{{ truncateString(item?.prompt) }}
          </c-typography>
          <c-icon
            class="mt-2"
            color="rgb(var(--v-theme-cta))"
            icon="ph:magnifying-glass"
            width="20"
          />
        </div>
      </template>
    </c-image-card>
  </div>
  <photo-prompt-dialog
    v-if="data.openPhotoDialog"
    v-model="data.openPhotoDialog"
    :photo-el="data.currentModelPreview"
    @show-next="updateCurrentModelPreview(1)"
    @show-prev="updateCurrentModelPreview(-1)"
  />
</template>

<script lang="ts" setup>
  // @ts-ignore
  // prettier-ignore
  import {  CIcon, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import { CImageCard } from "@charactr/wooper-ui/molecules";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { isMobile } from "@/core/utils/mobile";
  import PhotoPromptDialog from "./PhotoPromptDialog.vue";
  import type { PhotoTwinPhoto } from "@/core/types/synthetic.types";
  import { reactive } from "vue";
  import { storeToRefs } from "pinia";
  import { useUserStore } from "@/core/store/userStore";

  const userStore = useUserStore();

  const { promptItems, isLoadingPromptGallery } = storeToRefs(userStore);

  const data = reactive({
    editDialog: false,
    deleteDialog: false,
    newPhotoDialog: false,
    loading: true,
    error: false,
    openPhotoDialog: false,
    activePhotoId: 0,
    hoveredId: 0,
    deletePhotoDialog: false,
    editPhotoNameDialog: false,
    currentModelPreview: null as null | PhotoTwinPhoto,
    currentModelPreviewIndex: 0,
  });

  const openPhotoPreview = (item: { id: any }) => {
    data.currentModelPreview = promptItems.value.find(
      (el: { id: any }) => el.id === item.id
    );
    data.currentModelPreviewIndex = promptItems.value.findIndex(
      (el: { id: any }) => el.id === item.id
    );
    data.openPhotoDialog = true;
  };

  const updateCurrentModelPreview = (val: number) => {
    data.currentModelPreview = null;

    const newIndex = data.currentModelPreviewIndex + val;

    if (newIndex < 0) {
      data.currentModelPreviewIndex = promptItems.value.length - 1;
    } else if (newIndex >= promptItems.value.length) {
      data.currentModelPreviewIndex = 0;
    } else {
      data.currentModelPreviewIndex = newIndex;
    }

    data.currentModelPreview = promptItems.value[data.currentModelPreviewIndex];
  };

  const truncateString = (str: string): string => {
    const maxLength = 250;

    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };
</script>

<style lang="scss" scoped>

.gap-32 {
  gap: 32px;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 388px);
  justify-content: center;

  &--mobile {
    grid-template-columns: repeat(auto-fill, 160px);
  }
}

.prompt {
  max-height: 72px;
  overflow: hideen;
}
</style>

import { defineStore } from "pinia";
import type { SyntheticVideo } from "../types/video.types";
import { ref } from "vue";

export const useFullscreenVideoStore = defineStore("fullscreenVideoStore", () => {
    const fullscreenVideo = ref(null as SyntheticVideo | null);
    const showFullscreenVideo = ref(false);

    const $reset = () => {
        showFullscreenVideo.value = false;
        fullscreenVideo.value = null;
    };

    return {
        fullscreenVideo,
        showFullscreenVideo,

        $reset,
    };
});

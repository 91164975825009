<!-- eslint-disable no-await-in-loop -->
<template>
  <div class="multiple-image-uploder">
    <div   v-if="displayDuplicateMessage || displayErrorMessage" class="d-flex flex-column">
      <c-typography
        v-if="displayDuplicateMessage"
        class="d-block"
        color-class="error"
        variant="body-3-400"
      >
        Some of the photos you've chosen are already included and won't be added again.
      </c-typography>
      <c-typography
        v-if="displayErrorMessage"
        class="d-block"
        color-class="error"
        variant="body-3-400"
      >
        Oops! Some photos couldn't be uploaded. Make sure they meet the size and format requirements.
      </c-typography>
    </div>
    <drag-and-drop
      :class="[
        'drag-and-drop-multiple',
        { 'drag-and-drop-multiple--images': imagesList.length },
      ]"
      :disabled="disabled"
      :display-progress="displayProgress"
      height="auto"
      min-height="300"
      :supported-formats="IMAGE_FORMATS"
      @choose-file="choosePicture"
      @drop-file="handleFileChange"
    >
      <template v-if="imagesList.length" #custom>
        <div class="d-flex flex-wrap gap-8 mb-2 pa-4">
          <div
            v-for="(image, index) in imagesList"
            :key="index"
            class="image-thumbnail-container"
          >
            <div class="image-thumbnail">
              <img
                alt="Uploaded Image"
                class="thumbnail-img"
                :src="image.src"
              />
              <c-button
                class="d-inline-block delete-button"
                :disabled="disabled"
                icon="ph:x"
                mode="action"
                size="x-small"
                @click.stop="removeImage(index)"
              />
            </div>
          </div>
          <div
            v-if="imagesList.length < maxImages"
            class="add-more-container align-center d-flex justify-center"
          >
            <c-button
              icon="ph:plus"
              icon-height="14"
              mode="highlight"
              size="x-small"
            />
          </div>
        </div>
        <c-typography
          v-if="imagesList.length < maxImages && imagesList.length < minImages"
          class="align-self-end pb-4 pr-4"
          color-class="copy-secondary"
          variant="body-3-400"
        >
          {{ imagesList.length }} photo{{ imagesList.length === 1 ? "" : "s" }}
          uploaded. You need to upload at least
          {{ minImages - imagesList.length }} more.
        </c-typography>
        <c-typography
          v-else-if="imagesList.length"
          class="align-self-end pa-4"
          color-class="copy-secondary"
          variant="body-3-400"
        >
          {{ imagesList.length }} photos uploaded.
        </c-typography>
      </template>
    </drag-and-drop>
    <input
      id="source_picture"
      ref="pictureFileSelector"
      accept="image/*"
      class="d-none"
      multiple
      type="file"
      @change="handleFileChange"
    />
  </div>
</template>

<script setup lang="ts">
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import { defineProps, ref } from "vue";

  // @ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    minImages: {
      type: Number,
      default: 10,
    },
    maxImages: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    photos: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits(["update:photos"]);

  const IMAGE_FORMATS = ["jpg", "png", "jpeg"];
  const imagesList = ref<{ src: string; file: File }[]>([]);
  const displayProgress = ref(false);
  const displayErrorMessage = ref(false);
  const displayDuplicateMessage = ref(false);

  const isDuplicateImage = (file: File): boolean => {
    return imagesList.value.some(image => image.file.name === file.name && image.file.size === file.size);
  };

  const { loadImage } = useMediaFileLoader();

  const pictureFileSelector = ref<HTMLInputElement | null>(null);

  const choosePicture = () => {
    if (pictureFileSelector.value) {
      pictureFileSelector.value.click();
    }
  };

  const updatePhotosValue = () => {
    emit("update:photos", imagesList.value);
  };

  const handleFileChange = async (event: Event | DragEvent) => {
    let files: FileList | null = null;

    if (event instanceof DragEvent) {
      files = event.dataTransfer?.files || null;
    } else if (event instanceof Event) {
      const input = event.target as HTMLInputElement;

      files = input.files || null;
    }

    if (files && files.length > 0 && imagesList.value.length < props.maxImages) {
      displayProgress.value = true;

      const fileArray = Array.from(files);

      for (const file of fileArray) {
        if (imagesList.value.length >= props.maxImages) break;

        if (isDuplicateImage(file)) {
          displayDuplicateMessage.value = true;
          continue;
        }

        //error for maxMegaBytes = 1 show in console - we should display some info like "only photos that meet requirements are accepted"
        try {
          const image = await loadImage([file], {
            supportedTypes: IMAGE_FORMATS,
            // maxMegabytes: 1,
          });

          if (image) {
            imagesList.value.push({ src: image, file });
          }
        } catch (e) {
          console.error("Error loading image", e);
          displayErrorMessage.value = true;
        }
      }
      displayProgress.value = false;
      updatePhotosValue();
    } else {
      console.error("No files found or maximum number of images reached.");
    }
  };

  const removeImage = (idx: number) => {
    imagesList.value = imagesList.value.filter((el, index) => index !== idx);
    updatePhotosValue();
  };
</script>

<style scoped lang="scss">
.multiple-image-uploder {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.image-thumbnail-container {
  position: relative;
}

.image-thumbnail {
  border-radius: 12px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 18%);
  height: 100px;
  overflow: hidden;
  position: relative;
  width: fit-content;
}

.thumbnail-img {
  border-radius: 12px;
  height: 100%;
  width: 100%;
}

.delete-button {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
}

.gap-8 {
  gap: 8px;
}

.drag-and-drop-multiple--images {
  :deep(.v-card-text) {
    align-items: start !important;
    justify-content: space-between !important;
  }
}

.add-more-container {
  border: 1px solid rgb(var(--v-theme-dark-frame));
  border-radius: 12px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 18%);
  height: 100px;
  width: 100px;
}

//uncomment if you want to have square photos
// .image-thumbnail {
//   width: 100px;
// }

// .thumbnail-img {
//   object-fit: cover;
//   object-position: center;
// }
</style>

<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <c-dialog
    :cancel-option="false"
    :confirm-option="false"
    min-width="300"
    :model-value="modelValue"
    variant="secondary"
    width="auto"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>
      Create new Video Twin
    </template>
    <template #content>
      <div :class="['d-flex flex-column gap-16', { 'gap-8 mobile-wrapper': isMobile }]">
        <c-info-card
          v-for="tab in tabs"
          :id="tab.href"
          :key="tab.href"
          class="tab w-100"
          height="auto"
          :icon="tab.icon"
          :is-mini="false"
          :test-id="`twin-studio-tab-${tab.href}`"
          :text-cut="false"
          variant="feature"
          @click="navigateTo(tab)"
        >
          <template #title>{{ tab.label }}</template>
          <template #subtitle>{{ tab.description }}</template>
        </c-info-card>
      </div>
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { TWIN_STUDIO_TABS } from "../../../modules/videoTwinStudio/types/index";
  import { Routes } from "@/core/routes/core.guard";
  import { isMobile } from "@/core/utils/mobile";
  import { computed } from "vue";

  // @ts-ignore
  import { CDialog, CInfoCard } from "@charactr/wooper-ui/molecules";
  import { useRouter } from "vue-router";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);
  const router = useRouter();

  const tabs = computed(() => [
    {
      label: TWIN_STUDIO_TABS.LIPSYNC_MODELS,
      href: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.path,
      routeName: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
      icon: "ph:diamonds-four-fill",
      description:
        "Generate your AI Twin in the best possible quality. It will take time, but the result will be truly amazing!",
    },
    {
      label: TWIN_STUDIO_TABS.ZERO_SHOT,
      href: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.path,
      routeName: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
      icon: "mdi:fast-forward",
      description:
        "Don't have much time and want to generate a slightly lower quality but still cool AI Twin? Try this one!",
    },
    {
      label: TWIN_STUDIO_TABS.SYNTHETICS,
      href: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.path,
      routeName: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.name,
      icon: "ph:user-square-fill",
      description:
        "Don't have a video of yourself? Discover the feature where you can generate an AI avatar from your photo!",
    },
  ]);

  const navigateTo = (val: any) => {
    router.push({ name: val.routeName });
  };
</script>

<style lang="scss" scoped>

.tab {
  overflow: hidden;
}

.gap-16 {
    gap: 16px;
}

.gap-8 {
    gap: 8px
}

.mobile-wrapper {
    :deep(.align-start) {
        align-items: center !important;
    }
}
</style>

import type { ApiRecording } from "../types/recording.types";
import { apiUrl } from "@/core/utils/api";
import type { AxiosResponseHeaders } from "axios";
import type { PlaygroundFeature } from "../data/playgroundFeatures";
import { TOKEN } from "../types/login.types";
import { VoiceType } from "../types/voice.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export type AudioConversion = {
  voiceID: number;
  characterID: number | null;
  name: string;
};

type returnType = Promise<{
  data: Blob;
  headers: AxiosResponseHeaders;
}>;

export async function fetchVC(voiceId: number | string, audio: Blob, cloned = false, save=true): returnType {
  const formData = new FormData();

  formData.append("audio", audio);

  const url = apiUrl(`vc/convert?voiceId=${voiceId}&save=${save}&voiceType=${cloned ? VoiceType.CLONED : VoiceType.SYSTEM}&consent=true`);

  const response = await coreClient.post(url, formData, {
    responseType: "blob",
  });

  return {
    data: response.data,
    headers: response.headers,
  };
}

export async function createClonedVoice(name: string, audio: Blob): Promise<any> {
  const clientId = tokenClientId();

  const formData = new FormData();

  formData.append("audio", audio);
  formData.append("name", name);

  const url = apiUrl(`clients/${clientId}/cloned-voices?consent=true`);

  const response = await coreClient.post(url, formData);

  return response.data;
}

export async function editClonedVoice(clonedVoiceId: number | string, name: string): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/cloned-voices/${clonedVoiceId}`);

  const response = await coreClient.patch(url, { name: name });

  return response.data;

};

export async function deleteClonedVoice(clonedVoiceId: number | string): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/cloned-voices/${clonedVoiceId}`);

  const response = await coreClient.delete(url);

  return response.data;

}

export async function getClonedVoicePreviewUrl(previewUrl: string): Promise<string> {
  const resp = await coreClient.get(previewUrl, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
    responseType: "blob",
  });

  return URL.createObjectURL(resp.data);
}

export async function getRecordings(mode: string, limit? : number) : Promise<Array<ApiRecording>> {

  const clientId = tokenClientId();
  let url = apiUrl(`clients/${clientId}/${mode}/recordings`);

  if (limit) {
    url += `?limit=${limit}`;
  }

  const response =  await coreClient.get(url);

  return response.data?.items || [];
}

export async function deleteRecording(recordingId: number, mode: PlaygroundFeature["name"]) : Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/${mode}/recordings/${recordingId}`);
  const response = await coreClient.delete(url);

  return response.data;

}

export async function updateRecordingName(recordingId: number, newName: string, mode: PlaygroundFeature["name"]) : Promise<ApiRecording> {

  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/${mode}/recordings/${recordingId}`);

  const response = await coreClient.patch(url, {
    name: newName,
  });

  return response.data;
}


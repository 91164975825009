<template>
  <c-dialog
    :cancel-option="false"
    :confirm-option="false"
    :model-value="modelValue"
    variant="primary"
    width="auto"
    @cancel="$emit('update:modelValue', false)"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #title>
      {{ selectedMode.title }}
    </template>
    <template #content>
      <div class="d-flex flex-wrap justify-center">
        <c-custom-card
          v-for="(option, index) in selectedMode.options"
          :key="index"
          class="card mx-3 my-6"
          color="player-bg"
          height="290"
          width="290"
        >
          <template #content>
            <div class="align-center d-flex flex-column justify-center pa-4 text-center w-100">
              <img :alt="option.title" height="125" :src="option.image"/>
              <c-typography class="mt-3" color-class="copy-primary" variant="body-2-600">
                {{ option.title }}
              </c-typography>
              <c-typography class="mt-2 subtitle" color-class="copy-secondary" variant="body-3-400">
                {{ option.subtitle }}
              </c-typography>
              <c-button
                block
                class="mt-6"
                :to="option.params ? { name: option.routeName, params: option.params } : { name: option.routeName }"
              >
                {{ option.buttonLabel }}
              </c-button>
            </div>
          </template>
        </c-custom-card>
      </div>
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { Routes } from "@/core/routes/core.guard";

  // @ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CCustomCard, CDialog } from "@charactr/wooper-ui/molecules";
  import { TWIN_STUDIO_TABS } from "@/modules/videoTwinStudio/types";
  import type { PropType } from "vue";
  import { computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    activeMainTab: {
      type: String as PropType<TWIN_STUDIO_TABS>,
      default: TWIN_STUDIO_TABS.SYNTHETICS,
    },
    voiceCloning: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const selectedMode = computed(() => {
    if (props.voiceCloning) {
      return voiceCloningMode;
    }
    return modes[props.activeMainTab];
  });

  const voiceCloningMode = {
    title: "Clone a new voice",
    options: [
      {
        title: "Record your audio",
        subtitle: "Make a quick try with your microphone",
        buttonLabel: "Record",
        image: "/images/voiceCloning/upload.png",
        routeName: Routes.VOICE_CLONING.children.RECORD.name,
        params: { type: "zero-shot" },
      },
      {
        title: "Upload audio file",
        subtitle: "Use a ready-made recording of your voice.",
        buttonLabel: "Upload",
        image: "/images/voiceCloning/record.png",
        routeName: Routes.VOICE_CLONING.children.UPLOAD.name,
        params: {},
      },
    ],
  };

  const modes = {
    [TWIN_STUDIO_TABS.SYNTHETICS]: {
      title: "Create or upload a new Gemelo",
      options: [
        // {
        //   title: "Create Synthetic Gemelo",
        //   subtitle: "Create your custom generated Gemelo with a proper prompt",
        //   buttonLabel: "Create New",
        //   image: "/images/synthetics/createSynthetic.png",
        //   routeName: Routes.SYNTHETICS.children.FORM.name,
        //   params: {}
        // },
        // {
        //   title: "Upload image",
        //   subtitle: "Choose and upload an image from your device, ensuring it follows the rules",
        //   buttonLabel: "Upload",
        //   image: "/images/synthetics/uploadImage.png",
        //   routeName: Routes.SYNTHETICS.children.UPLOAD_PHOTO.name,
        //   params: {}
        // },
      ],
    },

    [TWIN_STUDIO_TABS.ZERO_SHOT]: {
      title: "Select file or record yourself",
      options: [
        {
          title: "Upload video file",
          subtitle: "Pre-made and upload your HD video",
          buttonLabel: "Select File",
          image: "/images/zeroShot/upload_file.png",
          routeName: Routes.VIDEO_TWIN_CREATOR.children.UPLOAD.name,
          params: { type: "zero-shot" },
        },
        {
          title: "Record with camera",
          subtitle: "Make a quick try with your device camera",
          buttonLabel: "Use Camera",
          image: "/images/zeroShot/webcam_record.png",
          routeName: Routes.VIDEO_TWIN_CREATOR.children.RECORD.name,
          params: {},
        },
      ],
    },

    [TWIN_STUDIO_TABS.LIPSYNC_MODELS]: {
      title: "Something for lipsync",
      options: [],
    },
  };
</script>

<style lang="scss" scoped>
.card {
  background: rgb(var(--v-theme-player-bg));
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
}

img {
  border-radius: 12px;
  height: 108px;
}

.subtitle {
  max-width: 170px;
}
</style>

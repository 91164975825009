import { apiUrl } from "@/core/utils/api";
import type {
  BillingAlertsRow,
  BillingHistoryRow,
} from "../types/billing.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export async function createAlert(threshold: number): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/alerts`);

  const data = {
    balanceThreshold: Number(threshold),
  };

  return await coreClient.post(url, data);
}

export async function deleteAlert(id: number): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/alerts/${id}`);

  const response = await coreClient.delete(url);

  return response.data;
}

// Currently using getClientData() from account.service to get balance
export async function getBalance(): Promise<any> {
  return {
    error: "Retrived alerts error",
  };
}

export async function getAlerts(): Promise<Array<BillingAlertsRow>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/alerts`);
  const response = await coreClient.get(url);

  return response.data || [];
}

export async function getHistory(): Promise<Array<BillingHistoryRow>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/pay/history`);
  const response = await coreClient.get(url);

  return response.data?.items || [];
}

export async function claimPromoCode(data: { code: string }): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/promo-codes/claim`);

  return await coreClient.post(url, data);
}

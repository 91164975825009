import { defineStore } from "pinia";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { isWeb } from "../utils/capacitor";
import { useDrawerStore } from "../components/RightDrawer/store";
import { useSdkStore } from "./useSdkStore";
import { useUserStore } from "./userStore";
import { useVoicesListStore } from "./useVoicesListStore";
import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
import { ref } from "vue";

export const useStore = defineStore("core", () => {
  const headerExtensionHeight = ref(0);
  const numOfVideoRecordingsListUpadtes = ref(0);

  const cleanUpData = () => {
    const userStore = useUserStore();

    userStore.cleanUp();

    const voicesListStore = useVoicesListStore();

    voicesListStore.$reset();

    const userSelectedVoiceStore = useVoicesStore();

    userSelectedVoiceStore.$reset();

    const { $reset: resetSdk } = useSdkStore();

    resetSdk();

    const drawerStore = useDrawerStore();

    drawerStore.cleanUp();
  };

  const logout = () => {
    if (!isWeb()) {
      GoogleAuth.signOut();
    }
    cleanUpData();
  };

  const triggerRecordingsListToUpdate = () => {
    numOfVideoRecordingsListUpadtes.value++;
  };

  return {
    headerExtensionHeight,
    numOfVideoRecordingsListUpadtes,

    logout,
    triggerRecordingsListToUpdate,
  };
});

<template>
  <div
    :class="[
      'recording recording-container',
      {
        'recording-container--border-bottom': bottomBorder,
        'recording-container--border-top': topBorder,
        'recording-container--bottom': bottomMobilePlayer,
      },
    ]"
    @click="selectRecording"
  >
    <div
      :class="[
        'recording',
        { 'recording--border-transparent': selectable && !active },
        {
          'recording--highlighted':
            (highlighted && mode !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name) ||
            (selectable && active),
        },
        wrapperClass,
      ]"
    >
      <div class="align-center d-flex justify-center" no-gutters>
        <c-player
          ref="player"
          :chip="props.chip"
          :class="[
            {
              'hide-play': isVideo && recordingStatus !== VideoStatus.READY,
            },
          ]"
          :cut-chip="cutChip"
          :disabled="disabled"
          :player-state="playerState"
          :recording-time-in-seconds="time"
          :simple="simple"
          :uploading="recordingStatus === VideoStatus.UPLOADING"
          variant="list"
          @stop-media="emit('stopMedia')"
          @toggle="emit('toggle')"
        >
          <template #play>
            <slot name="play" />
          </template>
          <template #img>
            <div class="cursor-pointer">
              <slot name="img" />
            </div>
          </template>
          <template #name>
            <c-typography
              :class="['d-block recording-name']"
              :color-class="
                disabled && disabledTitle ? 'copy-tertiary' : 'copy-primary'
              "
              :variant="simple ? 'body-3-600' : 'body-2-600'"
            >
              {{ props.name }}
            </c-typography>
          </template>
          <template #description>

            <template v-if="recordingStatus === VideoStatus.ERROR">
              <c-typography color-class="error" variant="body-4-400"
              >Error</c-typography
              >
              <c-tooltip content="Error during conversion" placement="top">
                <c-icon
                  class="ml-1"
                  color="rgb(var(--v-theme-alert-red))"
                  height="16"
                  icon="ph:warning-circle-fill"
                  width="16"
                />
              </c-tooltip>
            </template>
            <template
              v-if="
                recordingStatus !== VideoStatus.READY &&
                  recordingStatus !== VideoStatus.ERROR
              "
            >
              <c-typography color-class="copy-secondary" variant="body-4-400">
                {{ getStatusLabel(recordingStatus) }}
              </c-typography>
            </template>
            <template
              v-if="
                isVideo
                  ? recordingStatus === VideoStatus.READY ||
                    recordingStatus === VideoStatus.ERROR
                  : true
              "
            >
              <c-typography
                v-if="recordingStatus === VideoStatus.ERROR"
                class="mx-1"
                color-class="copy-secondary"
                variant="body-4-400"
              >
                |
              </c-typography>
              <c-typography
                class="info mx-1"
                color-class="copy-secondary"
                variant="body-4-400"
              >{{ createdDate }}</c-typography
              >
            </template>
            <c-chip
              v-if="props.chip.length"
              class="tag"
              mode="lilac"
              variant="mini_flow"
            >
              {{ props.chip }}
            </c-chip>
            <c-tag
              v-if="isVideo && showTag"
              :mode="
                recording.type === RecordingTypes.TTV_RAW
                  ? 'blue'
                  : recording.type === RecordingTypes.ZERO_SHOT
                    ? 'green'
                    : 'pink'
              "
            >
              {{
                recording.type === RecordingTypes.TTV_RAW
                  ? 'high quality'
                  : recording.type === RecordingTypes.ZERO_SHOT
                    ? 'express'
                    : 'synthetic'
              }}
            </c-tag>
          </template>
          <template #actions>
            <div
              v-if="
                recordingStatus === VideoStatus.LIPSYNC_GENERATING ||
                  recordingStatus === VideoStatus.LIVEPORTRAIT_GENERATING ||
                  recordingStatus === VideoStatus.DUBBING_GENERATING
              "
              class="pa-1"
            >
              <c-progress-circular
                bg-color="button-secondary"
                color="highlight-color"
                indeterminate
                :size="32"
                :width="4"
              />
            </div>
            <template
              v-else-if="
                isVideo
                  ? recordingStatus === VideoStatus.ERROR ||
                    recordingStatus === VideoStatus.READY
                  : true
              "
            >
              <template
                v-if="
                  !hideDetails &&
                    !selectable &&
                    showFullscreen &&
                    (isVideo ? recordingStatus !== VideoStatus.ERROR : true)
                "
              >
                <c-button
                  class="mr-2"
                  :disabled="isDownloadDisabled()"
                  height="20"
                  icon="ph:arrows-out"
                  mode="secondary"
                  width="20"
                  @click="emit('fullscreen')"
                />
              </template>
              <template
                v-if="
                  !hideDetails &&
                    !selectable &&
                    !hideDownload &&
                    (isVideo ? recordingStatus !== VideoStatus.ERROR : true)
                "
              >
                <c-button
                  class="mr-2"
                  :disabled="isDownloadDisabled()"
                  height="20"
                  icon="ph:download-simple"
                  mode="secondary"
                  width="20"
                  @click="emit('downloadRecording')"
                />
              </template>

              <template v-if="selectable">
                <c-checkbox-button
                  v-if="props.selectable"
                  :checked="props.active"
                  radio
                  @click="selectRecording"
                />
              </template>

              <template v-if="!selectable && !hideDetails">
                <c-tooltip
                  v-if="
                    recording.sharingActive &&
                      mode !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name && !simple && hideDownload
                  "
                  content="Copy link to video"
                  placement="top"
                >
                  <c-button
                    class="d-inline-block mx-1"
                    height="20"
                    icon="ph:link"
                    icon-height="20"
                    mode="secondary"
                    width="20"
                    @click="copyLink"
                  />
                </c-tooltip>
                <c-dropdown-menu
                  v-if="isVideo ? recordingStatus !== VideoStatus.ERROR : true"
                  :dropdown-options="dropdownOptions"
                  width="176"
                >
                  <template #activator>
                    <c-button
                      class="d-inline-block"
                      :disabled="disabled"
                      height="20"
                      icon="ph:dots-three-outline-vertical-fill"
                      icon-height="20"
                      mode="secondary"
                      width="20"
                    />
                  </template>
                </c-dropdown-menu>
                <template v-else>
                  <c-button
                    height="20"
                    icon="ph:trash-fill"
                    mode="secondary"
                    size="x-small"
                    width="20"
                    @click.stop="openDeleteDialog"
                  />
                </template>
              </template>
            </template>
          </template>
        </c-player>
      </div>
      <slot name="dialogs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { formatCreateDate } from "@/core/utils/formatters";
  import { isMobile } from "@/core/utils/mobile";
  import { PlayerState, RecordingTypes } from "@/core/types/recording.types";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { VideoStatus } from "@/core/types/video.types";
  import { AlertModes, RightPanelTabs } from "@/core/types/other.types";
  import { computed, onBeforeUnmount, onMounted, type PropType, ref } from "vue";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  // @ts-ignore
  import { CButton, CCheckboxButton, CChip, CIcon, CProgressCircular, CTag, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDropdownMenu, CPlayer } from "@charactr/wooper-ui/molecules";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  //PROPS & EMITS
  const props = defineProps({
    recording: {
      type: Object as PropType<any>,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    mode: {
      type: null as unknown as PropType<
        PlaygroundFeature["name"]
      >,
      default: "",
    },
    time: {
      type: Number,
      default: 0,
    },
    bottomBorder: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: String,
      default: "",
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    bottomMobilePlayer: {
      type: Boolean,
      default: false,
    },
    topBorder: {
      type: Boolean,
      default: false,
    },
    hideDownload: {
      type: Boolean,
      default: false,
    },
    showFullscreen: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTitle: {
      type: Boolean,
      default: true,
    },
    playerState: {
      type: String,
      default: PlayerState.Idle,
    },
    highlight: {
      type: Boolean,
      default: true,
    },
    cutChip: {
      type: Boolean,
      default: false,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits([
    "toggle",
    "stopMedia",
    "downloadRecording",
    "openEditDialog",
    "openDeleteDialog",
    "selectItem",
    "fullscreen",
  ]);

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab } = appDrawersState;
  const { showSnackbar } = useSnackbarStore();

  const highlighted = ref(false);
  const player = ref();

  const router = useRouter();

  const isDownloadDisabled = () => {
    return (
      (props.mode === PLAYGROUND_FEATURES.VTV.name &&
        props.recording.status !== VideoStatus.READY) ||
      props.disabled
    );
  };

  const formatAudioLength = computed(() => {
    const totalSeconds = Math.ceil(props.time);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    if (minutes > 0 || seconds > 0) {
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    }
    return "";
  });

  const getStatusLabel = (
    status: Exclude<VideoStatus, VideoStatus.READY | VideoStatus.ERROR>
  ): string => {
    const mappingVideoStatus: Record<
      Exclude<VideoStatus, VideoStatus.READY | VideoStatus.ERROR>,
      string
    > = {
      [VideoStatus.LIPSYNC_QUEUED]: "Waiting in the queue...",
      [VideoStatus.LIVEPORTRAIT_QUEUED]: "Waiting in the queue...",
      [VideoStatus.LIVEPORTRAIT_GENERATING]: "Converting...",
      [VideoStatus.LIPSYNC_GENERATING]: "Converting...",
      [VideoStatus.UPLOADING]: "Uploading to server...",
      [VideoStatus.DUBBING_QUEUED]: "Waiting in the queue...",
      [VideoStatus.DUBBING_GENERATING]: "Converting...",
    };

    return mappingVideoStatus[status];
  };

  const createdDate = computed(() => {
    const date = new Date(props.recording.createdAt);
    const today = new Date();

    const audioLengthPart = formatAudioLength.value
      ? `| ${formatAudioLength.value}`
      : "";

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      const [hour, minutes] = [
        date.getHours(),
        String(date.getMinutes()).padStart(2, "0"),
        String(date.getSeconds()).padStart(2, "0"),
      ];

      return `Today at ${hour}:${minutes} ${audioLengthPart}`;
    } else {
      const [hour, minutes] = [
        date.getHours(),
        String(date.getMinutes()).padStart(2, "0"),
        String(date.getSeconds()).padStart(2, "0"),
      ];

      return `${formatCreateDate(
        props.recording.createdAt
      )} at ${hour}:${minutes} ${audioLengthPart}`;
    }
  });

  const dropdownOptions = computed(() => {
    const isVtvNotReady =
      props.mode === PLAYGROUND_FEATURES.VTV.name &&
      props.recording.status !== VideoStatus.READY;

    const copyLinkItem = {
      name: "Copy link",
      icon: "ph:link",
      action: copyLink,
      disabled: isVtvNotReady,
    };

    const downloadItem = {
      name: "Download",
      icon: "ph:download-simple",
      action: downloadRecording,
      disabled: isVtvNotReady,
    };

    const renameItem = {
      name: "Rename",
      icon: "ph:pencil-line",
      action: openEditDialog,
      disabled: isVtvNotReady,
    };

    const shareItem = {
      name: "Share",
      icon: "ph:share",
      action: shareAction,
      disabled: isVtvNotReady,
    };

    const deleteItem = {
      name: "Delete",
      icon: "ph:trash-simple",
      action: openDeleteDialog,
      disabled: isVtvNotReady && props.recording.status !== VideoStatus.ERROR,
    };

    const defaultOptions = isVideo.value
      ? [props.recording.sharingActive && (props.simple || !props.hideDownload) ? copyLinkItem : null, props.hideDownload ? downloadItem : null, renameItem, shareItem, deleteItem].filter(option => option !== null)
      : [renameItem, downloadItem, deleteItem];

    if (props.bottomMobilePlayer && isMobile.value) {
      return [
        {
          name: "Go to Recordings",
          icon: "ph:list-plus",
          action: openRecordings,
        },
        ...defaultOptions,
      ];
    }

    return [...defaultOptions];
  });

  const openRecordings = () => {
    openRightPanelTab(RightPanelTabs.RECORDINGS);
  };

  const openDeleteDialog = () => {
    emit("openDeleteDialog");
  };

  const openEditDialog = () => {
    emit("openEditDialog");
  };

  const downloadRecording = () => {
    emit("downloadRecording");
  };

  const selectRecording = () => {
    if (!props.disabled) {
      emit("selectItem");
    }
  };

  const shareAction = () => {
    router.push({
      name: Routes.SHARE.children.SETTINGS.name,
      params: { id: props.recording.id },
    });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      "https://gemelo.ai/share/" + props.recording.shareId
    );
    showSnackbar("Video link copied", AlertModes.SUCCESS);
  };

  const isVideo = computed(() => {
    return (
      props.mode === PLAYGROUND_FEATURES.VTV.name ||
      props.mode === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name ||
      props.mode === PLAYGROUND_FEATURES.VIDEO_CREATOR.name
    );
  });

  const recordingStatus = computed(() => {
    return isVideo.value ? props.recording.status : "";
  });

  const wrapperClass = computed(() => {
    if (!isVideo.value || !props.hideDownload) {
      return "pa-2";
    } else if (props.simple) {
      return "pa-0";
    } else {
      return "pt-2";
    }
  });
</script>

<style lang="scss" scoped>
.recording-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recording {
  box-sizing: border-box;
  transition: 0.2s all ease;

  &-container {
    &--border-top {
      border-top: 1px solid rgb(var(--v-theme-dark-frame)) !important;
    }

    &--border-bottom {
      border-bottom: 1px solid rgb(var(--v-theme-dark-frame)) !important;
    }

    &--bottom {
      background-color: rgba(var(--v-theme-aphla-bg));
    }
  }

  &--highlighted {
    background: rgb(var(--v-theme-highlight-aphla));
    border: 2px solid rgb(var(--v-theme-button-primary)) !important;
    border-radius: 8px;
  }

  &--border-transparent {
    border: 2px solid transparent;
  }
}

.hide-play {
  :deep(.play-button) {
    color: rgb(var(--v-theme-copy-secondary-lock));
    pointer-events: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.info {
  width: 148px;
}
</style>

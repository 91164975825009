import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

//Removed dynamic import as this is a initial page from route
import Home from "../pages/Home.vue";

//Dynamically imported components
const PrivacyPolicy = (): Promise<RouteComponent> =>  import("../pages/PrivacyPolicy.vue");
const TermsOfService = (): Promise<RouteComponent> => import("../pages/TermsOfService.vue");

export default [
  {
    name: Routes.HOME.name,
    path: Routes.HOME.path,
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    name: Routes.PRIVACY_POLICY.name,
    path: Routes.PRIVACY_POLICY.path,
    component: PrivacyPolicy,
    meta: { requiresAuth: false },
  },
  {
    name: Routes.TERMS_OF_SERVICE.name,
    path: Routes.TERMS_OF_SERVICE.path,
    component: TermsOfService,
    meta: { requiresAuth: false },
  },
  {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    redirect: Routes.LOGIN.name,
  },
];

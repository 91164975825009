<template>
  <div
    :key="`${img.id}_${getStatus(img)}_${img.name}`"
    :class="['gallery__img', { border: !isHovered(img) }]"
    @click.once="handleClick"
    @mouseenter="emit('update:hoveredId', img.id)"
  >
    <c-image-card
      bottom-full
      :class="[
        'gallery-image-card',
        { 'gallery-image-card--active': isActive(img) },
        {
          'gallery-image-card--hover cursor-pointer':
            isHovered(img) && !isGenerating(img),
        },
        { 'gallery-image-card--blurred': isVariantChoice(img) },
        {
          'gallery-image-card--not-ready-img':
            img.status === 'training' &&
            !isSyntheticPortrait(img) &&
            img.coverUrl,
        },
      ]"
      color="button-secondary"
      gap="8"
      :gradient="
        isReady(img) || isSyntheticPortrait(img)
          ? true
          : !(img.status === 'training' && img.coverUrl)
      "
      :image-height="isMobile ? '114' : '154'"
      :image-src="isReady(img) ? getUrl(img) : getImageBg(img)"
      :width="isMobile ? '160' : '214'"
    >
      <template v-if="isHovered(img) || isMobile" #image-top-right>
        <c-dropdown-menu
          v-if="isReady(img) && showMenu(img)"
          :dropdown-options="getDropdownOptions(img)"
          width="160"
          @update:model-value="changeHoverState"
        >
          <template #activator>
            <c-button
              class="d-inline-block"
              icon="ph:dots-three-outline-fill"
              mode="action"
              size="x-small"
              @click="setDropdownItem(img)"
            />
          </template>
        </c-dropdown-menu>
        <c-button
          v-else-if="(isError(img) || isRejected(img)) && showMenu(img)"
          class="d-inline-block"
          icon="ph:trash-simple"
          mode="action"
          size="x-small"
          @click="deleteErrorTwin(img)"
        />
      </template>
      <template v-if="isError(img)" #image-center>
        <c-icon
          color="rgb(var(--v-theme-alert-red))"
          height="40"
          icon="material-symbols:error"
          width="40"
        />
        <c-typography class="mt-1" color-class="error" variant="body-1-600"
        >Error</c-typography
        >
      </template>
      <template v-else-if="isRejected(img)" #image-center>
        <video-to-video-guide
          v-if="data.videoGuideDialog"
          v-model="data.videoGuideDialog"
        />
        <c-tooltip
          action="Video Guide"
          class="ml-1"
          :content="img?.comment"
          :disabled="!img?.comment"
          placement="top"
          title="Reason for Rejection"
          width="200"
          @handle-action-click="handleActionClick"
        >
          <c-icon
            color="rgb(var(--v-theme-alert-red))"
            height="40"
            icon="bxs:error"
            width="40"
          />

          <div class="align-center d-flex mt-1">
            <c-typography color-class="error" variant="body-1-600"
            >Rejected</c-typography
            >
          </div>
        </c-tooltip></template
      >
      <template #image-bottom>
        <div :class="['d-flex align-center justify-start pb-2 pl-3']">
          <c-typography
            :class="['text-ellipsis', { 'text-ellipsis--mobile': isMobile }]"
            color-class="cta"
            variant="body-2-600"
          >{{ !img.name ? "generating..." : img.name }}</c-typography
          >
        </div>
      </template>
      <template v-if="isGenerating(img)" #image-center>
        <c-icon
          class="loading-icon"
          color="rgb(var(--v-theme-button-primary))"
          height="40"
          :icon="'ph:magic-wand'"
          width="40"
        />
      </template>
      <video-to-video-guide
        v-if="data.videoGuideDialog"
        v-model="data.videoGuideDialog"
      />
    </c-image-card>
  </div>
</template>

<script lang="ts" setup>
  import { downloadFromUrl } from "@/core/utils/download";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import VideoToVideoGuide from "@/core/components/Dialogs/VideoToVideoGuide.vue";
  import { analytics, SYNTHETICS_GALLERY } from "@/core/utils/analytics";
  import { computed, type PropType, reactive } from "vue";
  import {
    ModelType,
    type SyntheticModel,
    type SyntheticPortrait,
    SyntheticStatus,
  } from "@/core/types/synthetic.types";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CIcon, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import { CDropdownMenu, CImageCard } from "@charactr/wooper-ui/molecules";
  import { LipsyncStatus } from "../../../core/types/synthetic.types";

  const props = defineProps({
    img: {
      type: Object as PropType<SyntheticModel | SyntheticPortrait>,
      default: () => ({}),
    },
    clickedMenuParentPicture: {
      type: Object as PropType<SyntheticModel | SyntheticPortrait>,
      default: () => ({}),
    },
    hoveredId: {
      type: Number,
      default: 0,
    },
  });

  const emit = defineEmits([
    "openEditDialog",
    "openDeleteDialog",
    "update:clickedMenuParentPicture",
    "update:hoveredId",
  ]);

  const data = reactive({
    videoGuideDialog: false,
  });

  const isHovered = (image: any) => props.hoveredId === image.id;

  const isError = (img: any) => {
    return img.status === "error";
  };

  const isRejected = (img: any) => {
    return img.status === LipsyncStatus.PRE_REVIEW_REJECTED;
  };

  const openTwinPage = () => {
    if ((props.img as SyntheticModel).coverUrl) {
      router.push({
        name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.name,
        params: { id: props.img.id },
      });
    } else {
      router.push({
        name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.name,
        params: { id: props.img.id },
      });
    }
  };

  const handleClick = () => {
    if (isReady(props.img)) {
      openTwinPage();
    }
  };

  const router = useRouter();

  const imagesContainerHeight = computed(() => {
    return isMobile.value ? "calc(100vh - 190px)" : "calc(100vh - 240px)";
  });

  const isSyntheticPortrait = (
    img: SyntheticPortrait | SyntheticModel
  ): img is SyntheticPortrait => {
    return (img as SyntheticPortrait).url !== undefined;
  };

  const activePotraitsIds = computed(() => router.currentRoute.value.params.id);

  const isActive = (portrait: SyntheticPortrait | SyntheticModel) => {
    const activeIds = String(activePotraitsIds.value).split("_").map(Number);

    return activeIds.includes(portrait.id);
  };

  const setDropdownItem = (pic: any) => {
    emit("update:clickedMenuParentPicture", pic);
  };

  const deleteErrorTwin = (pic: any) => {
    emit("update:clickedMenuParentPicture", pic);
    emit("openDeleteDialog");
  };

  const isReady = (img: any) => {
    return img.status === SyntheticStatus.READY;
  };

  const isVariantChoice = (img: any) => {
    if (img.coverUrl) return false;
    return img.status === SyntheticStatus.VARIANT_CHOICE;
  };

  const isGenerating = (img: any) => {
    return (
      img.status !== LipsyncStatus.READY &&
      img.status !== LipsyncStatus.ERROR &&
      img.status !== LipsyncStatus.PRE_REVIEW_REJECTED
    );
  };

  const getUrl = (img: any) => {
    return img.thumbnailUrl || img.coverUrl;
  };

  const getImageBg = (img: any) => {
    return img.coverUrl || "";
  };

  const getStatus = (img: any) => {
    return img.status;
  };

  const showMenu = (img: any) => {
    return !img.isBuiltin;
  };

  const downloadImg = () => {
    if (isSyntheticPortrait(props.img) && props.img?.url) {
      downloadFromUrl(props.img?.url, "name", "_blank");
      analytics.sendEvent(
        "synth_gallery",
        SYNTHETICS_GALLERY.actions.DOWNLOAD_SYNTH_PICTURE
      );
    }
    emit("update:hoveredId", 0);
  };

  const getDropdownOptions = (portrait: any) => {
    if (portrait.isBuiltin) {
      return [
        {
          name: "Download",
          icon: "ph:download-simple",
          action: downloadImg,
        },
      ];
    } else {
      return [
        {
          name: "Download",
          icon: "ph:download-simple",
          action: downloadImg,
        },
        {
          name: "Rename",
          icon: "ph:pencil-line",
          action: () => emit("openEditDialog"),
        },
        {
          name: "Delete",
          icon: "ph:trash-simple",
          action: () => emit("openDeleteDialog"),
        },
      ];
    }
  };

  const changeHoverState = (val: boolean) => {
    if (!val) {
      emit("update:hoveredId", props.img.id);
    }
  };

  const handleActionClick = () => {
    data.videoGuideDialog = true;
  };
</script>

<style lang="scss" scoped>
@keyframes fadeInOut {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

.loading-icon {
  animation: fadeInOut 1400ms linear 500ms infinite;
}

.gallery {
  max-width: 767px;

  &-card {
    border: 1px solid rgb(var(--v-theme-light-frame));
    border-radius: 14px;
    //min-height set to display tooltip info properly - @todo: think about better solution
    min-height: 250px;

    &-bottom {
      background: rgb(var(--v-theme-highlight-aphla)) !important;
      height: 24px;
    }

    &--scroll {
      border-radius: 16px;
      overflow: auto;
    }

    &--mobile {
      border: none;
    }
  }

  &-image-card {
    background: rgb(var(--v-theme-button-secondary)) !important;
    border: 1px solid transparent;
    border-radius: 16px;

    &-content {
      &__active {
        background-color: rgb(var(--v-theme-button-primary-disabled));
        border-radius: 0 0 15px 15px;
      }
    }

    &--active {
      background: rgb(var(--v-theme-button-primary)) !important;
    }

    &--hover {
      border: 2px solid rgb(var(--v-theme-button-primary));
      // :deep(.c-card__container) {
      //   overflow: hidden;
      // }

      &--blurred {
        :deep(img) {
          filter: blur(4px) !important;
        }
      }
    }

    &--not-ready-img {
      :deep(.c-card__img-wrapper) {
        opacity: 30% !important;
      }
    }

    &-add-new {
      border: 1px dashed rgb(var(--v-theme-dark-frame));
      border-radius: 12px !important;
      box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
      cursor: pointer;

      &:hover {
        background: rgb(var(--v-theme-highlight-aphla)) !important;
        border: 1px solid rgb(var(--v-theme-button-primary));
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 160px);
    }

    &__img {
      width: fit-content;
    }
  }
}

@media (max-width: 1008px) {
  .gallery__grid {
    justify-content: center !important;
  }
}

.gap-16 {
  gap: 16px;
}

.gap-8 {
  grid-gap: 8px;
}

.text-ellipsis {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--mobile {
    max-width: 180px;
  }
}

.border {
  border: 1px solid transparent !important;
}

:deep(.c-card__img-wrapper) {
  filter: none;
}

.bottom-content {
  border-top: 1px solid rgb(var(--v-theme-dark-frame));
}

.cursor-pointer {
  cursor: pointer;
}
</style>

<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <c-dialog
    :confirm-option="false"
    :model-value="modelValue"
    variant="secondary"
    :width="isMobile ? 368 : 463"
    @keydown.enter="emit('update:modelValue', false)"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #content>
      <div
        :class="[
          'dialog-content d-flex flex-column',
          { 'dialog-content--mobile': isMobile },
        ]"
      >
        <div class="d-flex dialog-content__description">
          <c-icon
            color="rgb(var(--v-theme-highlight-color))"
            height="32"
            :icon="currentStep.icon"
          />

          <div class="align-center d-flex flex-column justify-center">
            <c-typography variant="body-2-500">Shooting Tips</c-typography>
            <c-typography class="mt-2" variant="subtitle-2-600">{{
              currentStep.title
            }}</c-typography>
          </div>

          <div class="subtitle">
            <c-typography color-class="copy-secondary" variant="body-3-400">
              {{ currentStep.subtitle }}
            </c-typography>
          </div>
        </div>
        <div class="d-flex dialog-content__actions justify-space-between pt-4">
          <div class="align-center d-flex">
            <c-checkbox
              v-model="userStore.ui_local_data.dontShowShootingTips"
              :gap="8"
              :height="24"
            />
            <c-typography
              color-class="copy-secondary"
              pointer
              variant="body-3-400"
              @click="
                userStore.ui_local_data.dontShowShootingTips =
                  !userStore.ui_local_data.dontShowShootingTips
              "
            >Don't show again</c-typography
            >
          </div>
          <div>
            <c-button
              mode="highlight-plain"
              @click="currentStep.backButtonAction"
            >
              {{ !currentStepIndex ? "Skip Tips" : "Back" }}
            </c-button>
            <c-button @click="currentStep.confirmButtonAction">
              {{ currentStep.confirmButtonLabel }}
            </c-button>
          </div>
        </div>
      </div>
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { useUserStore } from "@/core/store/userStore";
  import { computed, ref, watch } from "vue";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CCheckbox, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const userStore = useUserStore();

  const currentStepIndex = ref(0);

  const goToNextStep = () => {
    currentStepIndex.value++;
  };

  const goToPreviousStep = () => {
    currentStepIndex.value--;
  };
  const currentStep = computed(() => tipSteps[currentStepIndex.value]);

  const closeDialog = () => {
    emit("update:modelValue", false);
  };

  watch(
    () => props.modelValue,
    (val: boolean) => {
      if (val) {
        currentStepIndex.value = 0;
      }
    }
  );

  const tipSteps = [
    {
      icon: "ph:pause-circle",
      title: "Remember to make a pause",
      subtitle:
        "It’s important to make pauses and close your month during your recording speech.",
      confirmButtonLabel: "Next",
      confirmButtonAction: () => {
        goToNextStep();
      },
      backButtonAction: () => {
        closeDialog();
      },
    },
    {
      icon: "ph:eye",
      title: "Look directly in the camera",
      subtitle:
        "Try to look directly in the camera and don’t rotate or move your head.",
      confirmButtonLabel: "Next",
      confirmButtonAction: () => {
        goToNextStep();
      },
      backButtonAction: () => {
        goToPreviousStep();
      },
    },
    {
      icon: "ph:user-focus",
      title: "Make your face visible",
      subtitle:
        "Don’t use sunglasses, scarfs anything that covers your face, try to keep your hair far away your eyes.",
      confirmButtonLabel: "Next",
      confirmButtonAction: () => {
        goToNextStep();
      },
      backButtonAction: () => {
        goToPreviousStep();
      },
    },
    {
      icon: "ph:flashlight",
      title: "Light your face well",
      subtitle:
        "Bright and even lighting is crucial. Use front or side lighting to illuminate your face without harsh shadows.",
      confirmButtonLabel: "Ok",
      confirmButtonAction: () => {
        closeDialog();
      },
      backButtonAction: () => {
        goToPreviousStep();
      },
    },
  ];
</script>

<style lang="scss" scoped>
.dialog-content {
  &__description {
    align-items: center;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
  }

  &__actions {
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
  }
}

.subtitle {
  min-height: 40px;
  text-align: center;
}
</style>

import { Share } from "@capacitor/share";
import { isAndroid, isIOS } from "./capacitor";

export async function nativeShare(fileName: string, details: ShareData) : Promise<void> {

  if (isIOS() || isAndroid()) {

    if (await Share.canShare()) {
      console.log("We can share");
    }
    await Share.share({
      title: `${fileName}`,
      text: "Watch my gemelo.ai clone video",
      url: details.url,
      dialogTitle: "Share your AI Twin video",
    });
  }
  else {
    if (navigator.share) {
        if (navigator.canShare({ url: details.url })) {
          try {
            await navigator.share({
              text: "Watch my gemelo.ai clone video",
              title: `${fileName}`,
              url: details.url,
            });
          } catch (e) {
            console.error("Error during share", e);
          }
        }
      }
    }
}

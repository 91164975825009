<template>
  <c-data-table
    class="pricing-sheet"
    :headers="pricingSheetHeaders"
    :mobile="isMobile"
    rounded
    :rows="pricingSheetData"
    :show-pagination="false"
    :show-toolbar="false"
  >
    <template #[`item.cost`]="{ item }">
      <div class="align-center d-flex">
        <img v-if="item.cost !== 'Free'" class="mr-2" src="/images/token.svg" />
        <c-typography color-class="copy-primary" variant="body-2-400">{{
          item.cost
        }}</c-typography>
      </div>
    </template>
  </c-data-table>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";

  //@ts-ignore
  import { CDataTable } from "@charactr/wooper-ui/molecules";

  //@ts-ignore
  import { CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const pricingSheetHeaders = [
    {
      title: "Type of service",
      key: "service",
      sortable: false,
      minWidth: "150px",
    },
    { title: "Cost", key: "cost", sortable: false },
  ];

  const pricingSheetData = [
    { service: "New Photo Twin model", cost: 100 },
    { service: "Photo Twin prompting (per photo)", cost: 5 },
    { service: "Voice clone", cost: "Free" },
    { service: "1 minute* of audio", cost: 4 },
    { service: "1 minute* of Express Twin video", cost: 18 },
    { service: "1 minute* of High Quality Twin video", cost: 36 },
  ];
</script>

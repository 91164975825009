import "@charactr/wooper-ui/styles";
import "vue-advanced-cropper/dist/style.css";

// @ts-ignore
import { wooper } from "@charactr/wooper-ui";

import * as Sentry from "@sentry/vue";
import createApiClientConfig from "./core/api/_config";
import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createPinia } from "pinia";

import { getClientData } from "./core/services/account.service";

import "./assets/main.css";
import App from "./core/pages/index.vue";
import type { RouteLocationNormalized } from "vue-router";
import router from "./router";
import { Routes } from "./core/routes/core.guard";
import { useStore } from "@/core/store";
import { useUserStore } from "./core/store/userStore";
import { isLogged, tokenClientId } from "@/core/api/core.api";
import { REFRESH_TOKEN, TOKEN } from "@/core/types/login.types";

import { defaults } from "./core/data/userStorage";
import { useStorage } from "@vueuse/core";

import { registerSW } from "virtual:pwa-register";

registerSW({ immediate: true });

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cookiehub: any;
  }
}

const app = createApp(App);

const getLoginRedirectQuery = (to: RouteLocationNormalized) => {
  const redirectTo = to.path ?? undefined;

  const redirectQuery = Object.keys(to.query)
    ? new URLSearchParams(to.query as Record<string, string>).toString()
    : undefined;
  const redirectHash = to.hash ?? undefined;

  let query = {};

  if (redirectTo && redirectTo != "/") {
    query = { ...query, redirectTo };

    if (redirectQuery) {
      query = { ...query, redirectQuery };
    }

    if (redirectHash) {
      query = { ...query, redirectHash };
    }
  }

  return query;
};

router.beforeEach(async (to, from, next) => {
  const { logout: storeLogout } = useStore();

  if (localStorage.getItem(TOKEN) === "" || localStorage.getItem(REFRESH_TOKEN) === "") {
    storeLogout();
      next({ name: Routes.LOGIN.name });
      return;
  }

  if (to.meta.requiresAuth) {
    if (!isLogged()) {
      console.log("inside not logged logout");
      storeLogout();
      next({ name: Routes.LOGIN.name, query: getLoginRedirectQuery(to) });
      return;
    }
  }

  if (to.meta.requiresPortraitStudioFeature) {
    //get data from existing local storage
    const clientId = tokenClientId();

    const userLocalData = localStorage.getItem(`ui_data_${clientId}`);

    if (userLocalData) {
      const parsed = JSON.parse(userLocalData);

      if (!parsed.portraitStudioAccess) {
        next({ name: Routes.HOME.name });
        return;
      }
    }
  }

  next();
});

createApiClientConfig();

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        // "localhost", // uncomment this line to test local development
        /^https:\/\/app.gemelo\.ai/,
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  release:
    process.env.NODE_ENV == "production"
      ? import.meta.env.VITE_APP_VERSION
      : "dev",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app
  .use(createPinia())
  .use(wooper)
  .use(router)
  .use(
    createGtm({
      id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
      vueRouter: router,
    })
  );

//create user storage especiaslly for flags

const clientId = tokenClientId();

if (clientId) {
  const currentClientData = await getClientData(clientId);
  const userStore = useUserStore();

  defaults.portraitStudioAccess = true;

  defaults.multiTextAccess =
    currentClientData.featureFlags?.includes("multi-video-creator") || false;

  if (clientId) {
    //local storage data customized per clientId
    userStore.ui_local_data = useStorage(
      `ui_data_${clientId}`,
      defaults,
      localStorage,
      { mergeDefaults: true } //new added defaults will be merged automatically with local stoarge
    );
  }
}

app.mount("#app");


<template>
  <c-dialog
    cancel-option
    :close-option="false"
    :model-value="modelValue"
    width="440"
    @cancel="$emit('update:modelValue', false)"
    @confirm="deleteVoice"
    @keydown.enter="deleteVoice"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Delete voice from library</template>
    <template #content>
      <c-typography class="d-block text-center" color-class="copy-secondary" variant="body-2-400">
        Are you sure you want to delete cloned voice?
      </c-typography>
    </template>
    <template #confirm-label> Delete </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import { deleteClonedVoice } from "@/core/services/playground.service";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";
  import type { Voice } from "@/core/types/voice.types";
  import { analytics, VOICE_CLONING } from "@/core/utils/analytics";
  import { computed, type PropType, reactive } from "vue";

  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  //PROPS & EMITS
  const props = defineProps({
    voice: {
      type: Object as PropType<Voice>,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["deleteVoice", "update:modelValue"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const { removeClonedVoice } = useVoicesListStore();
  const router = useRouter();

  const data = reactive({
    isLoading: false,
  });

  const currRouteName = computed(() => {
    return router.currentRoute.value.name;
  });

  const deleteVoice = async () => {
    try {
      data.isLoading = true;
      await deleteClonedVoice(props.voice.id);

      emit("deleteVoice", props.voice.id);

      showSnackbar("Voice has been removed", AlertModes.SUCCESS);
      removeClonedVoice(props.voice.id as number);

      if (currRouteName.value === Routes.VOICE_CLONING.children.SUCCESS.name) {
        router.push({ name: Routes.VOICE_CLONING.name });
      }
      analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.VOICE_DELETE, {
        voice_id: props.voice.id,
      });

      emit("update:modelValue", false);
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.isLoading = false;
    }
  };
</script>

import { AlertModes } from "../types/other.types";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useSnackbarStore = defineStore("snackbarStore", () => {

    const snackbarOpen = ref(false);
    const snackbarMessage = ref("");
    const snackbarMode = ref(AlertModes.SUCCESS as AlertModes);

    const showSnackbar = (message: string, mode: AlertModes) => {
      snackbarMessage.value = message;
        snackbarMode.value = mode;
        snackbarOpen.value = true;
      };

      const $reset = () => {
        snackbarMessage.value = "";
        snackbarMode.value = AlertModes.SUCCESS as AlertModes;
        snackbarOpen.value = false;
      };

      return {
        snackbarOpen,
        snackbarMessage,
        snackbarMode,

        showSnackbar,
        $reset,
      };

});

//Removed dynamic import as this is a initial page from route
import Login from "../pages/Login.vue";

import coreClient from "@/core/api/core.api";
import { logout } from "@/core/services/login.service";
import { Routes } from "@/core/routes/core.guard";
import { TOKEN } from "@/core/types/login.types";
import { useStore } from "@/core/store";
import type { NavigationGuardNext, RouteComponent, RouteLocation } from "vue-router";

export default [
  {
    name: Routes.LOGIN.name,
    path: Routes.LOGIN.path,
    meta: { requiresAuth: false, hideMobileMenu: true, lighterBg: true },
    component: Login,
    beforeEnter: (
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) : void => {
      verifyLogin(next);
    },
  },
  {
    name: Routes.LOGOUT.name,
    path: Routes.LOGOUT.path,
    component: { template: "" },
    beforeEnter: async (
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) : Promise<void> =>  {
      const { logout: storeLogout } = useStore();

      console.log("inside logout LOGOUT beforeEnter");
      storeLogout();
      logout(); // don't await this
      coreClient.abort();
      next({ name: Routes.LOGIN.name });
    },
  },
];

const verifyLogin = (next: NavigationGuardNext) : void => {

  if (localStorage.getItem(TOKEN)) {
    next({ name: Routes.HOME.name });
  } else next();
};


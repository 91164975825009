<template>
  <c-typography
    v-if="isMainPage"
    class="d-block mb-7 text-center"
    data-testid="ai-twin-studio-title"
    variant="subtitle-2-600"
  >Photo Twin Studio
  </c-typography>
  <router-view />
</template>

<script lang="ts" setup>
// @ts-ignore
// prettier-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";

  import { computed } from "vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  const router = useRouter();

  const isMainPage = computed(
    () => router.currentRoute.value.name ===
      Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name
  );
</script>

<style lang="scss" scoped></style>

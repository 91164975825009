<template>
  <fullscreen-player
    v-if="showFullscreenVideo"
    @close-fullscreen="closeFullScreenDialog"
  />

  <c-theme-provider :theme="userStore.activeTheme">
    <c-layout
      ref="layout"
      :app-bar-extension-height="headerExtensionHeight"
      :center="false"
      :class="[
        'playground',
        {
          'ios-landscape':
            isIOS() && data.orientation.type?.includes('landscape'),
        },
        {
          'ios-portrait':
            isIOS() && data.orientation.type?.includes('portrait'),
        },
      ]"
      disable-route-watcher
      :expand-on-hover="isVideoCreatorRoute && expandNavOnHover && !isMobile"
      header
      :header-color="!isVideoShareRoute ? 'highlight-side-panel' : 'player-bg'"
      :hide-mobile-menu="isLogged() && !isVideoShareRoute"
      :logo-href="
        route.meta.requiresAuth ? Routes.HOME.path : 'https://gemelo.ai/'
      "
      :main-color="mainColor"
      :main-padding-class="mainClass"
      :mobile="isMobile"
      mobile-center-header-logo
      mobile-left-hamburger
      :mobile-menu-buttons="showMobileMenuButtons"
      :navigation="isLogged() && !isVideoShareRoute"
      :navigation-rail="false && !isMobile"
      navigation-width="256"
      outline
      permanent-navigation
      :privacy-link="Routes.PRIVACY_POLICY.path"
      :right-navigation="isRightNavigationVisible"
      right-navigation-width="360"
      right-panel-color="player-bg"
      :right-panel-fab="isRightPanelExpandable"
      :terms-link="Routes.TERMS_OF_SERVICE.path"
      @right-panel-opened="rightPanelOpened"
      @update-rail-value="updateRailValue"
    >
      <template #header-extension>
        <!-- here use teleport to insert app bar extension content here       -->
        <div class="h-100 header-extension w-100"></div>
      </template>

      <template #custom-logo>
        <img
          :src="
            isVideoShareRoute ? '/images/logo-black.svg' : '/images/logo.svg'
          "
        />
      </template>
      <template #header-links>
        <div
          v-if="isLogged() && !isVideoShareRoute"
          class="align-center d-flex flex-row jutify-center"
        >
          <user-ballance />
          <user-menu />
        </div>
        <div v-else></div>
      </template>
      <template
        v-if="isLogged() && isMobile && !isVideoShareRoute"
        #mobile-link
      >
        <user-menu />
      </template>

      <template #default>
        <img
          v-if="isVideoShareRoute"
          class="share-background"
          src="/images/share/bg.svg"
        />
        <div
          :class="[
            'main-container h-100',
            {
              'pa-0': isMobile,
              'main-container--synth-creator':
                router.currentRoute.value.name ===
                Routes.SYNTHETICS.children.LOOK_PICKER.name,
              'd-flex justify-center':
                router.currentRoute.value.name ===
                Routes.SYNTHETICS.children.GALLERY.name ||
                router.currentRoute.value.name ===
                Routes.SYNTHETICS.children.GALLERY_ID.name ||
                isVideoTwinCreator || router.currentRoute.value.meta.centered,
              'main-container--home':
                router.currentRoute.value.name === Routes.HOME.name ||
                router.currentRoute.value.name === Routes.OFFERS.name,
              'main-container--full': router.currentRoute.value.meta.fullWidth,
              'justify-center':
                isVideoShareRoute ||
                route.name === Routes.SHARE.children.SETTINGS.name
                  ? !isMobile
                  : true,
            },
          ]"
        >
          <router-view />
        </div>
      </template>
      <template #navigation>
        <nav-drawer @close-drawer="closeMobileDrawer" />
      </template>
      <template #rightNavigation>
        <div id="right-drawer">
          <right-drawer-content
            :key="recordingsVoices + componentKey"
            :mobile="isMobile"
            :variant="recordingsVoices"
          />
        </div>
      </template>
    </c-layout>
    <c-alert
      v-model="snackbarOpen"
      attach=".v-main"
      :class="[
        'alert',
        { 'alert--desktop': !isMobile && isLogged() && !isVideoShareRoute },
        { 'alert--mobile': isMobile },
        { 'alert--right-panel': isRightNavigationVisible && !isMobile },
        {
          'alert--right-panel--small':
            isRightNavigationVisible && width < 1000 && !isMobile,
        },
        { 'alert--navigation-rail': isVideoCreatorRoute && !isMobile },
        {
          'alert--navigation-rail--small':
            isVideoCreatorRoute && width < 1000 && !isMobile,
        },
      ]"
      :description="snackbarMessage"
      dismissable
      :mobile="isMobile"
      :mode="snackbarMode"
      :position="isMobile ? 'absolute' : 'fixed'"
      :timeout="5000"
      :width="isMobile ? '100%' : '520px'"
    />
    <standards-guidelines-dialog v-model="openStandardsGuidelinesDialog" />
    <not-enough-tokens-dialog v-model="notEnoughTokensDialog"/>
    <new-terms-dialog v-model="newTermsDialog" />
    <edit-cloned-voice-dialog
      v-model="editClonedVoiceDialog"
      :voice="clonedVoiceToModify"
      @edit-voice="(val: any) => editVoiceName(val)"
    />
    <delete-cloned-voice-dialog
      v-model="deleteClonedVoiceDialog"
      :voice="clonedVoiceToModify"
      @delete-voice="(val: number) => deleteVoice(val)"
    />
  </c-theme-provider>
</template>

<script setup lang="ts">
  import DeleteClonedVoiceDialog from "../components/Dialogs/DeleteClonedVoiceDialog.vue";
  import EditClonedVoiceDialog from "../components/Dialogs/EditClonedVoiceDialog.vue";
  import FullscreenPlayer from "@/core/components/FullscreenPlayer.vue";
  import { isLogged } from "../api/core.api";
  import { isMobile } from "@/core/utils/mobile";
  import NavDrawer from "@/core/components/NavDrawer/NavDrawer.vue";
  import NewTermsDialog from "../components/Dialogs/NewTermsDialog.vue";
  import NotEnoughTokensDialog from "../components/Dialogs/NotEnoughTokensDialog.vue";
  import { PLAYGROUND_FEATURES } from "../data/playgroundFeatures";
  import RightDrawerContent from "../components/RightDrawer/RightDrawerContent.vue";
  import { Routes } from "@/core/routes/core.guard";
  import StandardsGuidelinesDialog from "../components/Dialogs/StandardsGuidelinesDialog.vue";
  import UserBallance from "@/core/components/AppBar/UserBallance.vue";
  import UserMenu from "@/core/components/AppBar/UserMenu.vue";
  import { useRouter } from "vue-router";
  import { useStore } from "@/core/store";
  import { useUserStore } from "../store/userStore";
  import { useWindowSize } from "@vueuse/core";
  import {
    computed,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import { isAndroid, isIOS } from "../utils/capacitor";
  import {
    ScreenOrientation,
    type ScreenOrientationResult,
  } from "@capacitor/screen-orientation";

  // @ts-ignore
  import { CLayout } from "@charactr/wooper-ui/layouts";
  // @ts-ignore
  import { CAlert, CThemeProvider } from "@charactr/wooper-ui/atoms";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useDialogsStore } from "../store/useDialogsStore";
  import { useFullscreenVideoStore } from "../store/useFullscreenVideoStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import { useVoicesListStore } from "../store/useVoicesListStore";

  //STORES
  const store = useStore();
  const { headerExtensionHeight } = toRefs(store);
  const userStore = useUserStore();
  const appDrawersState = useAppDrawersStateStore();
  const userVoices = useVoicesStore();
  const voicesListStore = useVoicesListStore();
  const { $resetActiveClonedVoice } = voicesListStore;
  const { currentlyActiveClonedVoice } = toRefs(voicesListStore);

  const { showRightDrawer, isDefaultRail, expandNavOnHover, isRightPanelOpen } =
    toRefs(appDrawersState);
  const { setRail, setIsDefaultRail } = appDrawersState;

  const { snackbarMessage, snackbarMode, snackbarOpen } = toRefs(
    useSnackbarStore()
  );
  const { clonedVoicePostRemoveCleanUp } = userVoices;
  const videoFullscreenStore = useFullscreenVideoStore();
  const { $reset: resetFullscreenVideo } = videoFullscreenStore;
  const { showFullscreenVideo } = toRefs(videoFullscreenStore);
  const dialogsStore = useDialogsStore();

  const {
    newTermsDialog,
    editClonedVoiceDialog,
    deleteClonedVoiceDialog,
    openStandardsGuidelinesDialog,
    clonedVoiceToModify,
    notEnoughTokensDialog,
  } = toRefs(dialogsStore);

  //ROUTER
  const router = useRouter();

  const layout = ref();
  const { width } = useWindowSize();

  const data = reactive({
    orientation: "landscape-primary" as unknown as ScreenOrientationResult,
  });

  const closeFullScreenDialog = () => {
    resetFullscreenVideo();
  };

  ScreenOrientation.addListener(
    "screenOrientationChange",
    (orientation: ScreenOrientationResult) => {
      data.orientation = orientation;
    }
  );

  const componentKey = ref(1);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", () => {
      userStore.refreshUserActiveTheme();
    });

  const closeMobileDrawer = () => {
    layout.value.toggleLeftPanel();
  };

  const route = computed(() => {
    return router.currentRoute.value;
  });

  const isRightNavigationVisible = computed(
    () => route.value.meta.rightDrawer &&
      (!isMobile.value || (isMobile.value && showRightDrawer.value))
  );

  const mainColor = computed(() => {
    if (isMobile.value && router.currentRoute.value.meta.isDarkerMobile) {
      return "dark-bg";
    }

    if (isMobile.value && router.currentRoute.value.meta.isMobileFullScreenView) {
      return "player-bg";
    }

    return router.currentRoute.value.meta.lighterBg ? "player-bg" : "dark-bg";
  });

  const isLowerMarginTop = computed(() => {
    return router.currentRoute.value.meta.smallerTopMargin;
  });

  const isRightPanelExpandable = computed(() => {
    return width.value < 1400;
  });

  const mainClass = computed(() => {
    const classes = [];

    if (isMobile.value) {
      classes.push(
        router.currentRoute.value.meta.isMobileFullScreenView
          ? "pa-0 h-100"
          : "pa-4"
      );
    } else {
      if (isVideoCreatorRoute.value) {
        classes.push("full-height-video-creator py-4 px-6");
      } else if (width.value < 1000 && !isRequestVideoTwinPro.value) {
        classes.push(`${isLowerMarginTop.value ? "py-6" : "py-10"} px-2`);
      } else {
        classes.push(isLowerMarginTop.value ? "pa-6" : "pa-10");
      }
    }

    if (isVideoCreatorRoute.value) {
      classes.push("full-height-video-creator");
    }

    return classes.join(" ");
  });

  const showMobileMenuButtons = computed(() => route.value.name && isMobile.value ? !route.value.meta.hideMobileMenu : false
  );

  const isRecordingHistory = computed(() => {
    return (
      route.value.name === Routes.RECORDINGS_HISTORY.children.AUDIO.name ||
      route.value.name === Routes.RECORDINGS_HISTORY.children.VIDEO.name
    );
  });

  const isTtsPlaygroundRoute = computed(
    () => route.value.name === Routes.TEXT_TO_SPEECH.name
  );

  const isStsPlaygroundRoute = computed(
    () => route.value.name === Routes.SPEECH_TO_SPEECH.name
  );

  const isVoiceCloningPlaygroundRoute = computed(
    () => route.value.name === Routes.VOICE_CLONING.name
  );

  const isVideoShareRoute = computed(
    () => route.value.name === Routes.SHARE.children.PREVIEW.name
  );

  const isVideoTwinCreator = computed(() => route.value.path.includes(Routes.VIDEO_TWIN_CREATOR.path)
  );

  const isRequestVideoTwinPro = computed(
    () => route.value.name === Routes.REQUEST_VIDEO_TWIN_PRO.name
  );

  const isVideoToVideoPlaygroundRoute = computed(
    () => route.value.name === Routes.VIDEO_TO_VIDEO.children.CREATE.name ||
      route.value.name === Routes.VIDEO_TO_VIDEO.children.PREVIEW.name
  );

  const isVideoCreatorRoute = computed(() => route.value.path.includes(Routes.VIDEO_CREATOR.path)
  );

  const isVideoSynthesisPlaygroundRoute = computed(
    () => route.value.name === Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name ||
      route.value.name === Routes.VIDEO_SYNTHESIS.children.CREATE_AUDIO.name ||
      route.value.name === Routes.VIDEO_SYNTHESIS.children.PREVIEW.name
  );

  const recordingsVoices = computed(() => {
    if (isTtsPlaygroundRoute.value) return PLAYGROUND_FEATURES.TTS.name;

    if (isStsPlaygroundRoute.value) return PLAYGROUND_FEATURES.STS.name;

    if (isVideoCreatorRoute.value) return PLAYGROUND_FEATURES.VIDEO_CREATOR.name;

    if (isVoiceCloningPlaygroundRoute.value)
      return PLAYGROUND_FEATURES.VOICE_CLONING.name;

    if (isVideoToVideoPlaygroundRoute.value) return PLAYGROUND_FEATURES.VTV.name;

    if (isVideoSynthesisPlaygroundRoute.value)
      return PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name;

    return PLAYGROUND_FEATURES.VIDEO_CREATOR.name;
  });

  watch(
    () => recordingsVoices.value,
    (newVal: any) => {
      if (newVal) {
        componentKey.value++;
      }
    }
  );

  watch(
    () => route.value,
    (newVal) => {
      if (!newVal.path.includes(Routes.VIDEO_CREATOR.path)) {
        setIsDefaultRail(false);
        setRail(false);
      }
    },
    {
      immediate: true,
    }
  );

  const deleteVoice = (voiceId: number) => {
    if (
      currentlyActiveClonedVoice.value &&
      voiceId === currentlyActiveClonedVoice.value.id
    ) {
      $resetActiveClonedVoice();
    }
    clonedVoicePostRemoveCleanUp(voiceId);
  };

  const editVoiceName = (val: { id: number; name: string }) => {
    if (
      currentlyActiveClonedVoice.value &&
      val.id === currentlyActiveClonedVoice.value.id
    ) {
      currentlyActiveClonedVoice.value.name = val.name;
    }
  };

  const updateRailValue = (val: boolean) => {
    setRail(val);
  };

  onMounted(async () => {
    if (isIOS() || isAndroid()) {
      data.orientation = await ScreenOrientation.orientation();
    }
    if (document.querySelector(".ch2")) {
      (document.querySelector(".ch2") as HTMLElement).classList.add(
        "v-theme--defaultTheme"
      );
    }

    observeTeleportContainer();
  });

  const applyDialogStyle = () => {
    const dialog = document.querySelectorAll(".dialog")[0];

    if (!dialog) {
      return;
    }

    if (isMobile.value) {
      return;
    }

    if (
      isVideoCreatorRoute.value &&
      width.value < 1200 &&
      dialog.clientWidth > 620
    ) {
      return;
    }

    if (isRightNavigationVisible.value) {
      dialog.classList.add("dialog--right-panel");
      return;
    }

    if (dialog.parentElement && isLogged() && !isVideoShareRoute.value)
      dialog.parentElement.classList.add("dialog--left-panel");
  };

  const observerRef = ref<MutationObserver | null>(null);

  const observeTeleportContainer = () => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const addedNodes = Array.from(mutation.addedNodes);

          const isOverlayContainerModified = addedNodes.some(
            (node) => node.parentNode instanceof Element &&
              node.parentNode.classList &&
              node.parentNode.classList.contains("v-overlay-container")
          );

          if (isOverlayContainerModified) {
            applyDialogStyle();
          }
        }
      }
    });

    const config = { childList: true, subtree: true };

    observer.observe(document.body, config);

    observerRef.value = observer;
  };

  onBeforeUnmount(() => {
    if (observerRef.value) {
      observerRef.value.disconnect();
    }
  });

  watch(
    () => isRightPanelExpandable.value,
    (newVal) => {
      isRightPanelOpen.value = !newVal;
    }
  );

  const rightPanelOpened = (val: boolean) => {
    if (isRightPanelExpandable.value) {
      isRightPanelOpen.value = val;
    }
  };
</script>

<style scoped lang="scss">
.main-container {
  margin: auto;
  max-width: 1100px;

  &--synth-creator {
    max-width: 922px;
  }

  &--home {
    max-width: unset;
  }
}

@media (min-width: 1500px) {
  .main-container {
    max-width: 1200px;

    &--home {
      max-width: 1168px;
    }
  }
}

@media (min-width: 2000px) {
  .main-container {
    max-width: 1200px;

    &--home {
      max-width: 1168px;
    }
  }
}

@media (min-width: 2300px) {
  .main-container {
    max-width: 1200px;

    &--home {
      max-width: 1168px;
    }
  }
}

.main-container {
  &--full {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1400px;
  }
}

.pointer {
  cursor: pointer !important;
}

.studio {
  :deep(.outlined-left > .v-navigation-drawer__content) {
    display: flex;
    justify-content: center;
    padding-top: 24px !important;
  }
}

:deep(.outlined-left > .v-navigation-drawer__content) {
  background-color: rgb(var(--v-theme-player-bg)) !important;
  width: 100%;
}

:deep(.content--left-no-header) {
  justify-content: space-between;
}
</style>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.ch2-btn-primary,
.ch2-btn-secondary,
.ch2-open-settings-btn {
  border-radius: 8px !important;
}

.ch2-dialog {
  border-top: 1px solid rgba(var(--v-theme-outline));
}

.ch2-dialog,
.ch2-settings {
  font-family: "Inter";
}

.ch2-dialog,
.ch2-open-settings-btn {
  background-color: rgb(var(--v-theme-copy-primary)) !important;
}

.ch2-settings,
.ch2-settings-actions {
  background-color: rgb(var(--v-theme-copy-primary)) !important;
}

.active .ch2-select-tab {
  background: rgb(var(--v-theme-copy-primary)) !important;
  border-bottom-color: rgb(var(--v-theme-copy-primary)) !important;
}

.ch2-icon.ch2-visible {
  display: none !important;
}

.alert {
  bottom: 50px !important;

  &--desktop {
    .c-alert {
      //240px = width of nav drawer, we need 1/2 of 240px
      margin-left: 120px;
    }
  }

  &--right-panel {
    .c-alert {
      //240px = width of nav drawer && 360px = width of right drawer -> 120-180
      margin-left: -60px;
    }
  }
}

@media (min-width: 1500px) {
  .dialog {
    &--left-panel {
      //240px = width of nav drawer, we need 1/2 of 240px
      margin-left: 240px !important;
    }

    &--right-panel {
      //240px = width of nav drawer && 360px = width of right drawer -> 120-180
      margin-left: -60px;
    }
  }
}

.ios {
  &-portrait {
    @supports (padding: max(0px)) {
      nav,
      header,
      #app {
        padding-top: max(40px, env(safe-area-inset-top));
      }

      .v-main {
        padding-top: max(100px, env(safe-area-inset-top));
      }
    }
  }

  &-landscape {
    @supports (padding: max(0px)) {
      header {
        padding-inline-end: 40px !important;
        padding-left: max(40px, env(safe-area-inset-left));
      }

      nav {
        .nav__links {
          padding-left: 44px !important;
        }
      }

      .v-main {
        padding-left: max(44px, env(safe-area-inset-left));
        padding-right: max(24px, env(safe-area-inset-right));
        padding-top: max(64px, env(safe-area-inset-top));
      }
    }
  }
}

.full-height-video-creator {
  height: 100%;
}

#right-drawer {
  height: 100vh;
}

.attached {
  overflow: hidden;
}

.share-background {
  filter: blur(122px);
  height: 683px;
  left: 100px;
  position: absolute;
  top: 200px;
  transform: rotate(-124deg);
  width: 827px;
  z-index: 0;
}
</style>

<template>
  <c-dialog
    cancel-option
    :confirm-disabled="data.isDeletingAlert"
    width="500"
    @cancel="emit('update:modelValue', $event)"
    @confirm="deleteBillingAlert"
    @keydown.enter="deleteBillingAlert"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Delete token alert?</template>
    <template #content>
      <c-typography class="d-block text-center" color-class="copy-secondary" variant="body-2-400">
        You can always add a new one anytime later.
      </c-typography>
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { deleteAlert } from "@/core/services/billing.service";
  import { reactive } from "vue";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  //PROPS & EMITS
  const props = defineProps({
    id: {
      type: Number,
      default: 0,
    },
  });
  const emit = defineEmits(["alertDeleted", "update:modelValue"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();

  const data = reactive({
    isDeletingAlert: false,
    deleteAlertError: "",
  });

  async function deleteBillingAlert() {
    try {
      data.isDeletingAlert = true;
      await deleteAlert(props.id);
      emit("alertDeleted", props.id);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.isDeletingAlert = false;
    }
  }

</script>

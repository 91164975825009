<template>
  <div
    :class="[
      'd-flex flex-column',
      { wrapper: variant !== VideoPlayerVariants.LIST },
      { 'wrapper--active': variant !== VideoPlayerVariants.LIST && hover },
      { home: home },
      { 'pa-2': variant === VideoPlayerVariants.GALLERY_MINI },
    ]"
  >
    <div
      v-show="variant !== VideoPlayerVariants.LIST"
      :class="{
        'd-flex justify-center video-container position-relative':
          variant !== VideoPlayerVariants.LIST,
      }"
      :style="[{ width: `${imgDimensionX}px`, height: `${imgDimensionY}px` }]"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <c-tag
        v-if="showChip"
        :class="variant === VideoPlayerVariants.GALLERY_MINI ? 'tag--mini' : 'tag'"
        :mode="
          video.type === RecordingTypes.TTV_RAW
            ? 'blue'
            : video.type === RecordingTypes.ZERO_SHOT
              ? 'green'
              : 'pink'
        "
      >
        {{
          video.type === RecordingTypes.TTV_RAW
            ? 'high quality'
            : video.type === RecordingTypes.ZERO_SHOT
              ? 'express'
              : 'synthetic'
        }}
      </c-tag>
      <c-tag
        v-if="video.status === SyntheticStatus.ERROR && !showChip"
        mode="error"
      >ERROR</c-tag
      >
      <template v-if="!video.url">
        <c-skeleton
          v-if="!video.url && video.status !== SyntheticStatus.ERROR"
          class="skeleton-img"
        />
        <img
          v-show="!video.url && imageLoaded"
          class="img-absolute"
          :height="356"
          :src="data.videoPortraitUrl"
          width="auto"
          @load="loaded"
        />
      </template>
      <video-js-player
        v-else
        ref="videoPlayer"
        :options="{
          autoplay: false,
          controls: false,
          fill: true,

          poster: data.videoPortraitUrl,
          sources: [
            {
              src: video.url,
              type: 'video/mp4',
            },
          ],
        }"
        :variant="props.variant"
        @click="handleVideoClick"
        @end="videoEnded"
        @loaded="startLoadedVideo"
        @playing="startLoadedVideo"
        @stop="stopAudio"
      >
        <template #top-content>
          <img
            alt=""
            class="c-card__img-blur"
            :src="data.videoPortraitUrl"
          />
        </template>
      </video-js-player>
    </div>
    <right-drawer-player
      ref="rightDrawerPlayerRef"
      :bottom-border="bottomBorder"
      :bottom-mobile-player="props.bottomMobilePlayer"
      :chip="variant !== VideoPlayerVariants.LIST ? '' : chip"
      :cut-chip="cutChip"
      :disabled="!video.url"
      :hide-download="variant !== VideoPlayerVariants.LIST"
      :mode="props.mode"
      :name="props.video.name"
      :player-state="data.playerState"
      :recording="video"
      :show-tag="variant === VideoPlayerVariants.LIST && !twin"
      :simple="simple"
      :time="toSeconds(props.video.videoDurationMs)"
      :top-border="topBorder"
      @download-recording="downloadRecording"
      @edit-name="editName"
      @open-delete-dialog="data.deleteDialog = true"
      @open-edit-dialog="data.editDialog = true"
      @toggle="toggle"
    >
      <template v-if="variant === VideoPlayerVariants.GALLERY_MINI" #play>
        <!-- <c-button
          class="btn_gallery-mini"
          :data-testid="`vtv_player_play_${idx + 1}`"
          :disabled="!video.url"
          :icon="
            data.playerState === PlayerState.Playing
              ? 'ph:stop-fill'
              : 'ph:play-fill'
          "
          icon-height="12"
          :loading="data.playerState === PlayerState.Loading"
          mode="default"
          width="24"
          @click="toggle"
        /> -->
      </template>
      <template v-if="variant === VideoPlayerVariants.LIST" #img>
        <div class="align-center d-flex img-wrapper mr-3">
          <div class="">
            <img
              class="img"
              height="40"
              :src="data.videoPortraitUrl"
              width="40"
              @click="handleVideoClick"
            />
          </div>
        </div>
      </template>

      <template #dialogs>
        <edit-name-dialog
          v-if="data.editDialog"
          v-model="data.editDialog"
          :initial-name="props.video.name"
          @set-new-name="editName"
        />
        <delete-item-dialog
          v-if="data.deleteDialog"
          v-model="data.deleteDialog"
          @delete-item="deleteVideoRecording"
        />
        <video-dialog
          v-if="data.showPreview"
          v-model="data.showPreview"
          :mode="props.mode"
          :video="props.video"
          @download-recording="downloadRecording"
        />
      </template>
    </right-drawer-player>
  </div>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { AlertModes } from "@/core/types/other.types";
  import DeleteItemDialog from "../Dialogs/DeleteItemDialog.vue";
  import { downloadFromUrl } from "@/core/utils/download";
  import EditNameDialog from "../Dialogs/EditNameDialog.vue";
  import RightDrawerPlayer from "./RightDrawerPlayer.vue";
  import { useDrawerStore } from "../../store";
  import { useStore } from "@/core/store";
  import VideoDialog from "../Dialogs/VideoDialog.vue";
  import VideoJsPlayer from "@/core/components/RightDrawer/components/Players/VideoJsPlayer.vue";
  import {
    type Component,
    computed,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    type PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import {
    deleteVideo,
    updateVideoName,
  } from "@/core/services/synthetics.service";
  import { PlayerState, RecordingTypes } from "@/core/types/recording.types";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";
  import { VideoPlayerVariants, VideoStatus } from "@/core/types/video.types";

  // @ts-ignore
  import { CButton, CSkeleton, CTag } from "@charactr/wooper-ui/atoms";

  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { SyntheticStatus } from "@/core/types/synthetic.types";

  const props = defineProps({
    video: {
      type: Object as PropType<any>,
      default: null,
    },
    variant: {
      type: String as PropType<VideoPlayerVariants>,
      default: VideoPlayerVariants.LIST,
    },
    home: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: 0,
    },
    topBorder: {
      type: Boolean,
      default: true,
    },
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    bottomMobilePlayer: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: PLAYGROUND_FEATURES.VTV.name,
    },
    chip: {
      type: String,
      default: "",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: true,
    },
    twin: {
      type: Boolean,
      default: false,
    },
    playingVideoId: {
      type: Number,
      default: 0,
    },
    cutChip: {
      type: Boolean,
      default: false,
    },
    openPreviewOnClick: {
      type: Boolean,
      default: false,
    },
  });

  //PROPS & EMITS
  const emit = defineEmits(["setPlayingId"]);

  //STORE
  const { triggerRecordingsListToUpdate } = useStore();
  const { showSnackbar } = useSnackbarStore();
  const drawerStore = useDrawerStore();
  const progressStore = useProgressStore();
  const { stopAllVideos, stopAllAudio } = toRefs(progressStore);

  const videoPlayer = ref();
  const hover = ref();
  const imageLoaded = ref(false);
  const rightDrawerPlayerRef = ref<null | Component>(null);

  const data = reactive({
    playerState: PlayerState.Idle,
    editDialog: false,
    deleteDialog: false,
    showPreview: false,
    videoReady: false,
    videoPortraitUrl: "",
  });

  const loaded = () => {
    imageLoaded.value = true;
  };

  onBeforeUnmount(() => {
    resetVideoState();
  });

  onBeforeMount(async () => {
    data.videoPortraitUrl = props.video.thumbnailUrl || "/images/unknown.png";
  });

  const imgDimensionX = computed(() => {
    if (props.variant === VideoPlayerVariants.GALLERY) {
      if (isMobile.value) {
        return 288;
      } else {
        return 356;
      }
    }
    return 159;
  });

  const imgDimensionY = computed(() => {
    if (props.variant === VideoPlayerVariants.GALLERY) return 200;
    return 120;
  });

  watch(
    () => drawerStore.activePlayingVideoId,
    (val) => {
      if (val) {
        if (val !== props.video.id && props.variant === VideoPlayerVariants.LIST)
          resetVideoState();
      }
    }
  );

  watch(
    () => props.playingVideoId,
    (val) => {
      if (val !== props.video.id) resetVideoState();
    }
  );

  watch(
    () => stopAllVideos.value,
    () => {
      resetVideoState();

      nextTick(() => {
        stopAllVideos.value = false;
      });
    }
  );

  watch(
    () => props.variant,
    (newVal, oldVal) => {
      if (
        newVal === VideoPlayerVariants.LIST ||
        oldVal === VideoPlayerVariants.LIST
      ) {
        resetVideoState();
      }
    }
  );

  const toSeconds = (time: number) => {
    return time / 1000;
  };

  const handleVideoClick = () => {
    if (props.openPreviewOnClick) resetVideoState();
    data.showPreview = true;
  };

  const toggleListPreview = () => {
    if (props.video.status === VideoStatus.READY) {
      data.showPreview = true;
    } else {
      showSnackbar("Video not ready yet", AlertModes.ERROR);
    }
  };

  const stopAudio = () => {
    data.playerState = PlayerState.Idle;
  };

  const toggleGridPreview = async () => {
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
      }
    });
    if (props.video.status === VideoStatus.READY) {
      if (data.playerState === PlayerState.Idle) {
        stopAllAudio.value = true;
        data.playerState = PlayerState.Loading;
        videoPlayer.value.play();
      } else if (data.playerState === PlayerState.Playing) {
        data.playerState = PlayerState.Idle;
        videoPlayer.value.stop();
      }
    }
  };

  const startLoadedVideo = () => {
    data.playerState = PlayerState.Playing;
    drawerStore.activePlayingVideoId = props.video.id;
  };

  const toggle = async () => {
    if (props.variant === VideoPlayerVariants.LIST) {
      toggleListPreview();
    } else {
      emit("setPlayingId", props.video.id);

      toggleGridPreview();
    }
  };

  const downloadRecording = async () => {
    if (props.video.url) {
      downloadFromUrl(props.video.url, `${props.video.name}.wav`, "_blank");
    }
  };

  const deleteVideoRecording = async () => {
    try {
      await deleteVideo(props.video.id);

      const videoType = props.video.type;
      let mode = PLAYGROUND_FEATURES.VTV;

      if (
        videoType === RecordingTypes.TTV ||
        videoType === RecordingTypes.TTV_RAW ||
        videoType === RecordingTypes.ZERO_SHOT ||
        videoType === RecordingTypes.DUBBING
      ) {
        mode = PLAYGROUND_FEATURES.VIDEO_SYNTHESIS;
      }

      drawerStore.deleteVideo(props.video.id, mode);
      triggerRecordingsListToUpdate();
      showSnackbar("The video has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.deleteDialog = false;
    }
  };

  const editName = async (newName: string) => {
    try {
      await updateVideoName(props.video.id, newName);
      triggerRecordingsListToUpdate();
      showSnackbar(
        "The video name has been successfully changed.",
        AlertModes.SUCCESS
      );

      const videoType = props.video.type;
      let mode = PLAYGROUND_FEATURES.VTV;

      if (
        videoType === RecordingTypes.TTV ||
        videoType === RecordingTypes.TTV_RAW ||
        videoType === RecordingTypes.ZERO_SHOT ||
        videoType === RecordingTypes.DUBBING
      ) {
        mode = PLAYGROUND_FEATURES.VIDEO_SYNTHESIS;
      }

      drawerStore.updateVideoName(props.video.id, newName, mode);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.editDialog = false;
    }
  };

  const videoEnded = () => {
    data.playerState = PlayerState.Idle;
    drawerStore.activePlayingVideoId = 0;
  };

  const resetVideoState = () => {
    if (data.playerState === PlayerState.Playing) {
      videoPlayer.value.stop();
    }
    data.playerState = PlayerState.Idle;
  };
</script>

<style lang="scss" scoped>
.img-wrapper {
  background-color: rgb(var(--v-theme-light-frame)) !important;
  border-radius: 8px;
}

.img,
.video {
  border-radius: 8px;
  display: block;
  height: auto;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: contain;
  position: relative;
  width: auto;
}

.video-container {
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
}

.home {
  background-color: rgb(var(--v-theme-player-bg)) !important;
  width: 175px;
}

.video {
  cursor: pointer;
}

.tag {
  left: 8px;
  position: absolute;
  top: 8px;
  z-index: 120;

  &--mini {
    left: 4px;
    top: 4px;
  }
}

.skeleton {
  border-radius: 8px;
  opacity: 85%;
  position: relative;
  z-index: 1;

  :deep(.v-skeleton-loader__bone) {
    border-radius: 8px;
    height: 40px;
    width: 40px;
  }

  &--gallery {
    :deep(.v-skeleton-loader__bone) {
      border-radius: 8px;
      height: 288px;
      width: 288px;
    }

    &--mini {
      :deep(.v-skeleton-loader__bone) {
        border-radius: 8px;
        height: 159px;
        width: 159px;
      }
    }
  }
}

.wrapper {
  background: rgb(var(--v-theme-player-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  padding: 16px 16px 8px;

  &--active {
    background: rgb(var(--v-theme-aphla-bg));
    border-color: rgb(var(--v-theme-button-primary));
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%);
  }
}

.btn_gallery-mini {
  :deep(.v-btn__loader) {
    height: 12px !important;
    left: 6px;
    top: 6px;
    width: 12px !important;
  }
}

.skeleton-img {
  border-radius: 8px;
  opacity: 85%;
  position: relative;
  z-index: 1;

  :deep(.v-skeleton-loader__bone) {
    border-radius: 8px;
    height: 200px;
    width: 356px;
  }
}

.img-absolute {
  background-color: rgb(var(--v-theme-light-frame)) !important;
  border-radius: 8px;
  display: block;
  height: 200px;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 356px;
}

.c-card__img-blur {
  border-radius: 16px;
  filter: blur(10px);
  height: 100%;
  left: 50%;
  object-fit: cover;
  object-position: top;
  opacity: 70%;
  position: absolute;
  top: 0;
  transform: translateX(-50%) scale(1.2);
  width: 100%;
  z-index: 1

}
</style>

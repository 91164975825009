import { isMobile } from "@/core/utils/mobile";
import { Routes } from "@/core/routes/core.guard";
import { VIDEO_CREATOR } from "@/core/utils/analytics";

type VideoCreatorFeature = {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  disabled: boolean;
  routeName?: string;
  analyticsEvent? :string;
};

const uploadVideo: VideoCreatorFeature = {
  id: "uploadVideo",
  title: "Upload Video",
  subtitle: "Upload MOV or MP4 file from your device",
  icon: "ph:file-video-fill",
  disabled: false,
  routeName: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR.children?.VIDEO.name,
  analyticsEvent: VIDEO_CREATOR.actions.OPEN_UPLOAD_VIDEO,
};

const createAudioFeature = (lipsync: boolean): VideoCreatorFeature => {

  const modelKey = lipsync ? "LIPSYNC_MODEL" : "SYNTHETIC_PORTRAIT";

  return {
    id: "uploadAudio",
    title: "Upload Audio",
    subtitle: "Upload MP3, OGG or WAV file from your device",
    icon: "ph:file-audio-fill",
    disabled: false,
    routeName: Routes.AI_TWIN_STUDIO.children[modelKey]?.children?.VIDEO_CREATOR.children?.AUDIO.name,
    analyticsEvent: VIDEO_CREATOR.actions.OPEN_UPLOAD_AUDIO,
  };
};

const createTextFeature = (lipsync: boolean): VideoCreatorFeature => {
  const modelKey = lipsync ? "LIPSYNC_MODEL" : "SYNTHETIC_PORTRAIT";

  return {
    id: "typeText",
    title: "Type Text",
    subtitle: "Type message for text-to-speech",
    icon: "ph:text-t",
    disabled: false,
    routeName: Routes.AI_TWIN_STUDIO.children[modelKey]?.children?.VIDEO_CREATOR.children?.TEXT.name,
    analyticsEvent: VIDEO_CREATOR.actions.OPEN_TYPE_TEXT,
  };
};

// const dubbing: VideoCreatorFeature = {
//   id: "dubbing",
//   title: "Dubbing",
//   subtitle: "Upload any video with speech and dub over",
//   icon: "ph:microphone-stage",
//   disabled: false,
//   routeName: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR.children?.DUBBING.name,
//   analyticsEvent: VIDEO_CREATOR.actions.OPEN_DUBBING,
// };

// const multiText: VideoCreatorFeature = {
//   id: "multiText",
//   title: "Multi Text",
//   subtitle: "Upload CSV with multiple texts fields",
//   icon: "ph:text-t",
//   disabled: false,
//   routeName: Routes.AI_TWIN_STUDIO.children.VIDEO_CREATOR.children?.MULTI_TEXT.name,
//   analyticsEvent: VIDEO_CREATOR.actions.OPEN_MULTI_TEXT,
// };

// const importRecording: VideoCreatorFeature = {
//   id: "importRecording",
//   title: "Import Recording",
//   subtitle: "Select file from Recordings History",
//   icon: "ph:export",
//   disabled: false,
//   routeName: Routes.AI_TWIN_STUDIO.children.VIDEO_CREATOR.children?.RECORDING.name,
//   analyticsEvent: VIDEO_CREATOR.actions.OPEN_IMPORT_RECORDING,
// };

export const warningActionsTooltips = {
  MODEL_SELECTED: {
    desc: "You can not use selected visual in Upload Video section. Please change the visual selection.",
    title: "Forbidden action",
    action: null,
  },
  LIMIT_REACHED: {
    desc: "You've hit the limit on saved video recordings available for free accounts. Unlock unlimited recording history by upgrading to our premium plan.",
    title: "Recorded Video Limit Reached",
    action: "Upgrade",
  },
  NO_VOICE_FOR_TTV: {
    desc: "You can't use 'No Voice Conversion' with the Text-to-Video model. Please change the voice selection.",
    title: "Forbidden action",
    action: null,
  },
  NO_VOICE_FOR_DUBBING: {
    desc: "You can't use 'No Voice Conversion' with the Dubbing model. Please change the voice selection.'",
    title: "Forbidden action",
    action: null,
  },
};

function buildDesktopFeatures(lipsync: boolean): Array<VideoCreatorFeature> { ;

  const features = [createTextFeature(lipsync), createAudioFeature(lipsync)];

  if (!lipsync) {
    features.push(uploadVideo);
  }

  // if (userStore.ui_local_data.multiTextAccess) {
  //   features.push(multiText);
  // }

  return features;
}

export function buildVideoCreatorFeatures(lipsync: boolean): VideoCreatorFeature[] {

  return buildDesktopFeatures(lipsync);
}

<template>
  <VideoCreatorDesktop />
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { noVoiceConversionOption } from "@/core/types/voice.types";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { RightPanelTabs } from "@/core/types/other.types";
  import { useStore } from "@/core/store";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import { useUserStore } from "@/core/store/userStore";
  import VideoCreatorDesktop from "./VideoCreatorDesktop.vue";
  import { onBeforeMount, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";

  const store = useStore();
  const userStore = useUserStore();
  const route = useRoute();

  const appDrawersState = useAppDrawersStateStore();
  const { closeRightDrawerTab, openRightPanelTab, setExpandOnHover } = appDrawersState;

  const { updateVoice } = useVoicesStore();

  onBeforeMount(() => {

    if (!("selected" in route.query) || route.query.selected !== "true") {
      updateVoice(noVoiceConversionOption, PLAYGROUND_FEATURES.VIDEO_CREATOR.name);
    }
    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.RECORDINGS);
    }  });

  onMounted(() => {
    if (!userStore.videoSecondsMaxLength) {
      userStore.getSubscriptionLimits();
    }
    setTimeout(() => {
      setExpandOnHover(true);
    }, 1000);
  });

</script>

import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

//Removed dynamic import as this is a initial page from route
import SyntheticCreator from "../pages/SyntheticCreator.vue";

//Dynamically imported componetns
const SyntheticError = (): Promise<RouteComponent> =>  import("../components/SyntheticError.vue");
const SyntheticForm = (): Promise<RouteComponent> => import("../components/SyntheticForm.vue");
const SyntheticLookPicker = (): Promise<RouteComponent> => import("../components/SyntheticLookPicker.vue");
const SyntheticUploadPhoto = (): Promise<RouteComponent> => import("../components/SyntheticUploadPhoto.vue");

export default [
  {
    name: Routes.SYNTHETICS.name,
    path: Routes.SYNTHETICS.path,
    component: SyntheticCreator,
    meta: { requiresAuth: true, requiresPortraitStudioFeature: true, lighterBg: true },
    redirect: { name: Routes.SYNTHETICS.children.FORM.name },
    children: [
      {
        name: Routes.SYNTHETICS.children.FORM.name,
        path: Routes.SYNTHETICS.children.FORM.path,
        component: SyntheticForm,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
          isMobileFullScreenView: true,
        },
      },
      {
        name: Routes.SYNTHETICS.children.LOOK_PICKER.name,
        path: Routes.SYNTHETICS.children.LOOK_PICKER.path,
        component: SyntheticLookPicker,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
          isMobileFullScreenView: true,
        },
      },
      {
        name: Routes.SYNTHETICS.children.ERROR.name,
        path: Routes.SYNTHETICS.children.ERROR.path,
        component: SyntheticError,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
          isMobileFullScreenView: true,
        },
      },
    ],
  },
  {
    name: Routes.SYNTHETICS.children.UPLOAD_PHOTO.name,
    path: Routes.SYNTHETICS.children.UPLOAD_PHOTO.path,
    component: SyntheticUploadPhoto,
    meta: {
      requiresAuth: true,
      requiresPortraitStudioFeature: true,
      isMobileFullScreenView: true,
    },
  },
];

<template>
  <c-dialog
    cancel-option
    :close-option="false"
    :confirm-disabled="props.confirmDisabled"
    :height="653"
    :model-value="modelValue"
    persistent
    variant="secondary"
    :width="556"
    @cancel="cancelSelection"
    @confirm="confirmAction"
    @keydown.enter="confirmAction"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #confirm-label>
      Select
      {{ isCreateVideoButtonClicked ? "Voice & Create Video" : "" }}</template
    >
    <template #content>
      <div
        v-if="isCreateVideoButtonClicked || isGenerateAudioButtonClicked"
        class="d-flex flex-column frame mb-4 pa-3"
      >
        <c-typography
          class="b-block mb-1"
          color-class="highlight-color"
          variant="body-2-600"
        >{{
          isCreateVideoButtonClicked
            ? "Looks like your Twin doesn't have a voice yet!"
            : "Looks like we need a voice for your audio preview!"
        }}
        </c-typography>
        <c-typography
          class="b-block"
          color-class="highlight-color"
          variant="body-3-400"
        >
          {{
            isCreateVideoButtonClicked
              ? "Go ahead and pick one — you can always change it later for future videos."
              : "Please choose one to continue — you can always change it later."
          }}</c-typography
        >
      </div>
      <slot />
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
// @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  import { toRefs } from "vue";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["update:modelValue", "select", "cancel"]);

  const videoCreatorStore = useVideoCreatorStore();

  const { isCreateVideoButtonClicked, isGenerateAudioButtonClicked } =
    toRefs(videoCreatorStore);

  const cancelSelection = () => {
    emit("cancel");
    emit("update:modelValue", false);
  };

  const confirmAction = () => {
    emit("select");
    emit("update:modelValue", false);
  };
</script>

<style lang="scss" scoped>
.frame {
  background-color: rgb(var(--v-theme-button-secondary));
  border: 2px solid rgb(var(--v-theme-button-primary));
  border-radius: 8px;
}
</style>

<template>
  <voice-details
    v-if="
      selectedVoice &&
        props.variant.name !== PLAYGROUND_FEATURES.VOICE_CLONING.name
    "
    id="selectVoiceFeature"
    :class="[{ 'mt-3': isMobile }]"
    :cloned="selectedVoice.cloned"
    :disabled="isVoiceSelectionDisabled"
    :disabled-btn="isRecording"
    hide-cloned-actions
    :hide-info-details="true"
    :highlighted="props.highlighted"
    mode="single"
    no-divider
    :no-icon="selectedVoice.id === NoVoiceConversionType"
    :placeholder="usePlaceholder"
    :stop-playing="isRecording"
    :voice="selectedVoice"
    :wrapper-class="wrapperClass"
  >
    <template v-if="selectedVoice.id === NoVoiceConversionType" #img>
      <microphone-img />
    </template>
    <template #append>

      <c-button
        data-testid="select-voice-button"
        :disabled="isVoiceSelectionDisabled"
        :icon="usePlaceholder ? 'zondicons:add-solid' : 'fluent:arrow-swap-16-filled'"
        icon-height="24"
        mode="secondary-highlight"
        size="x-small"
        @click="openVoicesSelection"
      />
      <!-- {{
        label ? label :
        isMobile
          ? usePlaceholder || selectedVoice.id === NoVoiceConversionType
            ? "Select"
            : "Change"
          : usePlaceholder || selectedVoice.id === NoVoiceConversionType
            ? "Select Voice"
            : "Change Voice"
      }} -->
    </template>
  </voice-details>

  <voice-selection
    v-model="voiceSelectionDialogOpened"
    :mode="props.variant.name"
  />
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";

  import MicrophoneImg from "@/core/components/Voice/MicrophoneImg.vue";
  import VoiceDetails from "@/core/components/Voice/VoiceDetails.vue";
  import VoiceSelection from "@/core/components/Dialogs/VoiceSelection.vue";

  import { computed, type PropType, ref, toRefs } from "vue";

  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  import { NoVoiceConversionType, type Voice } from "@/core/types/voice.types";

  // @ts-ignore
  import { CButton } from "@charactr/wooper-ui/atoms";

  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  import { analytics, VIDEO_CREATOR } from "@/core/utils/analytics";

  import { storeToRefs } from "pinia";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  //PROPS & EMITS
  const props = defineProps({
    variant: {
      type: Object as PropType<PlaygroundFeature>,
      default: () => ({}),
    },
    voice: {
      type: Object as PropType<Voice>,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    hideInfoDetails: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["handleClick"]);

  //STORE
  const progressStore = useProgressStore();
  const { isRecording, isConverting } = toRefs(progressStore);
  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);
  const { isCreatingVideo, voiceSelectionDialogOpened } = storeToRefs(useVideoCreatorStore());

  const disabledVoice: Voice = {
    createdAt: "",
    id: 267,
    description: "",
    previewUrls: [],
    labels: [
      {
        category: "otherFeatures",
        label: "Explanatory",
      },
    ],
    name: "Voice Name",
  };

  const openVoicesSelection = () => {
    if (props.label) {
      emit("handleClick");

    } else {
      if (props.variant.name === PLAYGROUND_FEATURES.VIDEO_CREATOR.name) {
        analytics.sendEvent("video-creator", VIDEO_CREATOR.actions.OPEN_VOICE_CHOICE);
        voiceSelectionDialogOpened.value = true;
      } else {
        if (props.variant.name !== PLAYGROUND_FEATURES.VOICE_CLONING.name) {
          voiceSelectionDialogOpened.value = true;
        }
      }
    }
  };

  const usePlaceholder = computed(
    () => !userSelectedVoice.value[props.variant.name]
  );

  const selectedVoice = computed(
    () => props.voice || userSelectedVoice.value[props.variant.name] || disabledVoice
  );

  const isVoiceSelectionDisabled = computed(() => {
    if (
      props.variant.name === PLAYGROUND_FEATURES.VTV.name ||
      props.variant.name === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name ||
      props.variant.name === PLAYGROUND_FEATURES.VIDEO_CREATOR.name
    ) {
      return isCreatingVideo.value;
    }
    return isConverting.value || isRecording.value;
  });

  const wrapperClass = computed(
    () => `pl-2 pr-4 ${isMobile.value ? "" : ""}`
  );
</script>

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div>
    <c-dialog
      cancel-option
      :close-option="false"
      :model-value="modelValue"
      width="440"
      @cancel="$emit('update:modelValue', false)"
      @confirm="editVoice"
      @keydown.enter="editVoice"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #title>Edit cloned voice</template>
      <template #content>
        <div class="align-baseline d-flex">
          <!-- <img
            alt="voice icon"
            class="mr-2"
            :src="voice?.imageUrl || voiceImg"
          /> -->
          <c-input
            v-model="data.voiceName"
            class="mt-4"
            density="compact"
            maxLength="50"
            min-length="1"
            mode="outlined"
            placeholder="Voice Name"
            required
          />
        </div>
      </template>
      <template #confirm-label> Save </template>
    </c-dialog>
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import { editClonedVoice } from "@/core/services/playground.service";
  import type { Voice } from "@/core/types/voice.types";
  import voiceImg from "@/assets/voice.webp";
  import { analytics, VOICE_CLONING } from "@/core/utils/analytics";
  import { type PropType, reactive, watch } from "vue";

  // @ts-ignore
  import { CInput } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";

  //PROPS & EMITS
  const props = defineProps({
    voice: {
      type: Object as PropType<Voice>,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["editVoice", "update:modelValue"]);
  //STORE
  const { showSnackbar } = useSnackbarStore();
  const { updateClonedVoiceName } = useVoicesListStore();

  const data = reactive({
    voiceName: "",
    isLoading: false,
  });

  const editVoice = async () => {
    if (!props.voice.name) {
      return;
    }
    if (props.voice.name === data.voiceName) {
      emit("update:modelValue", false);
      return;
    }
    try {
      data.isLoading = true;

      await editClonedVoice(props.voice.id, data.voiceName);

      emit("editVoice", { id: props.voice.id, name: data.voiceName });

      showSnackbar("Voice name has been changed", AlertModes.SUCCESS);
      updateClonedVoiceName(props.voice.id as number, data.voiceName);

      analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.VOICE_EDIT, {
        voice_id: props.voice.id,
      });

      emit("update:modelValue", false);
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.isLoading = false;
    }
  };

  watch(
    () => props.modelValue,
    (newVal: boolean) => {
      if (newVal) {
        data.voiceName = props.voice.name;
      }
    }
  );
</script>

<style scoped lang="scss">
img {
  border-radius: 8px;
  height: 40px;
}
</style>

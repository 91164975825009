<template>
  <div>
    <c-typography
      class="d-block"
      color-class="copy-secondary"
      variant="body-2-500"
    >Twin name*</c-typography
    >
    <c-input
      v-model="name"
      class="mb-4 mt-2"
      density="compact"
      :disabled="isSubmittingVideo"
      :max-length="75"
      mode="outlined"
      placeholder="Type twin name"
    />
  </div>
  <div
    :class="[
      { 'upload-container d-flex flex-column': currentUploadStep === UPLOAD.INIT },
      {
        'upload-container--mobile mt-4': isMobile,
      },
    ]"
  >
    <HighlightedInfoPanel
      :lipsync="isLipsyncUpload"
    />
    <ConfigureVideoUploader
      hide-video-consent
      max-height="calc(-523px + 100vh)"
      :mini="false"
      :show-title="false"
      twin-creation
    />
  </div>
  <playground-consent-checkbox
    v-if="currentUploadStep === UPLOAD.SUBMIT"
    v-model="playgroundStore.consent.twinCreation"
    class="mt-2"
  />
  <div
    :class="[
      'action-buttons align-center justify-center mt-4',
      { 'd-flex': !isMobile },
      { 'gap-8': isMobile },
    ]"
  >
    <CButton
      :block="isMobile"
      class="action-buttons__btn"
      :disabled="!playgroundStore.consent.twinCreation || !name.length"
      :loading="isSubmittingVideo"
      @click="sendTwinData"
    >Submit Video</CButton
    >
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import ConfigureVideoUploader from "@/modules/videoCreator/components/video/ConfigureVideoUploader.vue";
  import CryptoJS from "crypto-js";
  import HighlightedInfoPanel from "./HighlightedInfoPanel.vue";
  import { isMobile } from "@/core/utils/mobile";
  import PlaygroundConsentCheckbox from "@/modules/playground/components/PlaygroundConsentCheckbox.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { storeToRefs } from "pinia";
  import { submitZeroShotVideo } from "../services/videoTwinCreator.service";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import { computed, onBeforeUnmount, ref, watch } from "vue";
  import {
    S3Upload,
    uploadModelVideo,
    verifyModel,
  } from "@/core/services/training.service";
  //@ts-ignore
  import { CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    id: {
      type: Boolean,
      default: true,
    },
  });

  const { showSnackbar } = useSnackbarStore();

  enum UPLOAD {
    INIT = "init",
    SUBMIT = "submit",
  }

  const currentUploadStep = ref(UPLOAD.INIT);
  const blockStepChange = ref(false);
  const isSubmittingVideo = ref(false);
  const playgroundStore = usePlaygroundStore();
  const name = ref("");
  const router = useRouter();
  const userStore = useUserStore();

  const { selectedVideo } = storeToRefs(
    useVideoCreatorStore()
  );

  const isLipsyncUpload = computed(() => {
    return router.currentRoute.value.params.type === "normal";
  });

  watch(
    () => selectedVideo.value,
    (val) => {
      if (!blockStepChange.value) {
        if (val) {
          currentUploadStep.value = UPLOAD.SUBMIT;
        } else {
          currentUploadStep.value = UPLOAD.INIT;
        }
      } else {
        blockStepChange.value = false;
      }
    }
  );

  const sendTwinData = async () => {
    if (isLipsyncUpload.value) {
      await uploadModel();
    } else {
      await submitVideo();
    }
  };

  const submitVideo = async () => {
    if (selectedVideo.value) {
      isSubmittingVideo.value = true;
      try {
        const payload = {
          video: selectedVideo.value.file,
          videoDurationMs: selectedVideo.value.duration * 1000 || 0,
          name: name.value,
        };
        const resp = await submitZeroShotVideo(payload);

        userStore.addLipsyncModel(resp);
        router.push({
          name: Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.name,
          params: { id: resp.id, type: "zero-shot" },
        });
      } catch (e) {
        console.error("e", e);
      } finally {
        isSubmittingVideo.value = false;
      }
    } else {
      console.error("No video");
    }
  };

  onBeforeUnmount(() => {
    selectedVideo.value = null;
  });

  const uploadModel = async () => {
    isSubmittingVideo.value = true;
    try {
      const contentMd5 = await getFileMd5(selectedVideo.value?.file as File);

      const result = await uploadModelVideo({
        name: name.value,
        contentLength: Number(selectedVideo.value?.file.size) || 0,
        contentType: "video/mp4",
        contentMd5: contentMd5,
      });

      await S3Upload({
        url: result.uploadUrl,
        video: selectedVideo.value?.file as File,
      });

      const resp = await verifyModel(result.id);

      userStore.addLipsyncModel(resp);

      router.push({
        name: Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.name,
        params: { id: result.id, type: "normal" },
      });

    } catch (e: any) {
      showSnackbar(
        e.response?.data?.message || "Error while uploading training video",
        AlertModes.ERROR
      );
    } finally {
      isSubmittingVideo.value = false;
    }
  };

  const getFileMd5 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const chunkSize = 1024 * 1024 * 10; // 10MB chunks
      const reader = new FileReader();
      const crypto = CryptoJS.algo.MD5.create();
      let offset = 0;

      const readNextChunk = () => {
        const slice = file.slice(offset, offset + chunkSize);

        reader.readAsArrayBuffer(slice);
      };

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result) {
          try {
            const wordArray = arrayBufferToWordArray(
              event.target.result as ArrayBuffer
            );

            crypto.update(wordArray);
            offset += chunkSize;

            if (offset < file.size) {
              readNextChunk();
            } else {
              const hash = crypto.finalize().toString(CryptoJS.enc.Hex);

              resolve(hash);
            }
          } catch (error) {
            reject(
              new Error("Failed to process file: " + (error as Error).message)
            );
          }
        } else {
          reject(new Error("File read error"));
        }
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };

      readNextChunk();
    });
  };

  const arrayBufferToWordArray = (ab: ArrayBuffer): any => {
    const i8a = new Uint8Array(ab);
    const words = [];

    for (let i = 0; i < i8a.length; i += 4) {
      words.push(
        (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]
      );
    }
    return CryptoJS.lib.WordArray.create(words, i8a.length);
  };

</script>

<style lang="scss" scoped>
.upload-container {
  height: 320px;

  &--mobile {
    :deep(.dnd-upload-container) {
      padding: 0 !important;
    }

    :deep(.drag-and-drop-wrapper) {
      padding: 0 !important;
    }
  }
}

.action-buttons {
  gap: 16px;

  &__btn {
    flex: 1;
  }
}

:deep(.dnd-upload-container) {
  height: 100%;
}

.gap-8 {
  gap: 8px;
}
</style>

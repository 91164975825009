export function getUrl(base: string, paths: string | Array<string>): string {
  base = base.replace(/\/+$/, "");

  if (typeof paths === "string") {
    paths = [paths];
  }

  paths = paths.map((path) => path.replace(/^\/+/, ""));

  return `${base}/${paths.join("/")}`;
}

export function apiUrl(path: string, queryParams?: string): string {
  return getUrl(`${import.meta.env.VITE_APP_SLOWPOKE_API_URL}`, ["v1", `${path}${queryParams ?? ""}`]);
}

export function apiV2Url(path: string, queryParams?: string): string {
  return getUrl(`${import.meta.env.VITE_APP_SLOWPOKE_API_URL}`, ["v2", `${path}${queryParams ?? ""}`]);
}

export function apiV3Url(path: string, queryParams?: string): string {
  return getUrl(`${import.meta.env.VITE_APP_SLOWPOKE_API_URL}`, ["v3", `${path}${queryParams ?? ""}`]);
}

export function noVersionApiUrl(path: string, queryParams?: string): string {
  return getUrl(`${import.meta.env.VITE_APP_SLOWPOKE_API_URL}`, [`${path}${queryParams ?? ""}`]);
}

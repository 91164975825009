
export type FileProcessingStage = {
    tag: string,
    title: string
  };

  export enum FileState {
    INITIAL= "initial",
    UPLOADING_AUDIO_FILE = "uploadingAudioFile",
    WAITING_FOR_AUDIO_PROCESSING = "waitingForAudioProcessing",
    AUDIO_PROCESSING = "audioProcessing",
    AUDIO_PROCESSING_SUCCESS = "audioProcessingSuccess",
    UPLOADING_VIDEO_FILE = "uploadingVideoFile",
    WAITING_FOR_VIDEO_PROCESSING = "waitingForVideoProcessing",
    VIDEO_PROCESSING = "videoProcessing",
    VIDEO_PROCESSING_SUCCESS = "videoProcessingSuccess",
    CONVERTING_TEXT_TO_AUDIO = "convertingTextToAudio"
  }

  export const INITIAL_STATE: FileProcessingStage = {
    tag: FileState.INITIAL,
    title: "Initial",
  };

  export const AUDIO_UPLOADING_FILE: FileProcessingStage = {
    tag: FileState.UPLOADING_AUDIO_FILE,
    title: "Uploading your audio...",
  };

  export const AUDIO_WAIT_FOR_PROCESSING: FileProcessingStage = {
    tag: FileState.WAITING_FOR_AUDIO_PROCESSING,
    title: "Audio is waiting to be processed...",
  };

  export const AUDIO_PROCESSING: FileProcessingStage = {
    tag: FileState.AUDIO_PROCESSING,
    title: "Audio is being processed...",
  };

  export const AUDIO_PROCESSING_DONE: FileProcessingStage = {
    tag: FileState.AUDIO_PROCESSING_SUCCESS,
    title: "Audio done",
  };

  export const VIDEO_UPLOADING_FILE: FileProcessingStage = {
    tag: FileState.UPLOADING_VIDEO_FILE,
    title: "Uploading your video...",
  };

  export const VIDEO_WAIT_FOR_PROCESSING: FileProcessingStage = {
    tag: FileState.WAITING_FOR_VIDEO_PROCESSING,
    title: "Video is waiting to be processed...",
  };

  export const VIDEO_PROCESSING: FileProcessingStage = {
    tag: FileState.VIDEO_PROCESSING,
    title: "Video is being processed...",
  };

  export const VIDEO_PROCESSING_DONE: FileProcessingStage = {
    tag: FileState.VIDEO_PROCESSING_SUCCESS,
    title: "Video done",
  };

  export const CONVERTING_TEXT_TO_AUDIO: FileProcessingStage = {
    tag: FileState.CONVERTING_TEXT_TO_AUDIO,
    title: "Uploading text...",
  };

  function isUploadingFile(state: FileProcessingStage): boolean {
    return state.tag === FileState.UPLOADING_AUDIO_FILE || state.tag === FileState.UPLOADING_VIDEO_FILE;
  }

  function isWaitingForProcessing(state: FileProcessingStage): boolean {
    return state.tag === FileState.WAITING_FOR_AUDIO_PROCESSING || state.tag === FileState.WAITING_FOR_VIDEO_PROCESSING;
  }

  function isConvertingTextToAudio(state: FileProcessingStage): boolean {
    return state.tag === FileState.CONVERTING_TEXT_TO_AUDIO;
  }

  function isProcessing(state: FileProcessingStage): boolean {
    return state.tag === FileState.AUDIO_PROCESSING || state.tag === FileState.VIDEO_PROCESSING;
  }

  function isProcessingCompleted(state: FileProcessingStage): boolean {
    return state.tag === FileState.AUDIO_PROCESSING_SUCCESS || state.tag === FileState.VIDEO_PROCESSING_SUCCESS;
  }

  function isInitial(state: FileProcessingStage): boolean {
    return state.tag === FileState.INITIAL;
  }

  function isAnyProcessingActive(state: FileProcessingStage) : boolean {
    return isUploadingFile(state) || isWaitingForProcessing(state) || isConvertingTextToAudio(state);
  }
  export const fileProcessing = {
    isInitial,
    isUploadingFile,
    isWaitingForProcessing,
    isProcessing,
    isProcessingCompleted,
    isAnyProcessingActive,
    isConvertingTextToAudio,
  };


import { connect } from "extendable-media-recorder-wav-encoder";
import { register } from "extendable-media-recorder";

const isConnected = false;

export async function useComposableMediaRecorder(): Promise<void> {
  if (!isConnected) {
    /**
     * @desc: needed for wav format.
     * @see:
     * https://stackoverflow.com/a/65191456
     * https://github.com/chrisguttandin/extendable-media-recorder
     */
    try {
      await register(await connect());
    } catch (e: unknown) {
      // TODO: fix connection workaround for hot reloading
      console.warn(e instanceof Error ? e.message : "ERROR");
    }
  }
}

import { PLAYGROUND_FEATURES } from "../data/playgroundFeatures";

export interface Label {
  category: string;
  label: string;
}

export enum VoiceTierType {
  PREMIUM = "premium",
  LEGENDARY = " legendary",
  FREE = "free",
}

export interface Voice {
  description: string;
  id: number | string;
  name: string;
  labels?: Array<Label>;
  previewUrls: string[];
  rating?: number | null;
  disabled?: boolean;
  new?: boolean;
  cloned?: boolean;
  createdAt?: string;
  imageUrl?: string;
  voiceTier?: VoiceTierType;
}

export enum VoiceType {
  SYSTEM = "system",
  CLONED = "cloned",
}

export enum VoiceTabs {
  ALL = "all",
  SYSTEM = "system",
  CLONED = "cloned",
}

export const NoVoiceConversionType = "noConversion" as const;
export type NoVoiceConversion = typeof NoVoiceConversionType;

export const noVoiceConversionOption = {
  id: NoVoiceConversionType,
  description: "",
  name: "No Voice Conversion",
  previewUrls: [],
};

export type FilterCategory = Record<string, Set<string>>;

export type FilterItem = {
  name: string;
  label: string;
  items: Set<string>;
};

export type SelectedFilter = Record<string, Array<string>>;

export const voiceLabelCategories = [
  "gender",
  "pitch",
  "voiceFeatures",
  "voiceMoods",
  "otherFeatures",
  "exampleUsages",
] as const;
export type VoiceLabelCategory = (typeof voiceLabelCategories)[number];

export const USER_DEFAULT_VOICES = {
  [PLAYGROUND_FEATURES.TTS.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.STS.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.VOICE_CLONING.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.TWIN_CREATOR.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.VIDEO_CREATOR.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]: undefined as Voice | undefined,
  [PLAYGROUND_FEATURES.SYNTH_UPLOAD_PIC.name]: undefined as Voice | undefined,
};

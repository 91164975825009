import { apiUrl } from "@/core/utils/api";
import type { ContactUsData } from "../types/contact.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export async function sendContactUsMessage(
  userData: ContactUsData
): Promise<void> {

  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/surveys/4`);

   const response = await coreClient.post(url, userData);

   return response.data;
}

import { defineStore } from "pinia";
import { onBeforeUnmount, ref } from "vue";
import type { ApiRecording } from "../types/recording.types";

export const useMobileRecentRecordingStore = defineStore("mobileRecentRecordingStore", () => {

    const mobileRecentAudioRecording = ref(null as ApiRecording | null);

    const $reset = () => {
        mobileRecentAudioRecording.value = null;
    };

    const setRecordingName = (newName: string) => {
        if (mobileRecentAudioRecording.value)
        mobileRecentAudioRecording.value.recordingName = newName;
    };

    onBeforeUnmount(() => {
        $reset();
    });

    return {
        mobileRecentAudioRecording,

        setRecordingName,
        $reset,
    };
  });

import { useComposableMediaRecorder } from "./useExtendableMediaRecorder";
import useListener from "./useListener";
import { computed, type ComputedRef, reactive, type Ref, ref } from "vue";
import {
  type IBlobEvent,
  type IMediaRecorder,
  MediaRecorder,
} from "extendable-media-recorder";
import { RecordingState, type Status } from "@/core/types/playground.types";

declare global {
  interface Window {
    webkitAudioContext?: AudioContext;
  }
}

type RecordingEvent = "onStop" | "onStart" | "onSend";

const initialRecorderOptions = {
  audio: true,
  video: false,
};

interface AppRecorder {
  isRecording: ComputedRef<boolean>;
  statusType: ComputedRef<RecordingState>;
  isError: ComputedRef<boolean>;
  statusMsg: ComputedRef<string | null>;
  accessDenied: Ref<boolean>;
  isCheckingAccess: Ref<boolean>;
  stop({ isCancelled }?: { isCancelled?: boolean | undefined }): void;
  start(): void;
  toggle(): void;
  listen(arg0: string, arg1: any): void;
  unlisten(arg0: string): void;
  unlistenAll(): void;
}

export default function useRecorder(): AppRecorder {
  const data = reactive({
    chunks: [] as Blob[],
    dataArr: new Uint8Array() as Uint8Array,
    support: false,
    status: {
      type: RecordingState.Idle,
    } as Status<RecordingState>,
  });

  let stream: MediaStream,
    recorder: IMediaRecorder,
    audioContext: AudioContext,
    source: MediaStreamAudioSourceNode,
    analyser: AnalyserNode;

  const accessDenied = ref(false);
  const isCheckingAccess = ref(true);
  const statusType = computed(() => data.status.type);
  const statusMsg = computed(() => data.status?.msg ?? null);

  const isRecording = computed(
    () => statusType.value === RecordingState.Recording
  );

  const isError = computed(() => statusType.value === RecordingState.Error);

  const { listen, unlisten, unlistenAll, dispatchToListener } =
    useListener<RecordingEvent>();

  const constraints = { ...initialRecorderOptions };

  useComposableMediaRecorder();

  data.support = !!navigator.mediaDevices;

  if (!data.support) {
    data.status = {
      type: RecordingState.Error,
      msg: "Recording is not supported.",
    };
  }

  const toggle = () => {
    if (!isRecording.value) {
      start();
    } else {
      stop();
    }
  };

  const start = async () => {
    if (!data.support) {
      return;
    }

    try {
      // @INFO: check permissions
      stream = await navigator.mediaDevices.getUserMedia(constraints);

      // @INFO: init
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      source = audioContext.createMediaStreamSource(stream);
      analyser = audioContext.createAnalyser();
      source.connect(analyser);
      recorder = new MediaRecorder(stream, { mimeType: "audio/wav" });
      data.dataArr = new Uint8Array(analyser.frequencyBinCount);

      // @INFO: init listeners
      recorder.ondataavailable = (e: IBlobEvent) => {
        data.chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(data.chunks, { type: "audio/wav" });

        data.chunks = [];
        data.dataArr = new Uint8Array();

        _stopAudioAccess();

        analyser.disconnect();
        audioContext.close();

        if (statusType.value !== RecordingState.Cancelled) {
          dispatchToListener("onSend", blob);

          data.status = {
            type: RecordingState.Stopped,
          };
        }
      };

      _startRecording();
    } catch (e: unknown) {
      _stopAudioAccess();
      accessDenied.value = true;
      data.status = {
        type: RecordingState.Error,
        msg: e instanceof Error ? e.message : "ERROR",
      };

      console.error(e);
    }
    finally {
      isCheckingAccess.value = false;
    }
  };

  const stop = ({ isCancelled }: { isCancelled?: boolean } = {}) => {
    _stopAudioAccess();
    dispatchToListener("onStop");
    if (recorder) recorder.stop();

    if (isCancelled) {
      data.status = {
        type: RecordingState.Cancelled,
      };
    }
  };

  const _startRecording = () => {
    recorder.start();

    data.status = {
      type: RecordingState.Recording,
    };

    dispatchToListener("onStart");
  };

  const _stopAudioAccess = () => {
    const audioTrack = stream?.getAudioTracks()[0] ?? null;

    if (audioTrack) {
      audioTrack.stop();
    }
  };

  return {
    statusType,
    statusMsg,
    isRecording,
    isError,
    accessDenied,
    isCheckingAccess,
    start,
    stop,
    toggle,
    listen,
    unlisten,
    unlistenAll,
  };
}

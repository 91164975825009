//Removed dynamic import as this is a initial page from route
import Billing from "../pages/Billing.vue";
const BillingCancel = (): Promise<RouteComponent> => import("../pages/BillingCancel.vue");
const BillingSuccess = (): Promise<RouteComponent> =>  import("../pages/BillingSuccess.vue");

import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.BILLING.name,
    path: Routes.BILLING.path,
    component: Billing,
    meta: { requiresAuth: true },
  },
  {
    name: Routes.BILLING.children.SUCCESS.name,
    path: Routes.BILLING.children.SUCCESS.path,
    component: BillingSuccess,
    meta: { requiresAuth: true },
  },
  {
    name: Routes.BILLING.children.CANCELLED.name,
    path: Routes.BILLING.children.CANCELLED.path,
    component: BillingCancel,
    meta: { requiresAuth: true },
  },
];

//Removed dynamic import as this is a initial page from route
import Shop from "../pages/Shop.vue";

import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.SHOP.name,
    path: Routes.SHOP.path,
    component: Shop,
    meta: { requiresAuth: true },
  },
];

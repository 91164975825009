<template>
  <c-dialog
    cancel-option
    icon="ph:lock-fill"
    :model-value="modelValue"
    width="424"
    @cancel="emit('update:modelValue', false)"
    @confirm="subscribe"
    @keydown.enter="subscribe"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title
    >{{ audio ? "Audio duration" : "Characters" }} limit exceeded</template
    >
    <template #content>
      <div class="text-center">
        <c-typography color-class="copy-secondary" variant="body-2-400">
          {{ dialogText }}
        </c-typography>
      </div>
    </template>
    <template #confirm-label>Subscribe</template>
  </c-dialog>
</template>

<script lang="ts" setup>

  import { AlertModes } from "@/core/types/other.types";
  import { checkoutNew } from "@/core/services/checkout.service";
  import { computed } from "vue";
  import useFormState from "@/core/composables/useFormState";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    audio: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();

  const checkoutForm = useFormState(checkoutNew, FORM_INITIAL_STATE);

  const state = computed(() => {
    return checkoutForm.state.value;
  });

  const subscribe = async () => {
    checkoutForm
      .submit({ subscription: true })
      .then((checkout: any) => {
        if (form.isSuccess(state.value) && checkout?.data.url) {
          window.location.href = checkout?.data.url;
        }
        if (form.isErrored(state.value)) {
          showSnackbar(
            state.value.message || "Error occured",
            AlertModes.ERROR
          );
        }
      });
  };

  const dialogText = computed(() => {
    if (props.audio)
      return "You have a limit of 30 seconds. Unlock 30 minutes of audio conversion with premium subscription.";
    else
      return "Get Early Birds Subscription to extend the characters limit from 300 to 25,000 (~30 minutes).";
  });
</script>

<template>
  <teleport to="body">
    <video
      id="fullscreen_video"
      ref="videoPlayer"
      class="video-js"
      preload="auto"
    />
  </teleport>
</template>

<script lang="ts" setup>
  import "video.js/dist/video-js.css";
  import { useFullscreenVideoStore } from "../store/useFullscreenVideoStore";
  import videojs from "video.js";
  import { onBeforeUnmount, onMounted, ref, toRefs } from "vue";

  const emit = defineEmits(["closeFullscreen"]);

  //STORE
  const fullscreenVideoStore = useFullscreenVideoStore();
  const { fullscreenVideo } = toRefs(fullscreenVideoStore);

  const videoPlayer = ref();
  const player = ref();

  onMounted(() => {
    if (fullscreenVideo.value) {
      const options = {
        autoplay: false,
        controls: true,
        width: "100%",
        height: "100%",
        poster: fullscreenVideo.value.thumbnailUrl,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: fullscreenVideo.value.url,
            type: "video/mp4",
          },
        ],
      };

      const sources = options.sources.filter(
        (s: { src: string; type: string }) => s.src
      );

      if (sources.length) {
        player.value = videojs(videoPlayer.value, options, () => {

          player.value.on("loadedmetadata", () => {
            setPlayPoster();
          });

          player.value.on("fullscreenchange", () => {
            if (!player.value.isFullscreen()) {
              emit("closeFullscreen");
            }
          });
        });
        player.value.requestFullscreen();
      }
      setInitialPoster();
    }
  });

  onBeforeUnmount(() => {
    setTimeout(() => {
      player.value.dispose();
    }, 0);
  });

  const getVideoBg = () => {
    return videoPlayer.value.parentElement;
  };

  const setInitialPoster = () => {
    const videoStartedBg = getVideoBg();

    if (fullscreenVideo.value)
      videoStartedBg.style.backgroundImage = `url('${fullscreenVideo.value.thumbnailUrl}')`;
  };

  const setPlayPoster = () => {
    const videoStartedBg = getVideoBg();

    videoStartedBg.style.backgroundImage = "";
  };

</script>

<style lang="scss">
.vjs-poster img {
  border-radius: 8px !important;
}

.vjs-poster {
  right: unset;
}

.vjs-tech {
  border-radius: 8px;
}
</style>

import { isMobile } from "@/core/utils/mobile";
import { useWindowSize } from "@vueuse/core";
import {
  computed,
  type ComputedRef,
  onBeforeUnmount,
  reactive,
  type Ref,
  ref,
  watch,
} from "vue";

interface VideoResize {
  videoHeightWithPx: ComputedRef<string>;
  videoWidthWithPx: ComputedRef<string>;
  calcVideoSize(): void;
  loadVideo(videoMetadata: { height: number; width: number }): void;
}

export default function useVideoResize(options: {
  rightElementWidth?: number;
  maxVideoSize: Ref<number>;
  containerWidthRef: Ref<any>;
  isColumnLayout?: ComputedRef<boolean>;
  isHeightMax: Ref<boolean>;
  maxVideoHeight?: Ref<number>; // Make maxVideoHeight optional
}): VideoResize {
  const { width: windowWidth } = useWindowSize();
  const videoHeight = ref(0);
  const videoWidth = ref(0);

  const data = reactive({
    loadedVideoOriginalHeight: 0,
    loadedVideoOriginalWidth: 0,
  });

  const rightElementWidth = options.rightElementWidth || 0;

  const loadVideo = (videoMetadata: { height: number; width: number }) => {
    data.loadedVideoOriginalHeight = videoMetadata.height;
    data.loadedVideoOriginalWidth = videoMetadata.width;
    calcVideoSize();
  };

  const calcVideoSize = () => {

    if (options.isHeightMax.value && !isMobile.value) {
      const conversionFactor =
        options.maxVideoSize.value / data.loadedVideoOriginalHeight;

      //112 is sum of main padding (40px + 40px) + inside padding (16px + 16px)

      const actualVideoWidth = windowWidth.value - 112;

      if (data.loadedVideoOriginalWidth * conversionFactor < actualVideoWidth) {
        videoWidth.value = data.loadedVideoOriginalWidth * conversionFactor;
        videoHeight.value = options.maxVideoSize.value;

        if (options.maxVideoHeight && videoHeight.value > options.maxVideoHeight.value) {
          const adjustedFactor =
            options.maxVideoHeight.value / data.loadedVideoOriginalHeight;

          videoHeight.value = data.loadedVideoOriginalHeight * adjustedFactor;
          videoWidth.value = data.loadedVideoOriginalWidth * adjustedFactor;
        }
        return;
      } else {
        videoWidth.value = actualVideoWidth;
        const newFactor = videoWidth.value / data.loadedVideoOriginalWidth;

        videoHeight.value = data.loadedVideoOriginalHeight * newFactor;

        if (options.maxVideoHeight && videoHeight.value > options.maxVideoHeight.value) {
          const adjustedFactor =
            options.maxVideoHeight.value / data.loadedVideoOriginalHeight;

          videoHeight.value = data.loadedVideoOriginalHeight * adjustedFactor;
          videoWidth.value = data.loadedVideoOriginalWidth * adjustedFactor;
        }
        return;
      }
    }

    let remainingVideoWidth =
      options.containerWidthRef.value - rightElementWidth;

    const isVideoWiderThanContainer =
      remainingVideoWidth > options.maxVideoSize.value;

    if (isVideoWiderThanContainer) {
      remainingVideoWidth = options.maxVideoSize.value;
    }

    if (
      remainingVideoWidth < 0 ||
      (options.isColumnLayout && options.isColumnLayout.value)
    ) {
      resizeVideoToFitContainer();
      return;
    }

    if (data.loadedVideoOriginalWidth > remainingVideoWidth) {
      const conversionFactor =
        remainingVideoWidth / data.loadedVideoOriginalWidth;

      resizeVideoWithFactor(conversionFactor);
    } else {
      resizeVideoWithFactor(1);
    }
  };

  const resizeVideoToFitContainer = () => {
    videoWidth.value = options.containerWidthRef.value;
    const conversionFactor = videoWidth.value / data.loadedVideoOriginalWidth;

    const newHeight = data.loadedVideoOriginalHeight * conversionFactor;

    videoHeight.value = newHeight;

    if (options.maxVideoHeight && videoHeight.value > options.maxVideoHeight.value) {
      const newConversionFactor =
        options.maxVideoHeight.value / data.loadedVideoOriginalHeight;

      videoHeight.value = data.loadedVideoOriginalHeight * newConversionFactor;
      videoWidth.value = data.loadedVideoOriginalWidth * newConversionFactor;
    } else if (newHeight > options.maxVideoSize.value) {
      const newConversionFactor =
        options.maxVideoSize.value / data.loadedVideoOriginalHeight;

      videoHeight.value = data.loadedVideoOriginalHeight * newConversionFactor;
      videoWidth.value = data.loadedVideoOriginalWidth * newConversionFactor;
    } else {
      videoHeight.value = data.loadedVideoOriginalHeight * conversionFactor;
    }
  };

  const resizeVideoWithFactor = (conversionFactor: number) => {
    const newHeight = data.loadedVideoOriginalHeight * conversionFactor;

    if (options.maxVideoHeight && newHeight > options.maxVideoHeight.value) {
      const newConversionFactor =
        options.maxVideoHeight.value / data.loadedVideoOriginalHeight;

      videoHeight.value = data.loadedVideoOriginalHeight * newConversionFactor;
      videoWidth.value = data.loadedVideoOriginalWidth * newConversionFactor;
    } else if (newHeight > options.maxVideoSize.value) {
      const newConversionFactor =
        options.maxVideoSize.value / data.loadedVideoOriginalHeight;

      videoHeight.value = data.loadedVideoOriginalHeight * newConversionFactor;
      videoWidth.value = data.loadedVideoOriginalWidth * newConversionFactor;
    } else {
      videoWidth.value = data.loadedVideoOriginalWidth * conversionFactor;
      videoHeight.value = newHeight;
    }
  };

  const videoHeightWithPx = computed(() => {
    return videoHeight.value + "px";
  });

  const videoWidthWithPx = computed(() => {
    return videoWidth.value + "px";
  });

  window.addEventListener("resize", calcVideoSize);

  onBeforeUnmount(() => {
    window.removeEventListener("resize", calcVideoSize);
  });

  watch(
    [() => options.maxVideoSize.value, () => options.isHeightMax.value],
    () => {
      calcVideoSize();
    }
  );

  return { videoHeightWithPx, videoWidthWithPx, calcVideoSize, loadVideo };
}

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <c-dialog
    cancel-option
    :close-option="false"
    :model-value="modelValue"
    width="440"
    @cancel="emit('update:modelValue', false)"
    @confirm="confirm"
    @keydown.enter="confirm"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>{{props.title}}</template>
    <template #content>
      <div class="align-baseline d-flex w-100">
        <c-input
          v-model="newName"
          class="mt-4"
          density="compact"
          maxLength="50"
          minLength="1"
          mode="outlined"
          placeholder="You can enter up to 50 characters"
          required
        />
      </div>
    </template>
    <template #confirm-label> Save </template>
  </c-dialog>
</template>

<script setup lang="ts">
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CInput } from "@charactr/wooper-ui/atoms";

  import { computed, ref } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    initialName: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Edit recording name",
    },
  });

  const emit = defineEmits(["update:modelValue", "setNewName"]);

  const recordingName = computed(() => props.initialName);

  const newName = ref(recordingName.value);

  const confirm = () => {
    if (props.initialName === newName.value) {
      emit("update:modelValue", false);
      return;
    }
    if (newName.value) {
      emit("setNewName", newName.value);
    }
  };
</script>

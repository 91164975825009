<template>
  <c-listed-voice
    :available="available"
    :border-bottom="props.borderBottom"
    :border-radius="8"
    :cloned="cloned"
    :cloned-date="voice.createdAt"
    :disabled="props.disabledBtn || disabled"
    :disabled-btn="voice.name === 'Voice Name'"
    :hide-details-info="props.hideInfoDetails"
    :highlighted="props.highlighted"
    :is-loading="loading || data.isLoading"
    :is-playing="isPlaying"
    :mobile="isMobile"
    :mode="mode"
    :no-icon="noIcon"
    :private-voice="voice.voiceTier === VoiceTierType.LEGENDARY"
    :voice="voice"
    :wrapper-class="[
      { outlined: outlined && !disabled },
      `${props.wrapperClass} width-inherit`,
      {
        'cloned-container':
          mode === MODES.SINGLE && cloned && !hideClonedActions && isMobile,
      },
    ]"
    @choose-voice="chooseVoice"
    @copy-id="displayCopyIdInfo"
    @play="toggleSamplePlaying"
  >
    <template #img><slot name="img" /> </template>
    <template #append>
      <template v-if="cloned && !hideClonedActions">
        <div v-if="mode === MODES.SINGLE">
          <c-button
            icon="ph:pencil-line-fill"
            mode="outline"
            @click.stop="openEditDialog"
          />
          <c-button
            class="ml-3"
            icon="ph:trash-simple-fill"
            mode="outline"
            @click.stop="openDeleteDialog"
          />
        </div>
        <div v-else-if="mode === MODES.MAIN_LIST" class="d-flex">
          <c-button
            height="20"
            icon="ph:pencil-line"
            mode="secondary"
            size="x-small"
            width="20"
            @click.stop="openEditDialog"
          />
          <c-button
            class="ml-3"
            height="20"
            icon="ph:trash-simple"
            mode="secondary"
            size="x-small"
            width="20"
            @click.stop="openDeleteDialog"
          />
        </div>
        <div
          v-else-if="mode === MODES.PANEL_LIST || mode === MODES.VIDEO_CREATOR"
        >
          <c-dropdown-menu
            :dropdown-options="getClonedVoiceDropdownOptions()"
            width="176"
          >
            <template #activator>
              <c-button
                class="d-inline-block"
                height="20"
                icon="ph:dots-three-outline-vertical-fill"
                icon-height="20"
                mode="secondary"
                width="20"
              />
            </template>
          </c-dropdown-menu>
        </div>
      </template>

      <slot name="append" />

      <voice-action
        v-if="mode === MODES.MAIN_LIST"
        :voice="voice"
        @delete="openDeleteDialog"
        @edit="openEditDialog"
      />
    </template>
  </c-listed-voice>
</template>

<script setup lang="ts">
  import VoiceAction from "@/core/components/Voice/VoiceAction.vue";

  import { getClonedVoicePreviewUrl } from "@/core/services/playground.service";
  import { isMobile } from "@/core/utils/mobile";
  import { MODES } from "./voice.types";
  import useAudioPlayer from "@/core/composables/useAudioPlayer";

  import { analytics, VOICES } from "@/core/utils/analytics";

  import { computed, onMounted, type PropType, reactive, toRefs, watch } from "vue";

  import { AlertModes } from "@/core/types/other.types";
  import { type Voice, VoiceTierType } from "@/core/types/voice.types";

  // @ts-ignore
  import { CButton } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDropdownMenu, CListedVoice } from "@charactr/wooper-ui/molecules";

  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  //PROPS & EMITS
  const props = defineProps({
    voice: {
      type: Object as PropType<Voice>,
      default: null,
    },
    isFavourite: {
      type: Boolean,
      default: false,
    },
    stopPlaying: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "main-list",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    hideInfoDetails: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: false,
    },
    cloned: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    hideClonedActions: {
      type: Boolean,
      default: false,
    },
    sendMounted: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits([
    "isPlayingAudio",
    "setCurrentId",
    "chooseVoice",
    "mounted",
  ]);

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const { openEditClonedVoiceDialog, openDeleteClonedVoiceDialog } = useDialogsStore();

  const getClonedVoiceDropdownOptions = () => {
    return [
      {
        name: "Rename",
        disabled: false,
        icon: "ph:pencil-line",
        action: () => {
          openEditDialog();
        },
      },
      {
        name: "Delete",
        icon: "ph:trash-simple",
        disabled: false,
        action: () => {
          openDeleteDialog();
        },
      },
    ];
  };

  const data = reactive({
    isLoading: false,
    deleteDialog: false,
  });

  onMounted(() => {
    if (props.sendMounted) {
      emit("mounted");
    }
  });

  watch(
    () => props.voice.id,
    () => {
      if (props.sendMounted) {
        emit("mounted");
      }
    }
  );

  const { audio, loading, stopAudio, toggleAudio } = useAudioPlayer(true);

  const toggleSamplePlaying = async () => {
    //choose voice in voice selection dialog
    if (props.mode === MODES.PANEL_LIST || props.mode === MODES.VIDEO_CREATOR) {
      emit("chooseVoice", props.voice);
    }
    if (isPlaying.value) {
      stopAudio();
      return;
    }

    let randomPreviewURL =
      props.voice?.previewUrls[
        Math.floor(Math.random() * props.voice?.previewUrls.length)
      ];

    if (props.cloned) {
      try {
        data.isLoading = true;
        randomPreviewURL = await getClonedVoicePreviewUrl(randomPreviewURL);
      } catch (e: any) {
        showSnackbar(
          e.response?.data.message || "Error while loading cloned voice preview",
          AlertModes.ERROR
        );
      } finally {
        data.isLoading = false;
      }
    }

    toggleAudio(props.voice.id, randomPreviewURL);
  };

  const isPlaying = computed(() => audio.isPlaying);

  watch(
    () => props.stopPlaying,
    (newVal) => {
      if (newVal && audio.isPlaying) {
        stopAudio();
      }
    }
  );

  const chooseVoice = () => {
    emit("chooseVoice", props.voice);
  };

  watch(
    () => audio.isPlaying,
    (newVal) => {
      emit("isPlayingAudio", newVal);
      emit("setCurrentId", props.voice.id);

      if (newVal)
        analytics.sendEvent("voices", VOICES.actions.PREVIEW, {
          voice_id: props.voice.id,
        });
    }
  );

  const displayCopyIdInfo = (val: string | number) => {
    showSnackbar(`Voice ID (${val}) copied`, AlertModes.SUCCESS);
  };

  const openEditDialog = () => {
    openEditClonedVoiceDialog(props.voice);
  };

  const openDeleteDialog = () => {
    openDeleteClonedVoiceDialog(props.voice);
  };
</script>

<style lang="scss" scoped>
.cloned-container {
  :deep(.v-list-item) {
    display: flex;
    flex-wrap: wrap;
  }

  :deep(.v-list-item__append) {
    display: flex;
    justify-content: center;
    margin: 16px 0 0;
    width: 100%;
  }
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";

export const useS3UploadStore = defineStore("s3UploadStore", () => {
  const percentage = ref(0);

  const $reset = () => {
    percentage.value = 0;
  };

  return {
    percentage,
    $reset,
  };
});

import type { AppFeatureCard } from "@/core/data/appFeatures";
import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
import { Routes } from "@/core/routes/core.guard";

export enum BTN_TOGGLE_MODES {
  TEXT = "textToVideo",
  AUDIO = "audioToVideo"
}

export const clonedVoiceFeatureCards: Array<AppFeatureCard> = [
  {
    name: "Text to Speech",
    description: "Transform text into natural AI-generated speech ",
    imgDark: "/images/home/dark/tts.svg",
    imgLight: "/images/home/light/tts.svg",
    testId: "tts",
    imgAlt: "Text to Speech",
    routeName: Routes.TEXT_TO_SPEECH.name,
    routePath: Routes.TEXT_TO_SPEECH.path,
    mode: PLAYGROUND_FEATURES.TTS,
  },
  {
    name: "Speech to Speech",
    description: "Change any voice input into a dynamic AI voice",
    imgDark: "/images/home/dark/voiceConversion.svg",
    imgLight: "/images/home/light/voiceConversion.svg",
    imgAlt: "Speech to Speech",
    testId: "sts",
    routeName: Routes.SPEECH_TO_SPEECH.name,
    routePath: Routes.SPEECH_TO_SPEECH.path,
    mode: PLAYGROUND_FEATURES.STS,
  },
];

export const cloningInfoPoints = [
  "Voice quality depends on microphone quality.",
  "Loud background noises impact the voice quality.",
  "Find good acoustic in the room.",
  "Try to avoid loud breathing sounds for better results.",
];


<template>
  <div :class="[{'margin-auto': !isMobile}]">
    <template v-if="!isErrorPath">
      <div
        v-if="!isMobile"
        class="align-center d-flex justify-center ma-0 pa-0 w-100"
      >
        <div class="align-center d-flex justify-center">
          <c-typography variant="subtitle-2-600">
            Generate Avatar
          </c-typography>
          <!-- <c-icon class="ml-2" height="25" icon="ph:info" /> -->
        </div>
      </div>

      <div v-else class="align-center d-flex parent-container pt-3 w-100">
        <div class="align-center d-flex justify-center w-100">
          <div class="align-center d-flex justify-center">
            <c-typography variant="subtitle-2-600">
              Generate Avatar
            </c-typography>
            <!-- <c-icon class="ml-2" height="25" icon="ph:info" /> -->
          </div>
        </div>
      </div>

    </template>
    <c-custom-card
      :class="['d-flex justify-center pa-4 margin-auto', {'mt-4': !isMobile}, {'syn-card': !isMobile && isFormPath }, {'syn-card--error': !isMobile && isErrorPath}]"
      :color="isErrorPath ? 'dark-bg' : 'player-bg'"
      :max-width="isMobile ? 'auto' : cardWidth"
    >
      <template #content>
        <router-view />
        <div v-if="!isErrorPath" class="align-center d-flex justify-center">
          <c-icon
            class="mr-2"
            :color="isFormPath ? 'rgb(var(--v-theme-copy-secondary))' : 'rgb(var(--v-theme-copy-secondary-lock))'"
            :height="isFormPath ? 16 : 20"
            icon="ph:info"
          />
          <c-typography :color-class="isFormPath ? 'copy-secondary' : 'copy-secondary-lock'" :variant="isFormPath ? 'body-3-400' : 'body-2-400'"
          >AI outputs can be misleading or wrong</c-typography
          >
        </div>
      </template>
    </c-custom-card>
    <info-dialog
      v-model="infoModal"
      description="Prompt and generate the perfect Gemelo visual, add it to the gallery for future use in the 'Video to Video' and other visual character-based flows with the Synthetic Gemelos."
      :title="'What is Synthetic Gemelo Creator?'"
    />
  </div>
</template>

<script lang="ts" setup>
  import InfoDialog from "@/core/components/Dialogs/InfoDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { computed, ref } from "vue";

  // @ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const router = useRouter();

  const infoModal = ref(false);

  const currRouteName = computed(() => {
    return router.currentRoute.value.name;
  });

  const isErrorPath = computed(() => {
    return currRouteName.value === Routes.SYNTHETICS.children.ERROR.name;
  });

  const isFormPath = computed(() => {
    return currRouteName.value === Routes.SYNTHETICS.children.FORM.name;
  });

  const cardWidth = computed(() => {
    if (isFormPath.value) {
      return 572;
    } else {
      return 922;
    }
  });

  const openInfoModal = () => {
    return;
    // infoModal.value = true;
  };
</script>

<style lang="scss" scoped>
:deep(.v-card-text) {
  display: block;
}

.icon-wrapper {
  padding: 8px;
}

:deep(.tab:not(:first-of-type)) {
  flex: 1;
}

:deep(.v-field) {
  height: 40px !important;
}

.margin-auto {
  margin: 0 auto;
}

.syn-card {
  border: 1px solid rgb(var(--v-theme-light-frame));
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
  margin: 0 auto;

  &--error {
    border: none !important;
  };
}

:deep(.v-card-text) {
  width: 100% !important;
}

.v-card-text {
  width: 100% !important;
}
</style>

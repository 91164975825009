import { apiUrl } from "@/core/utils/api";
import coreClient from "@/core/api/core.api";

export async function getClientData(clientId: number): Promise<any> {
  const url = apiUrl(`clients/${clientId}`);

  const resp = await coreClient.get(url);

  return resp.data;
}

<template>
  <view-layout>
    <template #header />
    <template #content>
      <c-container>
        <div class="section section-main">
          <div :class="['row row-title', { 'row-title--mobile': isMobile }]">
            <c-typography variant="subtitle-1-600">Your account</c-typography>
          </div>
          <div :class="['row-content', { 'row-content--mobile': isMobile }]">
            <div :class="['row', 'py-3', { 'row--input': !isMobile }]">
              <c-typography
                :class="[
                  isMobile
                    ? 'row--input__header--mobile'
                    : 'row--input__header',
                ]"
                color-class="copy-secondary"
                variant="body-2-400"
              >Email</c-typography
              >
              <c-typography
                class="row--input--text"
                color-class="copy-primary"
                variant="body-2-400"
              >{{ userStore.userEmail }}</c-typography
              >
            </div>
            <div
              :class="[
                'row',
                'py-3',
                'row--border',
                { 'row--input': !isMobile },
              ]"
            >
              <c-typography
                :class="[
                  isMobile
                    ? 'row--input__header--mobile'
                    : 'row--input__header',
                ]"
                color-class="copy-secondary"
                variant="body-2-400"
              >Password</c-typography
              >
              <c-typography
                class="row--input--text"
                color-class="copy-primary"
                variant="body-2-400"
              >•••••••••••••</c-typography
              >
            </div>
            <div
              :class="[
                'row',
                'py-3',
                { 'row--input': !isMobile },
                'row--border',
              ]"
            >
              <c-typography
                :class="[
                  isMobile
                    ? 'row--input__header--mobile'
                    : 'row--input__header',
                ]"
                color-class="copy-secondary"
                variant="body-2-400"
              >Subscription</c-typography
              >
              <c-typography
                class="row--input--text"
                :color-class="getActiveSubscription() !== 'Inactive' ? 'highlight-color' : 'copy-secondary'"
                variant="body-2-600"
              >{{ getActiveSubscription() }}</c-typography
              >
            </div>
          </div>
        </div>

        <Container class="px-6 py-4 themes w-100" outlined>
          <template #title>
            <c-row class="ma-0 pa-0">
              <c-typography color-class="copy-primary" variant="subtitle-2-600"
              >Appearance</c-typography
              >
            </c-row>
            <c-row class="ma-0 pa-0 py-2">
              <c-typography variant="body-3-400"
              >Change the appearance across all the app.</c-typography
              >
            </c-row>
          </template>

          <div
            :class="[
              isMobile ? 'themes__grid--mobile' : 'themes__grid',
              'py-4 w-100',
            ]"
            style="gap: 16px"
          >
            <div
              v-for="theme in themes"
              :key="theme.name"
              class="d-flex flex-column"
            >
              <c-custom-card
                :class="[
                  'themes__item',
                  {
                    'themes__item--active':
                      userStore.ui_local_data.theme === theme.name,
                  },
                ]"
                color="player-bg"
                height="116"
                width="100%"
                @click="setTheme(theme.name)"
              >
                <template #content>
                  <div class="themes__item__img">
                    <app-picture :image-path="theme.imgName" />
                  </div>
                </template>
              </c-custom-card>

              <c-radio
                v-if="userStore.ui_local_data"
                :id="theme.name"
                v-model="userStore.ui_local_data.theme"
                class="pt-2"
                :column="false"
                :gap="8"
                :options="theme.options"
                required
                typography="body-2-600"
              />
            </div>
          </div>
        </Container>

        <c-container
          :class="[
            'section--small-gap',
            'section-secondary',
            { 'section-secondary--mobile': isMobile },
          ]"
        >
          <div class="row">
            <c-typography variant="body-2-600">Cookie settings</c-typography>
            <c-button
              class="row-button"
              mode="tertiary"
              size="default"
              @click="openCookieSettings()"
            >
              Change
            </c-button>
          </div>
          <c-divider class="divider" />
          <div class="row">
            <c-typography variant="body-2-600"
            >Logout from all devices</c-typography
            >
            <c-button
              id="logOut"
              class="row-button"
              mode="tertiary"
              size="default"
              @click="showLogoutDialog"
            >
              Log Out
            </c-button>
          </div>
          <c-divider class="divider" />
          <div class="row">
            <div class="section-row">
              <c-typography color-class="copy-primary" variant="body-2-600"
              >Delete account</c-typography
              >
              <c-typography color-class="copy-secondary" variant="body-3-400"
              >This action is permanent and cannot be undone</c-typography
              >
            </div>
            <c-button
              class="ml-2 row-button"
              mode="tertiary"
              size="default"
              @click="data.showDeleteAccount = true"
            >
              Delete
            </c-button>
          </div>
        </c-container>
      </c-container>
      <account-delete-confirm v-model="data.showDeleteAccount" />
      <account-logout-all v-model="data.showLogoutAll" />
    </template>
  </view-layout>
</template>

<script setup lang="ts">
  import AccountDeleteConfirm from "../components/AccountDeleteConfirm.vue";
  import AccountLogoutAll from "../components/AccountLogoutAll.vue";
  import AppPicture from "@/core/components/AppPicture.vue";
  import Container from "@/core/components/Container/Container.vue";
  import { getSubscriptionTitle } from "@/core/utils/subscription";
  import { isMobile } from "@/core/utils/mobile";
  import { Themes } from "@/core/types/other.types";
  import ViewLayout from "@/core/layouts/ViewLayout.vue";
  import { ACCOUNT, analytics } from "@/core/utils/analytics";
  import { reactive, watch } from "vue";

  import { useUserStore } from "@/core/store/userStore";

  // @ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CContainer, CDivider, CRadio, CRow, CTypography } from "@charactr/wooper-ui/atoms";

  const themes = [
    {
      name: Themes.LIGHT,
      options: [{ value: Themes.LIGHT, text: "Light" }],
      imgName: "/images/account/light",
    },
    {
      name: Themes.DARK,
      options: [{ value: Themes.DARK, text: "Dark" }],
      imgName: "/images/account/dark",
    },
    {
      name: Themes.SYSTEM,
      options: [{ value: Themes.SYSTEM, text: "System" }],
      imgName: "/images/account/system",
    },
  ];

  const userStore = useUserStore();

  const data = reactive({
    showDeleteAccount: false,
    showLogoutAll: false,
  });

  const getActiveSubscription = () => {
    if (userStore.subscriptionOption) {
      const subsOption = getSubscriptionTitle(userStore.subscriptionOption);
      const subsPromo = userStore.subscriptionPromo?.name ? ` - ${userStore.subscriptionPromo.name}` : "";

      return `${subsOption} ${subsPromo}`;
    }

    return "Inactive";
  };

  const openCookieSettings = () => {
    analytics.sendEvent("account", ACCOUNT.actions.CHANGE_COOKIE_SETTINGS);
    window.cookiehub.openSettings();
  };

  const showLogoutDialog = () => {
    data.showLogoutAll = true;
    analytics.sendEvent("account", ACCOUNT.actions.LOGOUT_ALL);
  };

  const setTheme = (theme: Themes) => {
    userStore.ui_local_data.theme = theme;
    analytics.sendEvent("account", ACCOUNT.actions.CHANGE_APPEARANCE);
  };

  watch(() => userStore.ui_local_data.theme, (val: string) => {
    localStorage.setItem("ui_recentGlobalTheme", val);
  });
</script>

<style scoped lang="scss">
.section {
  overflow: hidden;

  &-secondary {
    padding: 0 24px !important;

    &--mobile {
      padding: 0 16px !important;
    }
  }

  &--small-gap {
    gap: 12px !important;
  }

  &-main {
    background: rgba(var(--v-theme-aphla-bg)) !important;
    border: 1px solid rgba(var(--v-theme-light-frame));
    border-radius: 15px !important;
  }

  &-row {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &--border {
    border-top: 1px solid rgba(var(--v-theme-light-frame)) !important;
  }

  &--input {
    justify-content: flex-start;

    &__header {
      min-width: 180px;
      text-align: left;

      &--mobile {
        margin-right: 8px;
        min-width: unset;
      }
    }

    &--text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-button {
    width: 100px;
  }

  &-content {
    background-color: rgb(var(--v-theme-player-bg)) !important;
    padding: 16px 24px !important;

    &--mobile {
      padding: 16px !important;
    }
  }

  &-title {
    background-color: rgb(var(--v-theme-aphla-bg)) !important;
    border-bottom: 1px solid rgba(var(--v-theme-light-frame)) !important;
    padding: 16px 24px !important;

    &--mobile {
      padding: 16px !important;
    }
  }
}

.account {
  &--container {
    margin: auto;
    width: 600px;
  }

  &--row {
    border-bottom: 1px solid var(--v-theme-light-frame);
    display: flex;
    flex-wrap: wrap;
  }

  &--column {
    display: flex;
    justify-content: flex-start;
    text-align: left;

    &__header {
      flex: 0 1 25%;
      font-weight: 700;
    }

    &__value {
      flex: 0 0 50%;
      font-size: 14px;
    }

    &__action {
      flex: 0 0 25%;
      justify-content: flex-end;

      &--full {
        flex: 100%;
      }
    }
  }
}

:deep(.v-input__details) {
  height: 0;
  min-height: 0;
}

p {
  margin-left: 4px;
}

.error {
  color: var(--vt-c-red);
}

.themes {
  background-color: rgb(var(--v-theme-aphla-bg)) !important;
  border-radius: 16px;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, auto);

    &--mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    border: 1px solid rgb(var(--v-theme-light-frame)) !important;
    border-radius: 8px;
    display: inline-block;
    width: fit-content;

    &--active {
      border: 2px solid rgb(var(--v-theme-button-primary)) !important;
    }

    &__img {
      border-radius: 8px;
      height: 112px;
      overflow-y: hidden;
      position: relative;
      width: 100%;

      picture {
        :deep(img) {
          object-position: 16px !important;
        }
      }
    }
  }
}

.divider {
  color: rgb(var(--v-theme-dark-frame)) !important;
  opacity: 100%;
}
</style>

type EventFn = <T>(data?: T) => void | Promise<void>;
type ListenerSygnature = string | number | symbol;

type Listeners<T extends ListenerSygnature> = {
  [eventName in T]: EventFn | null;
};

interface AppListener {
  listen(arg0: ListenerSygnature, arg1: EventFn): void;
  unlisten(arg0: ListenerSygnature): void;
  unlistenAll(): void;
  dispatchToListener(arg0: ListenerSygnature, arg1?: any): void
}

export default function useListener<
  T extends ListenerSygnature
>(): AppListener {
  const listeners: Partial<Listeners<T>> = {};

  const listen = (event: keyof Listeners<T>, fn: EventFn) => {
    if (listeners[event]) {
      console.warn("Event already has an active listener.");
    } else if (event) {
      listeners[event] = fn;
    } else {
      console.warn("Event is not defined.");
    }
  };

  const unlisten = (event: keyof Listeners<T>) => {
    if (event) {
      listeners[event] = null;
    } else {
      console.warn("Event is not defined.");
    }
  };

  const unlistenAll = () => {
    Object.keys(listeners).forEach((listener) => {
      listeners[listener as keyof Listeners<T>] = null;
    });
  };

  const dispatchToListener = <V>(event: keyof Listeners<T>, data?: V) => {
    const fn = listeners[event];

    if (fn) {
      data ? fn<V>(data) : fn();
    }
  };

  return {
    listen,
    unlisten,
    unlistenAll,
    dispatchToListener,
  };
}

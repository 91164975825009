<template>
  <div
    :class="[
      'd-flex h-100 pa-4 text-center flex-column twin-data-wrapper',
      { 'w-100': isMobile },
    ]"
  >
    <div :class="[{ 'd-flex gap-12': isMobile }]">
      <div class="h-100 image-container">
        <div
          :class="['profile-pic', { 'profile-pic--mobile': isMobile }]"
          :style="{
            backgroundImage: `url(${photoImg})`,
          }"
          @click="data.openPreview = true"
        ></div>
      </div>
      <div class="d-flex flex-grow-1 justify-center">
        <div :class="['d-flex flex-column width-fit-content flex-grow-1', {'mt-2': !isMobile}]">
          <div
            :class="[
              'd-flex justify-space-between',
              { 'align-center': !isMobile },
              { 'flex-column align-start': isMobile },
            ]"
          >
            <div
              :class="[
                'text-ellipsis',
                { 'text-ellipsis--full': twinData.isBuiltin },
                { 'text-ellipsis--mobile w-100': isMobile },
              ]">
              <c-typography
                variant="subtitle-2-600"
              >{{ twinData.name }}</c-typography
              >
            </div>
            <div v-if="isMobile" class="align-center d-flex gap-8 justify-space-between">
              <div>
                <c-typography
                  class="d-block mt-2 text-start"
                  color-class="highlight-color"
                  variant="body-3-400"
                >Created on</c-typography
                >
                <c-typography
                  class="d-block mb-4 text-start"
                  color-class="copy-primary"
                  variant="body-2-400"
                >
                  {{ formatCreateDate(twinData.createdAt) }}</c-typography
                >
              </div>
              <c-tag
                v-if="showTag"
                :mode="
                  twinData.type === ModelType.NORMAL ? 'blue' : twinData.type === ModelType.ZEROSHOT ? 'green' : 'pink'
                "
              >{{
                twinData.type === ModelType.NORMAL
                  ? "HIGH QUALITY" : twinData.type === ModelType.ZEROSHOT ? "EXPRESS" : "SYNTHETIC"
              }}</c-tag
              >
            </div>
            <div class="d-flex gap-8">
              <c-button
                v-if="!twinData.isBuiltin"
                height="20"
                icon="ph:pencil-simple-line-fill"
                mode="secondary-highlight"
                width="20"
                @click.stop=" () => emit('update:editDialog', true)"
              />
              <c-button
                v-if="!twinData.isBuiltin"
                height="20"
                icon="ph:trash-fill"
                mode="secondary-highlight"
                width="20"
                @click.stop="() => emit('update:deleteDialog', true)"
              />
            </div>
          </div>
          <div v-if="!isMobile" class="align-center d-flex justify-space-between w-100">
            <div >
              <c-typography
                class="d-block mt-2 text-start"
                color-class="highlight-color"
                variant="body-3-400"
              >Created on</c-typography
              >
              <c-typography
                class="d-block mb-4 text-start"
                color-class="copy-primary"
                variant="body-2-400"
              >
                {{ formatCreateDate(twinData.createdAt) }}</c-typography
              >
            </div>
            <c-tag
              v-if="showTag"
              :mode="
                twinData.type === ModelType.NORMAL ? 'blue' : twinData.type === ModelType.ZEROSHOT ? 'green' : 'pink'
              "
            >{{
              twinData.type === ModelType.NORMAL
                ? "HIGH QUALITY" : twinData.type === ModelType.ZEROSHOT ? "EXPRESS" : "SYNTHETIC"
            }}</c-tag
            >
          </div>
          <slot v-if="!isMobile" name="bottom-action" />
        </div>
      </div>
    </div>
    <slot v-if="isMobile" name="bottom-action" />
  </div>
  <!-- avoid jumping elements in background with v-show="false"-->
  <div v-show="false">
    <avatar-preview
      v-if="data.openPreview && twinData"
      v-model="data.openPreview"
      :avatar-name="twinData?.name"
      cancel-label="Back to Gallery"
      :url="photoImg"
    />
    <edit-name-dialog
      v-if="editDialog"
      :initial-name="twinData?.name"
      :model-value="editDialog"
      title="Edit twin name"
      @set-new-name="editName"
      @update:model-value="emit('update:editDialog', $event)"
    />

    <delete-item-dialog
      v-if="deleteDialog"
      :model-value="deleteDialog"
      subtitle="Are you sure you want to delete this item? This action cannot be undone."
      title="Delete from gallery?"
      @delete-item="deletePicture"
      @update:model-value="emit('update:deleteDialog', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
  import AvatarPreview from "@/core/components/Dialogs/AvatarPreview.vue";
  import DeleteItemDialog from "@/core/components/RightDrawer/components/Dialogs/DeleteItemDialog.vue";
  import EditNameDialog from "@/core/components/RightDrawer/components/Dialogs/EditNameDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { formatCreateDate } from "@/core/utils/formatters";
  import { ModelType } from "@/core/types/synthetic.types";
  import {  reactive } from "vue";
  import type {
    SyntheticModel,
    SyntheticPortrait,
  } from "@/core/types/synthetic.types";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CTag, CTypography } from "@charactr/wooper-ui/atoms";
  import type { PropType } from "vue";

  const props = defineProps({
    twinData: {
      type: Object as PropType<SyntheticPortrait | SyntheticModel> | undefined,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    photoImg: {
      type: String,
      default: "",
    },
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    editDialog: {
      type: Boolean,
      default: false,
    },
    showTag: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["edit", "delete", "update:editDialog", "update:deleteDialog"]);

  const data = reactive({
    error: false,
    loading: true,
    loadingVideos: true,
    openPreview: false,
    voiceSelectionOpened: false,
  });

  const editName = (name: string) => {
    emit("edit", name);
  };

  const deletePicture = (name: string) => {
    emit("delete", name);
  };
</script>

<style lang="scss" scoped>
.profile-pic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  cursor: pointer;
  height: 280px;
  width: 280px;

  &--mobile {
    height: 100px;
    width: 168px;
  }
}

.gap-32 {
  gap: 32px;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.twin-data-wrapper {
  background: rgb(var(--v-theme-light-frame-secondary));
  border-radius: 12px;
  height: 100%;
  text-align: center;
  width: min-content;
}

.width-fit-content {
  width: fit-content;
}

.image-container {
  display: inline-block;
  position: relative;
}

.image-container::after {
  background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 55%,
      rgb(71 65 154 / 20%) 75%,
      rgb(71 65 154 / 40%) 85%,
      rgb(71 65 154 / 55%) 95%,
      rgb(71 65 154 / 70%) 100%
    )
    no-repeat;
  border-radius: 12px;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.text-ellipsis {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--full {
    max-width: 280px;
  }

  &--mobile {
    max-width: 150px;
  }
}

.no-video-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-dark-frame));
  border-radius: 12px;
  height: 196px;
}

.hidden {
  visibility: hidden;
}

.gap-8 {
  gap: 8px;
}
</style>

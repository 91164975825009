<template>
  <div
    :class="[
      'w-100',
      mode === PLAYGROUND_FEATURES.VIDEO_CREATOR.name
        ? 'mt-3 h-100 video-creator'
        : 'mt-3 mb-4',
    ]"
  >
    <c-textarea
      v-model="videoSynthesisInputText"
      :disabled="isCreatingVideoFromText || isRecordingsLimitReached"
      :full-height="isMobile"
      :height="'auto'"
      :loading="isCreatingVideoFromText"
      :loading-text="getSubtitle(dataProcessingState)"
      :loading-title="getTitle(dataProcessingState)"
      :max="userStore.ttsCharactersLimitInTextToVideoMaxLength"
      :max-height="maxTextAreaHeight"
      mode="outlined"
      placeholder="Type your text"
      :show-action-c-button="true"
      test-id="video-creator"
    >
      <template #btm>
        <div class="align-center d-flex">
          <c-button
            class="mr-2"
            :disabled="!videoSynthesisInputText.length || data.currentRecordingText === videoSynthesisInputText"
            :loading="data.isGeneratingAudio"
            mode="blurred"
            size="default"
            @click="generateAudioPreview"
          >
            {{data.recording ? 'Regenerate' : 'Generate' }} audio preview
          </c-button>
          <c-button
            v-if="!data.recording"
            disabled
            icon="ph:play-fill"
            mode="blurred"
          />
          <c-player
            v-else
            ref="playerRef"
            blurred
            :player-state="data.playerState"
            :recording-time-in-seconds="data.recordingLength"
            simple
            variant="mini"
            @stop-media="audioStop"
            @toggle="toggle"
          />
        </div>
      </template>
    </c-textarea>
  </div>
</template>

<script lang="ts" setup>
// @ts-ignore
  import { CButton, CTextarea } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CPlayer } from "@charactr/wooper-ui/molecules";

  import { PlayerState } from "@/core/types/recording.types";
  import { PLAYGROUND_FEATURES } from "../../../../core/data/playgroundFeatures";
  import type { PlaygroundFeature } from "@/core/data/playgroundFeatures";
  import { storeToRefs } from "pinia";
  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import useAudioPlayer from "@/core/composables/useAudioPlayer";
  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSdkStore } from "@/core/store/useSdkStore";
  import useStreaming from "../../../playground/composables/useStreaming";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import { computed, type PropType, reactive, ref, toRefs, watch } from "vue";
  import {
    fileProcessing,
    type FileProcessingStage,
  } from "@/core/types/file-processing.types";
  import { isIOS, isMobile } from "@/core/utils/mobile";
  import { type Voice, VoiceType } from "@/core/types/voice.types";

  const props = defineProps({
    headerHeight: {
      type: Number,
      default: 0,
    },
    bottomPlayerHeight: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name,
    },
  });

  const progressStore = useProgressStore();
  const { dataProcessingState, dataProcessingPercentage } = toRefs(progressStore);
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();
  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);

  const data = reactive({
    isGeneratingAudio: false,
    isPlayButtonDisabled: false,
    audio: null,
    playerState: PlayerState.Idle,
    recording: null as null | Blob,
    recordingLength: 0,
    recordingId: "",
    currentRecordingText: "",
  });

  const {
    isCreatingVideoFromText,
    videoSynthesisInputText,
    voiceSelectionDialogOpened,
    isGenerateAudioButtonClicked,
  } = storeToRefs(useVideoCreatorStore());

  const getTitle = (currentState: FileProcessingStage) => currentState.title;

  const getSubtitle = (currentState: FileProcessingStage) => {
    if (fileProcessing.isUploadingFile(currentState)) {
      return `${dataProcessingPercentage.value}%`;
    }
    return "Stay tuned";
  };

  const maxTextAreaHeight = computed(() => {
    if (!isMobile.value) return "236px";

    const padding = 61 + 16; // app toolbar + extra padding
    const additionalOffsets = (isMobile.value ? 76 : 0) + (isIOS.value ? 60 : 0);

    return `calc(100vh - ${props.bottomPlayerHeight}px - ${padding}px - ${additionalOffsets}px - ${props.headerHeight}px)`;
  });

  const isRecordingsLimitReached = computed(() => {
    if (userStore.isUnlimitedVideoAccess) return false;
    return (
      drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit
    );
  });

  const streaming = useStreaming();
  const sdkStore = useSdkStore();
  const { sdkInitialized } = toRefs(sdkStore);

  const generateTtsAudio = async (
    voice: Voice,
    voiceType: VoiceType,
    text: string,
    format = "mp3"
  ) => {
    if (!sdkInitialized.value) {
      await streaming.createApiKeyWithSDKInit(true);
    }
    const result = await streaming.convertStreamPreview(
      Number(voice.id),
      voiceType,
      text,
      format
    );

    return new Blob([...result]);
  };

  const generateAudioPreview = async () => {
    const voice = userSelectedVoice.value[PLAYGROUND_FEATURES.VIDEO_CREATOR.name] as Voice;

    if (!voice) {
      isGenerateAudioButtonClicked.value = true;
      voiceSelectionDialogOpened.value = true;
      return;
    }

    data.isGeneratingAudio = true;
    data.recording = null;
    const voiceType = voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM;

    data.currentRecordingText = videoSynthesisInputText.value;

    const result = await generateTtsAudio(
      voice,
      voiceType,
      videoSynthesisInputText.value,
      "wav"
    );

    data.recordingId = Math.random().toString(36).substring(2, 12);
    data.recordingLength = await getAudioDurationMs(result);
    data.recording = result;
    data.isGeneratingAudio = false;

    return result;
  };

  const playerRef = ref();

  const { audio, stopAudio, toggleRecording } = useAudioPlayer();
  const appDrawersState = useAppDrawersStateStore();
  const { rightPanelPlayingRecordingId } = toRefs(appDrawersState);

  watch(rightPanelPlayingRecordingId, (val) => {
    if (audio.isPlaying && val !== data.recordingId) {
      playerRef.value.stopPlayer();
      data.playerState = PlayerState.Idle;
    }
  });

  watch(voiceSelectionDialogOpened, async (newVal) => {
    if (!newVal && isGenerateAudioButtonClicked.value) {
      await generateAudioPreview();
      isGenerateAudioButtonClicked.value = false;
    }
  });

  const audioPlay = async () => {
    if (data.recordingId) {
      rightPanelPlayingRecordingId.value = data.recordingId;
    }

    data.playerState = PlayerState.Playing;
    if (data.recording) {
      toggleRecording(
        String(data.recordingId),
        String(URL.createObjectURL(data.recording)),
        "wav"
      );
    }
  };

  const audioStop = () => {
    stopAudio();
    data.playerState = PlayerState.Idle;
  };

  const toggle = () => {
    if (data.playerState === PlayerState.Playing) {
      audioStop();
    } else {
      audioPlay();
    }
  };

  const getAudioDurationMs = (
    sourceAudio?: Blob | null
  ): Promise<number> => {
    if (!sourceAudio) return Promise.resolve(0);

    return new Promise((resolve) => {
      const audio = new Audio(URL.createObjectURL(sourceAudio));

      audio.onloadedmetadata = () => resolve(audio.duration);
      audio.onerror = () => resolve(0);
    });
  };

</script>

<style lang="scss">
.video-creator {
  .c-textarea {
    display: flex !important;
    flex-flow: column !important;
    height: 100% !important;
  }

  .wrapper {
    height: 100%;
  }

  :deep(.wrapper) {
    height: 100%;
  }

  .v-input__control {
    flex-grow: 1;
  }

  .v-field {
    height: 100% !important;
  }

  :deep(.v-field--variant-solo) {
    height: 100% !important;
  }

  .v-field--variant-solo {
    height: 100% !important;
  }

  :deep(.v-field) {
    height: 100% !important;
  }
}

.c-icon-button--blurred {
  height: 36px !important;
  width: 36px !important;

  svg {
    height: 18px !important;
    width: 18px !important
  }
}
</style>


export enum CloningSteps  {
    INITIAL ="initial",
    BEFORE_MIC_RECORDING ="before_recording",
    BUILDING =  "building",
    MIC_RECORDING = "mic_recording",
    FILE_SELECTED = "file_selected",
    CLONE_READY ="ready",
  };

const isInitial = (state: CloningSteps): boolean => {
    return state === CloningSteps.INITIAL;
  };

  const isBeforeRecording = (state: CloningSteps): boolean => {
    return state === CloningSteps.BEFORE_MIC_RECORDING;
  };

  const isRecording = (state: CloningSteps): boolean => {
    return state === CloningSteps.MIC_RECORDING;
  };

  const isFileSelected = (state: CloningSteps): boolean => {
    return state === CloningSteps.FILE_SELECTED;
  };

  const isCloneReady = (state: CloningSteps): boolean => {
    return state === CloningSteps.CLONE_READY;
  };

  const isBuildingClone = (state: CloningSteps): boolean => {
    return state === CloningSteps.BUILDING;
  };

export const cloning = {
  isInitial,
  isBeforeRecording,
  isRecording,
  isFileSelected,
  isCloneReady,
  isBuildingClone,
};

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type { Voice } from "../types/voice.types";
import { getClonedVoices, getSystemVoices } from "../services/voice.service";
import { AlertModes } from "../types/other.types";
import { useSnackbarStore } from "./useSnackbarStore";

export const useVoicesListStore = defineStore("voicesListStore", () => {
  const cloningVoicesList = ref([] as Array<Voice>);
  const systemVoicesList = ref([] as Array<Voice>);
  const currentlyActiveClonedVoice = ref(undefined as Voice | undefined);

  const clonedVoices = computed(() => {
    return cloningVoicesList.value;
  });

  const systemVoices = computed(() => {
    return systemVoicesList.value;
  });

  const $reset = () => {
    cloningVoicesList.value = [];
    systemVoicesList.value = [];
    $resetActiveClonedVoice();
  };

  const $resetActiveClonedVoice = () => {
    currentlyActiveClonedVoice.value = undefined;
  };

  const removeClonedVoice = async(id: number) => {
    cloningVoicesList.value = cloningVoicesList.value.filter(
      (el) => el.id !== id
    );
  };

  const updateClonedVoiceName= async(id: number, name: string) => {
    const index = cloningVoicesList.value.findIndex((el) => el.id === id);

    if (index !== -1) {
      cloningVoicesList.value[index].name = name;
    }
  };

  const addClonedVoice =(voice: Voice) => {
    cloningVoicesList.value.unshift(voice);
  };

  const fetchClonedVoices = async() => {
    await fetchVoices(getClonedVoices, true);
  };

  const fetchAllVoices = async() => {
    await fetchVoices(getClonedVoices, true);
    await fetchVoices(getSystemVoices);
  };

  const fetchSystemVoices = async() => {
    await fetchVoices(getSystemVoices);
  };

  const setCurrentlyActiveClonedVoice =(voice: Voice | undefined) => {
    currentlyActiveClonedVoice.value = voice;
  };

  const fetchVoices = async(
    fetchFunction: () => Promise<Array<Voice>>,
    isCloned = false
  ) => {
    const { showSnackbar } = useSnackbarStore();

    try {
      const voices = await fetchFunction();

      // Custom code for UI to know that the voice is cloned
      if (isCloned) {
        for (const voice of voices) {
          voice.labels = [];
          voice.cloned = true;
        }
      }

      if (isCloned) {
        cloningVoicesList.value = voices;
      } else {
        systemVoicesList.value = voices;
      }
    } catch (e: any) {
      showSnackbar(
        e.code === "ERR_NETWORK"
          ? e.message
          : e.response.data
          ? e.response?.data.message
          : e.message || "Error occured",
        AlertModes.ERROR
      );
    }
  };

  return {
    clonedVoices,
    systemVoices,
    currentlyActiveClonedVoice,

    $reset,
    $resetActiveClonedVoice,
    removeClonedVoice,
    updateClonedVoiceName,
    addClonedVoice,
    fetchAllVoices,
    fetchClonedVoices,
    fetchSystemVoices,
    setCurrentlyActiveClonedVoice,
  };
});

<template>
  <c-typography
    class="d-block mb-7 text-center"
    data-testid="ai-twin-studio-title"
    variant="subtitle-2-600"
  >{{isAudioTab ? 'Audio' : 'Video' }} Creations
  </c-typography>
  <div class="align-center d-flex justify-center my-2 w-100">
    <c-dropdown-menu
      :disabled="!recordings.length"
      :dropdown-options="sortingCriterias"
      title="Sort by"
    >
      <template #activator="{ isActive }">
        <c-button
          class="mr-2"
          :disabled="!recordings.length"
          icon="ph:sort-ascending"
          mode="outline"
          :open="isActive"
        />
      </template>
    </c-dropdown-menu>

    <c-input
      ref="tabsEl"
      v-model="data.search"
      density="compact"
      mode="search"
      @click.stop
      @focus="searchFocus"
    />

    <c-button
      class="ml-2"
      :disabled="!recordings.length"
      :icon="data.showOrigin ? 'ph:sliders-fill' : 'ph:sliders'"
      mode="outline"
      :open="data.showOrigin"
      @click="data.showOrigin = !data.showOrigin"
    />
  </div>

  <div v-if="data.showOrigin" class="w-100">
    <c-dropdown
      v-model="data.origin"
      class="my-2"
      clearable
      density="compact"
      :disabled="data.isLoading"
      :items="originItems"
      label="Flow Origin"
      :mobile="isMobile"
      mode="outlined"
    />
  </div>

  <template v-if="isVideoTab">
    <c-tabs
      class="mb-2"
      :tabs="videoTabs"
      variant="secondary"
      @update-route="changeVideoTab"
    />
  </template>

  <template v-if="!data.isLoading">
    <c-custom-card
      :class="[
        [isMobile ? 'voices-card--mobile' : 'voices-card--desktop w-100'],
      ]"
      color="player-bg"
      draggable
      type="primary"
    >
      <template #content>
        <div
          v-if="isAudioTab"
          :class="['w-100', { 'recordings-wrapper': recordings.length }]"
        >
          <v-virtual-scroll
            :height="recordings.length ? windowHeight : 0"
            :item-height="77"
            :items="recordings"
          >
            <template #default="{ item, index }">
              <audio-recording-player
                :key="`${item.id}${userStore.ui_local_data.gallerySorting}`"
                :active="props.currentRecordingId === item.id"
                :bottom-border="index === recordings.length"
                :chip="getChip(item.mode)"
                :disabled="isDisabled(item)"
                :recording="item"
                :recording-mode="item.mode"
                :selectable="props.videoCreator"
                :top-border="index > 0"
                @select-recording="emit('selectRecording', item)"
              />
            </template>
          </v-virtual-scroll>
        </div>
        <div v-if="isVideoTab" class="w-100">
          <div
            :class="[
              {
                'recordings-wrapper':
                  userStore.ui_local_data.galleryVideoVariant ===
                  VideoPlayerVariants.LIST && recordings.length,
              },
            ]"
          >
            <v-virtual-scroll
              ref="videoScroll"
              v-memo="[
                data.search,
                data.origin,
                userStore.ui_local_data.gallerySorting,
                unlockedNumberOfGalleryItemsInOneRow,
                scrollerWidth && allowRecordingsHistoryScreenResize,
                scrollerWidth && allowRecordingsHistoryScreenResize,
                scrollerHeight && allowRecordingsHistoryScreenResize,
                userStore.ui_local_data.galleryVideoVariant,
                windowWidth && allowRecordingsHistoryScreenResize,
                windowHeight && allowRecordingsHistoryScreenResize,
                numOfVideoRecordingsListUpadtes,
              ]"
              :height="recordings.length ? windowHeight : 0"
              :item-height="getItemHeight"
              :items="packedRecordings"
            >
              <template #default="{ item, index }">
                <div
                  :class="[
                    {
                      'gallery pb-2':
                        userStore.ui_local_data.galleryVideoVariant !==
                        VideoPlayerVariants.LIST,
                      'gallery--mobile': isMobile,
                    },
                  ]"
                >
                  <vtv-player
                    v-for="(recording, idx) in item"
                    :key="recording.id"
                    :bottom-border="
                      userStore.ui_local_data.galleryVideoVariant ===
                        VideoPlayerVariants.LIST
                        ? index !== recordings.length - 1
                        : false
                    "
                    :chip="''"
                    :cut-chip="isMobile"
                    :idx="idx + index * numberOfGalleryVideosInRow"
                    :length="recordings.length"
                    :mode="recording.mode"
                    open-preview-on-click
                    :playing-video-id="data.playingVideoId"
                    :simple="
                      userStore.ui_local_data.galleryVideoVariant ===
                        VideoPlayerVariants.GALLERY_MINI
                    "
                    :top-border="false"
                    :variant="userStore.ui_local_data.galleryVideoVariant"
                    :video="recording"
                    @set-playing-id="setPlayingId"
                  />
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
      </template>
    </c-custom-card>
  </template>

  <template v-if="!recordings.length && !data.isLoading">
    <div class="align-center d-flex flex-column justify-center pt-80">
      <c-typography color-class="copy-primary" variant="body-2-600">{{
        noRecordingsTitle
      }}</c-typography>
      <c-typography color-class="copy-secondary" variant="body-4-400">{{
        noRecordingsText
      }}</c-typography>

      <c-button
        v-if="!data.search && !data.origin"
        class="mt-6"
        mode="secondary"
        :to="Routes.HOME.path"
        width="156"
      >Create Content</c-button
      >
      <c-button
        v-if="data.origin"
        class="mt-1"
        mode="highlight-plain"
        @click="data.origin = null"
      >Clear</c-button
      >
    </div>
  </template>

  <circle-loader v-if="data.isLoading" title="Loading recordings" />
</template>

<script lang="ts" setup>
  import AudioRecordingPlayer from "@/core/components/RightDrawer/components/Players/AudioRecordingPlayer.vue";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { RecordingTypes } from "@/core/types/recording.types";
  import { Routes } from "@/core/routes/core.guard";
  import { sortByPropertyName } from "@/core/utils/sorting";
  import { useRouter } from "vue-router";
  import VtvPlayer from "../../../core/components/RightDrawer/components/Players/VtvPlayer.vue";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useUserStore } from "@/core/store/userStore";
  import { useWindowSize } from "@vueuse/core";
  import { analytics, RECORDINGS_HISTORY } from "@/core/utils/analytics";
  import {
    computed,
    onBeforeMount,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";

  import { RECORDING_TABS } from "@/core/types/recording.types";
  import { sortingIds } from "../types/history.types";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";
  import { VideoPlayerVariants, VideoStatus } from "@/core/types/video.types";

  // @ts-ignore
  // prettier-ignore
  import {  CButton, CDropdown, CInput, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import {  CCustomCard, CDropdownMenu, CTabs } from "@charactr/wooper-ui/molecules";

  import { useElementSize } from "@vueuse/core";

  import { useStore } from "@/core/store";
  import { useDialogsStore } from "@/core/store/useDialogsStore";

  //PROPS & EMITS
  const props = defineProps({
    videoCreator: {
      type: Boolean,
      default: false,
    },
    currentRecordingId: {
      type: Number,
      default: 0,
    },
  });
  const emit = defineEmits(["selectRecording"]);

  //STORE
  const dialogsStore = useDialogsStore();
  const { allowRecordingsHistoryScreenResize } = toRefs(dialogsStore);
  const drawerStore = useDrawerStore();
  const store = useStore();
  const { numOfVideoRecordingsListUpadtes } = toRefs(store);
  const userStore = useUserStore();

  const tabsEl = ref();
  const videoScroll = ref();
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const { width: scrollerWidth, height: scrollerHeight } =
    useElementSize(videoScroll);

  const unlockedNumberOfGalleryItemsInOneRow = computed(() => {
    return (
      allowRecordingsHistoryScreenResize.value && numberOfGalleryVideosInRow.value
    );
  });
  const router = useRouter();

  const currRoute = computed(() => {
    const fullPath = router.currentRoute.value.path;

    return fullPath.substring(fullPath.lastIndexOf("/") + 1);
  });

  const isAudioTab = computed(
    () => currRoute.value === RECORDING_TABS.AUDIO ||
      (props.videoCreator &&
        userStore.ui_local_data.galleryRecentMainTab === RECORDING_TABS.AUDIO)
  );

  const isVideoTab = computed(
    () => currRoute.value === RECORDING_TABS.VIDEO ||
      (props.videoCreator &&
        userStore.ui_local_data.galleryRecentMainTab === RECORDING_TABS.VIDEO)
  );

  const data = reactive({
    isLoading: false,
    search: "",
    showOrigin: false,
    origin: null,
    playingVideoId: 0,
  });

  data.showOrigin = isAudioTab.value
    ? Boolean(userStore.ui_local_data.galleryAudioOrigin)
    : Boolean(userStore.ui_local_data.galleryVideoOrigin);

  const noRecordingsTitle = computed(() => data.origin
    ? `No "${data.origin}" results`
    : data.search
      ? "Oops"
      : "No recordings"
  );

  const noRecordingsText = computed(() => data.origin
    ? "Clear this filter to show all recordings."
    : data.search
      ? `No results for ${data.search}`
      : "There are no recordings to show. Start to create now."
  );

  const sortingCriterias = computed(() => [
    {
      name: "Newest First",
      appendIcon: isSortingActive(sortingIds.NEWEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NEWEST_FIRST);
      },
    },
    {
      name: "Oldest First",
      appendIcon: isSortingActive(sortingIds.OLDEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.OLDEST_FIRST);
      },
    },
    {
      name: "Name (A-Z)",
      appendIcon: isSortingActive(sortingIds.NAME_ASCENDING) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NAME_ASCENDING);
      },
    },
    {
      name: "Name (Z-A)",
      appendIcon: isSortingActive(sortingIds.NAME_DESCENDING) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.NAME_DESCENDING);
      },
    },

    {
      name: "Longest First (s)",
      appendIcon: isSortingActive(sortingIds.LONGEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.LONGEST_FIRST);
      },
    },
    {
      name: "Shortest First (s)",
      appendIcon: isSortingActive(sortingIds.SHORTEST_FIRST) ? "ph:check" : "",
      action: () => {
        setSorting(sortingIds.SHORTEST_FIRST);
      },
    },
  ]);

  const getItemHeight = computed(() => {
    switch (userStore.ui_local_data.galleryVideoVariant) {
    case VideoPlayerVariants.LIST:
      return 50;
    case VideoPlayerVariants.GALLERY_MINI:
      return 200;
    default:
      return 400;
    }
  });

  const tabs = computed(() => {
    if (props.videoCreator || !userStore.ui_local_data.portraitStudioAccess) {
      return [
        {
          label: isMobile.value ? "Audio" : "Audio Recordings",
          active: isAudioTab.value,
          href: RECORDING_TABS.AUDIO,
        },
      ];
    }
    return [
      {
        label: isMobile.value ? "Audio" : "Audio Recordings",
        active: isAudioTab.value,
        href: RECORDING_TABS.AUDIO,
      },
      {
        label: isMobile.value ? "Video" : "Video Recordings",
        active: isVideoTab.value,
        href: RECORDING_TABS.VIDEO,
      },
    ];
  });

  const videoTabs = computed(() => {
    return [
      {
        icon: "ph:stop-fill",
        active:
          userStore.ui_local_data.galleryVideoVariant ===
          VideoPlayerVariants.GALLERY,
        href: "gallery",
      },
      {
        icon: "ph:list-bullets",
        active:
          userStore.ui_local_data.galleryVideoVariant ===
          VideoPlayerVariants.LIST,
        href: "list",
      },
    ];
  });

  const recordingsContainerHeight = computed(() => {
    const baseHeight = data.showOrigin ? 326 : 278;

    if (props.videoCreator) {
      if (windowHeight.value < 650) {
        return "auto";
      }
      if (!data.isLoading) {
        return data.showOrigin ? "calc(100vh - 586px)" : "calc(100vh - 530px)";
      } else {
        return "calc(100vh - 660px)";
      }
    } else if (isAudioTab.value) {
      return data.showOrigin ? "calc(100vh - 288px)" : "calc(100vh - 240px)";
    } else {
      return `calc(100vh - ${baseHeight}px)`;
    }
  });

  const originItems = computed(() => {
    if (isAudioTab.value) {
      return [PLAYGROUND_FEATURES.TTS.label, PLAYGROUND_FEATURES.STS.label];
    } else {
      return [
        "High quality",
        "Express",
        "Synthetic",
      ];
    }
  });

  const recordings = computed(() => {
    let filteredRecordings = [];

    if (props.videoCreator) {
      filteredRecordings = filterRecordings(
        getOriginAudioRecordings(data.origin),
        "recordingName"
      );
    } else if (isAudioTab.value) {
      filteredRecordings = filterRecordings(
        getOriginAudioRecordings(data.origin),
        "recordingName"
      );
    } else {
      filteredRecordings = filterRecordings(
        getOriginVideoRecordings(data.origin),
        "name"
      );
    }
    // const x = [filteredRecordings[0], filteredRecordings[1]];

    return sortRecordings(filteredRecordings, isVideoTab.value);
  });

  function splitArray<T>(arr: T[], chunkSize: number): T[][] {
    const result: T[][] = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const { width } = useElementSize(tabsEl);

  const numberOfGalleryVideosInRow = computed(() => {
    if (width.value > 0) {
      switch (userStore.ui_local_data.galleryVideoVariant) {
      case VideoPlayerVariants.GALLERY:
        return Math.floor(width.value / 330);
      case VideoPlayerVariants.GALLERY_MINI:
        return Math.floor(width.value / 190);
      default:
        return 1;
      }
    } else {
      return 1;
    }
  });

  const packedRecordings = computed(() => {
    return splitArray(recordings.value, numberOfGalleryVideosInRow.value || 1);
  });

  watch(
    () => data.origin,
    (newVal) => {
      if (isAudioTab.value) {
        userStore.ui_local_data.galleryAudioOrigin = newVal;
      } else {
        userStore.ui_local_data.galleryVideoOrigin = newVal;
      }
    }
  );

  watch(
    () => currRoute.value,
    (newVal) => {
      if (newVal) {
        data.origin =
          newVal === RECORDING_TABS.AUDIO
            ? userStore.ui_local_data.galleryAudioOrigin
            : userStore.ui_local_data.galleryVideoOrigin;
        data.showOrigin = Boolean(data.origin);
        loadData();
      }
    }
  );

  onBeforeMount(async () => {
    loadData();
  });

  onMounted(() => {
    data.origin = isAudioTab.value
      ? userStore.ui_local_data.galleryAudioOrigin
      : userStore.ui_local_data.galleryVideoOrigin;
  });

  const loadVideos = async () => {
    if (userStore.ui_local_data.portraitStudioAccess) {
      data.isLoading = true;
      await drawerStore.loadVideos();
      data.isLoading = false;
    }
  };

  const isDisabled = (recording: any) => {
    if (props.videoCreator) {
      return recording.outputAudioLength / 1000 > userStore.videoSecondsMaxLength;
    }
    return false;
  };

  const loadAudios = async () => {
    if (!drawerStore.ttsRecordings.length) {
      data.isLoading = true;
      await drawerStore.loadRecordings(PLAYGROUND_FEATURES.TTS.name);
      data.isLoading = false;
    }

    if (!drawerStore.vcRecordings.length) {
      data.isLoading = true;
      await drawerStore.loadRecordings(PLAYGROUND_FEATURES.STS.name);
      data.isLoading = false;
    }
  };

  const loadData = async () => {
    if (!drawerStore.vtvRecordings.length && !props.videoCreator) {
      await loadVideos();
    }

    if (isAudioTab.value) {
      await loadAudios();
    }
  };

  const searchFocus = () => {
    analytics.sendEvent(
      "recordings_history",
      RECORDINGS_HISTORY.actions.SEARCH_RECORDING_INPUT_SELECT
    );
  };

  const sortRecordings = (recordings: Array<any>, isVideo = false) => {
    if (userStore.ui_local_data.gallerySorting) {
      //todo would be nice if we have common props names for video/audio recordings
      const propSortingName = isVideo ? "name" : "recordingName";
      const propSortingLength = isVideo ? "videoDurationMs" : "outputAudioLength";

      switch (userStore.ui_local_data.gallerySorting) {
      case sortingIds.NAME_ASCENDING:
        return sortByPropertyName(recordings, propSortingName, "asc");
      case sortingIds.NAME_DESCENDING:
        return sortByPropertyName(recordings, propSortingName, "desc");
      case sortingIds.NEWEST_FIRST:
        return sortByPropertyName(recordings, "createdAt", "desc");
      case sortingIds.OLDEST_FIRST:
        return sortByPropertyName(recordings, "createdAt", "asc");
      case sortingIds.LONGEST_FIRST:
        return sortByPropertyName(recordings, propSortingLength, "desc");
      case sortingIds.SHORTEST_FIRST:
        return sortByPropertyName(recordings, propSortingLength, "asc");
      }
    }
    return sortByPropertyName(recordings, "createdAt", "desc");
  };

  const filterRecordings = (list: Array<any>, prop: string) => {
    if (data.search && prop) {
      const searchQuery = data.search.toLowerCase();

      return list.filter((recording) => {
        if (recording[prop]) {
          return recording[prop].toLowerCase().includes(searchQuery);
        }
        return false;
      });
    }
    return list;
  };

  const getOriginVideoRecordings = (origin: string | null): Array<any> => {

    const clonedVtv = cloneModeRecordings(
      drawerStore.vtvRecordings.filter((r) => r.status === VideoStatus.READY),
      PLAYGROUND_FEATURES.VTV
    );

    const clonedGemeloVideos = cloneModeRecordings(
      drawerStore.videoSyntheticsRecordings.filter(
        (r) => r.status === VideoStatus.READY
      ),
      PLAYGROUND_FEATURES.VIDEO_SYNTHESIS
    );

    if (origin) {
      switch (origin) {
      case "High quality":
        return [...clonedGemeloVideos.filter((el: any) => el.type === RecordingTypes.TTV_RAW)];
      case "Express":
        return [...clonedGemeloVideos.filter((el: any) => el.type === RecordingTypes.ZERO_SHOT)];

      default:
        return [...clonedVtv, ...clonedGemeloVideos.filter((el: any) => el.type === RecordingTypes.TTV)];
      }
    } else {
      return [...clonedVtv, ...clonedGemeloVideos];
    }
  };

  const cloneModeRecordings = (
    recordings: Array<any>,
    mode: PlaygroundFeature
  ) => {
    const cloned = JSON.parse(JSON.stringify(recordings));

    cloned.forEach((e: any) => (e.mode = mode.name));
    return cloned;
  };

  const getOriginAudioRecordings = (origin: string | null): Array<any> => {
    const clonedTts = cloneModeRecordings(
      drawerStore.ttsRecordings,
      PLAYGROUND_FEATURES.TTS
    );

    const clonedVc = cloneModeRecordings(
      drawerStore.vcRecordings,
      PLAYGROUND_FEATURES.STS
    );

    if (origin) {
      switch (origin) {
      case PLAYGROUND_FEATURES.STS.label:
        return clonedVc;
      case PLAYGROUND_FEATURES.TTS.label:
        return clonedTts;
      default:
        return [...clonedTts, ...clonedVc];
      }
    } else {
      return [...clonedTts, ...clonedVc];
    }
  };

  const getChip = (mode: string) => {
    return (
      (
        Object.values(PLAYGROUND_FEATURES).find(
          (feature: PlaygroundFeature) => feature.name === mode
        ) as PlaygroundFeature
      ).label || "Other"
    );
  };

  const isSortingActive = (sorting: sortingIds) => {
    return userStore.ui_local_data.gallerySorting === sorting;
  };

  const changeTab = (tab: RECORDING_TABS) => {
    userStore.ui_local_data.galleryRecentMainTab = tab;
    router.push(tab);
  };

  const changeVideoTab = (tab: VideoPlayerVariants) => {
    drawerStore.activePlayingVideoId = -1;
    userStore.ui_local_data.galleryVideoVariant = tab;
    if (videoScroll.value) videoScroll.value.scrollToIndex(0);
  };

  const setSorting = (sorting: sortingIds) => {
    userStore.ui_local_data.gallerySorting = sorting;
  };

  const setPlayingId = (val: number) => {
    data.playingVideoId = val;
  };
</script>

<style scoped lang="scss">
.recordings-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  max-height: v-bind(recordingsContainerHeight);
  overflow: auto;
}

.pt-80 {
  padding-top: 80px;
}

.gallery {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 390px);
  justify-content: center;

  &--mobile {
    grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 320px);
  }
}

:deep(.tab) {
  flex: 1;
}
</style>

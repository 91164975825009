import { Capacitor } from "@capacitor/core";
import { FileSharer, type ShareFileOptions } from "@byteowls/capacitor-filesharer";
import { Filesystem, FilesystemDirectory } from "@capacitor/filesystem";

enum Platforms {
  IOS = "ios",
  WEB = "web",
  ANDROID = "android",
}
function getCurrentPlatform() {
  return Capacitor.getPlatform();
}

export function isWeb(): boolean {
  return getCurrentPlatform() === Platforms.WEB;
}

export function isAndroid(): boolean {
  return getCurrentPlatform() === Platforms.ANDROID;
}

export function isIOS(): boolean {
  return getCurrentPlatform() === Platforms.IOS;
}

export function isPluginAvailable(pluginName: string) : boolean {
  return Capacitor.isPluginAvailable(pluginName);
}

export async function downloadMobile(fileName: string, blob: Blob) : Promise<void> {
  const base64 = (await convertBlobToBase64(blob)) as string;

  if (base64) {
    if (isAndroid()) {
      await Filesystem.writeFile({
        path: fileName,
        data: base64,
        directory: FilesystemDirectory.Documents,
      });
    }

    if (isIOS()) {
      const base64Data = base64.replace(/^data:audio\/[a-z]+;base64,/, "");

      const options: ShareFileOptions = {
        filename: fileName,
        contentType: "audio/wav",
        base64Data: base64Data,
      };

      FileSharer.share(options)
        .then(() => {
          // save was compoleted
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }

  async function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }
}

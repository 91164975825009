<template>
  <div
    :class="[
      'mt-7 twins-section-wrapper',
      { 'pa-6': !isMobile },
      { 'pa-2': isMobile },
    ]"
  >
    <div class="align-center d-flex gap-16 w-100">
      <c-input
        v-model="data.search"
        class="flex-grow-1 mb-4"
        density="compact"
        mode="search"
        @click.stop
      />
    </div>
    <circle-loader
      v-if="loadingPhotoTwinModels"
      height="380"
      title="Loading photo twins..."
    />

    <div v-else>
      <div
        v-if="
          !yoursPhotoModels.length && !systemPhotoModels.length && data.search
        "
        class="align-center d-flex flex-column mt-8"
      >
        <img src="/images/noResults.svg" width="440" />
        <c-typography
          class="d-block my-4"
          color-class="copy-tertiary"
          data-testid="get-started-dashboard-title"
          variant="body-1-600"
        >{{ 'No results found for "' + data.search + '"' }}
        </c-typography>
      </div>
      <template v-if="!yoursPhotoModels.length && !data.search">
        <c-typography
          class="d-block mb-3"
          data-testid="get-started-dashboard-title"
          variant="body-2-600"
        >Your Photo Twins
        </c-typography>

        <div :class="['d-flex', { 'mb-250': !isMobile }]">
          <div class="position-relative">
            <create-new-card
              @click="createNew"
              @mouseover="data.hoveredId = 0"/>

            <div
              v-if="!isMobile"
              :class="[
                'create-new-pictures-wrapper d-flex position-absolute',
                {
                  'create-new-pictures-wrapper--mobile': isMobile,
                },
              ]"
            >
              <img class="create-new-arrow" src="/images/arrow.svg" /><img
                class="create-new-cat"
                src="/images/spaceCat.svg"
              />
            </div>
          </div>
          <c-typography
            :class="[
              'button-secondary d-flex',
              { 'ml-10': !isMobile },
              { 'ml-4 align-center': isMobile },
            ]"
            color-class="button-secondary"
            :variant="
              windowWidth < 1100 ? (isMobile ? 'subtitle-1-600' : 'h2') : 'h1'
            "
          >Start the process of creating perfect Photo Twin</c-typography
          >
        </div>
      </template>
      <template v-else>
        <c-typography
          class="d-block mb-3"
          data-testid="get-started-dashboard-title"
          variant="body-2-600"
        >Your Photo Twins
        </c-typography>

        <div
          :class="[
            'align-start mt-3',
            {
              'gap-16 justify-space-between gallery__grid w-100': !isMobile,
              'justify-center d-flex flex-wrap gap-8': isMobile,
            },
          ]"
        >
          <create-new-card
            @click="createNew"
            @mouseover="data.hoveredId = 0"/>
          <twin-card
            v-for="img in yoursPhotoModels"
            :key="`${img.id}_${img.status}_${img.name}`"
            v-model:clickedMenuParentPicture="data.clickedMenuParentPicture"
            v-model:hoveredId="data.hoveredId"
            :img="img"
            @open-delete-dialog="() => (data.deleteDialog = true)"
            @open-edit-dialog="() => (data.editDialog = true)"
          />
        </div>
      </template>
      <template v-if="systemPhotoModels.length">
        <c-typography
          class="d-block mt-4"
          data-testid="get-started-dashboard-title"
          variant="body-2-600"
        >System AI Twins
        </c-typography>
        <div
          :class="[
            'align-start mt-3',
            {
              'gap-16 justify-space-between gallery__grid w-100': !isMobile,
              'justify-center d-flex flex-wrap gap-8': isMobile,
            },
          ]"
        >
          <twin-card
            v-for="img in systemPhotoModels"
            :key="`${img.id}_${img.status}_${img.name}`"
            v-model:clickedMenuParentPicture="data.clickedMenuParentPicture"
            v-model:hoveredId="data.hoveredId"
            :img="img"
            @open-delete-dialog="() => (data.deleteDialog = true)"
            @open-edit-dialog="() => (data.editDialog = true)"
          />
        </div>
      </template>
    </div>
  </div>
  <edit-name-dialog
    v-if="data.editDialog"
    v-model="data.editDialog"
    :initial-name="data.clickedMenuParentPicture.name"
    title="Edit photo twin name"
    @set-new-name="editName"
  />

  <delete-item-dialog
    v-model="data.deleteDialog"
    subtitle="Are you sure you want to delete this item? This action cannot be undone."
    title="Delete from gallery?"
    @delete-item="deletePicture"
  />

  <create-new-model-dialog
    v-if="data.createNewDialog"
    v-model="data.createNewDialog"
  />
</template>

<script lang="ts" setup>
  import TwinCard from "./TwinCard.vue";
  import CreateNewCard from "@/core/components/CreateNewCard.vue";
  import { AlertModes } from "@/core/types/other.types";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import CreateNewModelDialog from "./CreateNewModelDialog.vue";
  import DeleteItemDialog from "@/core/components/RightDrawer/components/Dialogs/DeleteItemDialog.vue";
  import EditNameDialog from "@/core/components/RightDrawer/components/Dialogs/EditNameDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { sortByPropertyName } from "@/core/utils/sorting";
  import { storeToRefs } from "pinia";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import type { PropType } from "vue";
  import { computed, onMounted, reactive, ref, watch } from "vue";
  import {
    deletePhotoTwinModel,
    updatePhotoTwinModelName,
  } from "@/core/services/photoTwins.service";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";

  import { TWIN_STUDIO_TABS } from "../types";
  import { useWindowSize } from "@vueuse/core";
  import type { PhotoTwinModel } from "../../../core/types/synthetic.types";

  const props = defineProps({
    activeMainTab: {
      type: String as PropType<TWIN_STUDIO_TABS>,
      default: TWIN_STUDIO_TABS.LIPSYNC_MODELS,
    },
  });

  const { width: windowWidth } = useWindowSize();

  const { showSnackbar } = useSnackbarStore();

  const userStore = useUserStore();
  const { changePhotoTwinModelData } = userStore;

  const {
    listOfPhotoTwinModels,
    systemPhotoTwinModels,
    userPhotoTwinModels,
    loadingPhotoTwinModels,
  } = storeToRefs(userStore);

  const router = useRouter();

  const isOpenDialog = computed(() => {
    return router.currentRoute.value.params.openDialog === "1";
  });

  const data = reactive({
    loading: true,
    hoveredId: 0,
    search: "",
    showPreview: false,
    editDialog: false,
    deleteDialog: false,
    clickedMenuParentPicture: {} as any,
    createNewDialog: false,
  });

  watch(() => isOpenDialog.value, (newVal) => {
    if (newVal) {
      data.createNewDialog = true;
    }
  }, {
    immediate: true,
  });

  const editName = async (newName: string) => {
    try {
      const model = await updatePhotoTwinModelName(
        data.clickedMenuParentPicture.id,
        newName
      );

      changePhotoTwinModelData(model);

      showSnackbar(
        "The photo twin name has been successfully changed.",
        AlertModes.SUCCESS
      );
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.hoveredId = 0;
      data.editDialog = false;
    }
  };

  const deletePicture = async () => {
    try {
      await deletePhotoTwinModel(data.clickedMenuParentPicture.id);
      listOfPhotoTwinModels.value = listOfPhotoTwinModels.value.filter(
        (picture) => picture.id !== data.clickedMenuParentPicture.id
      );
      showSnackbar("The portrait has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      console.error(e);
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.hoveredId = 0;
      data.deleteDialog = false;
    }
  };

  const yoursPhotoModels = computed(() => {
    return getFilteredPictures(userPhotoTwinModels.value);
  });

  const systemPhotoModels = computed(() => {
    return getFilteredPictures(systemPhotoTwinModels.value);
  });

  const getFilteredPictures = (photoModelList: Array<PhotoTwinModel>) => {
    let filteredPhotoModel = photoModelList;

    if (data.search) {
      const searchTerm = data.search.toLowerCase();

      filteredPhotoModel = photoModelList.filter((synthetic) => {
        return synthetic.name.toLowerCase().includes(searchTerm);
      });
    }

    return sortByPropertyName(filteredPhotoModel, "createdAt", "desc");
  };

  const createNew = () => {
    data.createNewDialog = true;
  };

</script>

<style lang="scss" scoped>
@keyframes fadeInOut {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

.twins-section-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 16px;
}

.loading-icon {
  animation: fadeInOut 1400ms linear 500ms infinite;
}

.gallery {
  max-width: 767px;

  &-card {
    border: 1px solid rgb(var(--v-theme-light-frame));
    border-radius: 16px;
    //min-height set to display tooltip info properly - @todo: think about better solution
    min-height: 250px;

    &-bottom {
      background: rgb(var(--v-theme-highlight-aphla)) !important;
      height: 24px;
    }

    &--scroll {
      border-radius: 16px;
      overflow: auto;
    }

    &--mobile {
      border: none;
    }
  }

  &-image-card {
    border-radius: 12px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%);

    &-content {
      &__active {
        background-color: rgb(var(--v-theme-button-primary-disabled));
        border-radius: 0 0 11px 11px;
      }
    }

    &--active {
      background: rgb(var(--v-theme-button-primary)) !important;
    }

    &--hover {
      border: 1px solid rgb(var(--v-theme-button-primary));
    }

    &--blurred {
      :deep(img) {
        filter: blur(4px) !important;
      }
    }
  }

  &-add-new {
    border: 1px dashed rgb(var(--v-theme-dark-frame));
    border-radius: 12px !important;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
    cursor: pointer;

    &:hover {
      background: rgb(var(--v-theme-highlight-aphla)) !important;
      border: 1px solid rgb(var(--v-theme-button-primary));
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 214px);
  }

  &__img {
    width: fit-content;
  }
}

@media (max-width: 1008px) {
  .gallery__grid {
    justify-content: center !important;
  }
}

.gap {
  &-8 {
    gap: 8px;
  }

  &-16 {
    gap: 16px;
  }
}

.border {
  border: 1px solid transparent !important;
}

.w-300px {
  min-width: 300px;
}

.hide {
  display: none !important;
}

.button-secondary {
  color: rgb(var(--v-theme-button-secondary)) !important;
}

.mb-250 {
  margin-bottom: 250px;
}

.create-new {
  &-arrow {
    transform: rotate(30.652deg);
    width: 250px;
  }

  &-pictures-wrapper {
    gap: 20px;
    top: 210px;

    &--mobile {
      left: 50px;
      top: 130px;
    }
  }
}

@media (min-width: 850px) {
  .create-new {
    &-pictures-wrapper {
      left: 50px;
      top: 180px;
    }
  }
}

@media (min-width: 1100px) {
  .create-new {
    &-arrow {
      transform: rotate(18.652deg);
      width: 350px;
    }

    &-pictures-wrapper {
      gap: 50px;
      left: 100px;
      top: 220px;
    }
  }
}

@media (min-width: 1311px) {
  .create-new {
    &-arrow {
      transform: rotate(7.652deg);
      width: 350px;
    }

    &-pictures-wrapper {
      gap: 100px;
      left: 200px;
      top: 160px;
    }

    &-cat {
      width: 230px;
    }
  }
}

@media (min-width: 1900px) {
  .create-new {
    &-arrow {
      transform: rotate(2.652deg);
      width: 430px;
    }

    &-pictures-wrapper {
      gap: 100px;
      left: 250px;
      top: 160px;
    }
  }
}

.w-138 {
  max-width: 138px;
}
</style>

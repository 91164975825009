export enum HttpStatus {
  BadRequest = 400,
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
}

export class ApiError extends Error {
  status?: HttpStatus;

  message: string;

  constructor(status?: HttpStatus) {
    super();

    this.status = status;
    this.message = this.getMessage(this.status);
  }

  private getMessage(status?: HttpStatus): string {
    switch (status) {
      case HttpStatus.NotFound:
        return `Resource not found. Status Code: ${status}.`;
      case HttpStatus.BadRequest:
        return `Bad request. Status Code: ${status}.`;
      default:
        return `"We've encountered a problem. Status Code: ${status}.`;
    }
  }
}

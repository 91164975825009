<template>
  <div class="section w-100">
    <c-custom-card
      :class="['py-4 outlined', [mobile ? 'px-4' : 'px-6']]"
      color="aphla-bg"
      width="100%"
    >
      <template #card-title>
        <c-typography variant="subtitle-2-600">Purchase tokens</c-typography>
      </template>
      <template #content>
        <div
          :class="[
            props.mobile ? 'pricing-grid--mobile' : 'pricing-grid',
            'py-4 w-100',
          ]"
          style="gap: 16px"
        >
          <c-price-card
            v-for="tier in pricingTiers"
            :key="tier.id"
            :active="data.activePriceCardId === tier.id"
            :data-testid="`tier-${tier.id}`"
            mode="plain"
            :price="
              userStore.subscriptionOption ? tier.priceWithDiscount : tier.price
            "
            width="100%"
            @click="setPriceTier(tier.id)"
          >
            <template #title
            ><div class="align-center d-flex flex-wrap">
              <img  class="mr-2" src="/images/token.svg" />
              <c-typography variant="body-1-600">
                {{ numberWithSpaces(tier.tokens) }}</c-typography
              >
            </div></template
            >
            <template #subtitle>Tokens</template>
          </c-price-card>
        </div>
        <div class="summary w-100">
          <c-row class="ma-0"
          ><c-typography color-class="copy-secondary" variant="body-3-400"
          >Purchase of extra tokens does not include Professional subscription features. For customized large volume purchase please,
            <a
              class="summary-link"
              href="https://gemelo.ai/contact"
              target="_blank"
            >Contact us</a
            ></c-typography
          >
          </c-row>
          <c-row
            class="align-center d-flex justify-center ma-0 pt-6 text-center"
          >
            <div
              v-if="selectedTier?.id"
              :class="['d-flex align-center', { 'pb-4 w-100': props.mobile }]"
            >
              <c-typography variant="body-2-400">Total</c-typography>
              <c-typography
                class="pl-4"
                data-testid="total-price"
                variant="subtitle-1-600"
              >{{ totalPrice }}</c-typography
              >
            </div>
            <c-spacer v-if="!props.mobile" />
            <div
              :class="[
                'purchase-buttons align-center d-flex justify-center ',
                { 'w-100 ': props.mobile },
              ]"
            >
              <div :class="{ 'w-50': props.mobile }">
                <c-button
                  id="hideTiers"
                  :block="props.mobile"
                  :disabled="!selectedTier?.id"
                  mode="secondary"
                  size="default"
                  width="130"
                  @click="data.activePriceCardId = 0"
                >Cancel</c-button
                >
              </div>
              <div :class="{ 'w-50': props.mobile }">
                <c-button
                  :block="props.mobile"
                  :disabled="!selectedTier?.id"
                  :loading="form.isLoading(state)"
                  size="default"
                  width="130"
                  @click="loadCheckoutNew"
                >
                  Buy Now
                </c-button>
              </div>
            </div>
          </c-row>
        </div>
      </template>
    </c-custom-card>
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import { checkoutNew } from "@/core/services/checkout.service";
  import { numberWithSpaces } from "@/core/utils/formatters";
  import { pricingTiers } from "../data/billing.data";
  import useFormState from "@/core/composables/useFormState";
  import { useUserStore } from "@/core/store/userStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  import { analytics, PAYMENTS } from "@/core/utils/analytics";
  import { computed, reactive } from "vue";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";

  // @ts-ignore
  import { CButton, CIcon, CRow, CSpacer, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CCustomCard, CPriceCard } from "@charactr/wooper-ui/molecules";

  //PROPS & EMITS
  const props = defineProps({
    mobile: {
      type: Boolean,
      default: false,
    },
  });

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();

  const data = reactive({
    showMore: true,
    promoCodesDialog: false,
    activePriceCardId: 0,
  });

  const selectedTier = computed(() => {
    return pricingTiers.find((el) => el.id === data.activePriceCardId);
  });

  const checkoutForm = useFormState(checkoutNew, FORM_INITIAL_STATE);

  const loadCheckoutNew = async () => {
    analytics.sendEvent("billing", PAYMENTS.actions.BUY_MINUTES);

    if (selectedTier.value?.tokens) {
      checkoutForm
        .submit({ subscription: false, tokens: selectedTier.value.tokens })
        .then((checkout: any) => {
          if (form.isSuccess(state.value) && checkout?.data.url) {
            window.location.href = checkout?.data.url;
          }
          if (form.isErrored(state.value)) {
            showSnackbar(
              state.value.message || "Error occured",
              AlertModes.ERROR
            );
          }
        });
    }
  };

  const setPriceTier = (tierId: number) => {
    data.activePriceCardId = tierId;
  };

  const state = computed(() => {
    return checkoutForm.state.value;
  });

  const totalPrice = computed(() => {
    if (selectedTier.value) {
      return `${
        userStore.subscriptionOption
          ? selectedTier.value.priceWithDiscount.toFixed(2)
          : selectedTier.value.price.toFixed(2)
      } USD`;
    }

    return 0;
  });
</script>

<style scoped lang="scss">
.outlined {
  border: 1px solid rgba(var(--v-theme-light-frame));
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);

  &--mobile {
    display: flex;
    flex-direction: column;
  }
}

.summary-link {
  color: rgba(var(--v-theme-button-primary)) !important;
  font-weight: 600;
}

.purchase-buttons {
  gap: 16px;
}
</style>

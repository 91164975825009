<template>
  <c-dialog
    :confirm-option="false"
    width="500"
    @update:model-value="resetState"
  >
    <template #title>New billing alert</template>
    <template #content>
      <div class="align-center justify-center">
        <c-typography class="d-block text-center" color-class="copy-secondary" variant="body-2-400">
          A billing alert will notify you when your account charges have
          exceeded the tokens amount you have set.
        </c-typography>
        <c-input
          id="treshold"
          v-model="data.threshold"
          autofocus
          class="mt-6"
          :disabled="form.isLoading(state)"
          min="1"
          mode="outlined"
          type="number"
          @keydown.enter="createNewAlert"
        >Minutes</c-input
        >
        <div class="mt-6">
          <c-button
            id="confirmCreateBilling"
            block
            :disabled="disableSubmit"
            :loading="form.isLoading(state) || props.loading"
            @click="createNewAlert"
          >
            Create billing alert
          </c-button>
        </div>
      </div>
      <div v-if="form.isErrored(state)" class="error-container text-center">
        <c-typography error variant="body-2-500">
          {{ state.message }}
        </c-typography>
      </div>
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { createAlert } from "@/core/services/billing.service";
  import useFormState from "@/core/composables/useFormState";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { analytics, BILLING } from "@/core/utils/analytics";
  import { computed, reactive } from "vue";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  //PROPS & EMITS
  const props = defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["refresh"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();

  const data = reactive({
    threshold: 1,
  });

  const createAlertForm = useFormState(createAlert, FORM_INITIAL_STATE);

  const createNewAlert = async () => {
    if (!disableSubmit.value) {
      createAlertForm.submit(data.threshold).then(() => {
        if (form.isSuccess(state.value)) {
          emit("refresh");
          showSnackbar("Billing alert created", AlertModes.SUCCESS);
          analytics.sendEvent("billing", BILLING.actions.CREATE_ALERT);
        }
      });
    }
  };

  const state = computed(() => {
    return createAlertForm.state.value;
  });

  const disableSubmit = computed(
    () => form.isLoading(state.value) || !isThresholdValid.value
  );

  const resetState = (val: boolean) => {
    if (!val) {
      createAlertForm.state.value = FORM_INITIAL_STATE;
      data.threshold = 1;
    }
  };

  const isThresholdValid = computed(() => data.threshold > 0);
</script>

<style scoped lang="scss">
  @import "@/assets/common.scss";

  :deep(.v-container) {
    border-radius: 0;
    height: 74px;
    padding-left: 0;
    padding-right: 0;
  }

  .error-container {
    height: 25px;
    text-align: center;
  }
</style>

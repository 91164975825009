<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <c-dialog
    :confirm-disabled="!agreeRules || message.length > 500"
    :confirm-loading="form.isLoading(state)"
    :model-value="modelValue"
    :width="isMobile ? '100%' : 515"
    @cancel="emit('update:modelValue', false)"
    @confirm="submitQuestionnaire"
    @keydown.enter="submitQuestionnaire"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title
    ><c-typography color-class="copy-primary" variant="subtitle-1-600"
    >{{ selectedOptionData?.title }}</c-typography
    ></template
    >
    <template #content>
      <c-form ref="formWrapper" v-model="formValid" @submit.prevent>
        <div class="d-flex flex-column">
          <c-typography
            class="pb-3"
            color-class="copy-secondary"
            variant="body-3-400"
          >{{ selectedOptionData?.description }}
          </c-typography>

          <c-typography
            class="pb-3"
            color-class="copy-secondary"
            variant="body-3-500"
          >{{ selectedOptionData?.descriptionPrice }}
          </c-typography>
        </div>

        <div>
          <c-typography color-class="copy-secondary" variant="body-2-600"
          >Personal Information</c-typography
          >
        </div>

        <div class="mt-2">
          <div class="d-flex flex-row" style="gap: 8px">
            <c-input
              :key="componentKey + 'a'"
              v-model="userName"
              density="compact"
              :disabled="form.isLoading(state)"
              maxLength="50"
              mode="outlined"
              placeholder="Name"
              :required="rulesOn"
            />
            <c-input
              :key="componentKey + 'b'"
              v-model="userLastName"
              density="compact"
              :disabled="form.isLoading(state)"
              maxLength="50"
              mode="outlined"
              placeholder="Last Name"
              :required="rulesOn"
            />
          </div>
        </div>

        <div class="d-flex flex-row mt-2" style="gap: 8px">
          <c-phone-number
            v-model="phonePrefix"
            :disabled="form.isLoading(state)"
          />

          <c-input
            :key="componentKey + 'c'"
            v-model="phoneNumber"
            density="compact"
            :disabled="form.isLoading(state)"
            maxLength="20"
            mode="outlined"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="Phone number"
            type="tel"
          />
        </div>

        <div class="mb-2 pt-3">
          <c-typography color-class="copy-secondary" variant="body-2-600"
          >Your request</c-typography
          >
        </div>

        <c-dropdown
          :key="componentKey + 'd'"
          v-model="yourRequest"
          class="my-2"
          :clearable="false"
          density="compact"
          :disabled="form.isLoading(state)"
          :items="yourRequestOptions"
          label="Choose a product"
          :mobile="isMobile"
          mode="outlined"
          :required="rulesOn"
        />

        <div class="mb-2 pt-3">
          <c-typography color-class="copy-secondary" variant="body-2-600"
          >Additional message (optional)</c-typography
          >
        </div>

        <c-input
          :key="componentKey + 'e'"
          v-model="message"
          density="compact"
          :disabled="form.isLoading(state)"
          maxLength="100"
          mode="outlined"
          placeholder="Type here"
          :required="rulesOn"
        />

        <accept-terms v-model="agreeRules" :loading="form.isLoading(state)" />

        <c-typography v-if="form.isErrored(state)" error variant="body-2-400">{{
          errorMessage
        }}</c-typography>
      </c-form>
    </template>
    <template #confirm-label> Submit </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import AcceptTerms from "@/core/components/AcceptTerms.vue";
  import { AlertModes } from "@/core/types/other.types";
  import { analytics  } from "@/core/utils/analytics";
  import { isMobile } from "@/core/utils/mobile";
  import type { RequestDataType } from "../../types/form.types";
  import { sendFormDataToHubSpot } from "@/core/services/hubspot.service";
  import { sendRequest } from "../../services/form.service";
  import useFormState from "@/core/composables/useFormState";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { computed, nextTick, ref } from "vue";
  import { form, FORM_INITIAL_STATE, type FormStateError } from "@/core/types/form-state.type";
  import { mappingDialogContent, yourRequestOptions } from "../../data/form-requests.data";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CDropdown, CForm, CInput, CPhoneNumber, CTypography } from "@charactr/wooper-ui/atoms";

  //PROPS & EMITS
  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    request: {
      type: String,
      default: "",
    },
  });
  const emit = defineEmits(["update:modelValue"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();

  const userName = ref("");
  const userLastName = ref("");
  const message = ref("");
  const agreeRules = ref(true);
  const yourRequest = ref(props.request);
  const phonePrefix = ref("");
  const phoneNumber = ref("");
  const formValid = ref(false);
  const formWrapper = ref<null | HTMLFormElement>(null);
  const rulesOn = ref(false);
  const componentKey = ref(0);

  const requestForm = useFormState(sendRequest, FORM_INITIAL_STATE);

  const selectedOptionData = computed(() => {
    const el = Object.values(mappingDialogContent).find(item => item.label === yourRequest.value);

    return el;
  });

  const state = computed(() => {
    return requestForm.state.value;
  });

  const errorMessage = computed(() => {
    return (state.value as FormStateError).message;
  });

  const submitQuestionnaire = async () => {
    rulesOn.value = true;
    componentKey.value++;

    nextTick(() => {
      (formWrapper.value as HTMLFormElement).validate().then(() => {
        if (!formValid.value) {
          return;
        }
        const questionnaireData: RequestDataType = {
          email: userStore.userEmail,
          firstName: userName.value,
          lastName: userLastName.value,
          phone: phoneNumber.value
            ? `${phonePrefix.value} ${phoneNumber.value}`
            : "",
          subject: yourRequest.value,
          message: message.value,
        };

        submit(questionnaireData);
      });
    });
  };

  const yourRequestKey = computed(() => {
    const keys = Object.keys(mappingDialogContent) as (keyof typeof mappingDialogContent)[];

    return keys.find(key => mappingDialogContent[key].label === yourRequest.value);
  });

  const submit = async (payload: any) => {

    requestForm.submit(payload).then(() => {
      if (form.isSuccess(state.value)) {

        sendToHubSpot(payload);
        showSnackbar(mappingDialogContent[yourRequestKey.value as keyof typeof mappingDialogContent].successMessage, AlertModes.SUCCESS);
        emit("update:modelValue", false);
        analytics.sendEvent(
          "video-creator",
          mappingDialogContent[yourRequestKey.value as keyof typeof mappingDialogContent].analytics.success
        );
      }
      if (form.isErrored(state.value)) {
        showSnackbar(state.value.message, AlertModes.ERROR);
        analytics.sendEvent(
          "video-creator",
          mappingDialogContent[yourRequestKey.value as keyof typeof mappingDialogContent].analytics.error
        );
      }
    });
  };

  const sendToHubSpot = (data: RequestDataType) => {

    const hubspotData = {
      urlId: import.meta.env.VITE_HUBSPOT_FORM_REQUEST,
      formData: {
        email: userStore.userEmail,
        firstname: data.firstName,
        lastname: data.lastName,
        phone: data.phone,
        message: data.subject + "\n" + message.value,
      },
      pageName: "App - Request Custom form",
    };

    sendFormDataToHubSpot(hubspotData);
  };

</script>

<style scoped lang="scss">
.other {
  width: 100px !important;
}
</style>

<template>
  <c-tooltip
    action="Upgrage"
    :content="content"
    :disabled="disabled"
    placement="bottom"
    :title="title"
    width="330"
    @handle-action-click="goToShop"
  >
    <slot>
      <c-icon
        color="rgb(var(--v-theme-copy-primary-lock))"
        height="20"
        icon="ph:lock-fill"
      />
    </slot>
  </c-tooltip>
</template>

<script lang="ts" setup>
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  // @ts-ignore
  import { CIcon, CTooltip } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default:
        "Unlock this voice and get unlimited access to Voice Library and Voice Cloning",
    },
    title: {
      type: String,
      default: "Premium Voice",
    },
  });

  const router = useRouter();

  const goToShop = () => {
    router.push({ name: Routes.SHOP.name });
  };
</script>

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <wrapper
    ref="wrapperRef"
    :hide-details="hideDetails"
    :mobile="isMobile"
    :show-voice="isConfigure && !videoErrorStatus"
    :variant="PLAYGROUND_FEATURES.VTV"
  >
    <template #streaming>
      <div
        v-if="noApiKey"
        :class="['text-center d-flex flex-column w-100 py-2', { 'px-4': isMobile }]"
      >
        <c-typography class="text-center" color-class="copy-secondary" variant="body-2-400">
          To fully utilize our TTS Streaming feature, an active API key is
          required. Don't worry, we've got you covered! <br />
          Click the button below, and we'll generate a personalized API key for
          you.
        </c-typography>
        <c-button class="mt-4 w-100" size="large" @click="streaming.createApiKeyWithSDKInit"
        >Create API key
        </c-button>
      </div>
    </template>
    <template #visual>
      <router-view v-if="!videoErrorStatus" name="picSelector" />
    </template>
    <template #playground>
      <router-view v-if="!videoErrorStatus" />
      <generation-error v-if="videoErrorStatus" />
    </template>
    <template #action>
      <router-view v-if="!videoErrorStatus" name="action" />

      <div v-if="!hideDetails" class="align-center d-flex justify-center pt-3">
        <c-icon
          class="mx-2"
          color="rgb(var(--v-theme-copy-secondary))"
          height="20"
          icon="ph:info"
        />
        <c-typography color-class="copy-secondary" variant="body-2-400"
        >AI outputs can be misleading or wrong</c-typography
        >
      </div>
    </template>
  </wrapper>

  <!-- hidden div to cache picutres in browser  -->
  <div class="d-none">
    <img v-for="pic in userStore.readyGalleryPictures" :key="pic.id" :src="pic.thumbnailUrl" />
  </div>
</template>

<script setup lang="ts">
  import GenerationError from "../components/syntheticVideo/ConfigureGenerationError.vue";
  import Wrapper from "@/core/components/Wrapper/Wrapper.vue";

  import { isMobile } from "@/core/utils/mobile";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { RightPanelTabs } from "@/core/types/other.types";

  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  import useStreaming from "../composables/useStreaming";
  import { computed, onBeforeMount, onBeforeUnmount, toRefs } from "vue";

  import { storeToRefs } from "pinia";
  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useSdkStore } from "@/core/store/useSdkStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  // @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab, closeRightDrawerTab } = appDrawersState;
  const sdkStore = useSdkStore();
  const { noApiKey } = toRefs(sdkStore);
  const playgroundStore = usePlaygroundStore();
  const userStore = useUserStore();
  const { videoPreview, videoErrorStatus } = storeToRefs(useVideoCreatorStore());

  //ROUTER
  const router = useRouter();

  const streaming = useStreaming(false);

  const isConfigure = computed(
    () => router.currentRoute.value.name === Routes.VIDEO_TO_VIDEO.children.CREATE.name
  );

  const hideDetails = computed(
    () => videoErrorStatus.value ||  (router.currentRoute.value.name === Routes.VIDEO_TO_VIDEO.children.PREVIEW.name && !videoPreview.value)
  );

  onBeforeMount(async () => {

    if (!userStore.videoSecondsMaxLength) {
      userStore.getSubscriptionLimits();
    }
    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.RECORDINGS);
    }
  });

  onBeforeUnmount(() => {
    videoErrorStatus.value = false;
  });
</script>

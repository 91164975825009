import type { PricingTier } from "../types/shop.types";

export const pricingTiers: Array<PricingTier> = [
  {
    id: 1,
    tokens: 100,
    priceWithDiscount: 2.62,
    price: 3.50,
  },
  {
    id: 2,
    tokens: 1000,
    price: 32.00,
    priceWithDiscount: 24.00,
  },
  {
    id: 3,
    tokens: 10000,
    priceWithDiscount: 210.00,
    price: 280.00,
  },
];

export const billingAlertsHeaders = [
  {
    title: "Tokens remaining",
    key: "balanceThresholdMinutes",
  },
  { title: "Creation date", key: "createdAt" },
  { title: "Actions", key: "actions", sortable: false, align: "center" },
];

export const billingHistoryHeaders = [
  {
    title: "Quantity",
    key: "formatedQuantity",
  },
  {
    title: "Cost",
    key: "formattedCost",
  },
  {
    title: "Date",
    key: "boughtAt",
  },
];

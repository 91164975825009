<template>
  <c-dialog
    :model-value="modelValue"
    variant="secondary"
    width="510"
    @confirm="closeWithEmit"
    @keydown.enter="closeWithEmit"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>
      {{ title }}
    </template>
    <template #content>
      <c-typography color-class="copy-secondary" variant="body-3-400">
        {{ description }}
      </c-typography>
    </template>
    <template #confirm-label>{{ confirmLabel }}</template>
  </c-dialog>
</template>

<script lang="ts" setup>
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    confirmLabel: {
      type: String,
      default: "OK",
    },

  });

  const emit = defineEmits(["update:modelValue", "confirm"]);

  const closeWithEmit = () => {
    emit("confirm");
    emit("update:modelValue", false);
  };
</script>

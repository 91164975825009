
export const enum PlayerState {
  Idle = "idle",
  Playing = "playing",
  Paused = "paused",
  Stopped = "stopped",
  Error = "error",
  Loading = "loading",
}

export interface Status<T> {
  type: T;
  msg?: string;
}

export enum RecordingState {
  Idle = "idle",
  Recording = "recording",
  Stopped = "stopped",
  Cancelled = "cancelled",
  Error = "error",
}

export enum VoiceInputSource {
  MIC = "mic",
  FILE = "file",
}

export enum VoiceCloningInputModes {
  MIC = "mic",
  FILE = "file",
}

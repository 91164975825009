<template>
  <c-dialog
    :cancel-option="true"
    :confirm-disabled="data.isDeletingApiKey"
    :model-value="modelValue"
    persistent
    variant="primary"
    width="500"
    @cancel="emit('update:modelValue', false)"
    @confirm="deleteKey"
    @keydown.enter="deleteKey"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Delete API Key</template>
    <template #content>
      <c-typography
        class="d-block text-center"
        color-class="copy-secondary"
        variant="body-2-400"
      >
        Are you sure you want to delete selected API Key? <br>
        WARNING: This action cannot be undone
      </c-typography>
    </template>
    <template #confirm-label>Delete</template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { deleteApiKey } from "@/core/services/apikeys.service";
  import { reactive } from "vue";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { analytics, API_KEYS } from "@/core/utils/analytics";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  //PROPS & EMITS
  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    keyToDelete: {
      type: Number,
      default: 0,
    },
  });

  const emit = defineEmits(["update:modelValue", "loadApiKeys"]);

  //STORE
  const { showSnackbar } = useSnackbarStore();

  const data = reactive({
    isDeletingApiKey: false,
  });

  const deleteKey = async () => {

    try {
      if (props.keyToDelete) {
        data.isDeletingApiKey = true;
        await deleteApiKey(Number(props.keyToDelete));
        emit("loadApiKeys");
        showSnackbar("API key deleted", AlertModes.SUCCESS);
        analytics.sendEvent("api_keys", API_KEYS.actions.DELETE_API_KEY);
      }
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      emit("update:modelValue", false);
      data.isDeletingApiKey = false;
    }
  };
</script>

<template>
  <template>
    <c-dialog
      cancel-option
      :model-value="modelValue"
      :width="isMobile ? '100%' : 844"
      @cancel="emit('update:modelValue', false)"
      @confirm="confirmAction"
      @keydown.enter="confirmAction"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #title>Plans & Pricing</template>
      <template #content>
        <div class="text-center">
          <c-typography color-class="copy-secondary" variant="body-2-400">
            Gain access to premium features
          </c-typography>
        </div>
        <div
          :class="['pricing-cards pt-6', { 'pricing-cards--mobile': isMobile }]"
        >
          <pricing-tier
            v-for="tier in pricingTiers"
            :key="tier.name.label"
            :border="4"
            :color="tier.background"
            :list="tier.features"
            margin-title="6"
            :mobile="isMobile"
            :tier="tier"
            :width="isMobile ? '100%' : 399"
          />

        </div>
      </template>
      <template #confirm-label>
        {{ isPremium ? "OK" : "Subscribe now" }}
      </template>
    </c-dialog>
  </template>
</template>

<script lang="ts" setup>
  import { computed } from "vue";
  import { isMobile } from "@/core/utils/mobile";
  import PricingTier from "./PricingTier.vue";
  import { SubscriptionTypes } from "@/core/types/checkout.types";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";

  import { useUserStore } from "@/core/store/userStore";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue", "buy"]);

  const userStore = useUserStore();

  const confirmAction = () => {
    if (isPremium.value) {
      emit("update:modelValue", false);
    } else {
      emit("buy");
    }
  };

  const isPremium = computed(
    () => userStore.subscriptionOption &&
      (userStore.subscriptionOption === SubscriptionTypes.EARLY_BIRDS ||
        userStore.subscriptionOption === SubscriptionTypes.PROFESSIONAL)
  );

  const pricingTiers = computed(() => {
    return [
      {
        name: { label: "Professional ", color: "white" },
        background: "button-primary",

        cost: "$19/monthly",
        costDesc: "10k tokens per year / 900 tokens per month for video & audio production",
        features: [
          { label: "1 Video Twin Pro included", leftIcon: { icon: "ph:check" } },
          { label: "900 tokens for video & audio output", leftIcon: { icon: "ph:check" } },
          {
            label: "30 minutes max audio recording length",
            leftIcon: { icon: "ph:check" },
            rightIcon: { icon: "ph:question", tooltip: "Or 25000 characters", colorClass: "copy-primary" },
          },
          {
            label: "3 minutes max video recording length",
            leftIcon: { icon: "ph:check" },
          },
          { label: "73+ Voices Available", leftIcon: { icon: "ph:check" } },

          { label: "Full Voice Cloning access", leftIcon: { icon: "ph:check" } },
          { label: "Premium Avatars", leftIcon: { icon: "ph:check" } },

          {
            label: "No Watermark on videos",
            leftIcon: { icon: "ph:check" },
          },
          { label: "Access to all system Avatars", leftIcon: { icon: "ph:check" } },
          {
            label: "25% off for additional tokens",
            leftIcon: { icon: "ph:check" },
          },
        ],
      },
      {
        name: { label: "Enterprise", color: "highlight-color" },
        background: "button-secondary",

        cost: "Contact us",
        costDesc: "Multi-seat, Fastest Generations, Finetune AI Twins and full access to all voices and avatars",
        features: [
          { label: "Everything from Professional tier", leftIcon: { icon: "ph:check" } },
          {
            label: "Dedicated Clusters and Fastest Generation",
            leftIcon: { icon: "ph:check" },
          },
          { label: "Dedicated Support", leftIcon: { icon: "ph:check" } },
          { label: "Finetune AI Twins and full access to all voices and avatars", leftIcon: { icon: "ph:check" } },
          { label: "Full Voice Cloning access", leftIcon: { icon: "ph:check" } },
          { label: "Generate own Avatar Pro", leftIcon: { icon: "ph:check" } },
          { label: "Support for Video Twin Pro training process", leftIcon: { icon: "ph:check" } },
          { label: "Access to Beta and in development features", leftIcon: { icon: "ph:check" } },
          { label: "Earlier access to Product Roadmap", leftIcon: { icon: "ph:check" } },
          {
            label: "Custom pricing for tokens",
            leftIcon: { icon: "ph:check" },
          },
        ],
      },
    ];
  });
</script>

<style scoped lang="scss">
.pricing-cards {
  display: flex;
  gap: 24px;

  &--mobile {
    flex-direction: column-reverse;
  }
}

.sub-price {
  gap: 8px;

  &__old {
    text-decoration: line-through;
  }

  &__chip {
    background-color: rgb(var(--v-theme-button-secondary)) !important;
    border-radius: 12px;
    height: 24px;
    padding: 0 12px;
  }
}
</style>

import type { ApiKeyData } from "../types/apikey.types";
import { apiUrl } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export async function createApiKey(): Promise<ApiKeyData> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/api-keys?full`);
  const response = await coreClient.post(url, null);

  return response.data;
}

export async function deleteApiKey(id: number): Promise<void> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/api-keys/${id}`);
  const response = await coreClient.delete(url);

  return response.data;
}

export async function getApiKeys(isFull: boolean): Promise<Array<ApiKeyData>> {
  const clientId = tokenClientId();
  const full = isFull ? "?full" : "";

  const url = apiUrl(`clients/${clientId}/api-keys${full}`);
  const response =  await coreClient.get(url);

  return response.data;
}

<template>
  <error-dialog
    v-model="data.loadingError"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />
  <input
    id="target_file"
    ref="fileSelector"
    :accept="isMobile ? '*' : 'audio/*'"
    class="d-none"
    type="file"
    @change="loadUserAudioFile"
  />
  <template
    v-if="
      cloning.isFileSelected(cloningCurrentState) && data.fileRecording
    "
  >
    <div :class="[{ 'px-4': isMobile }]">
      <cloning-title solo-line title="Voice sample is ready to use!" />
      <local-audio-player
        audio-label="Selected audio"
        class="my-4"
        :disabled="isCreatingVoice"
        :recording="data.fileRecording"
        @delete="clearCloningData"
      />
      <cloning-consent-checkbox
        v-model:consent-confirmed="data.consentConfirmed"
        :disabled="isRecording || isCreatingVoice"
      />
      <div class="align-center d-flex justify-center">
        <c-button
          block
          description="Record"
          :disabled="!data.consentConfirmed || isCreatingVoice"
          :loading="isCreatingVoice"
          size="large"
          @click="generateRecordingFromFile"
        >Clone Now
        </c-button>
      </div>
    </div>
  </template>

  <div v-show="!(cloning.isFileSelected(cloningCurrentState) && data.fileRecording)" :class="{ 'px-4': isMobile }">
    <cloning-title
      :disabled="isCreatingVoice"
      subtitle="Upload your sample"
      title="We support audios up to 60 seconds in length"
    >
      <template #append>
        <drag-and-drop
          :disabled="isVoiceCloningLimitReached || isCreatingVoice"
          :height="240"
          :supported-formats="AUDIO_FORMATS"
          test-id="dnd-voice-cloning"
          @choose-file="chooseFile"
          @drop-file="loadUserAudioFile($event, true)"
        />
      </template>
    </cloning-title>
    <cloning-consent-checkbox
      v-if="
        cloning.isFileSelected(cloningCurrentState) && data.fileRecording
      "
      v-model:consent-confirmed="data.consentConfirmed"
      :disabled="isRecording || isCreatingVoice"
    />
    <c-button
      block
      description="Record"
      :disabled="!data.consentConfirmed || isCreatingVoice"
      :loading="isCreatingVoice"
      size="large"
      @click="generateRecordingFromFile"
    >Upload
    </c-button>
  </div>

</template>

<script setup lang="ts">
  import CloningConsentCheckbox from "../components/voiceCloning/CloningConsentCheckbox.vue";
  import CloningTitle from "../components/voiceCloning/CloningTitle.vue";
  import { createClonedVoice } from "@/core/services/playground.service";
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import LocalAudioPlayer from "@/core/components/LocalAudioPlayer.vue";
  import type { LocalRecording } from "@/core/types/recording.types";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { Routes } from "@/core/routes/core.guard";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import useRecorder from "@/core/composables/useRecorder";
  import { useRouter } from "vue-router";
  import useTimer from "@/modules/voicesPlayground/composables/useTimer";
  import { VoiceCloningInputModes } from "@/core/types/playground.types";
  import { useUserStore } from "@/core/store/userStore";

  import { AUDIO_FORMATS } from "@/core/types/file.types";

  import { AlertModes, RightPanelTabs } from "@/core/types/other.types";
  import { analytics, VOICE_CLONING } from "@/core/utils/analytics";
  import { cloning, CloningSteps } from "../types/cloning-state.type";
  import {
    computed,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";

  // @ts-ignore
  import { CButton } from "@charactr/wooper-ui/atoms";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab, closeRightDrawerTab } = appDrawersState;
  const userStore = useUserStore();
  const { addClonedVoice } = useVoicesListStore();
  const { clonedVoices, currentlyActiveClonedVoice } = toRefs(useVoicesListStore());
  const voicesListStore = useVoicesListStore();
  const { $resetActiveClonedVoice, setCurrentlyActiveClonedVoice } = voicesListStore;
  const progressStore = useProgressStore();
  const { isRecording  } = toRefs(progressStore);

  const fileSelector = ref();
  const noAccess = ref(false);
  const isCheckingMicrophoneAccess = ref(false);
  const isCreatingVoice = ref(false);
  const recorder = useRecorder();

  const timer = useTimer({ maxSecondsDuration: 60 });
  const router = useRouter();

  const { loadAudioFile } = useMediaFileLoader();

  const data = reactive({
    cloningState: CloningSteps.INITIAL,
    cloningInfoDialog: false,
    tryNowSelectionDialog: false,
    voiceCloningInputMode: VoiceCloningInputModes.MIC,
    fileRecording: null as LocalRecording | null,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
    consentConfirmed: false,
    confirmConsentDialog: false,
  });

  onMounted(() => {
    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.VOICES);
    }
  });

  onBeforeUnmount(() => {
    removeListeners();
  });

  const cloningCurrentState = computed(() => {
    return data.cloningState;
  });

  const isVoiceCloningLimitReached = computed(() => {
    if (userStore.isUnlimitedVoiceCloningAccess) return false;
    return clonedVoices.value.length >= userStore.clonedVoicesLimit;
  });

  watch(
    () => recorder.accessDenied,
    (val) => {
      if (val.value) {
        noAccess.value = true;
      }
    },
    { deep: true }
  );

  watch(
    () => recorder.isCheckingAccess,
    (val) => {
      isCheckingMicrophoneAccess.value = val.value;
    },
    { deep: true }
  );

  const chooseFile = () => {
    fileSelector.value.click();
  };

  const loadUserAudioFile = async (event: any, isDropped = false) => {
    const files = isDropped ? event.dataTransfer?.files : event.target.files;

    await loadAudioFile(files, {
      maxDuration: 60,
      supportedExtensions: AUDIO_FORMATS,
    }).catch((e) => {
      data.loadingErrorMessage = e;
      data.loadingError = true;
      analytics.sendEvent(
        "voice_cloning",
        VOICE_CLONING.actions.FILE_UPLOADED_ERROR
      );
    }).then((audioFile) => {
      if (audioFile && audioFile.time < 10) {
        data.loadingErrorMessage = {
          title: "Audio to short",
          description: "Uploaded file must be at least 10 seconds long",
        };
        data.loadingError = true;
        data.cloningState = CloningSteps.INITIAL;
        return;
      }

      if (audioFile) {
        data.fileRecording = {
          ...audioFile,
          type: PLAYGROUND_FEATURES.STS.name,
          createdAt: new Date().toISOString(),
          voiceId: 0,
          voiceType: "other",
        };
        data.cloningState = CloningSteps.FILE_SELECTED;
        isCheckingMicrophoneAccess.value = false;

        analytics.sendEvent(
          "voice_cloning",
          VOICE_CLONING.actions.FILE_UPLOADED_SUCCESS
        );

        data.confirmConsentDialog = true;
      }
    });

  };

  const clearCloningData = () => {
    //reset selected FileList solution
    fileSelector.value.type = "text";
    fileSelector.value.type = "file";
    data.fileRecording = null;
    data.cloningState = CloningSteps.INITIAL;
    $resetActiveClonedVoice();
  };

  const generateRecordingFromFile = async () => {
    if (data.fileRecording) {
      await generateRecording(data.fileRecording.file);
    }
  };

  const goToSuccessPage = () => {
    router.push({ name: Routes.VOICE_CLONING.children.SUCCESS.name });
  };

  const generateRecording = async (recording: Blob) => {
    try {
      analytics.sendEvent(
        "voice_cloning",
        VOICE_CLONING.actions.START_CONVERSION
      );

      isCreatingVoice.value = true;

      const resp = await createClonedVoice("Cloned Voice", recording);

      resp.cloned = true;
      addClonedVoice(resp);

      setCurrentlyActiveClonedVoice({ ...resp, description: "" });

      goToSuccessPage();

      analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.SUCCESS);
    } catch (e: any) {
      if (e.response?.status === 402) {
        showSnackbar(
          "Your account balance is too small to use voice cloning",
          AlertModes.ERROR
        );
        analytics.sendEvent(
          "voice_cloning",
          VOICE_CLONING.actions.NO_BALANCE_ERROR
        );
        data.cloningState = CloningSteps.INITIAL;
      } else {
        showSnackbar(
          e.response?.data?.message || "Something went wrong",
          AlertModes.ERROR
        );
        analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.ERROR);
      }
    } finally {
      isCreatingVoice.value = false;
      removeListeners();
    }
  };

  const removeListeners = () => {
    if (timer) timer.unlistenAll();

    if (recorder) {
      recorder.stop();
      recorder.unlistenAll();
    }
  };

</script>

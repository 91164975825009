/* eslint-disable no-duplicate-imports */
import type { Ref } from "vue";
import { useStore } from "../store";
import { Howl, Howler } from "howler";
import { onBeforeUnmount, reactive, ref, toRefs } from "vue";
import { useAppDrawersStateStore } from "../store/useAppDrawersStateStore";

type returnType = {
  error: Ref<boolean>;
  audio: {
    isPlaying: boolean;
    currentId: string;
  };
  loading: Ref<boolean>;
  toggleAudio(id: number | string, url?: string): Promise<void>;
  toggleRecording(id: string, file: string, format: string): Promise<void>;
  stopAudio(): void;
};

export default function useAudioPlayer(voicePreview = false): returnType {
  const audio = reactive({
    isPlaying: false,
    currentId: "",
    isError: false,
  });

  const store = useStore();

  const audioEl = ref();
  const loading = ref(false);
  const error = ref(false);

  const appDrawersState = useAppDrawersStateStore();
  const { rightPanelPlayingRecordingId } = toRefs(appDrawersState);

  const setAudio = (url: string, id: string, format?: string, callback?: ()=> void) => {
    audioEl.value = new Howl({
      format: [format ?? "wav"],
      src: [url],
      html5: true,
      onload: () => {
        loading.value = false;
        audio.isPlaying = true;
        audio.currentId = id;
        if (id !== rightPanelPlayingRecordingId.value && !voicePreview) {
          return;
        }
        audioEl.value.play();
        if (callback) {
          callback();
        }
      },
      onplay: () => {
        loading.value = false;
        audio.isPlaying = true;
      },
      onend: () => {
        audio.isPlaying = false;
        audio.currentId = "";
      },
      onstop: () => {
        audio.isPlaying = false;
        URL.revokeObjectURL(url);
        audio.currentId = "";
      },
    });
  };

  const stopAudio = () => {
    audioEl.value.stop();
    audio.isPlaying = false;
  };

  const toggleAudio = async (id: number | string, url?: string) => {

    if (!url) {
      return;
    }

    audio.currentId = String(id);

    if (!audio.isPlaying) {
      await Howler.stop();
      loading.value = true;
    }

    try {
      if (!audio.isPlaying) {
        await Howler.stop();
        audio.currentId = String(id);
        setAudio(url, String(id));
      } else {
        stopAudio();
        if (audio.currentId !== String(id)) {
          setAudio(url, String(id));
        }
      }
    } catch (err) {
      error.value = true;
    }
  };

  const toggleRecording = async (id: string, file: string, format: string): Promise<void> => {
    return new Promise((resolve) => {
      audio.currentId = id;
      Howler.stop();

      if (!audio.isPlaying) {
        audio.currentId = id;
        setAudio(file, id, format, () => {
          if (id !== rightPanelPlayingRecordingId.value) {
            return;
          }
          resolve();
        });
      } else {
        stopAudio();
        if (audio.currentId !== id) {
          setAudio(file, id, format, () => {
            resolve();
          });
        }
      }
    });
  };

  onBeforeUnmount(() => {
    if (audioEl.value && audio.isPlaying) {
      stopAudio();
    }
  });

  return { audio, loading, stopAudio, toggleAudio, toggleRecording, error };
}

<template>
  <div class="align-center d-flex justify-center px-4">
    <c-progress-circular
      v-if="userStore.isLoadingAccountBallance"
      color="#A67DFF"
      indeterminate
    />
    <div v-else class="align-center ballance d-flex">
      <div
        class="align-center cursor-pointer d-flex"
        @click="showPricingModal = true"
      >
        <img class="mr-2" src="/images/token.svg" />
        <c-typography
          color-class="universal-gray"
          data-testid="total-balance"
          variant="body-2-400"
        >
          {{ numberWithSpaces(userStore.userAccountBallance) }}
        </c-typography>
      </div>
      <c-button
        v-if="!userStore.subscriptionOption"
        class="ml-3 mr-4"
        height="32"
        mode="special"
        :to="{ name: Routes.SHOP.name }"
        @click="showSubscriptionDetailsDialog = true"
      >Upgrade</c-button
      >
    </div>
  </div>

  <pricing-dialog v-model="showPricingModal" />
</template>

<script lang="ts" setup>
  import { numberWithSpaces } from "@/core/utils/formatters";
  import { Routes } from "@/core/routes/core.guard";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useUserStore } from "@/core/store/userStore";

  import PricingDialog from "../Dialogs/PricingDialog.vue";

  import { ref, toRefs } from "vue";

  // @ts-ignore
  import { CButton, CProgressCircular, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const dialogsStore = useDialogsStore();
  const { showSubscriptionDetailsDialog } = toRefs(dialogsStore);
  const userStore = useUserStore();

  const showPricingModal = ref(false);
</script>

<style lang="scss" scoped>
.ballance {
  color: rgb(var(--v-theme-cta)) !important;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.custom-btn {
  padding-left: 8px;

  :deep(.c-typography--highlight-color) {
    color: rgb(var(--v-theme-highlight-panel)) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>

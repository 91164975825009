import { AlertModes } from "@/core/types/other.types";
import type { TTSStreamingOptions } from "@charactr/api-sdk/dist/@types/tts";
import { useSnackbarStore } from "@/core/store/useSnackbarStore";
import { useUserStore } from "@/core/store/userStore";
import { CharactrAPISDK, type Credentials } from "@charactr/api-sdk";
import { createApiKey, getApiKeys } from "@/core/services/apikeys.service";
import { onBeforeMount, toRefs, watch } from "vue";
import { useSdkStore } from "@/core/store/useSdkStore";
import type { Voice } from "@charactr/api-sdk/dist/@types/types";

enum WsMsgType {
  AuthApiKey = "authApiKey",
  Convert = "convert",
  Close = "close",
}

interface TTSStreamSimplexCallbacks {
  onData?(data: ArrayBuffer): void;
}

interface TtsStreaming {
  initializeSDK(): Promise<void>;
  createApiKeyWithSDKInit(skipSnackbar?: boolean): Promise<void>;
  convertStream(voiceId: number, voiceType: string, userText: string, format: string, save?: boolean): Promise<Array<ArrayBuffer>>;
  convertStreamPreview(voiceId: number, voiceType: string, userText: string, format: string): Promise<Array<ArrayBuffer>>;
}

export default function useStreaming(initialize = true): TtsStreaming {

  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();
  const sdkStore = useSdkStore();
  const { sdkInitialized, noApiKey, sdk: storeSdk } = toRefs(sdkStore);

  onBeforeMount(() => {
    if (initialize) {
      initializeSDK();
    }
  });

  watch(
    () => userStore.userKey,
    async (newVal: string) => {
      if (newVal && !sdkInitialized.value && initialize) {
        await initializeSDK();
      }
    }
  );

  const initializeSDK = async () => {
    if (!userStore.userKey || sdkInitialized.value) return;

    try {
      const keys = await getApiKeys(true);

      if (!keys.length) {
        noApiKey.value = true;
        return;
      }

      const credentials: Credentials = {
        ClientKey: userStore.userKey,
        APIKey: keys[0].apiKey,
      };

      const sdk = new CharactrAPISDK(credentials, {
        charactrAPIUrl: String(import.meta.env.VITE_APP_SLOWPOKE_API_URL),
        charactrAPIUrlWs: String(import.meta.env.VITE_APP_SOCKET_URL),
      });

      await sdk.init();
      storeSdk.value = Object.assign({}, sdk);
      sdkInitialized.value = true;

    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occurred", AlertModes.ERROR);
    }
  };

  const createApiKeyWithSDKInit = async (skipSnackbar = false) => {
    try {
      await createApiKey();
      await initializeSDK();
      if (!skipSnackbar) {
        showSnackbar("API key created", AlertModes.SUCCESS);
      }
    } catch (e: any) {
      showSnackbar("Error while creating API key", AlertModes.ERROR);
    } finally {
      noApiKey.value = false;
    }
  };

  const convertStreamInternal = async (
    voiceId: number,
    voiceType: string,
    userText: string,
    format: string,
    save: boolean,
    isPreview: boolean
  ): Promise<Array<ArrayBuffer>> => {
    const streamData: Array<ArrayBuffer> = [];
    const options: TTSStreamingOptions = { save, voiceType, format };

    const callback: TTSStreamSimplexCallbacks = {
      onData: (data) => streamData.push(data),
    };

    if (isPreview) {
      await convertStreamWebSocket(voiceId, userText, callback, options, true);
    } else {
      await storeSdk.value.tts.convertStreamSimplex(voiceId, userText, callback, options);
    }

    return streamData;
  };

  const convertStream = async (
    voiceId: number,
    voiceType: string,
    userText: string,
    format = "wav",
    save = true
  ): Promise<Array<ArrayBuffer>> => {
    return await convertStreamInternal(voiceId, voiceType, userText, format, save, false);
  };

  const convertStreamPreview = async (
    voiceId: number,
    voiceType: string,
    userText: string,
    format = "wav"
  ): Promise<Array<ArrayBuffer>> => {
    return await convertStreamInternal(voiceId, voiceType, userText, format, false, true);
  };

  const convertStreamWebSocket = async (
    voice: number | Voice,
    text: string,
    cb: TTSStreamSimplexCallbacks,
    options: TTSStreamingOptions,
    isPreview: boolean
  ): Promise<void> => {
    const keys = await getApiKeys(true);

    const credentials: Credentials = {
      ClientKey: userStore.userKey,
      APIKey: keys[0].apiKey,
    };

    const wsUrl = `${String(import.meta.env.VITE_APP_SOCKET_URL)}/v1/tts/stream/${isPreview ? "simplex-preview" : "simplex"}/ws?${getTTSStreamingQueryParams(voice, options).toString()}`;

    return new Promise((resolve, reject) => {
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        ws.send(JSON.stringify({ type: WsMsgType.AuthApiKey, clientKey: credentials.ClientKey, apiKey: credentials.APIKey }));
        ws.send(JSON.stringify({ type: WsMsgType.Convert, text }));
      };

      ws.onclose = (event: CloseEvent) => {
        if (event.code === 1000) resolve();
        else reject(new Error(`Error [${event.code}]: ${event.reason || "unknown reason"}`));
      };

      ws.onmessage = (message: MessageEvent) => {
        if (cb.onData) cb.onData(message.data);
      };
    });
  };

  const getTTSStreamingQueryParams = (
    voice: number | Voice,
    options: TTSStreamingOptions
  ): URLSearchParams => {
    const params = new URLSearchParams({
      ua: "sdk-ts",
      voiceId: String(getValidVoiceIdOrThrow(voice)),
    });

    if (options.voiceType) params.set("voiceType", options.voiceType);
    return params;
  };

  const getValidVoiceIdOrThrow = (voice: number | Voice): number => {
    if (Number.isInteger(voice) && voice as number > 0) return voice as number;
    if (Number.isInteger((voice as Voice).id)) return (voice as Voice).id;
    throw new TypeError("Invalid 'voice' argument: must be either a valid integer or a Voice object.");
  };

  return {
    initializeSDK,
    createApiKeyWithSDKInit,
    convertStream,
    convertStreamPreview,
  };
}

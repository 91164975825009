import { VIDEO_CREATOR } from "@/core/utils/analytics";

export const mappingDialogContent = {
  voice: {
    title: "Request Voice Clone Pro",
    description:
      "Unlock the full potential of personalized audio with our fine-tuned Voice Clone Pro.",
    descriptionPrice: "Price starts from $49 monthly / $500 yearly",
    label: "Voice Clone Pro",
    analytics: {
      success: VIDEO_CREATOR.actions.SEND_REQUEST_TWIN_PRO_SUCCESS,
      error: VIDEO_CREATOR.actions.SEND_REQUEST_TWIN_PRO_ERROR,
    },
    successMessage: "Request Voice Clone Pro send",
  },

  aiTwinVideoPro: {
    title: "Request Video Twin Pro",
    description:
      "Create a lifelike Twin from a video with our Video Twin Pro. Utilize your own model, trained for seamless voice and facial synchronization, in your digital creations.",
    descriptionPrice: "Prices starts from $39 monthly / $400 yearly",
    label: "Video Twin Pro",
    analytics: {
      success: VIDEO_CREATOR.actions.SEND_REQUEST_AI_TWIN_SUCCESS,
      error: VIDEO_CREATOR.actions.SEND_REQUEST_AI_TWIN_ERROR,
    },
    successMessage: "AI Gemelo Synthetic request send",
  },

  aiTwinPhotoPro: {
    title: "Request Photo Twin Pro",
    description:
      "Transform a single photo into a stylized, live portrait with Photo Twin Pro.",
    descriptionPrice: "Price starts from $10 monthly / $100 yearly",
    label: "Photo Twin Pro",
    analytics: {
      success: VIDEO_CREATOR.actions.SEND_REQUEST_PHOTO_TWIN_PRO_SUCCESS,
      error: VIDEO_CREATOR.actions.SEND_REQUEST_PHOTO_TWIN_PRO_ERROR,
    },
    successMessage: "AI Gemelo Synthetic request send",
  },
  bundle1: {
    title: "Video Twin Pro + Voice Clone Pro",
    description: "Elevate your digital presence with our Pro Bundle, offering comprehensive access to Video Twin Pro and Voice Clone Pro. Achieve professional-grade realism and interactivity in all your projects.",
    descriptionPrice: "Price starts from $70 monthly / $750 yearly",
    label: "Video Twin Pro + Voice Clone Pro",
    analytics: {
      success: VIDEO_CREATOR.actions.SEND_REQUEST_BUNDLE_VIDEO_TWIN_SUCCESS,
      error: VIDEO_CREATOR.actions.SEND_REQUEST_BUNDLE_VIDEO_TWIN_ERROR,
    },
    successMessage: "Bundle request send",
  },
  bundle2: {
    title: "Photo Twin Pro + Voice Clone Pro",
    description: "Boost your digital impact using our Pro Bundle, which provides full access to both Photo Twin Pro and Voice Clone Pro. Reach new heights of professional-grade, stylized realism and interactivity across all your projects.",
    descriptionPrice: "Price starts from $59 monthly / $600 yearly",
    label: "Photo Twin Pro + Voice Clone Pro",
    analytics: {
      success: VIDEO_CREATOR.actions.SEND_REQUEST_BUNDLE_PHOTO_TWIN_SUCCESS,
      error: VIDEO_CREATOR.actions.SEND_REQUEST_BUNDLE_PHOTO_TWIN_ERROR,
    },
    successMessage: "Bundle request send",
  },
};

export const yourRequestOptions = Object.values(mappingDialogContent).map(
  (el) => el.label
);

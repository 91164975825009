<template>
  <div :class="['align-center d-flex justify-start w-100 my-4']">
    <c-checkbox
      :disabled="props.disabled"
      :gap="8"
      :height="22"
      :model-value="consentConfirmed"
      test-id="voice-cloning-consent"
      typography="body-2-400"
      @update:model-value="emit('update:consentConfirmed', $event)"
    />

    <div class="align-center d-flex">
      <c-typography
        color-class="copy-secondary"
        pointer
        test-id="voice-cloning-consent-text"
        variant="body-3-400"
        @click="
          props.disabled
            ? ''
            : emit('update:consentConfirmed', !consentConfirmed)
        "
      >
        I have a consent for the digital replication of their voice.

        <a class="link" @click="openStandardsDialog">Read more</a>
      </c-typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  // @ts-ignore
  import { CCheckbox, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    consentConfirmed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:consentConfirmed"]);

  //STORE
  const { toggleStandardsGuidelinesDialog } = useDialogsStore();

  const openStandardsDialog = () => {
    toggleStandardsGuidelinesDialog(true);
  };
</script>

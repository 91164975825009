<template>
  <div class="d-flex flex-column wrapper">
    <div class="justify-center mb-1 picture-container">
      <c-tag
        v-if="
          video.url &&
            props.video.type &&
            (props.video.type === RecordingTypes.TTV_RAW ||
              props.video.type === RecordingTypes.ZERO_SHOT)
        "
        class="tag"
        :mode="props.video.type === RecordingTypes.TTV_RAW ? 'berry' : 'lilac'"
      >
        {{
          props.video.type === RecordingTypes.TTV_RAW
            ? "high quality"
            : "express"
        }}
      </c-tag>
      <c-skeleton v-if="!video.url" class="skeleton" />
      <div
        v-if="drawerStore.showInfo"
        class="floating-info pa-4"
        data-testid="floating-info"
      >
        <c-typography
          class="d-flex mb-1"
          color-class="cta"
          variant="body-3-600"
        >
          It may take some time...
        </c-typography>
        <c-typography
          class="d-flex"
          color-class="universal-gray"
          variant="body-3-400"
        >
          We'll notify you by email once your video is ready. Thank you for your
          patience.
        </c-typography>
      </div>
      <img
        v-show="!video.url && imageLoaded"
        :key="imageKey"
        class="img img-absolute"
        :height="328"
        :src="data.videoPortraitUrl"
        :width="328"
        @load="loaded"
      />

      <video-js-player
        v-if="video.url"
        :key="data.videoKey"
        ref="videoPlayer"
        :hide-background="
          (video.lipsyncId && !video.portraitId) ||
            video.type === RecordingTypes.DUBBING
        "
        :options="{
          autoplay: false,
          controls: false,
          width: 328,
          height: 328,
          poster: data.videoPortraitUrl,
          sources: [
            {
              src: video.url,
              type: 'video/mp4',
            },
          ],
        }"
        @end="videoEnded"
        @loaded="setPlayingState"
        @playing="setPlayingState"
      />
    </div>
    <right-drawer-player
      :key="'rightDrawerPlayer' + playerKey"
      ref="rightDrawerPlayerRef"
      :bottom-border="false"
      :disabled="!video.url"
      :disabled-title="false"
      :mode="props.mode"
      :name="props.video.name"
      :player-state="data.playerState"
      :recording="video"
      reduce-padding
      :show-fullscreen="Boolean(props.video.url)"
      :time="toSeconds(props.video.videoDurationMs)"
      :top-border="false"
      @download-recording="downloadRecording"
      @edit-name="editName"
      @fullscreen="showFullscreen"
      @open-delete-dialog="data.deleteDialog = true"
      @open-edit-dialog="data.editDialog = true"
      @toggle="toggle"
    >
      <template #dialogs>
        <edit-name-dialog
          v-if="data.editDialog"
          v-model="data.editDialog"
          :initial-name="props.video.name"
          @set-new-name="editName"
        />
        <delete-item-dialog
          v-if="data.deleteDialog"
          v-model="data.deleteDialog"
          @delete-item="deleteVideoRecording"
        />
      </template>
    </right-drawer-player>
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import DeleteItemDialog from "../Dialogs/DeleteItemDialog.vue";
  import { downloadFromUrl } from "@/core/utils/download";
  import EditNameDialog from "../Dialogs/EditNameDialog.vue";
  import RightDrawerPlayer from "./RightDrawerPlayer.vue";
  import { VideoStatus } from "@/core/types/video.types";
  import {
    type Component,
    onBeforeMount,
    type PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import {
    deleteVideo,
    updateVideoName,
  } from "@/core/services/synthetics.service";
  import { PlayerState, RecordingTypes } from "@/core/types/recording.types";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  import VideoJsPlayer from "./VideoJsPlayer.vue";

  // @ts-ignore
  import { CSkeleton, CTag, CTypography } from "@charactr/wooper-ui/atoms";

  import { useDrawerStore } from "../../store";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useFullscreenVideoStore } from "@/core/store/useFullscreenVideoStore";

  const props = defineProps({
    video: {
      type: Object as PropType<any>,
      default: null,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: PLAYGROUND_FEATURES.VTV.name,
    },
  });

  //STORE
  const drawerStore = useDrawerStore();
  const { showSnackbar } = useSnackbarStore();
  const fullscreenVideoStore = useFullscreenVideoStore();
  const { fullscreenVideo, showFullscreenVideo } = toRefs(fullscreenVideoStore);

  const videoPlayer = ref();
  const imageLoaded = ref(false);
  const imageKey = ref(0);
  const playerKey = ref(0);

  const loaded = () => {
    imageLoaded.value = true;
  };

  const showFullscreen = () => {
    data.videoKey++;
    fullscreenVideo.value = props.video;
    showFullscreenVideo.value = true;
  };

  const rightDrawerPlayerRef = ref<null | Component>(null);

  const data = reactive({
    playerState: PlayerState.Idle,
    editDialog: false,
    deleteDialog: false,
    showPreview: false,
    videoPortraitUrl: "",
    videoKey: 0,
  });

  const toSeconds = (time: number) => {
    return time / 1000;
  };

  const getPictureUrl = async () => {
    if (!props.video.type) {
      data.videoPortraitUrl = props.video.imageLoadingUrl;
      return;
    }
    data.videoPortraitUrl = props.video.thumbnailUrl || "/images/unknown2.png";

    data.videoKey++;
  };

  onBeforeMount(async () => {
    getPictureUrl();
  });

  const stopPlayingVideos = () => {
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
      }
    });
  };

  watch(
    () => drawerStore.activePlayingVideoId,
    (val) => {
      if (val) {
        if (drawerStore.activePlayingVideoId !== props.video.id) {
          videoPlayer.value.stop();
          data.playerState = PlayerState.Idle;
        }
      }
    }
  );
  watch(
    () => props.video,
    (newVal, oldVal) => {
      if (newVal.status === VideoStatus.READY) {
        playerKey.value++;
      }
      if (
        ((newVal.lipsyncId &&
          newVal.lipsyncId === oldVal.lipsyncId &&
          !newVal.portraitId) ||
          (newVal.portraitId && newVal.portraitId === oldVal.portraitId)) &&
        (oldVal.id === newVal.id || oldVal.imageLoadingUrl)
      ) {
        return;
      }

      if (newVal.status === VideoStatus.DUBBING_QUEUED) {
        data.videoPortraitUrl = "/images/unknown2.png";
        imageLoaded.value = true;
        imageKey.value++;
      } else {
        data.videoPortraitUrl = "";
        imageLoaded.value = false;
        imageKey.value++;
        getPictureUrl();
      }
    }
  );

  const toggleGridPreview = async () => {
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
      }
    });
    if (props.video.status === VideoStatus.READY) {
      if (data.playerState === PlayerState.Idle) {
        stopPlayingVideos();
        data.playerState = PlayerState.Loading;

        drawerStore.activePlayingVideoId = props.video.id;
        videoPlayer.value.play();
      } else if (data.playerState === PlayerState.Playing) {
        data.playerState = PlayerState.Idle;
        videoPlayer.value.stop();
      }
    }
  };

  const toggle = () => {
    toggleGridPreview();
  };

  const downloadRecording = async () => {
    if (props.video.url) {
      downloadFromUrl(props.video.url, `${props.video.name}.wav`, "_blank");
    }
  };

  const deleteVideoRecording = async () => {
    try {
      await deleteVideo(props.video.id);

      const videoType = props.video.type;
      let mode = PLAYGROUND_FEATURES.VTV;

      if (
        videoType === RecordingTypes.TTV ||
        videoType === RecordingTypes.TTV_RAW ||
        videoType === RecordingTypes.ZERO_SHOT ||
        videoType === RecordingTypes.DUBBING
      ) {
        mode = PLAYGROUND_FEATURES.VIDEO_SYNTHESIS;
      }

      drawerStore.deleteVideo(props.video.id, mode);
      showSnackbar("The video has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.deleteDialog = false;
    }
  };

  const editName = async (newName: string) => {
    try {
      await updateVideoName(props.video.id, newName);
      showSnackbar(
        "The video name has been successfully changed.",
        AlertModes.SUCCESS
      );

      const videoType = props.video.type;
      let mode = PLAYGROUND_FEATURES.VTV;

      if (
        videoType === RecordingTypes.TTV ||
        videoType === RecordingTypes.TTV_RAW ||
        videoType === RecordingTypes.ZERO_SHOT ||
        videoType === RecordingTypes.DUBBING
      ) {
        mode = PLAYGROUND_FEATURES.VIDEO_SYNTHESIS;
      }

      drawerStore.updateVideoName(props.video.id, newName, mode);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.editDialog = false;
    }
  };

  const videoEnded = () => {
    data.playerState = PlayerState.Idle;
  };

  const setPlayingState = () => {
    data.playerState = PlayerState.Playing;
  };
</script>

<style lang="scss" scoped>
.img,
.video {
  border-radius: 8px;
}

.img-absolute {
  background-color: rgb(var(--v-theme-light-frame)) !important;
  border-radius: 8px;
  display: block;
  height: 328px;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 328px;
}

.video {
  cursor: pointer;
}

.skeleton {
  border-radius: 8px;
  opacity: 85%;
  position: relative;
  z-index: 1;

  :deep(.v-skeleton-loader__bone) {
    border-radius: 8px;
    height: 328px;
    width: 328px;
  }
}

.wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  padding: 16px 16px 8px;
}

.picture-container {
  background-color: rgb(var(--v-theme-dark-bg));
  border-radius: 8px;
  position: relative;
  width: 328px;
  z-index: 0;
}

.floating-info {
  background-color: rgb(var(--v-theme-side-panel-bg));
  border-radius: 8px;
  bottom: 8px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 316px;
  z-index: 1000;
}

.tag {
  left: 8px;
  position: absolute;
  top: 8px;
  z-index: 10;
}
</style>

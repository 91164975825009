<template>
  <div :class="['align-center d-flex justify-start w-100']">
    <c-checkbox
      :disabled="isCreatingVideo"
      :gap="8"
      :height="22"
      :model-value="modelValue"
      test-id="playground-consent"
      typography="body-2-400"
      @update:model-value="updateModelValue"
    />

    <div class="align-center d-flex">
      <c-typography
        color-class="copy-secondary"
        data-testid="playground-consent-text"
        pointer
        variant="body-3-400"
        @click="updateModelValue"
      >
        <slot name="content">
          By submitting, I confirm that I have the copyright to reproduce,
          distribute or display copyrighted materials.
          <a class="link" data-testid="read-more" @click="openStandardsDialog"
          ><b>Read more</b></a
          >
        </slot>
      </c-typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { storeToRefs } from "pinia";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  // @ts-ignore
  import { CCheckbox, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  //STORE
  const { toggleStandardsGuidelinesDialog } = useDialogsStore();
  const { isCreatingVideo } = storeToRefs(useVideoCreatorStore());

  const openStandardsDialog = () => {
    toggleStandardsGuidelinesDialog(true);
  };

  const updateModelValue = () => {
    if (!isCreatingVideo.value) {
      emit("update:modelValue", !props.modelValue);
    }
  };
</script>


import type {
  FilterCategory,
  FilterItem,
  Voice,
  VoiceLabelCategory,
} from "@/core/types/voice.types";

export const getVoiceLabelCategoryTitle = (
  category: VoiceLabelCategory
): string => {
  switch (category) {
    case "gender":
      return "Gender";
    case "pitch":
      return "Pitch";
    case "voiceFeatures":
      return "Attribute";
    case "voiceMoods":
      return "Tone";
    case "otherFeatures":
      return "Usability";
    case "exampleUsages":
      return "Other";
    default:
      // eslint-disable-next-line no-case-declarations
      //const exhaustiveCheck: never = category;
      return "Other";
     //throw new Error(`Unknown voice label category: ${exhaustiveCheck}`);
  }
};

export function createFilters(voices: Array<Voice>): Array<FilterItem> {
  const filters = {} as FilterCategory;

  if (!voices.length || !voices[0].labels) {
return [];
  }

  voices.forEach((voice) => {
    voice.labels?.forEach((voiceLabel) => {

      const category = voiceLabel.category as VoiceLabelCategory;

      if (filters[category]) {
        filters[category].add(voiceLabel.label);
      } else {
        filters[category] = new Set([voiceLabel.label]);
      }
    });
  });

  return Object.keys(filters).map((key) => {
    return {
        label: getVoiceLabelCategoryTitle(key as VoiceLabelCategory),
        name: key,
        items: filters[key],
     };
  });
}

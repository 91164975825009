import type { VoiceType } from "./voice.types";

export enum SyntheticType {
  CLONE = "clone",
  SYNTHETIC = "synthetic",
  UPLOAD = "upload",
}

export enum SyntheticStatus {
  NEW = "new",
  UPLOAD = "upload",
  TRAINING = "training",
  TRAINING_QUEQUED = "training_quequed",
  INFERENCE = "inference",
  FACECHECKING_QUEQUED = "facechecking_queued",
  FACECHECKING = "facechecking",
  TEXTCHECKING_QUEQUED = "textchecking_queued",
  TEXTCHECKING = "textchecking",
  INFERENCE_QUEQUED = "inference_queued",
  VARIANT_CHOICE = "variant_choice",
  READY = "ready",
  ERROR = "error",
}

export enum LipsyncStatus {
UPLOAD = "upload",
GENERATING_COVER = "generating_cover",
PRE_REVIEW = "pre_review",
PRE_REVIEW_REJECTED = "pre_review_rejected",
TRAINING = "training",
TRAINING_QUEQUED = "training_quequed",
READY = "ready",
ERROR = "error",
EDIT_REQUIRED = "edit_required"
}

export enum ModelType {
  ZEROSHOT = "zeroshot",
  NORMAL = "normal",
}

export type SyntheticPortrait = {
  id: number;
  clientId: number;
  name: string;
  type: SyntheticType;
  status: SyntheticStatus;
  variantUrls?: [];
  error?: string;
  url?: string;
  thumbnailUrl: string;
  isBuiltin?: boolean;
  createdAt: string;
  icon?: string;
  voiceType?: VoiceType;
  voiceId?: number | string;
  comment?: string;
};

export type SyntheticModel = {
  id: number;
  uuid: number;
  name: string;
  coverUrl: string;
  isBuiltin: boolean;
  createdAt: string;
  status: LipsyncStatus;
  trainingPriority: number;
  type: ModelType;
  voiceType?: VoiceType;
  voiceId?: number | string;
  comment?: string;
};

export type PhotoTwinModel = {
  id: number;
  name: string;
  thumbnailUrl: string;
  url: string;
  createdAt: string;
  status: string;
  isBuiltin: boolean;
  gender: string;
};

export type PhotoTwinPhoto = {
  id: number;
  url: string;
  name: string;
  prompt: string;
  twinIds: Array<number>;
};

export enum SYNTHETIC_TABS {
  ALL = "all",
  SYSTEM = "system",
  USER = "user",
  MODEL = "model",
}

export type RandomizedInputs = {
  attitude: string;
  industry: string;
  other: string;
  proficiency: string;
  target: string;
};

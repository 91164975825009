<template>
  <c-dialog
    cancel-option
    class="scrollable"
    :model-value="modelValue"
    revert-buttons-order
    :width="isMobile ? '100%' : 580"
    @cancel="deny"
    @confirm="accept"
    @keydown.enter="accept"
    @update:model-value="deny"
  >
    <template #title
    ><slot name="title"
    >Do you have a consent of the individual depicted in the photo?</slot
    ></template
    >
    <template #content>
      <c-typography
        class="d-block mb-6"
        color-class="copy-secondary"
        variant="body-2-400"
      >
        <slot name="description">
          By uploading the photo of the individual, you confirm that you have
          obtained their explicit consent for the digital cloning of their
          appearance. You also acknowledge that you are solely responsible for
          ensuring that you have the legal right to use and manipulate the
          uploaded image.
        </slot>
      </c-typography>
      <standards-guideline-common-content />
    </template>
    <template #confirm-label> Yes, confirm </template>
    <template #cancel-label> No, decline </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import StandardsGuidelineCommonContent from "@/core/components/Dialogs/StandardsGuidelineCommonContent.vue";
  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["accept", "deny", "update:modelValue"]);

  const accept = async () => {
    emit("accept");
  };

  const deny = async () => {
    emit("update:modelValue", false);
    emit("deny");
  };
</script>

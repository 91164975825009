<template>
  <error-dialog
    v-model="data.loadingError"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />

  <input
    id="source_video"
    ref="videoFileSelector"
    accept="video/*"
    class="d-none"
    type="file"
    @change="loadTargetVideoFile"
  />
  <div
    :class="[
      'd-flex flex-column flex-grow mt-3',
    ]"
  >
    <div
      v-if="!selectedDubbingVideo && !isCreatingDubbingVideo"
      class="align-center d-flex flex-wrap h-100 justify-center"
    >
      <drag-and-drop
        class="h-100 mb-4"
        :disabled="isRecordingsLimitReached"
        :supported-formats="VIDEO_FORMATS"
        test-id="dnd-video-creator-dubbing"
        @choose-file="chooseFile"
        @drop-file="loadTargetVideoFile($event, true)"
      />
    </div>

    <Video
      v-else
      id="player"
      :delete="true"
      :file="selectedDubbingVideo || {}"
      :loading="isCreatingDubbingVideo"
      max-height="calc(100vh - 546px)"
      :mobile="isMobile"
      :show-details="true"
      video-creator
      @delete="cleanUp"
    />
  </div>

  <video-to-video-guide v-model="data.showVideoGuide" />
</template>

<script lang="ts" setup>
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import Video from "../../../playground/components/videoToVideo/Video.vue";
  import VideoToVideoGuide from "@/core/components/Dialogs/VideoToVideoGuide.vue";

  import { analytics, VIDEO } from "@/core/utils/analytics";
  import { computed, reactive, ref, toRefs } from "vue";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useUserStore } from "@/core/store/userStore";

  import { VIDEO_FORMATS } from "@/core/types/file.types";
  import { storeToRefs } from "pinia";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  //STORE
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();
  const { loadVideoFile } = useMediaFileLoader();
  const videoFileSelector = ref();
  const progressStore = useProgressStore();
  const { isConverting } = toRefs(progressStore);
  const { isCreatingDubbingVideo, selectedDubbingVideo } = storeToRefs(useVideoCreatorStore());

  const data = reactive({
    showVideoGuide: false,
    showPreview: false,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
    videoName: "",
    loadedVideoSize: 0,
    agreeRules: false,
  });

  const isRecordingsLimitReached = computed(() => {

    if (userStore.isUnlimitedVideoAccess) return false;

    return (
      drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit
    );
  });

  const cleanUp = () => {
    selectedDubbingVideo.value = null;
  };

  const chooseFile = () => {
    videoFileSelector.value.type = "text";
    videoFileSelector.value.type = "file";
    videoFileSelector.value.click();
  };

  const loadTargetVideoFile = async (event: any, isDropped = false) => {
    isConverting.value = true;
    cleanUp();

    const files = isDropped ? event.dataTransfer?.files : event.target.files;

    data.videoName = files.length ? files[0].name : "";
    const videoFile = await loadVideoFile(files, {
      maxDuration: userStore.videoSecondsMaxLength,
      maxMegabytes: userStore.uploadedVideoFileSizeMb,
      supportedExtensions: VIDEO_FORMATS,
    }).catch((err) => {
      data.loadingErrorMessage = err;
      data.loadingError = true;
      analytics.sendEvent("vtv", VIDEO.actions.SOURCE_VIDEO_LOAD_ERROR);
      return;
    });

    if (videoFile) {
      selectedDubbingVideo.value = videoFile;
      analytics.sendEvent("vtv", VIDEO.actions.SOURCE_VIDEO_LOAD_SUCCESS);
    }
    isConverting.value = false;
  };

</script>

<style scoped lang="scss">
.file-wrapper {
  background: transparent;
  border: 1px solid rgba(var(--v-theme-dark-frame)) !important;
  border-radius: 8px;
  box-sizing: border-box;
}

.flex-grow {
  flex: 1;
}
</style>

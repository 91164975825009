export enum SubscriptionTypes {
  EARLY_BIRDS = "subscriptionTier0",
  PROFESSIONAL = "subscriptionTier1",
  TRIAL = "subscriptionTrial",
  FREE = "",
}

export const SubscriptionTitlesMap = new Map<SubscriptionTypes, string>([
  [SubscriptionTypes.EARLY_BIRDS, "Gemelo Early Birds"],
  [SubscriptionTypes.TRIAL, "Gemelo Free Trial"],
  [SubscriptionTypes.FREE, "Gemelo Professional"],
  [SubscriptionTypes.PROFESSIONAL, "Gemelo Professional"],
]);

export interface SubscriptionPromo {
  end: string;
  name: string;
  amountCents: number;
  amountOffCents: number;
  amountFinalCents: number;
}

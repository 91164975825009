<template>
  <c-custom-card
    class="title-card"
    color="player-bg"
    :height="isMobile ? '100%' : '221'"
    :width="isMobile ? 240 : 574"
  >
    <template #content>
      <div :class="['d-flex pa-4 w-100', { 'flex-column': isMobile }]">
        <div>
          <img
            v-if="!videoLoaded || isIOS"
            :class="isMobile ? 'img--mobile' : 'img'"
            :src="props.feature.image"
          />
          <video
            v-show="props.feature.video && videoLoaded && !isIOS"
            autoplay
            :class="isMobile ? 'img--mobile' : 'img'"
            loop
            muted
            preload="metadata"
            @loadedmetadata="handleVideoLoadMeta"
          >
            <source :src="props.feature.video" />
          </video>
        </div>
        <div :class="['w-100', { 'pl-4': !isMobile }]">
          <div class="align-center d-flex justify-space-between">
            <c-typography color-class="copy-primary" variant="subtitle-2-600">{{
              props.feature.title
            }}</c-typography>
            <c-button
              v-if="!isMobile"
              height="40"
              mode="highlight-plain"
              no-padding
              @click="emit('openModal')"
            >
              Learn More
            </c-button>
          </div>
          <div :class="{ 'description-container': isMobile }">
            <c-typography color-class="copy-secondary" variant="body-3-400">{{
              props.feature.desc
            }}</c-typography>
            <div :class="['d-flex pt-4', { 'flex-wrap test': isMobile }]">
              <c-tag
                v-for="(tag, index) in props.feature.tags"
                :key="index"
                :class="['mr-1 tag', { 'ma-1': isMobile }]"
              >{{ tag }}</c-tag
              >
            </div>
          </div>
          <div :class="['pt-5 d-flex justify-space-between gap-12', { 'confirm-button': isMobile }]">
            <c-button
              class="flex-grow-1"
              :data-testid="`dashboard-create-button-${props.feature.testId}`"
              :disabled="props.feature.soon"
              height="40"
              :mode="props.feature.soon ? 'secondary' : 'special'"
              :prepend-icon="props.feature.actionIcon"
              :to="{ name: props.feature.routeName }"
              @click="props.feature.action"
            >{{ props.feature.actionLabel }}</c-button
            >

          </div>
        </div>
      </div>
    </template>
  </c-custom-card>
</template>

<script lang="ts" setup>
  import type { TitleFeature } from "@/core/types/other.types";
  import { isIOS, isMobile } from "../../utils/mobile";
  import { type PropType, ref } from "vue";

  //@ts-ignore
  import { CButton, CTag, CTypography } from "@charactr/wooper-ui/atoms";
  //@ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    feature: {
      type: Object as PropType<TitleFeature>,
      default: () => ({}),
    },
  });

  const emit = defineEmits(["openModal"]);

  const videoLoaded = ref(false);

  const handleVideoLoadMeta = () => {
    videoLoaded.value = true;
  };
</script>

<style lang="scss" scoped>
.img {
  border-radius: 8px;
  height: 182px;
  width: 183px;

  &--mobile {
    border-radius: 8px;
    height: 208px;
    width: 208px;
  }
}

:deep(.v-card-text) {
  height: 100%;
}

.description-container {
  height: 148px;
}

.title-card {
  border: 1px solid rgb(var(--v-theme-light-frame));
}

.gap-12 {
  gap: 12px;
}
</style>

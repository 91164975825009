<template>
  <c-custom-card
    v-bind="$attrs"
    :class="[
      `align-start d-flex justify-center pa-${border}`,
      { 'frame-card': !isMobile && border },
      { 'frame-card--mobile': isMobile && border },
    ]"
    :color="$attrs.color"
  >
    <template #content>
      <div
        v-if="title"
        :class="[`align-center d-flex justify-center mb-${marginTitle}`]"
      >
        <c-typography :color-class="titleColor" :variant="titleVariant">
          {{ title }}
        </c-typography>
      </div>
      <c-custom-card :class="['card w-100']" color="player-bg">
        <template #content>
          <div class="d-flex flex-column w-100">
            <slot name="content" />
          </div>
        </template>
      </c-custom-card>
    </template>
  </c-custom-card>
</template>

<script lang="ts" setup>
  import { computed } from "vue";
  import { isMobile } from "../utils/mobile";

  // @ts-ignore
  import { CTypography  } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    tier: {
      type: Object,
      default: () => ({}),
    },
    titleVariant: {
      type: String,
      default: "body-3-600",
    },
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "cta",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Number,
      default: 1,
    },
    marginTitle: {
      type: Number,
      default: 1,
    },
    borderRadius: {
      type: Number,
      default: 12,
    },
  });

  const cardBorderRadius = computed(() => {
    return props.borderRadius + "px";
  });
</script>

<style lang="scss" scoped>
:deep(.v-card-text) {
  align-items: stretch;
  line-height: normal;
}

.v-card {
  border-radius: v-bind("cardBorderRadius");
}

.justify-between {
  justify-content: space-between;
}

@media (min-width: 2000px) {
  .main-container {
    max-width: 1056px;
  }
}

.frame-card {
  &--mobile {
    @media (max-width: 570px) {
      margin: 0 -4px;
    }
  }
}
</style>

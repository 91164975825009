<template>
  <div
    :class="[
      'align-center d-flex flex-column nav',
      {
        'nav--no-chips':
          selectedDrawerTab === RightPanelTabs.RECORDINGS ||
          props.variant === PLAYGROUND_FEATURES.VOICE_CLONING.name,
      },
    ]"
  >
    <div
      id="rightDrawerContent"
      :class="[
        'align-center d-flex nav-tabs pa-4 w-100 justify-space-between',
        {
          'drawer--ios-landscape':
            isIOS() && mobileData.orientation.type?.includes('landscape'),
          'nav-tabs--mobile': mobile,
        },
      ]"
    >
      <c-button
        v-if="mobile ? !data.searchBar : isRightPanelOpen"
        :class="['d-flex', data.searchBar ? 'mr-2' : 'mr-8']"
        height="20"
        icon="ph:magnifying-glass"
        icon-color="rgb(var(--v-theme-copy-secondary))"
        icon-height="20"
        plain
        size="x-small"
        width="20"
        @click="openSearchBar"
      ></c-button>
      <template v-if="data.searchBar">
        <template v-if="!mobile">
          <div class="align-center d-flex search w-100">
            <c-input
              v-model="data.search"
              autofocus
              density="compact"
              mode="plain"
              placeholder="Find..."
              @click.stop
            />
          </div>
          <c-button
            icon="ph:x"
            icon-color="rgb(var(--v-theme-copy-secondary))"
            icon-height="20"
            mode="action"
            plain
            size="small"
            @click="closeSearchBar"
          />
        </template>
        <template v-else>
          <div class="align-center d-flex search w-100">
            <c-input
              v-model="data.search"
              autofocus
              class="search--mobile"
              density="compact"
              mode="search"
              placeholder="Find..."
              @click.stop
            />
          </div>
          <c-button
            mode="highlight-plain"
            no-padding
            size="small"
            @click="showRightDrawer = false"
          ><c-typography
            :class="{ 'pl-4': data.searchBar }"
            color-class="highlight-color"
            variant="body-1-600"
          >Close</c-typography
          ></c-button
          >
        </template>
      </template>
      <template v-else>
        <c-typography
          v-if="props.variant === PLAYGROUND_FEATURES.VOICE_CLONING.name"
          class="mr-5"
          color-class="copy-primary"
          pointer
          variant="body-1-600"
        >Voice Library</c-typography
        >
        <c-typography
          v-else-if="!route.meta.hideRecordingsTab"
          :color-class="
            selectedDrawerTab === RightPanelTabs.VOICES
              ? 'copy-tertiary'
              : 'primary'
          "
          pointer
          :variant="
            selectedDrawerTab === RightPanelTabs.VOICES
              ? 'body-1-400'
              : 'body-1-600'
          "
        >Recent Recordings</c-typography
        >
        <c-button
          mode="highlight-plain"
          no-padding
          size="x-small"
          @click="
            props.mobile
              ? (showRightDrawer = false)
              : goToRecordingsList()
          "
        ><c-typography color-class="highlight-color" variant="body-2-600">{{
          props.mobile ? "Close" : "See All"
        }}</c-typography></c-button
        >
      </template>
    </div>

    <div
      v-if="data.searchBar"
      :class="[
        'align-center d-flex px-4 py-2 results w-100',
        {
          'results--border-bottom':
            rightDrawerOpenedPanel === RightPanelTabs.VOICES &&
            props.variant !== PLAYGROUND_FEATURES.VOICE_CLONING.name,
        },
      ]"
    >
      <c-typography color-class="copy-secondary" variant="body-2-400"
      >{{
        rightDrawerOpenedPanel === RightPanelTabs.VOICES
          ? filterResults
          : isVideoList
            ? filteredVideos.length
            : filteredRecordings.length
      }}
        results</c-typography
      >
    </div>
  </div>
  <div
    v-if="
      selectedDrawerTab === RightPanelTabs.RECORDINGS &&
        props.variant !== PLAYGROUND_FEATURES.VOICE_CLONING.name
    "
    :class="[
      'tab',
      {
        'tab--no-chips': !data.searchBar,
        'tab--mobile': mobile,
        'tab--no-chips--mobile': !data.searchBar && mobile,
        'drawer--ios-landscape':
          isIOS() && mobileData.orientation.type?.includes('landscape'),
      },
    ]"
  >
    <limits-reached
      v-if="
        isAudioRecordingsLimitReached &&
          userStore.allLimitsDataLoaded &&
          props.variant !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name
      "
      :limit-type="RightPanelLimitTypes.AUDIO_RECORDINGS"
    />
    <div
      v-if="data.loading || drawerStore.loading"
      :class="[
        'align-center d-flex flex-column justify-center tab',
        { 'tab--mobile': mobile },
      ]"
    >
      <v-progress-circular
        class="mt-4"
        color="rgb(var(--v-theme-highlight-color))"
        indeterminate
      />
      <c-typography
        class="align-center d-flex mt-2"
        color-class="copy-secondary"
        variant="body-2-400"
      >Loading...</c-typography
      >
    </div>
    <right-panel-info
      v-if="showNoResultsInfo"
      :text="
        data.search
          ? `No results for ${data.search}`
          : 'Your recordings will appear here'
      "
    />
    <div
      v-else-if="
        props.variant === PLAYGROUND_FEATURES.VTV.name && !drawerStore.loading
      "
    >
      <vtv-player
        v-for="(video, idx) in filteredVideos"
        :key="video.id"
        :bottom-border="idx === filteredVideos.length - 1"
        :idx="idx"
        :mode="video.mode"
        :top-border="idx > 0"
        :video="video"
      />
    </div>
    <div
      v-else-if="
        props.variant === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name &&
          !drawerStore.loading
      "
    >
      <vtv-player
        v-for="(video, idx) in filteredVideos"
        :key="video.id"
        :bottom-border="idx === filteredVideos.length - 1"
        :idx="idx"
        :mode="PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name"
        :top-border="idx > 0"
        :video="video"
      />
    </div>
    <div
      v-else-if="
        props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR.name &&
          !drawerStore.loading
      "
    >
      <limits-reached
        v-if="isVideoRecordingsLimitReached && userStore.allLimitsDataLoaded"
        :limit-type="RightPanelLimitTypes.VIDEO_RECORDINGS"
      />
      <latest-video-right-drawer-player
        v-if="drawerStore.newVideoFromVideoCreator"
        :mode="PLAYGROUND_FEATURES.VIDEO_CREATOR.name"
        :video="filteredVideos[0]"
      />

      <v-virtual-scroll
        :height="getVideoScrollHeight"
        :item-height="listItemVideoHeightPx"
        :items="
          drawerStore.newVideoFromVideoCreator
            ? filteredVideos.slice(1)
            : filteredVideos
        "
      >
        <template #default="{ item: video, index: idx }">
          <vtv-player
            :key="video.id"
            :bottom-border="idx === filteredVideos.length - 1"
            :chip="''"
            :idx="idx"
            :mode="PLAYGROUND_FEATURES.VIDEO_CREATOR.name"
            :top-border="idx > 0"
            :video="video"
          />
        </template>
      </v-virtual-scroll>
    </div>

    <div>
      <audio-recording-player
        v-for="(recording, idx) in filteredRecordings"
        :key="recording.id"
        :bottom-border="idx === filteredRecordings.length - 1"
        :recording="recording"
        :recording-mode="props.variant"
        :top-border="idx > 0"
      />
    </div>
  </div>
  <div
    v-if="props.variant === PLAYGROUND_FEATURES.VOICE_CLONING.name"
    :class="[
      'tab',
      {
        'tab--no-chips': !data.searchBar,
        'drawer--ios-landscape':
          isIOS() && mobileData.orientation.type?.includes('landscape'),
      },
    ]"
  >
    <limits-reached
      v-if="isVoiceCloningLimitReached && userStore.allLimitsDataLoaded"
      :limit-type="RightPanelLimitTypes.CLONED_VOICES"
    />

    <template v-if="!data.loading">
      <right-panel-info
        v-if="!clonedVoices.length"
        text="Your cloned voice will appear here"
      />
      <voice-details
        v-for="voice in voicesFilteredCloned"
        v-else
        :key="voice.id"
        border-bottom
        cloned
        mode="panel-list"
        :voice="voice"
        :wrapper-class="wrapperClass"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { isIOS } from "@/core/utils/capacitor";
  import { isMobile } from "@/core/utils/mobile";
  import {
    ScreenOrientation,
    type ScreenOrientationResult,
  } from "@capacitor/screen-orientation";

  import AudioRecordingPlayer from "./components/Players/AudioRecordingPlayer.vue";
  import LatestVideoRightDrawerPlayer from "./components/Players/LatestVideoRightDrawerPlayer.vue";
  import LimitsReached from "./components/LimitsReached.vue";
  import RightPanelInfo from "./components/RightPanelInfo.vue";
  import VoiceDetails from "@/core/components/Voice/VoiceDetails.vue";
  import VtvPlayer from "./components/Players/VtvPlayer.vue";

  import { RECORDING_TABS } from "@/core/types/recording.types";
  import type { Voice } from "@/core/types/voice.types";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";
  import { RightPanelLimitTypes, RightPanelTabs } from "@/core/types/other.types";

  import { useDrawerStore } from "./store";
  import { useStore } from "@/core/store";
  import { useUserStore } from "@/core/store/userStore";

  import router from "@/router";
  import { Routes } from "@/core/routes/core.guard";
  import { useWindowSize } from "@vueuse/core";

  import {
    computed,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    type PropType,
    reactive,
    toRefs,
  } from "vue";

  // @ts-ignore
  import { CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";

  const props = defineProps({
    variant: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  });

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { showRightDrawer, rightDrawerOpenedPanel, isRightPanelOpen } = toRefs(appDrawersState);
  const store = useStore();
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();
  const voicesListStore = useVoicesListStore();
  const { fetchClonedVoices } = voicesListStore;
  const { clonedVoices } = toRefs(voicesListStore);

  const data = reactive({
    searchBar: false,
    search: "",
    loading: false,
  });

  const mobileData = reactive({
    orientation: "landscape-primary" as unknown as ScreenOrientationResult,
  });

  ScreenOrientation.addListener(
    "screenOrientationChange",
    (orientation: ScreenOrientationResult) => {
      mobileData.orientation = orientation;
    }
  );

  const isVideoRecordingsLimitReached = computed(() => {
    if (userStore.isUnlimitedVideoAccess) return false;

    return (
      drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit
    );
  });

  const { height: windowHeight } = useWindowSize();

  const isAudioRecordingsLimitReached = computed(() => {
    if (userStore.isUnlimitedAudioAccess) return false;

    const totalRecordings =
      drawerStore.ttsRecordings.length + drawerStore.vcRecordings.length;

    return totalRecordings >= userStore.audioRecordingsLimit;
  });

  const goToRecordingsList = () => {
    const isVoiceCloning =
      props.variant === PLAYGROUND_FEATURES.VOICE_CLONING.name;

    const isAudioRecordingsList =
      props.variant === PLAYGROUND_FEATURES.TTS.name ||
      props.variant === PLAYGROUND_FEATURES.STS.name;

    const isVideoRecordingsList =
      props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR.name;

    const routeName = isVoiceCloning
      ? Routes.VOICES.children.CLONED.name
      : isAudioRecordingsList
        ? Routes.RECORDINGS_HISTORY.children.AUDIO.name
        : Routes.RECORDINGS_HISTORY.children.VIDEO.name;

    if (isAudioRecordingsList) {
      userStore.ui_local_data.galleryRecentMainTab =
        RECORDING_TABS.AUDIO;
    } else if (isVideoRecordingsList) {
      userStore.ui_local_data.galleryRecentMainTab =
        RECORDING_TABS.VIDEO;
    }

    router.push({ name: routeName });
  };

  const loadData = () => {
    if (
      isVtvRoute.value ||
      isVideoSynthesisRoute.value ||
      isVideoCreatorRoute.value
    ) {
      if (userStore.ui_local_data.portraitStudioAccess) {
        drawerStore.loadVideos(0);
      }
    } else if (props.variant === PLAYGROUND_FEATURES.VOICE_CLONING.name) {
      getClonedVoices();
    } else if (
      props.variant === PLAYGROUND_FEATURES.STS.name ||
      props.variant === PLAYGROUND_FEATURES.TTS.name
    ) {
      if (!recordings.value.length) {
        drawerStore.loadRecordings(props.variant);
      }
    }
  };

  onBeforeMount(async () => {
    loadData();
  });

  onBeforeUnmount(() => {
    if (
      props.variant === PLAYGROUND_FEATURES.VTV.name ||
      props.variant === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name
    ) {
      drawerStore.cancelCheckingVideo();
    }
  });

  onMounted(async () => {
    if (isIOS()) {
      mobileData.orientation = await ScreenOrientation.orientation();
    }
  });

  const listItemVideoHeightPx = 77;

  const getVideoScrollHeight = computed(() => {
    const videos = drawerStore.newVideoFromVideoCreator
      ? filteredVideos.value.slice(1)
      : filteredVideos.value;

    const recordingsTotalHeight = videos.length * listItemVideoHeightPx;

    if (recordingsTotalHeight >= windowHeight.value) {
      return windowHeight.value;
    }
    return recordingsTotalHeight;
  });

  const isVideoList = computed(
    () => props.variant === PLAYGROUND_FEATURES.VTV.name ||
      props.variant === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name ||
      props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR.name
  );

  const showNoResultsInfo = computed(() => {
    if (!drawerStore.loading) {
      if (isVideoList.value) return !filteredVideos.value.length;
      else if (!filteredRecordings.value.length)
        return !filteredRecordings.value.length;
      else return false;
    } else return false;
  });

  const selectedDrawerTab = computed(() => rightDrawerOpenedPanel.value);

  const route = computed(() => {
    return router.currentRoute.value;
  });

  const voicesFilteredCloned = computed(() => {
    return clonedFiltered(clonedVoices.value);
  });

  const filterResults = computed(() => {
    return voicesFilteredCloned.value.length;
  });

  const filterRecordings = (list: Array<any>, prop = "name") => {
    if (data.search) {
      const searchQuery = data.search.toLowerCase();

      return list.filter((recording) => {
        if (recording[prop]) {
          return recording[prop].toLowerCase().includes(searchQuery);
        }
        return false;
      });
    }
    return list;
  };

  const recordings = computed(() => {
    if (props.variant === PLAYGROUND_FEATURES.TTS.name) {
      return drawerStore.ttsRecordings;
    } else if (props.variant == PLAYGROUND_FEATURES.STS.name) {
      return drawerStore.vcRecordings;
    } else if (props.variant === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name) {
      return drawerStore.videoSyntheticsRecordings;
    } else if (props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR.name) {
      return drawerStore.videoCreatorRecordings;
    }
    return [];
  });

  const filteredRecordings = computed(() => {
    return filterRecordings(recordings.value, "recordingName");
  });

  const filteredVideos = computed(() => {
    if (props.variant === PLAYGROUND_FEATURES.VTV.name)
      return filterRecordings(drawerStore.vtvRecordings);
    else if (props.variant === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name)
      return filterRecordings(drawerStore.videoSyntheticsRecordings);
    else if (props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR.name)
      return filterRecordings(drawerStore.videoCreatorRecordings);
    else return filterRecordings(drawerStore.videoSyntheticsRecordings);
  });

  const isVtvRoute = computed(() => {
    const currentRoute = router.currentRoute.value.name;

    return (
      currentRoute === Routes.VIDEO_TO_VIDEO.children.CREATE.name ||
      currentRoute === Routes.VIDEO_TO_VIDEO.children.PREVIEW.name
    );
  });

  const isVoiceCloningLimitReached = computed(() => {
    if (userStore.isUnlimitedVoiceCloningAccess) return false;

    return clonedVoices.value.length >= userStore.clonedVoicesLimit;
  });

  const isVideoCreatorRoute = computed(() => route.value.path.includes(Routes.VIDEO_CREATOR.path)
  );

  const isVideoSynthesisRoute = computed(() => {
    const currentRoute = router.currentRoute.value.name;

    return (
      currentRoute === Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name ||
      currentRoute === Routes.VIDEO_SYNTHESIS.children.CREATE_AUDIO.name ||
      currentRoute === Routes.VIDEO_SYNTHESIS.children.PREVIEW.name
    );
  });

  const getClonedVoices = async () => {
    if (!clonedVoices.value.length) {
      data.loading = true;
      await fetchClonedVoices();
      data.loading = false;
    }
  };

  const clonedFiltered = (voiceList: Voice[]) => {
    let filteredVoices = voiceList;

    if (data.search) {
      const searchTerms = data.search.toLowerCase().split(",");

      for (const index in searchTerms) {
        const searchTerm = searchTerms[index].trim();

        filteredVoices = filteredVoices.filter(({ name, labels = [] }) => {
          const tags = labels.map((el) => el.label);

          return [name, tags.join(", "), ...tags].some((el) => el.trim().toLowerCase().includes(searchTerm)
          );
        });
      }
    }

    return filteredVoices;
  };

  const openSearchBar = () => {
    data.searchBar = !data.searchBar;
  };

  const closeSearchBar = () => {
    data.searchBar = false;
    data.search = "";
  };

  const wrapperClass = computed(
    () => `${isMobile.value ? "pl-2 pr-3 py-2" : "py-3 pl-2 pr-3"}`
  );
</script>

<style lang="scss" scoped>
.results {
  border-top: 1px solid rgb(var(--v-theme-dark-frame)) !important;
  min-height: 42px;

  &--border-bottom {
    border-bottom: 1px solid rgb(var(--v-theme-dark-frame)) !important;
  }
}

.nav {
  background: rgba(var(--v-theme-player-bg)) !important;
  position: fixed;
  width: 100%;
  z-index: 1;

  &-tabs {
    &--mobile {
      justify-content: space-between;
    }
  }

  &--no-chips {
    border-bottom: 1px solid rgb(var(--v-theme-dark-frame));
  }

  svg {
    color: rgb(var(--v-theme-copy-secondary)) !important;
  }

  &-filter {
    cursor: pointer;
    justify-content: center;
    width: 156px;
  }

  &-filters {
    @media (max-width: 999px) {
      flex-direction: column;

      .nav-filter {
        margin-inline: 0 !important;
        width: 100%;
      }

      .first {
        margin-bottom: 8px;
      }
    }
  }
}

.x-icon {
  cursor: pointer;
}

.tab {
  margin-top: 2px;
  padding-top: 97px;
  transition: all 0.5s ease;

  &--mobile {
    padding-top: 93px;
  }

  &--no-chips {
    padding-top: 54px;

    &--mobile {
      padding-top: 52px;
    }
  }
}

.drawer--ios-landscape {
  padding-left: 44px !important;
}

.search {
  height: 24px;

  &--mobile {
    :deep(.v-field__input) {
      padding-top: 0;
    }

    :deep(.v-field__clearable) {
      height: 32px !important;
    }

    :deep(.v-field) {
      height: 32px !important;
    }

    :deep(.v-field__prepend-inner) {
      height: 32px !important;
    }
  }
}
</style>

<template>
  <div class="d-flex flex-column limits px-4 py-3">
    <c-typography color-class="copy-primary" variant="body-2-600"
    >Limit Reached</c-typography
    >
    <div class="pt-3">
      <c-typography color-class="copy-secondary" variant="body-3-400">{{
        limitDescription
      }}</c-typography>
    </div>

    <div class="pt-4">
      <c-button height="40" mode="special" :to="{name: Routes.SHOP.name}" width="125">Upgrade</c-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
//@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";
  import { computed, type PropType } from "vue";

  import { Routes } from "@/core/routes/core.guard";
  import { useUserStore } from "@/core/store/userStore";

  import {
    RightPanelLimits,
    RightPanelLimitTypes,
  } from "@/core/types/other.types";

  const props = defineProps({
    limitType: {
      type: Object as PropType<RightPanelLimitTypes>,
      default: RightPanelLimitTypes.AUDIO_RECORDINGS,
    },
  });

  const userStore = useUserStore();

  const getLimit = (limit: RightPanelLimitTypes) => {
    switch (limit) {
    case RightPanelLimitTypes.AUDIO_RECORDINGS:
      return userStore.audioRecordingsLimitNumber;
    case RightPanelLimitTypes.VIDEO_RECORDINGS:
      return userStore.videoRecordingsLimitNumber;
    case RightPanelLimitTypes.CLONED_VOICES:
      return userStore.clonedVoicesLimitNumber;
    default:
      return 0;
    }
  };

  const limitDescription = computed(() => {
    return (RightPanelLimits[props.limitType]|| "").replace(
      "#limit#",
      String(getLimit(props.limitType))
    );
  });
</script>

<style scoped lang="scss">
.limits {
  background-color: rgb(var(--v-theme-highlight-aphla));
}
</style>


import { apiUrl, apiV2Url } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";
import type { LoginResponse } from "../types/login.types";

export async function claimEmail(userEmail: string): Promise<void> {
  const url = apiUrl("auth/claim");

  await coreClient.post(url, {
    email: userEmail,
  });
}

export async function loginUser(
  queryUrl: string
): Promise<any> {
  const url = apiV2Url(`auth/login?redirect=${queryUrl ? queryUrl : import.meta.env.VITE_REDIRECT_LOGIN}`);

  try {
    const response = await coreClient.post<any>(url);

    return response.data;

  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function handleUserRedirect(query: string): Promise<LoginResponse> {
  const url = apiV2Url(`auth/authkit/code?${query}`);

  try {
    const response = await coreClient.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw "Something went wrong!";
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteUserAccount(confirmData: {
  password: string;
}): Promise<void> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/delete`);

  const response = await coreClient.post(url, confirmData);

  return response.data;
}

export async function acceptNewTerms(): Promise<void> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/consent`);

  const response = await coreClient.post(url, { consent: true });

  return response.data;
}

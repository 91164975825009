<template>
  <error-dialog
    v-model="data.loadingError"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />
  <not-enough-ballance-dialog v-model="data.noMinutesDialog" />
  <audio-limit-dialog v-model="data.limitDialog" audio />
  <wrapper
    :is-recording="recorder.isRecording.value"
    :mobile="isMobile"
    :variant="PLAYGROUND_FEATURES.STS"
  >
    <template #playground>
      <input
        id="target_file"
        ref="fileSelector"
        :accept="isMobile ? '*' : 'audio/*'"
        class="hidden"
        type="file"
        @change="loadUserAudioFile"
      />
      <div
        v-if="!isRecordingPanelVisible && !data.fileRecording"
        :class="[
          'align-center d-flex flex-wrap justify-center',
          { 'h-100 pa-4 pb-0': isMobile, 'mt-4': !isMobile },
        ]"
      >
        <drag-and-drop
          :class="[{ 'h-100': isMobile }]"
          :disabled="isRecordingsLimitReached"
          :height="240"
          :supported-formats="AUDIO_FORMATS"
          test-id="dnd-speech-to-speech"
          @choose-file="chooseFile"
          @drop-file="loadUserAudioFile($event, true)"
        />
      </div>
      <template v-else>
        <template
          v-if="
            !isCheckingMicrophoneAccess &&
              !noAccess &&
              data.inputMode === VoiceInputSource.MIC
          "
        >
          <div :class="{ 'pa-4': isMobile }">
            <div
              ref="element"
              :class="['recording', { 'recording--mobile': isMobile }]"
            >
              <c-button
                :disabled="isConverting"
                icon="ph:trash-simple"
                mode="outline"
                size="small"
                @click="cancelRecording"
              />

              <div class="recording__canvas">
                <AVMedia
                  v-if="waveComponent"
                  :bar-width="2"
                  :canv-width="waveWidth"
                  frequ-direction="mo"
                  :frequ-lnum="waveWidth / 6"
                  height="70"
                  line-color="#A8A8AA"
                  :media="stream"
                  type="frequ"
                />
              </div>

              <div class="recording__remaining_time">
                <c-typography color-class="copy-primary" variant="body-3-400">
                  {{ data.timer ? unref(data.timer.remainingTime) : "" }}
                </c-typography>
              </div>
            </div>

            <c-button
              block
              class="mt-4"
              :loading="isConverting"
              size="large"
              warning
              @click="toggleRecording"
            >Stop Recording
            </c-button>
          </div>
        </template>

        <template
          v-if="data.inputMode === VoiceInputSource.FILE && data.fileRecording"
        >
          <local-audio-player
            :bottom-border="false"
            :class="[isMobile ? 'ma-4' : 'my-4']"
            hide-details
            :recording="data.fileRecording"
            wrapped
            @delete="clearFileSelection"
          />
        </template>

        <div
          v-if="!isCheckingMicrophoneAccess && noAccess"
          class="align-center d-flex justify-center"
          style="height: 32px; margin-top: 28px"
        >
          <c-icon class="error mr-3" icon="mdi:alert-outline" width="21px" />
          <c-typography class="align-center d-flex" error variant="body-2-500">
            Microphone not authorized. Please check your media permissions
            settings.</c-typography
          >
        </div>
        <div
          v-if="isCheckingMicrophoneAccess"
          class="d-flex justify-center mt-7"
        >
          <c-progress-circular class="loader mr-2" indeterminate />
          <c-typography class="align-center d-flex" variant="body-2-500"
          >Loading...</c-typography
          >
        </div>
      </template>
    </template>
    <template #action>
      <div v-if="!isRecordingPanelVisible && !data.fileRecording">
        <c-typography class="d-flex my-4 separator w-100" variant="body-3-400"
        >or</c-typography
        >
        <playground-consent-checkbox
          v-model="playgroundStore.consent[PLAYGROUND_FEATURES.STS.name]"
          class="mb-2"
        />
        <c-tooltip
          action="Upgrade"
          class="w-100"
          content="Your current free account limit for audio voice conversions has been reached. Unlock the ability to transform more audio and access unlimited conversions by upgrading to a premium account today."
          manual
          placement="top"
          :show="userStore.allLimitsDataLoaded && isRecordingsLimitReached"
          title="Audio Voice Conversion Limit Reached"
          width="300"
          @handle-action-click="handleActionClick"
        >
          <c-button
            block
            description="Record"
            :disabled="!userSelectedVoice[PLAYGROUND_FEATURES.STS.name] || !playgroundStore.consent[PLAYGROUND_FEATURES.STS.name] || isRecordingsLimitReached"
            size="large"
            @click="record"
          >
            Record Now
          </c-button>
        </c-tooltip>
      </div>
      <div
        v-if="data.inputMode === VoiceInputSource.FILE && data.fileRecording"
        class="mt-4"
      >
        <playground-consent-checkbox
          v-model="playgroundStore.consent[PLAYGROUND_FEATURES.STS.name]"
          class="mb-2 mt-4"
        />
        <c-tooltip
          action="Upgrade"
          class="w-100"
          content="Your current free account limit for audio voice conversions has been reached. Unlock the ability to transform more audio and access unlimited conversions by upgrading to a premium account today."
          manual
          placement="top"
          :show="userStore.allLimitsDataLoaded && isRecordingsLimitReached"
          title="Limit reached"
          width="300"
          @handle-action-click="handleActionClick"
        >
          <c-button
            block
            description="Record"
            :disabled="!userSelectedVoice[PLAYGROUND_FEATURES.STS.name] || !playgroundStore.consent[PLAYGROUND_FEATURES.STS.name] || isRecordingsLimitReached"
            :loading="isConverting"
            :price="(!userSelectedVoice[PLAYGROUND_FEATURES.STS.name] || !playgroundStore.consent[PLAYGROUND_FEATURES.STS.name] || isRecordingsLimitReached) ? '' : price"
            size="large"
            @click="generateRecordingFromFile"
          >Convert Now
          </c-button>
        </c-tooltip>
      </div>
    </template>
  </wrapper>
</template>

<script setup lang="ts">
  import AudioLimitDialog from "../components/dialogs/AudioLimitDialog.vue";
  import { AVMedia } from "vue-audio-visual";
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import LocalAudioPlayer from "@/core/components/LocalAudioPlayer.vue";
  import NotEnoughBallanceDialog from "../components/dialogs/NotEnoughBallanceDialog.vue";
  import PlaygroundConsentCheckbox from "@/modules/playground/components/PlaygroundConsentCheckbox.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useElementSize } from "@vueuse/core";
  import useLibraryCanvas from "@/core/composables/useLibraryCanvas";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import useRecorder from "@/core/composables/useRecorder";
  import useTimer from "@/modules/voicesPlayground/composables/useTimer";
  import { VoiceInputSource } from "@/core/types/playground.types";
  import Wrapper from "@/core/components/Wrapper/Wrapper.vue";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useMobileRecentRecordingStore } from "@/core/store/useMobileRecentRecordingStore";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useRouter } from "vue-router";
  import { useStore } from "@/core/store";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  import { useUserStore } from "@/core/store/userStore";
  import { AlertModes, RightPanelTabs } from "@/core/types/other.types";
  import { analytics, VC } from "@/core/utils/analytics";
  import type { ApiRecording, LocalRecording } from "@/core/types/recording.types";

  import { AUDIO_FORMATS } from "@/core/types/file.types";

  import {
    computed,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    reactive,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";
  import { fetchVC, getRecordings } from "@/core/services/playground.service";

  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";

  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";

  // @ts-ignore
  import { CButton, CIcon, CProgressCircular, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";
  import { storeToRefs } from "pinia";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab, closeRightDrawerTab } = appDrawersState;
  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);
  const drawerStore = useDrawerStore();
  const playgroundStore = usePlaygroundStore();
  const mobileRecentRecording = useMobileRecentRecordingStore();
  const { mobileRecentAudioRecording } = toRefs(mobileRecentRecording);
  const { $reset } = mobileRecentRecording;
  const progressStore = useProgressStore();
  const { isConverting, isRecording } = toRefs(progressStore);

  const userStore = useUserStore();
  const { audioCostPerUnit, billableUnitDurationS } = storeToRefs(userStore);

  //COMPOSABLES
  const { stream, startCanvas, stopCanvas } = useLibraryCanvas();
  const { loadAudioFile } = useMediaFileLoader();
  const recorder = useRecorder();

  const router = useRouter();

  const data = reactive({
    remainingTime: "",
    timer: null as any,
    isLoading: true,
    noMinutesDialog: false,
    limitDialog: false,
    inputMode: VoiceInputSource.MIC,
    vcRecordingId: 1,
    fileRecording: null as LocalRecording | null,
    recentRecording: undefined as ApiRecording | undefined,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
  });

  const element = ref(null);

  const { width } = useElementSize(element);

  const price = computed(() => {
    const basePrice = Number(audioCostPerUnit.value);
    const c = Number(billableUnitDurationS.value);

    return data.fileRecording ? Math.ceil(data.fileRecording.time / c) * basePrice : "";
  });

  const waveWidth = computed(() => {
    //minus width of delete button and remaing time width
    return width.value - 120;
  });

  const noAccess = ref(false);
  const isCheckingMicrophoneAccess = ref(true);
  const waveComponent = ref(true);

  //this is workaround to re-render canvas component of sound wave
  const forceRenderWaveComponent = async () => {
    waveComponent.value = false;
    await nextTick();
    waveComponent.value = true;
  };

  watch(
    () => recorder.accessDenied,
    (val) => {
      if (val.value) {
        noAccess.value = true;
      }
    },
    { deep: true }
  );

  watch(
    () => recorder.isCheckingAccess,
    (val) => {
      isCheckingMicrophoneAccess.value = val.value;
    },
    { deep: true }
  );

  const isRecordingPanelVisible = ref(false);

  const record = () => {
    data.timer = useTimer({ maxSecondsDuration: userStore.stsAudioSecondsMaxLength });

    data.inputMode = VoiceInputSource.MIC;
    setTimeout(() => {
      recorder.start();
      isRecording.value = true;
      analytics.sendEvent("vc", VC.actions.START_RECORDING);
      isRecordingPanelVisible.value = true;
    }, 200);

    recorder.listen("onStart", () => {
      startCanvas();
      data.timer.start();
    });

    recorder.listen("onStop", () => {
      data.timer.stop();
      stopCanvas();
      isRecording.value = false;
    });

    recorder.listen("onSend", function (blob: Blob): void {
      generateRecording(blob);
    });

    data.timer.listen("onTimeIsUp", () => {
      if (recorder.isRecording) {
        recorder.stop();
        stopCanvas();
        isRecording.value = false;
      }
    });
  };

  const fileSelector = ref();

  const chooseFile = () => {
    fileSelector.value.click();
  };

  const loadUserAudioFile = async (event: any, isDropped = false) => {
    const files = isDropped ? event.dataTransfer?.files : event.target.files;

    const audioFile = await loadAudioFile(files, {
      maxDuration: userStore.stsAudioSecondsMaxLength,
      supportedExtensions: AUDIO_FORMATS,
    }).catch((err) => {
      data.loadingErrorMessage = err;
      data.loadingError = true;
    });

    if (audioFile) {
      data.fileRecording = {
        ...audioFile,
        type: PLAYGROUND_FEATURES.STS.name,
        createdAt: new Date().toISOString(),
        voiceId: 0,
        voiceType: "other",
      };
      data.vcRecordingId++;
      data.inputMode = VoiceInputSource.FILE;
      isCheckingMicrophoneAccess.value = false;
    }
  };

  const clearFileSelection = () => {
    //reset selected FileList solution
    fileSelector.value.type = "text";
    fileSelector.value.type = "file";
    data.fileRecording = null;
  };

  onBeforeMount(async () => {
    $reset();
    if (!userStore.stsAudioSecondsMaxLength) {
      await userStore.getSubscriptionLimits();
    }

    if (!drawerStore.ttsRecordings.length) {
      await drawerStore.loadRecordings(PLAYGROUND_FEATURES.TTS.name);
    }

    data.timer = useTimer({ maxSecondsDuration: userStore.stsAudioSecondsMaxLength });

    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.RECORDINGS);
    }
  });

  onBeforeUnmount(() => {
    removeListeners();
  });

  watch(waveWidth, () => {
    forceRenderWaveComponent();
  });

  const toggleRecording = () => {
    recorder.toggle();
  };

  const generateRecordingFromFile = async () => {
    if (Number(userStore.accountBallance) < Number(data?.fileRecording?.time)) {
      data.noMinutesDialog = true;
      clearFileSelection();
      return;
    }

    if (
      Number(data?.fileRecording?.time) > userStore.stsAudioSecondsMaxLength &&
      !userStore.subscriptionOption
    ) {
      data.limitDialog = true;
      return;
    }

    if (data.fileRecording) {
      await generateRecording(data.fileRecording.file);
    }
  };

  const generateRecording = async (recording: Blob) => {
    try {
      const vcVoice = userSelectedVoice.value[PLAYGROUND_FEATURES.STS.name];

      if (vcVoice) {
        analytics.sendEvent("vc", VC.actions.START_CONVERSION);

        isConverting.value = true;

        await fetchVC(vcVoice.id, recording, Boolean(vcVoice.createdAt), true);

        drawerStore.prependAudio(PLAYGROUND_FEATURES.STS.name);

        analytics.sendEvent("vc", VC.actions.SUCCESS);

        if (isMobile.value) {
          const recentRecordings = await getRecordings(PLAYGROUND_FEATURES.STS.name, 1);
          if (recentRecordings.length > 0 && recentRecordings[0])

            mobileRecentAudioRecording.value = recentRecordings[0];
        } else {
          showSnackbar("File added to the Recordings", AlertModes.SUCCESS);
        }

        userStore.updateBallance();
      }
    } catch (e: any) {
      if (e.response?.status === 402) {
        showSnackbar(
          "Your account balance is too small to use voice-to-voice",
          AlertModes.ERROR
        );
        analytics.sendEvent("vc", VC.actions.NO_BALANCE_ERROR);
      } else {
        showSnackbar("Error during conversion", AlertModes.ERROR);
        analytics.sendEvent("vc", VC.actions.ERROR);
      }
    } finally {
      isConverting.value = false;
      removeListeners();
      isRecordingPanelVisible.value = false;
    }
  };

  const removeListeners = () => {
    if (data.timer) data.timer.unlistenAll();

    if (recorder) {
      recorder.stop();
      recorder.unlistenAll();
      stopCanvas();
    }
  };

  const cancelRecording = () => {
    removeListeners();
    isRecordingPanelVisible.value = false;
  };

  const isRecordingsLimitReached = computed(() => {

    if (userStore.isUnlimitedAudioAccess) return false;

    const totalRecordings = drawerStore.ttsRecordings.length + drawerStore.vcRecordings.length;

    return totalRecordings >= userStore.audioRecordingsLimit;
  });

  const handleActionClick = () => {
    router.push({ name: Routes.SHOP.name });
  };

</script>

<style scoped lang="scss">
.recording {
  align-items: center;
  display: flex;
  width: 100%;

  &--mobile {
    align-items: center;
    background: var(--Aphla--BG, #fff);
    border: 1px solid var(--Light-Frame, #e8ebf0);
    border-radius: 8px;
    color: black;
    display: flex;
    height: 116px;
    justify-content: center;
    padding: 8px;
  }

  &__canvas {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__panel {
    align-items: center;
    height: 70px;
    justify-content: space-between;
    width: inherit;
  }

  &__remaining_time {
    display: flex;
    justify-content: flex-end;
  }
}

.hidden {
  display: none;
}

.recorder {
  &-content {
    padding: 14px 0 11px;
  }
}

.error {
  color: var(--vt-c-red);
}

.separator {
  color: #a8a8aa !important;

  &::after,
  &:before {
    border-bottom: 1px solid;
    content: "";
    flex: 1 1;
    margin: auto;
  }

  &::after {
    margin-left: 8px;
  }

  &::before {
    margin-right: 8px;
  }
}
</style>

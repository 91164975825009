import type { AxiosResponseHeaders } from "axios";

export type FormStateInitial = {
  __tag: "initial";
};

export const FORM_INITIAL_STATE: FormStateInitial = {
  __tag: "initial",
};

export type FormStateLoading = {
  __tag: "loading";
};
export const FORM_LOADING_STATE: FormStateLoading = {
  __tag: "loading",
};

export type FormStateSuccess<T> = {
  __tag: "success";
  data: T;
};

export type FormStateError = {
  __tag: "error";
  message: string;
  errors: string[];
  headers?: AxiosResponseHeaders;
  code: number | string,
};

export type FormState<T> =
  | FormStateInitial
  | FormStateLoading
  | FormStateSuccess<T>
  | FormStateError;

function makeSuccess<T>(data: T): FormStateSuccess<T> {
  return {
    __tag: "success",
    data,
  };
}

function makeError(message: string, code: number | string, errors: string[] = []): FormStateError {
  return {
    __tag: "error",
    message,
    code,
    errors,
  };
}

function isInitial<T>(state: FormState<T>): boolean {
  return state.__tag === "initial";
}

function isLoading<T>(state: FormState<T>): boolean {
  return state.__tag === "loading";
}

function isSuccess<T>(state: FormState<T>): state is FormStateSuccess<T> {
  return state.__tag === "success";
}

function isErrored<T>(state: FormState<T>): state is FormStateError {
  return state.__tag === "error";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export function combineForms<T extends FormState<unknown>[]>(...forms: T) : any {
  return {
    isAnyLoading(): boolean {
      return forms.some(f => isLoading(f));
    },
    isAnyErrored(): boolean {
      return forms.some(f => isErrored(f));
    },
    isAnySucceeded(): boolean {
      return forms.some(f => isSuccess(f));
    },
    isEveryInitial(): boolean {
      return forms.every(f => isInitial(f));
    },
    isEverySucceeded(): boolean {
      return forms.every(f => isSuccess(f));
    },
    getFirstSuccessful() {
      return forms.find(f => isSuccess(f));
    },
    getFirstErrored() {
      return forms.find(f => isErrored(f));
    },
    getFirstErroredMessage(): string | undefined {
      const errored = forms.find(f => isErrored(f));

      if (errored) {
        return (errored as FormStateError).message;
      }
    },
  };
}

export const form = {
  isInitial,
  isLoading,
  makeSuccess,
  isSuccess,
  isErrored,
  makeError,
};

import { useProgressStore } from "../store/useProgressStore";
import { useStore } from "../store";
import type {
  LoadedAudioFile,
  LoadedVideoFile,
} from "../types/recording.types";
import { ref, toRefs } from "vue";

type fileLoaderOptions = {
  maxDuration: number;
  minDuration?: number;
  maxMegabytes?: number;
  supportedExtensions?: Array<string>;
};
type imageLoaderOptions = {
  supportedTypes: Array<string>;
  maxMegabytes?: number;
  minPixelsSize?: number;
  maxPixelsSize?: number;
};

type fileLoaderType = {
  loadAudioFile(
    files: FileList,
    options: fileLoaderOptions
  ): Promise<LoadedAudioFile>;
  loadVideoFile(
    files: FileList,
    options: fileLoaderOptions
  ): Promise<LoadedVideoFile>;
  loadImage(files: FileList | File[], options: imageLoaderOptions): Promise<string>;
};

export default function useFileLoader(): fileLoaderType {
  const loadAudioFile = (files: FileList, options: fileLoaderOptions) => {
    const audio = document.createElement("audio");

    const upperCasedExtensions = (options.supportedExtensions || []).map((e) => e.toUpperCase()
    );

    return new Promise<LoadedAudioFile>((resolve, reject) => {
      try {
        if (files.length) {
          const audioFile = files[0];

          if (audioFile.type.match("audio.*")) {
            if (options.maxMegabytes) {
              const filesizeMbytes = audioFile.size / 1000000;

              if (filesizeMbytes > options.maxMegabytes) {
                reject({
                  title: "The file size exceeds limits",
                  description: `The maximum file size limit is ${options.maxMegabytes}MB`,
                });
              }
            }

            const reader = new FileReader();

            reader.onload = function (e: ProgressEvent<FileReader>) {
              const preview = e.target?.result as ArrayBuffer;

              const fileData = new Blob([new Uint8Array(preview)], {
                type: audioFile.type,
              });

              const audioBlob = {
                blob: fileData,
                url: URL.createObjectURL(fileData),
                type: audioFile.type,
              };

              audio.src = audioBlob.url;
              audio.addEventListener(
                "loadedmetadata",
                () => {
                  const duration = audio.duration;

                  if (options.minDuration && duration < options.minDuration) {
                    reject({
                      title: "Audio too short",
                      description: `The minimum duration for audio files is ${secondsToTime(
                        options.minDuration
                      )}.`,
                    });
                  } else if (duration > options.maxDuration) {
                    reject({
                      title: "Audio too long",
                      description: `Please check the duration – we support audios up to ${secondsToTime(
                        options.maxDuration
                      )} in length`,
                    });
                  } else {
                    resolve({
                      id: audioBlob.url.split("/").pop() || "",
                      file: audioBlob.blob,
                      time: audio.duration,
                      url: audioBlob.url,
                      name: `${audioFile.name}`,
                    });
                  }
                },
                false
              );
            };
            reader.readAsArrayBuffer(audioFile);
          } else {
            reject({
              title: "Unsupported file format",
              description: `Please try to use one of these formats: ${upperCasedExtensions}`,
            });
          }
        } else {
          reject({
            title: "Oops, something went wrong!",
            description: "No file selected",
          });
        }
      } catch (e) {
        reject({
          title: "Oops, something went wrong!",
          description: String(e),
        });
      }
    });
  };

  const secondsToTime = (seconds: number) => {
    if (seconds > 60) {
      const minutes = seconds / 60;
      const remainingSeconds = seconds - minutes * 60;

      return seconds % 60 === 0
        ? `${minutes} minutes`
        : `${minutes} minutes ${remainingSeconds} seconds`;
    } else {
      return `${seconds} seconds`;
    }
  };

  const loadVideoFile = (files: FileList, options: fileLoaderOptions) => {
    const video = document.createElement("video");

    const upperCasedExtensions = (options.supportedExtensions || []).map((e) => e.toUpperCase()
    );

    const reader = new FileReader();
    const totalSize = ref(0);
    const store = useStore();

    //STORE
    const progressStore = useProgressStore();

    const {
      startLoadingFileProgress,
      successLoadingFileProgress,
      errorLoadingFileProgress,
    } = progressStore;
    const { clientUploadFileProgress } = toRefs(progressStore);

    startLoadingFileProgress();

    return new Promise<LoadedVideoFile>((resolve, reject) => {
      try {
        if (files.length) {
          const videoFile = files[0];

          const filesizeMbytes = videoFile.size / 1000000;

          if (options.maxMegabytes && filesizeMbytes > options.maxMegabytes) {
            reject({
              title: "The file size exceeds limits",
              description: `The maximum file size limit is ${options.maxMegabytes}MB`,
            });
          }

          const fileExtension =
            videoFile.name.split(".").pop()?.toLowerCase() || "";

          if (upperCasedExtensions.includes(fileExtension.toUpperCase())) {
            reader.onloadend = function () {
              successLoadingFileProgress();
            };
            reader.onprogress = function (e: ProgressEvent<FileReader>) {
              clientUploadFileProgress.value =
                (Number(e.loaded) / Number(totalSize.value)) * 100;
            };

            reader.onloadstart = function (e: ProgressEvent<FileReader>) {
              totalSize.value = e.total;
            };

            reader.onerror = function () {
              errorLoadingFileProgress();
            };

            reader.onload = function (e: ProgressEvent<FileReader>) {
              try {
                const preview = e.target?.result as ArrayBuffer;

                const fileData = new Blob([new Uint8Array(preview)], {
                  type: videoFile.type,
                });

                const videoUrl = URL.createObjectURL(fileData);

                video.src = videoUrl;

                video.addEventListener("loadedmetadata", () => {
                  const duration = video.duration;

                  if (options.minDuration && duration < options.minDuration) {
                    reject({
                      title: "Video too short",
                      description: `The minimum duration for video files is ${secondsToTime(
                        options.minDuration
                      )}.`,
                    });
                  } else if (duration > options.maxDuration) {
                    reject({
                      title: "Video too long",
                      description: `Please check the duration – we support videos up to ${secondsToTime(
                        options.maxDuration
                      )} in length.`,
                    });
                  } else {
                    resolve({
                      duration,
                      file: fileData,
                      url: videoUrl,
                      type: videoFile.type,
                      name: videoFile.name.split(".").slice(0, -1).join("."),
                      id: videoUrl.split("/").pop() || "",
                      extension: fileExtension,
                    });
                  }
                });
              } catch (e) {
                reject({
                  title: "Oops, something went wrong!",
                  description: String(e),
                });
              }
            };
            reader.readAsArrayBuffer(videoFile);
          } else {
            reject({
              title: "Unsupported file format",
              description: `Please try to use one of these formats: ${upperCasedExtensions}`,
            });
          }
        } else {
          reject({
            title: "Oops, something went wrong!",
            description: "No file selected",
          });
        }
      } catch (e) {
        reject({
          title: "Oops, something went wrong!",
          description: String(e),
        });
      }
    });
  };

  const loadImgForSize = async (
    pictureFile: File,
    isAllowed: boolean
  ): Promise<{ imgWidth: number; imgHeight: number }> => {
    let imgWidth = 0;
    let imgHeight = 0;

    return new Promise((resolve) => {
      if (isAllowed) {
        const img = new Image();

        img.src = URL.createObjectURL(pictureFile);
        img.onload = (e: any) => {
          if (e.target) {
            imgWidth = e.target.width;
            imgHeight = e.target.height;
            img.remove();
            resolve({ imgWidth, imgHeight });
          } else {
            resolve({ imgWidth: 0, imgHeight: 0 });
          }
        };
        img.onerror = () => {
          resolve({ imgWidth: 0, imgHeight: 0 });
        };
      } else {
        resolve({ imgWidth: 0, imgHeight: 0 });
      }
    });
  };

  const loadImage = async (files: FileList | File[], options: imageLoaderOptions) => {
    const pictureFile = files[0];
    const fileExtension = pictureFile.name.split(".").pop() || "";

    const isAllowedExtension = options.supportedTypes.includes(
      fileExtension.toLowerCase()
    );

    const { imgWidth, imgHeight } = await loadImgForSize(
      pictureFile,
      isAllowedExtension
    );

    return new Promise<string>((resolve, reject) => {
      try {
        if (files.length) {
          if (!isAllowedExtension) {
            reject({
              title: "Unsupported file format",
              description: `Please try to use one of these formats: ${options.supportedTypes.map(
                (e) => e.toUpperCase()
              )}`,
            });
          }
          if (options.maxMegabytes) {
            const filesizeMbytes = pictureFile.size / 1000000;

            if (filesizeMbytes > options.maxMegabytes) {
              reject({
                title: "The file size exceeds limits",
                description: `The maximum file size limit is ${options.maxMegabytes}MB`,
              });

            }
          }

          if (options.minPixelsSize) {
            if (
              imgWidth < options.minPixelsSize ||
              imgHeight < options.minPixelsSize
            ) {
              reject({
                title: "Incorrect image size",
                description: `The minimum acceptable image width and height is ${options.minPixelsSize} pixels.`,
              });
            }
          }

          if (options.maxPixelsSize) {
            if (
              imgWidth > options.maxPixelsSize ||
              imgHeight > options.maxPixelsSize
            ) {
              reject({
                title: "Incorrect image size",
                description: `The maximum allowed image width and height is ${options.maxPixelsSize} pixels.`,
              });
            }
          }

          if (FileReader) {
            const fr = new FileReader();

            fr.onload = function () {
              resolve(String(fr.result));
            };
            fr.readAsDataURL(pictureFile);
          }
        } else {
          reject("No file selected");
        }
      } catch (e) {
        reject(String(e));
      }
    });
  };

  return { loadAudioFile, loadVideoFile, loadImage };
}

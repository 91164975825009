<template>
  <c-tabs
    ref="tabsEl"
    class="mb-4 w-100"
    :mobile="isMobile"
    :tabs="videoTabs"
    variant="secondary"
    @update-route="changeTab"
  />
  <c-custom-card
    :class="[[isMobile ? 'voices-card--mobile' : 'voices-card--desktop w-100']]"
    color="player-bg"
    draggable
    type="primary"
  >
    <template #content>
      <div class="w-100">
        <div
          :class="[
            {
              'recordings-wrapper': data.activeTab === 'list',
            },
          ]"
        >
          <v-virtual-scroll
            ref="videoScroll"
            :item-height="200"
            :items="packedRecordings"
          >
            <template #default="{ item, index }">
              <div
                :class="[
                  {
                    'gallery pb-2': data.activeTab !== 'list',
                  },
                  {
                    'gallery--mobile': isMobile,
                  },
                ]"
              >
                <vtv-player
                  v-for="(recording, idx) in item"
                  :key="recording.id"
                  :bottom-border="
                    data.activeTab === 'list'
                      ? index !== props.recordings.length - 1
                      : false
                  "
                  :chip="''"
                  :cut-chip="isMobile"
                  :idx="idx + index * numberOfGalleryVideosInRow"
                  :length="props.recordings.length"
                  :mode="PLAYGROUND_FEATURES.VIDEO_CREATOR.name"
                  open-preview-on-click
                  :playing-video-id="data.playingVideoId"
                  :show-chip="false"
                  :simple="false"
                  :top-border="false"
                  twin
                  :variant="data.activeTab"
                  :video="recording"
                  @set-playing-id="setPlayingId"
                />
              </div>
            </template>
          </v-virtual-scroll>
        </div>
      </div>
    </template>
  </c-custom-card>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import type { SyntheticVideo } from "@/core/types/video.types";
  import { useElementSize } from "@vueuse/core";
  import VtvPlayer from "@/core/components/RightDrawer/components/Players/VtvPlayer.vue";
  import { computed, type PropType, reactive, ref } from "vue";
  // @ts-ignore
  // prettier-ignore
  import { CCustomCard, CTabs } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    recordings: {
      type: Array as PropType<Array<SyntheticVideo>>,
      default: () => [],
    },
  });

  const tabsEl = ref();

  const data = reactive({
    search: "",
    showOrigin: false,
    origin: null,
    playingVideoId: 0,
    activeTab: "list",
  });

  const packedRecordings = computed(() => {
    return splitArray(props.recordings, numberOfGalleryVideosInRow.value);
  });

  function splitArray<T>(arr: T[], chunkSize: number): T[][] {
    const result: T[][] = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const { width } = useElementSize(tabsEl);

  const numberOfGalleryVideosInRow = computed(() => {
    if (width.value > 0) {
      switch (data.activeTab) {
      case "gallery":
        return Math.floor(width.value / 330);
      default:
        return 1;
      }
    } else {
      return 1;
    }
  });

  const setPlayingId = (val: number) => {
    data.playingVideoId = val;
  };

  const videoTabs = computed(() => {
    return [
      {
        icon: "ph:list-bullets",
        active: data.activeTab === "list",
        href: "list",
      },
      {
        icon: "ph:stop-fill",
        active: data.activeTab === "gallery",
        href: "gallery",
      },
    ];
  });

  const changeTab = (tab: string) => {
    data.activeTab = tab;
  };
</script>

<style scoped lang="scss">
.recordings-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  overflow: auto;
}

.pt-80 {
  padding-top: 80px;
}

.gallery {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 390px);
  justify-content: center;

  &--mobile {
    grid-template-columns: repeat(v-bind(numberOfGalleryVideosInRow), 320px);
  }
}

:deep(.tab) {
  flex: 1;
}
</style>

//const emailRegExp =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type RuleFn = Array<(value :any) => boolean | string>

export function isEmailValid(currentValue: string): boolean {
    return Boolean(currentValue && emailRegExp.test(currentValue));
}

export function isPasswordValid(currentValue: string): boolean {
    return Boolean(currentValue) && currentValue.length >= 8 && currentValue.length <= 64;
}

export function passwordRules(): RuleFn {
    return  [
        (v: string) => !!v || "The password is incorrect.",
        (v: string) => v.length <= 64 || "Max 64 characters.",
        (v: string) => v.length >= 8 || "Min 8 characters.",
      ];
}

export function emailRules(): RuleFn  {
    return [
      (value: any) => isEmailValid(value) || "Please enter a valid email address.",
    ];
}

export function multipleSelectRules(): RuleFn {
    return [
        (v: string) => !!v.length || "Please select at least one option.",
    ];
}

export function inputRules() : {password: RuleFn, email: RuleFn} {
   return {
    password: passwordRules(), email: emailRules() };
}

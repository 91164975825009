<template>
  <login-layout :error="error">
    <template #content>
      <circle-loader v-if="loading" :size="40" title="Loading..." />
    </template>
  </login-layout>
</template>

<script setup lang="ts">
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { HttpStatus } from "@/core/api/_messages";
  import LoginLayout from "@/core/layouts/LoginLayout.vue";
  import router from "@/router";
  import { Routes } from "@/core/routes/core.guard";
  import { tokenClientId } from "@/core/api/core.api";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import useFormState from "@/core/composables/useFormState";
  import { useStore } from "@/core/store";
  import { useUserStore } from "../../../core/store/userStore";
  import { analytics, LOGIN } from "@/core/utils/analytics";
  import {
    form,
    FORM_INITIAL_STATE,
    type FormStateSuccess,
  } from "@/core/types/form-state.type";
  import { computed, onMounted, ref, toRefs } from "vue";
  import {
    type LoginResponse,
    TERMS_CONSENT,
    TOKEN,
  } from "@/core/types/login.types";

  import { handleUserRedirect, loginUser } from "@/core/api/slowpoke.auth.api";
  import { type RouteLocationRaw, useRoute } from "vue-router";

  // @ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  import { CLIENT_ID } from "../../../core/types/login.types";

  //STORE
  const dialogsStore = useDialogsStore();
  const { newTermsDialog, newTermsConfirmedFromDialog } = toRefs(dialogsStore);
  const route = useRoute();

  const userStore = useUserStore();

  const loading = ref(false);
  const error = ref("");

  const handleRedirectForm = useFormState(handleUserRedirect, FORM_INITIAL_STATE);

  const getLoginRedirectRoute = (): RouteLocationRaw => {
    const redirectTo = route.query["redirectTo"];
    const redirectQuery = route.query["redirectQuery"];
    const redirectHash = route.query["redirectHash"];

    if (redirectTo) {
      const location: RouteLocationRaw = {
        path: String(redirectTo),
        query: {},
        hash: "",
      };

      if (redirectQuery) {
        location.query = Object.fromEntries(
          new URLSearchParams(String(redirectQuery)).entries()
        );
      }

      if (redirectHash) {
        location.hash = String(redirectHash);
      }

      return location;
    }

    return { name: Routes.HOME.name };
  };

  const state = computed(() => {
    return handleRedirectForm.state.value;
  });

  const userLogin = async (url: string) => {
    loading.value = true;
    try {
      const response = await loginUser(url);

      window.location.href = response.url;
      loading.value = false;
    } catch (e: any) {
      error.value = e.response?.data.message || "Error occured";
    } finally {
      loading.value = false;
    }
  };

  const handleRedirect = async () => {
    loading.value = true;
    const params = new URLSearchParams(window.location.search);

    handleRedirectForm.submit(params.toString()).then((response) => {
      const formState = handleRedirectForm.state.value;

      if (form.isSuccess(formState)) {

        const data = (response as FormStateSuccess<LoginResponse>).data;

        localStorage.setItem(CLIENT_ID, String(data.client.id));
        localStorage.setItem(TOKEN, data.token);

        if (data.mainConsentValid) {
          userStore.setClientSession(data);
        }

        const clientId = tokenClientId();

        userStore.setUserFeatures(data, clientId);

        if (localStorage.getItem(`${TERMS_CONSENT}_${clientId}`) === "false") {
          newTermsDialog.value = true;
        } else {

          router.push(getLoginRedirectRoute());
        }
      } else if (
        form.isErrored(formState) &&
        formState.code === HttpStatus.Gone
      ) {
        router.push({ name: Routes.RESTORE.name });
      }
    }).finally(() => {
      loading.value = false;
    });
  };

  onMounted(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.toString().startsWith("code=")) {
      handleRedirect();
    } else {
      let url = "";

      if (window.location.hostname === "localhost") {
        url = "http://localhost:5173/login/redirect";
      }
      userLogin(url);
    }
  });
</script>

<style scoped lang="scss"></style>

<template>
  <c-dialog
    :cancel-option="false"
    class="scrollable"
    :confirm-option="false"
    :model-value="modelValue"
    :width="isMobile ? '100%' : 580"
    @update:model-value="close"
  >
    <template #title>Standards & Guidelines </template>
    <template #content>
      <standards-guideline-common-content />
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import StandardsGuidelineCommonContent from "./StandardsGuidelineCommonContent.vue";
  import { useDialogsStore } from "@/core/store/useDialogsStore";
  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
  });

  //STORE
  const { toggleStandardsGuidelinesDialog } = useDialogsStore();

  const close = () => {
    toggleStandardsGuidelinesDialog(false);
  };
</script>

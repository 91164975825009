<template>
  <c-custom-card
    :class="['pa-2', { 'flex-grow': !isWelcomePage }]"
    color="light-frame-secondary"
    :height="!isWelcomePage ? '100%' : 'auto'"
  >
    <template #content>
      <div class="h-100 w-100">
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex flex-wrap gap-8">
            <c-info-card
              v-for="feature in buildVideoCreatorFeatures(isLipsyncModel)"
              :id="feature.id"
              :key="feature.id"
              :active="isActive(feature.routeName)"
              class="feature w-100"
              :disabled="feature.disabled"
              height="auto"
              :icon="feature.icon"
              :max-width="`calc(${
                100 / buildVideoCreatorFeatures(isLipsyncModel).length
              }% - 7px)`"
              :mini="isMobile"
              :test-id="`welcome-${feature.id}`"
              :text-cut="isTextWithEllipsis"
              variant="feature"
              @click="runAction(feature)"
            >
              <template #title>{{ feature.title }}</template>
              <template #subtitle>{{ feature.subtitle }}</template>
            </c-info-card>
          </div>
          <router-view name="content" />
        </div>
      </div>
    </template>
  </c-custom-card>
  <router-view name="action" />
  <div
    v-if="isWelcomePage"
    class="align-start d-flex flex-grow justify-center pt-16 w-100"
  >
    <img src="/images/spaceCat.svg" />
  </div>
</template>

<script setup lang="ts">
  import { buildVideoCreatorFeatures } from "../../data/buildFeatures";
  import { computed, toRefs } from "vue";
  import Onboarding from "@/core/onboarding/components/Onboarding.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import { useWindowSize } from "@vueuse/core";

  //@ts-ignore
  import { CCustomCard, CInfoCard } from "@charactr/wooper-ui/molecules";

  import { analytics } from "@/core/utils/analytics";

  import { RECORDING_TABS } from "@/core/types/recording.types";

  import { isMobile } from "@/core/utils/mobile";
  import { storeToRefs } from "pinia";

  //STORE
  const userStore = useUserStore();
  const dialogStore = useDialogsStore();

  const { videoCreatorDesktopGuide, videoCreatorMobileGuide } =
    storeToRefs(dialogStore);
  const videoCreatorStore = useVideoCreatorStore();
  const { stopAllLoaders } = videoCreatorStore;

  const { videoCreatorCurrentlySelectedAudioRecording } =
    storeToRefs(videoCreatorStore);

  const onboardingSteps = [
    {
      attachTo: { element: "#visualSelection" },
      content: {
        title: "Pick your avatar",
        description: "You can browse or add a Gemelo avatar for your video",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
      on: {
        beforeStep: function () {
          //added due to onboarding lib refresh issue
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 400);
        },
      },
    },
    {
      attachTo: { element: "#selectVoiceFeature" },
      content: {
        title: "Pick an avatar’s voice",
        description:
          "You can browse the Voice Library to find a perfect voice for your video",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    {
      attachTo: { element: "#uploadVideo" },
      content: {
        title: "Upload your video file",
        description:
          "You can upload a video file from your device and use as a base for your video audio-track or for life portrait video",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    {
      attachTo: { element: "#uploadAudio" },
      content: {
        title: "Upload your audio file",
        description:
          "You can upload audio or video file from your device and use as a base for your video audio-track or for life portrait video",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    {
      attachTo: { element: "#typeText" },
      content: {
        title: "Transform any text into speech in video",
        description:
          "You can type or paste any text that will be speech by Gemelo in the video",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    {
      attachTo: { element: "#dubbing" },
      content: {
        title: "Dub your video",
        description:
          "You can replace the voice track of your uploaded video file with any voice from the Voice Library",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },

    {
      attachTo: { element: "#importRecording" },
      content: {
        title: "Use recording from other flows",
        description:
          "You can use any of your previous recording file from your previous creations in Speech to Speech or Test to Speech flow.",
      },
      options: {
        overlay: {
          borderRadius: 12,
          padding: 8,
        },
      },
      on: {
        beforeStep: function () {
          //added due to onboarding lib refresh issue
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 0);
        },
      },
    },
    {
      attachTo: { element: "#right-drawer", classList: ["attached"] },
      content: {
        title: "You’ll find here your video result",
        description:
          "After clicking 'Create Video', your file will appear here. You can also follow the status of your file while it is converting.",
      },
      options: {
        popper: {
          placement: "left",
          strategy: "fixed",
        },
        overlay: {
          borderRadius: 12,
        },
      },
      on: {
        //added due to onboarding lib refresh issue

        afterStep: function () {
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 0);
        },
      },
    },
  ];

  const router = useRouter();

  const { width } = useWindowSize();

  const isTextWithEllipsis = computed(() => {
    return width.value < 1150;
  });

  const runAction = (feature: any) => {
    if (feature.analyticsEvent)
      analytics.sendEvent("video-creator", feature.analyticsEvent);

    if (feature.routeName) {
      userStore.ui_local_data.galleryRecentMainTab = RECORDING_TABS.AUDIO;
      stopAllLoaders();
      router.push({ name: feature.routeName });
    }
  };

  const closeWelcomeOnboarding = () => {
    if (isMobile.value) {
      videoCreatorMobileGuide.value = false;
      userStore.ui_local_data.videoCreatorFirstTimeMobileVisit = false;
    } else {
      userStore.ui_local_data.videoCreatorFirstTimeDesktopVisit = false;
      videoCreatorDesktopGuide.value = false;
    }
  };

  const routeName = computed(() => {
    return router.currentRoute.value.name;
  });

  const isActive = (name: string | undefined) => {
    return routeName.value === name;
  };

  const isWelcomePage = computed(() => {
    return (
      routeName.value ===
      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
        .name ||
      routeName.value ===
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR
        .name
    );
  });

  const isLipsyncModel = computed(() => router.currentRoute.value.path.includes("model"));
</script>

<style lang="scss" scoped>
.gap-8 {
  gap: 8px;
}

.flex-grow {
  flex-grow: 1;
}

:deep(.v-card-text) {
  height: 100%;
}

:deep(.feature) {
  overflow: hidden;
}
</style>

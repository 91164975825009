<template>
  <create-new-photo-dialog
    v-if="data.newPhotoDialog"
    :id="photoModelData?.id || 0"
    v-model="data.newPhotoDialog"
    :name="photoModelData?.name || ''"
  />
  <circle-loader v-if="data.loading" title="Loading data..." />
  <template v-else-if="photoModelData">
    <div class="d-flex justify-start mb-4 w-100">
      <c-button
        mode="highlight-plain"
        no-padding
        prepend-icon="ph:caret-left"
        size="x-small"
        @click="navigateToGallery"
      >
        Photo Twin Studio
      </c-button>
    </div>
    <div
      :class="[
        'd-flex gap-32 twin-page-wrapper',
        { 'flex-column gap-16': isMobile },
      ]"
    >
      <twin-profile-card
        v-model:delete-dialog="data.deleteDialog"
        v-model:edit-dialog="data.editDialog"
        :photo-img="
          photoModelData?.url
            ? photoModelData?.url
            : `/images/photoTwinStudio/${photoModelData.gender}.png`
        "
        :show-tag="false"
        :twin-data="photoModelData"
        @delete="deleteModel"
        @edit="editName"
      >
        <template #bottom-action>
          <c-button
            :class="[{ 'mt-2': isMobile }]"
            @click="data.newPhotoDialog = true"
          >Create New Photo</c-button
          >
        </template>
      </twin-profile-card>
      <div
        v-if="photoModelData && !modelPhotos.length"
        :class="[
          'align-center d-flex flex-grow-1 justify-center no-photo-wrapper pa-4 text-center',
        ]"
      >
        <div v-if="!loadingModelPhotos" class="d-flex flex-column">
          <c-typography
            class=""
            color-class="copy-secondary"
            variant="body-2-600"
          >Photos created with
            <c-typography
              class=""
              color-class="button-primary"
              variant="body-2-600"
            >{{ photoModelData?.name }}</c-typography
            >
            Photo Twin will be displayed here</c-typography
          >
          <img height="120" src="/images/spaceCat.svg" />
        </div>
        <circle-loader v-else title="Loading photos..." />
      </div>
      <div
        v-else
        :class="['gallery gap-12 w-100', { 'gallery--mobile': isMobile }]"
      >
        <c-image-card
          v-for="item in modelPhotos"
          :key="item.id"
          :class="[
            'gallery-image-card',
            {
              'gallery-image-card--hover cursor-pointer':
                isHovered(item) && !isReady(item),
            },
          ]"
          color="button-secondary"
          gap="8"
          :image-height="isMobile ? '114' : '221'"
          :image-src="item.thumbnailUrl"
          :width="isMobile ? '160' : '277'"
          @click="() => openPhotoPreview(item)"
          @mouseover="data.hoveredId = item.id"
        >
          <template v-if="!isReady(item)" #image-center>
            <c-icon
              class="loading-icon"
              color="rgb(var(--v-theme-button-primary))"
              height="40"
              :icon="'ph:magic-wand'"
              width="40"
            />
          </template>

          <template v-if="isError(item)" #image-center>
            <c-icon
              color="rgb(var(--v-theme-alert-red))"
              height="48"
              icon="material-symbols:error"
              width="48"
            />
            <c-typography class="mt-1" color-class="error" variant="body-1-600"
            >Error</c-typography
            >
          </template>
          <template
            v-if="isReady(item) && (isHovered(item) || isMobile)"
            #image-top-right
          >
            <c-dropdown-menu
              :dropdown-options="dropdownOptions"
              width="160"
              @update:model-value="data.hoveredId = item.id"
            >
              <template #activator>
                <c-button
                  class="d-inline-block"
                  icon="ph:dots-three-outline-fill"
                  mode="action"
                  size="x-small"
                  @click="() => updateCurrentClickedElementData(item)"
                />
              </template>
            </c-dropdown-menu>
          </template>
          <template v-if="isError(item)" #image-top-right>
            <c-button
              class="mt-1"
              icon="ph:trash-simple"
              mode="action"
              size="x-small"
              @click="() => deleteErrorPhoto(item)"
            />
          </template>
        </c-image-card>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="align-center d-flex flex-column">
      <img src="/images/404.svg" />
      <div class="align-center d-flex flex-column gap-16 mt-4">
        <c-typography variant="h3">
          {{ `Photo Twin model not found` }}
        </c-typography>
        <c-typography color-class="copy-secondary" variant="body-1-400">
          {{
            `It looks like we can't find a photo twin model with id = ${photoModelId}.`
          }}
        </c-typography>
        <c-button class="mt-4" @click="navigateToGallery"
        >See available models</c-button
        >
      </div>
    </div>
  </template>
  <photo-prompt-dialog
    v-if="data.openPhotoDialog"
    v-model="data.openPhotoDialog"
    :extended="false"
    :photo-el="data.currentModelPreview"
    :prompt-dialog="false"
    @show-next="updateCurrentModelPreview(1)"
    @show-prev="updateCurrentModelPreview(-1)"
  >
    <template #actions-container>
      <div class="align-center d-flex justify-space-between">
        <c-typography color-class="cta" variant="body-2-500">
          Photo {{ data.currentModelPreviewIndex + 1 }} of
          {{ currentModelPhotos.length }}
        </c-typography>
        <div :class="['d-flex', [isMobile ? 'gap-8' : 'gap-24']]">
          <div class="align-center cursor-pointer d-flex" @click="updateCover">
            <c-icon
              class="mr-2"
              color="rgb(var(--v-theme-cta))"
              height="16"
              icon="ic:baseline-portrait"
            />
            <c-typography color-class="cta" variant="body-2-500">
              Set as cover
            </c-typography>
          </div>
          <div class="align-center cursor-pointer d-flex" @click="deletePhoto">
            <c-icon
              class="mr-2"
              color="rgb(var(--v-theme-alert-red))"
              height="16"
              icon="ph:trash-fill"
            />
            <c-typography color-class="error" variant="body-2-500">
              Delete
            </c-typography>
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <div
        :class="['actions d-flex margin-top-auto w-100', { 'mt-2': isMobile }]"
      >
        <c-button
          v-if="isMobile"
          class="actions__btn"
          mode="secondary"
          prepend-icon="ph:share-network-fill"
          @click="sharePhoto"
        >Share</c-button
        >
        <c-button
          class="actions__btn"
          mode="primary"
          prepend-icon="ph:download-simple"
          @click="
            () =>
              downloadPhoto({
                name: data.currentModelPreview?.name || '',
                url: data.currentModelPreview?.url || '',
              })
          "
        >Download</c-button
        >
      </div>
    </template></photo-prompt-dialog
  >
  <delete-item-dialog
    v-if="data.deletePhotoDialog"
    v-model="data.deletePhotoDialog"
    subtitle="Are you sure you want to delete this photo twin? This action cannot be undone."
    title="Delete from gallery?"
    warning
    @delete-item="deleteCurrentModelTwinPhoto"
  />
  <edit-name-dialog
    v-if="data.editPhotoNameDialog"
    v-model="data.editPhotoNameDialog"
    :initial-name="modelPhotos.find((el) => el.id === data.activePhotoId).name"
    title="Edit photo name"
    @set-new-name="editPhotoName"
  />
</template>

<script lang="ts" setup>
  import PhotoPromptDialog from "./PhotoPromptDialog.vue";
  import { AlertModes } from "@/core/types/other.types";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import CreateNewPhotoDialog from "../components/CreateNewPhotoDialog.vue";
  import DeleteItemDialog from "@/core/components/RightDrawer/components/Dialogs/DeleteItemDialog.vue";
  import EditNameDialog from "@/core/components/RightDrawer/components/Dialogs/EditNameDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { computed, onBeforeUnmount, reactive, watch } from "vue";
  import { nativeShare } from "@/core/utils/share";
  import { Routes } from "@/core/routes/core.guard";
  import TwinProfileCard from "@/core/components/TwinProfileCard.vue";
  import { useUserStore } from "@/core/store/userStore";
  import { storeToRefs } from "pinia";
  import { useRouter } from "vue-router";
  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { downloadFromUrl } from "@/core/utils/download";
  import type { PhotoTwinPhoto } from "@/core/types/synthetic.types";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  import {
    deletePhotoTwinModel,
    deletePhotoTwinPhoto,
    updatePhotoTwinModelCover,
    updatePhotoTwinModelName,
    updatePhotoTwinPhotoName,
  } from "@/core/services/photoTwins.service";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  // @ts-ignore
  // prettier-ignore
  import { CDropdownMenu, CImageCard } from "@charactr/wooper-ui/molecules";

  const data = reactive({
    editDialog: false,
    deleteDialog: false,
    newPhotoDialog: false,
    loading: true,
    error: false,
    openPhotoDialog: false,
    activePhotoId: 0,
    hoveredId: 0,
    deletePhotoDialog: false,
    editPhotoNameDialog: false,
    currentModelPreview: null as null | PhotoTwinPhoto,
    currentModelPreviewIndex: 0,
  });

  const router = useRouter();
  const userStore = useUserStore();
  const drawerStore = useDrawerStore();
  const { showSnackbar } = useSnackbarStore();
  const { listOfPhotoTwinModels } = storeToRefs(userStore);
  const { changePhotoTwinModelData } = userStore;

  const { modelPhotos, currentModelPhotos, loadingModelPhotos } =
    storeToRefs(drawerStore);

  const photoModelId = computed(() => router.currentRoute.value.params.id);

  const isOpenDialog = computed(() => {
    return router.currentRoute.value.params.openDialog === "1";
  });

  const photoModelData = computed(() => {
    return listOfPhotoTwinModels.value.find(
      (el) => String(el.id) === String(photoModelId.value)
    );
  });

  watch(
    () => isOpenDialog.value,
    (newVal) => {
      if (newVal) {
        data.newPhotoDialog = true;
      }
    },
    {
      immediate: true,
    }
  );

  const editName = async (newName: string) => {
    try {
      const model = await updatePhotoTwinModelName(
        Number(photoModelId.value),
        newName
      );

      changePhotoTwinModelData(model);

      showSnackbar(
        "The photo twin name has been successfully changed.",
        AlertModes.SUCCESS
      );
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.editDialog = false;
    }
  };

  const deleteModel = async () => {
    try {
      await deletePhotoTwinModel(Number(photoModelId.value));
      router.push({
        name: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
      });
      listOfPhotoTwinModels.value = listOfPhotoTwinModels.value.filter(
        (picture) => picture.id !== Number(photoModelId.value)
      );
      showSnackbar("The photo model has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      console.error(e);
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.deleteDialog = false;
    }
  };

  const loadPhotos = async () => {
    await drawerStore.loadPhotoModelsPhotos(photoModelId.value as string);
  };

  watch(
    () => listOfPhotoTwinModels.value,
    (newVal) => {
      if (newVal.length) {
        data.loading = false;
        if (photoModelData.value) {
          loadPhotos();
        } else {
          data.error = true;
        }
      }
    },
    { immediate: true }
  );

  const navigateToGallery = () => {
    router.push({
      name: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
    });
  };

  const openPhotoPreview = (item: PhotoTwinPhoto) => {
    if (!isReady(item)) {
      return;
    }

    data.currentModelPreview = modelPhotos.value.find((el) => el.id === item.id);
    data.currentModelPreviewIndex = modelPhotos.value.findIndex(
      (el) => el.id === item.id
    );
    data.openPhotoDialog = true;
  };

  const isReady = (img: any) => {
    return img.status === "ready";
  };

  const isError = (img: any) => {
    return img.status === "error";
  };

  const isHovered = (image: any) => data.hoveredId === image.id;

  const downloadPhoto = async (item: PhotoTwinPhoto | any) => {
    downloadFromUrl(item.url, item.name, "_blank");
  };

  const sharePhoto = async () => {
    nativeShare(data.currentModelPreview?.name || "", {
      url: data.currentModelPreview?.url || "",
    });
  };

  const deleteCurrentModelTwinPhoto = async () => {
    try {
      await deletePhotoTwinPhoto(data.activePhotoId);
      currentModelPhotos.value = currentModelPhotos.value.filter(
        (picture) => picture.id !== Number(data.activePhotoId)
      );
      showSnackbar("The photo twin has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      console.error(e);
      showSnackbar(
        e.response?.data.message ||
          "Something went wrong while deleting photo twin",
        AlertModes.ERROR
      );
    } finally {
      data.deletePhotoDialog = false;
      updateCurrentModelPreview(1);
    }
  };

  const dropdownOptions = computed(() => {
    return [
      {
        name: "Download",
        icon: "ph:download-simple",
        action: () => downloadPhoto(
          modelPhotos.value.find((el) => el.id === data.activePhotoId)
        ),
      },
      {
        name: "Set as cover",
        icon: "ic:baseline-portrait",
        action: updatePhotoCover,
      },
      {
        name: "Delete",
        icon: "ph:trash-simple",
        action: () => (data.deletePhotoDialog = true),
      },
    ];
  });

  const editPhotoName = async (newName: string) => {
    try {
      const photo = await updatePhotoTwinPhotoName(
        Number(data.activePhotoId),
        newName
      );

      drawerStore.updatePhotoTwinPhoto(photo);

      showSnackbar(
        "Photo name has been successfully changed.",
        AlertModes.SUCCESS
      );
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.editPhotoNameDialog = false;
    }
  };

  const updatePhotoCover = async () => {
    try {
      const model = await updatePhotoTwinModelCover(
        Number(photoModelData.value?.id),
        data.activePhotoId
      );

      changePhotoTwinModelData(model);

      showSnackbar(
        "Photo Twin Model cover has been successfully changed.",
        AlertModes.SUCCESS
      );
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    }
  };

  const updateCurrentClickedElementData = (img: PhotoTwinPhoto) => {
    data.activePhotoId = img.id;
  };

  const deleteErrorPhoto = (item: PhotoTwinPhoto) => {
    updateCurrentClickedElementData(item);
    data.deletePhotoDialog = true;
  };

  const updateCurrentModelPreview = (val: number) => {
    data.currentModelPreview = null;

    const newIndex = data.currentModelPreviewIndex + val;

    if (newIndex < 0) {
      data.currentModelPreviewIndex = modelPhotos.value.length - 1;
    } else if (newIndex >= modelPhotos.value.length) {
      data.currentModelPreviewIndex = 0;
    } else {
      data.currentModelPreviewIndex = newIndex;
    }

    data.currentModelPreview = modelPhotos.value[data.currentModelPreviewIndex];
  };

  const updateCover = () => {
    updateCurrentClickedElementData(data?.currentModelPreview as PhotoTwinPhoto);
    updatePhotoCover();
  };

  const deletePhoto = () => {
    updateCurrentClickedElementData(data?.currentModelPreview as PhotoTwinPhoto);
    data.deletePhotoDialog = true;
  };

  onBeforeUnmount(() => {
    currentModelPhotos.value = [];
  });
</script>

<style lang="scss" scoped>
@keyframes fadeInOut {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

.gap-32 {
  gap: 32px;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 277px);
  justify-content: center;

  &--mobile {
    grid-template-columns: repeat(auto-fill, 160px);
  }
}

.loading-icon {
  animation: fadeInOut 1400ms linear 500ms infinite;
}

.actions {
  gap: 12px;

  &__btn {
    flex: 1;
  }
}

.photo-desc {
  max-width: 648px;
}

.avatar-preview {
  max-width: 600px !important;
}

.margin-top-auto {
  margin-top: auto;
}

.no-photo-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-dark-frame));
  border-radius: 12px;
  height: 196px;
}

.cursor-pointer {
  cursor: pointer;
}

// .gallery-image-card {
//     border: 1px solid rgb(var(--v-theme-light-frame));
//     &--hover {
//         border: 2px solid rgb(var(--v-theme-button-primary));
//     }
// }
</style>

<template>
  <div>
    <div class="d-flex mb-2 w-100">
      <div class="align-center d-flex justify-center" @click="clickBackBtn">
        <c-icon
          v-show="isMobile"
          :color="
            data.isWindowHistoryAvailable
              ? 'rgba(var(--v-theme-copy-primary))'
              : 'rgba(var(--v-theme-copy-primary-lock))'
          "
          height="20"
          icon="ph:caret-left"
          size="x-small"
          width="20"
        />
      </div>

      <div
        :class="[
          'align-center d-flex flex-grow-1 justify-center mr-5',
          { 'mr-5': isMobile },
        ]"
      >
        <c-typography color-class="copy-primary" variant="subtitle-2-600">
          {{
            isLipsyncUpload ? "Create High Quality Twin" : "Create Express Twin"
          }}</c-typography
        >
      </div>
    </div>

    <div v-if="isNotVideoSubmitted" class="d-flex justify-start mb-3 w-100">
      <c-button
        mode="highlight-plain"
        no-padding
        prepend-icon="ph:caret-left"
        size="x-small"
        @click="goToAITwinStudio"
      >
        Video Twin Studio
      </c-button>
    </div>
    <c-custom-card
      :class="{ 'main-card': !isMobile, 'pa-4': isMobile }"
      color="player-bg"
      :width="mainCardWidth"
    >
      <template #content>
        <div class="w-100">
          <div :class="['d-flex flex-column', { 'pa-4': !isMobile }]">
            <router-view />
          </div></div
        ></template>
    </c-custom-card>
  </div>
</template>

<script lang="ts" setup>
// @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";
  import { isMobile } from "../../../core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { computed, onMounted, reactive } from "vue";

  const router = useRouter();

  const data = reactive({
    isWindowHistoryAvailable: false,
  });

  onMounted(async () => {
    data.isWindowHistoryAvailable = router.options.history.state.back !== null;
  });

  const isLipsyncUpload = computed(() => {
    return router.currentRoute.value.params.type === "normal";
  });

  const mainCardWidth = computed(() => {
    if (isMobile.value) {
      return "100%";
    } else {
      if (router.currentRoute.value.path === Routes.VIDEO_TWIN_CREATOR.path) {
        return 772;
      } else {
        return 600;
      }
    }
  });

  const clickBackBtn = () => {
    if (!data.isWindowHistoryAvailable) {
      return;
    }
    router.go(-1);
  };

  const isNotVideoSubmitted = computed(() => {
    return (
      router.currentRoute.value.name !==
      Routes.VIDEO_TWIN_CREATOR.children.VIDEO_SUBMITTED.name
    );
  });

  const goToAITwinStudio = () => {
    router.push({
      name: isLipsyncUpload.value
        ? Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name
        : Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
    });
  };
</script>

<style lang="scss" scoped>
.chip {
  border-radius: 25px;
  height: 20px;

  &-primary {
    background-color: rgb(var(--v-theme-button-primary));
  }

  &-secondary {
    background-color: rgb(var(--v-theme-light-frame));
    color: rgb(var(--v-theme-copy-secondary));
  }
}

.main-card {
  border-radius: 16px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
}

.card {
  &__img {
    height: 100px;
    width: 158px;
  }
}

.info-wrapper {
  width: 420px;
}

.gap-24 {
  gap: 24px;
}

.twin-creator-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto auto;
}

.border {
  border: 1px solid rgb(var(--v-theme-dark-frame));
  margin-top: 8px;
}
</style>

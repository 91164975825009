import { apiUrl } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";

type CheckoutPayloadType = { subscription: boolean; tokens?: number };
enum PAYLOAD_NAME_OPTIONS {
  EARLY_BIRDS = "subscriptionTier0",
  PROFESSIONAL = "subscriptionTier1",
  FREE = "tokens"
}

export async function checkoutNew(payload: CheckoutPayloadType): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/pay/checkout/new`);

  const data = {
    name: payload.subscription ? PAYLOAD_NAME_OPTIONS.PROFESSIONAL : PAYLOAD_NAME_OPTIONS.FREE,
    quantity: payload.subscription ? 1 : Number(payload.tokens),
  };

  const response = await coreClient.post(url, data);

  return response.data;
}

export async function manageSubscription(): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(
    `clients/${clientId}/pay/customer/portal`
  );

  const response = await coreClient.get(url);

  return response.data;
}

export async function getSubscriptionLimit(): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(`/clients/${clientId}/subscription/access`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function checkoutPaymentSuccess(
  checkoutSessionId: string
): Promise<any> {
  const clientId = tokenClientId();

  const url = apiUrl(
    `clients/${clientId}/pay/checkout/success?session=${checkoutSessionId}`
  );

  const response = await coreClient.get(url);

  return response.data;
}

export async function enableTrial(): Promise<any> {

 const clientId = tokenClientId();

 const url = apiUrl(`/clients/${clientId}/pay/trial`);
 const response = await coreClient.post(url);

 return response.data;

}

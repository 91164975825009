<template>
  <div class="align-center d-flex flex-column justify-center overflow-hidden position-relative video-container w-100">
    <slot name="top-content" />
    <video
      ref="videoPlayer"
      class="video-js"
      preload="none"
    />
  </div>
</template>

<script lang="ts" setup>
  import "video.js/dist/video-js.css";
  import videojs from "video.js";
  import { VideoPlayerVariants } from "@/core/types/video.types";
  import { onBeforeUnmount, onMounted, type PropType, ref } from "vue";

  const props = defineProps({
    options: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String as PropType<VideoPlayerVariants>,
      default: VideoPlayerVariants.LIST,
    },
    hideBackground: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["stop", "start", "end", "playing", "loaded"]);
  const videoPlayer = ref();
  const player = ref();

  const loaded = ref(false);

  onMounted(() => {
    const sources = props.options.sources.filter(
      (s: { src: string; type: string }) => s.src
    );

    if (sources.length) {
      player.value = videojs(videoPlayer.value, props.options, () => {
        //player ready callback

        player.value.on("loadedmetadata", function (this: any) {
          loaded.value = true;
          clearPoster();
          emit("loaded", {
            width: this.videoWidth(),
            height: this.videoHeight(),
          });
        });

        player.value.on("ended", () => {
          emit("end");
        });
      });
    }

    setInitialPoster();
  });

  onBeforeUnmount(() => {
    if (player.value) {
      player.value.dispose();
    }
  });

  const getVideoBg = () => {
    return player.value ? player.value.el() : videoPlayer.value.parentElement;
  };

  const setInitialPoster = () => {
    const videoStartedBg = getVideoBg();

    videoStartedBg.style.backgroundImage = `url('${props.options.poster}')`;
  };

  const clearPoster = () => {
    const videoStartedBg = getVideoBg();

    if (videoStartedBg)
      videoStartedBg.style.backgroundImage = "";
  };

  const play = () => {
    if (loaded.value)
      emit("playing");
    player.value.play();

  };

  const stop = () => {
    player.value.pause();
    player.value.currentTime(0);
    emit("stop");
  };

  const switchToFullscreen = () => {
    player.value.requestFullscreen();
  };

  defineExpose({
    play,
    stop,
    switchToFullscreen,
  });
</script>

<style scoped lang="scss">
.video {
  &-container {
    border-radius: 12px;
  }

  &-js {
    background-position: center;
    background-size: contain;
    border-radius: 14px !important;
    display: flex;
    object-fit: contain;
  }
}
</style>

<style lang="scss">
.vjs-poster img {
  border-radius: 12px !important;
}

.vjs-poster {
  right: unset;
}

.vjs-tech {
  border-radius: 12px;
}

.vjs-control-bar {
  border-radius: 0 0 12px 12px;
}

.video-js {
  background-color: transparent;
  position: relative;
  z-index: 10;
}

.video-container {
  border-radius: 14px;
}
</style>

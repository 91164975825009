import { apiUrl } from "@/core/utils/api";
import type { Voice } from "../types/voice.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export async function getSystemVoices(): Promise<Array<Voice>> {
  //@todo update endpoint url when provided by backend
  const url = apiUrl("tts/voices?show=all");
  const response = await coreClient.get(url);

  return response.data || [];
}

export async function getClonedVoices(): Promise<Array<Voice>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/cloned-voices`);
  const response = await coreClient.get(url);

  return response.data?.items || [];
}

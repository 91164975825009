import { apiUrl } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";

function defineUploadConfig() {
  return {
    headers: {
      "X-File-Ms": 0,
    },
  };
}

export async function submitZeroShotVideo(payload: {
  video: any;
  videoDurationMs: number;
  name: string,
}): Promise<any> {
  const clientId = tokenClientId();
  const formData = new FormData();

  formData.append("name", payload.name);
  formData.append("file", payload.video);

  const url = apiUrl(`clients/${clientId}/lipsync-zero-shot?consent=true&media-type=${payload.video.type.split(";")[0]}`);
  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.videoDurationMs);
  const response = await coreClient.post(url, formData, config);

  return response.data;
}

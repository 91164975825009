export function sortByPropertyName<T, K extends keyof T>(
    values: T[],
    propertyName: K,
    order: "asc" | "desc"
  ): T[] {
    return values.sort((a, b) => {
      if (a[propertyName] < b[propertyName])
        return order === "asc" ? -1 : 1;

      if (a[propertyName] > b[propertyName])
      return order === "asc" ? 1 : -1;

      return 0;
    });
  }

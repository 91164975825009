//Removed dynamic import as this is a initial page from route
import RecordingsHistory from "../pages/RecordingsHistory.vue";
import { Routes } from "@/core/routes/core.guard";

export default [
  {
    path: Routes.RECORDINGS_HISTORY.path,
    redirect: Routes.RECORDINGS_HISTORY.children.AUDIO.path,
    children: [
      {
        name: Routes.RECORDINGS_HISTORY.children.AUDIO.name,
        path: Routes.RECORDINGS_HISTORY.children.AUDIO.path,
        component: RecordingsHistory,
        meta: { requiresAuth: true, lighterBg: true  },
      },
      {
        name: Routes.RECORDINGS_HISTORY.children.VIDEO.name,
        path: Routes.RECORDINGS_HISTORY.children.VIDEO.path,
        component: RecordingsHistory,
        meta: { requiresAuth: true, lighterBg: true, requiresPortraitStudioFeature: true  },
      },
    ],
  },
];

import { defineStore } from "pinia";
import { ref } from "vue";
import type { Voice } from "../types/voice.types";

export const useDialogsStore = defineStore("useDialogsStore", () => {
  const requestTwinProDialog = ref(false);
  const newTermsDialog = ref(false);
  const editClonedVoiceDialog = ref(false);
  const deleteClonedVoiceDialog = ref(false);
  const clonedVoiceToModify = ref(undefined as Voice | undefined);
  const openStandardsGuidelinesDialog = ref(false);
  const showSubscriptionDetailsDialog = ref(false);
  const newTermsConfirmedFromDialog = ref(false);
  const allowRecordingsHistoryScreenResize = ref(true);
  const videoCreatorDesktopGuide = ref(false);
  const videoCreatorMobileGuide = ref(false);
  const notEnoughTokensDialog = ref(false);

  const toggleStandardsGuidelinesDialog = (val: boolean) => {
    openStandardsGuidelinesDialog.value = val;
  };

  const newTermsAccepted = () => {
    newTermsDialog.value = false;
    newTermsConfirmedFromDialog.value = true;
  };

  const openEditClonedVoiceDialog = (voice: Voice) => {
    clonedVoiceToModify.value = voice;
    editClonedVoiceDialog.value = true;
  };

  const openDeleteClonedVoiceDialog = (voice: Voice) => {
    clonedVoiceToModify.value = voice;
    deleteClonedVoiceDialog.value = true;
  };

  return {
    requestTwinProDialog,
    newTermsDialog,
    editClonedVoiceDialog,
    deleteClonedVoiceDialog,
    openStandardsGuidelinesDialog,
    showSubscriptionDetailsDialog,
    newTermsConfirmedFromDialog,
    allowRecordingsHistoryScreenResize,
    clonedVoiceToModify,
    videoCreatorDesktopGuide,
    videoCreatorMobileGuide,
    notEnoughTokensDialog,
    toggleStandardsGuidelinesDialog,
    newTermsAccepted,
    openEditClonedVoiceDialog,
    openDeleteClonedVoiceDialog,
  };
});

<template>
  <div :class="['local-recording mb-4 mt-3 px-4 py-2']">
    <div class="align-center d-flex justify-start mb-2">
      <div class="d-flex local-recording__container w-100">
        <div class="d-flex flex-column">
          <c-typography
            v-if="!hideDetails"
            class="mb-2"
            color-class="highlight-color"
            variant="body-3-400"
          >{{ props.audioLabel }}</c-typography
          >
          <c-typography class="recording-name" variant="body-2-600">{{
            props.recording.name
          }}</c-typography>
        </div>
        <div>
          <c-button
            :disabled="disabled"
            icon="ph:trash-simple"
            mode="outline"
            @click="deleteRecording"
          />
        </div>
      </div>
      <c-spacer />
    </div>
    <div class="align-center d-flex justify-center" no-gutters>
      <c-player
        ref="player"
        :disabled="disabled"
        :player-state="data.playerState"
        :recording-time-in-seconds="props.recording.time"
        variant="mini"
        @stop-media="audioStop"
        @toggle="toggle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PLAYGROUND_FEATURES  } from "../data/playgroundFeatures";
  import useAudioPlayer from "@/core/composables/useAudioPlayer";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useStore } from "@/core/store";
  import { analytics, RECORDING } from "@/core/utils/analytics";
  import {
    type LocalRecording,
    PlayerState,
  } from "../types/recording.types";
  import { type PropType, reactive, ref, toRefs, watch } from "vue";

  // @ts-ignore
  import { CPlayer } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CSpacer, CTypography } from "@charactr/wooper-ui/atoms";
  import { useAppDrawersStateStore } from "../store/useAppDrawersStateStore";

  const props = defineProps({
    recording: {
      type: Object as PropType<LocalRecording>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    audioLabel: {
      type: String,
      default: "Selected voice",
    },
  });

  const emit = defineEmits(["delete", "edit"]);

  const player = ref();
  const store = useStore();
  const playgroundStore = usePlaygroundStore();

  const { audio, stopAudio, toggleRecording } = useAudioPlayer();

  const appDrawersState = useAppDrawersStateStore();
  const { rightPanelPlayingRecordingId } = toRefs(appDrawersState);

  const data = reactive({
    playerState: PlayerState.Idle,
  });

  watch(
    () => rightPanelPlayingRecordingId.value,
    (val) => {
      if (audio.isPlaying && val !== props.recording.id) {
        player.value.stopPlayer();
        data.playerState = PlayerState.Idle;
      }
    }
  );

  const audioPlay = async () => {
    if (props.recording.id) {
      rightPanelPlayingRecordingId.value = props.recording.id;
    }

    data.playerState = PlayerState.Playing;

    toggleRecording(
      String(props.recording.id),
      String(URL.createObjectURL(props.recording.file)),
      "wav"
    );
  };

  const audioStop = () => {
    stopAudio();
    data.playerState = PlayerState.Idle;
    analytics.sendEvent("recording", RECORDING.actions.STOP_AUDIO);
  };

  const toggle = () => {
    if (data.playerState === PlayerState.Playing) {
      audioStop();
    } else {
      audioPlay();
    }
  };

  const deleteRecording = () => {
    emit("delete");
  };
</script>

<style lang="scss" scoped>
.local-recording {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  box-sizing: border-box;
  transition: 0.2s all ease;

  &__container {
    justify-content: space-between;
  }
}

:deep(.player-actions__items) {
  justify-content: flex-start;
}
</style>

import { defineStore } from "pinia";
import { onBeforeUnmount, onUnmounted, ref } from "vue";
import { RightPanelTabs } from "../types/other.types";

export const useAppDrawersStateStore = defineStore(
  "appDrawersStateStore",
  () => {

    //RAIL
    const rail = ref(true);

    const setRail=(val: boolean) => {
      rail.value = val;
    };

    //DEFAULT RAIL
     // This property is to avoid collapsing the navigation drawer after a page refresh on the video creator path
     const isDefaultRail = ref(true);

     const setIsDefaultRail =(val: boolean) => {
      isDefaultRail.value = val;
    };

    //EXPAND ON HOVER
    const expandNavOnHover = ref(false);

    const setExpandOnHover= (val: boolean) => {
      expandNavOnHover.value= val;
    };

    //RIGHT DRAWER
    const isRightPanelOpen = ref(true);
    const showRightDrawer = ref(false);
    const rightDrawerOpenedPanel = ref(RightPanelTabs.RECORDINGS);
    const rightPanelPlayingRecordingId = ref("");

    const openRightPanelTab = (tab: RightPanelTabs) => {
      rightDrawerOpenedPanel.value = tab;
      showRightDrawer.value = true;
    };

    const closeRightDrawerTab = () => {
      showRightDrawer.value = false;
    };

    //RESET
    const $reset = () => {
      showRightDrawer.value = false;
      rightDrawerOpenedPanel.value = RightPanelTabs.RECORDINGS;
      rightPanelPlayingRecordingId.value = "";
      rail.value = true;
      isDefaultRail.value = true;
      expandNavOnHover.value = false;
      isRightPanelOpen.value = true;
    };

    onUnmounted(() => {
      $reset();
    });

    return {
      showRightDrawer,
      rightDrawerOpenedPanel,
      rightPanelPlayingRecordingId,
      rail,
      isDefaultRail,
      expandNavOnHover,
      isRightPanelOpen,

      openRightPanelTab,
      closeRightDrawerTab,
      setRail,
      setExpandOnHover,
      setIsDefaultRail,
      $reset,
    };
  }
);

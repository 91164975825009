
import type { SyntheticVideo } from "@/core/types/video.types";
import { toRefs } from "vue";
import { useProgressStore } from "../store/useProgressStore";
import { VIDEO_PROCESSING } from "../types/file-processing.types";
import { apiUrl, apiV2Url } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";
import {
    LipsyncStatus,
    ModelType,
    type SyntheticModel,
  } from "@/core/types/synthetic.types";
import { NoVoiceConversionType, VoiceType } from "@/core/types/voice.types";

function defineUploadConfig() {
  const progressStore = useProgressStore();

  const { dataProcessingState, dataProcessingPercentage } =
    toRefs(progressStore);

  dataProcessingPercentage.value = 0;

  return {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      dataProcessingPercentage.value = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (dataProcessingPercentage.value === 100) {
        dataProcessingState.value = VIDEO_PROCESSING;
      }
    },
    headers: {
      "X-File-Ms": 0,
    },
  };
}

export async function createZeroShotVideo(payload: {
  audio?: Blob;
  voiceId?: number | string;
  voiceType?: string;
  modelId: number;
  recordingId?: number;
  recordingType?: string;
  audioDurationMs: number;
}): Promise<SyntheticVideo> {
  const clientId = tokenClientId();

  const isRecording = payload.recordingId && payload.recordingType;
  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.audioDurationMs);

  if (isRecording) {
    const voicePart =
      payload.voiceId === NoVoiceConversionType
        ? ""
        : `&voiceId=${payload.voiceId}&voiceType=${payload.voiceType}`;

    const url = apiUrl(
      `clients/${clientId}/lipsync-zero-shot/${payload.modelId}/ttv?inputType=recording&recordingId=${payload.recordingId}&recordingType=${payload.recordingType}${voicePart}&consent=true`
    );
    const response = await coreClient.post(url, null, config);

    return response.data;
  } else {
    const formData = new FormData();

    if (payload.audio) {
      formData.append("audio", payload.audio);
    }

    const voicePart =
      payload.voiceId === NoVoiceConversionType
        ? ""
        : `&voiceId=${payload.voiceId}&voiceType=${payload.voiceType}`;

    const url = apiUrl(
      `clients/${clientId}/lipsync-zero-shot/${payload.modelId}/ttv?inputType=audio${voicePart}&consent=true`
    );

    const response = await coreClient.post(
      url,
      isRecording ? null : formData,
      config
    );

    return response.data;
  }
}

export async function createLipsyncVideo(payload: {
  audio?: Blob;
  voiceId?: number | string;
  voiceType?: string;
  modelId: number;
  recordingId?: number;
  recordingType?: string;
  audioDurationMs: number;
}): Promise<SyntheticVideo> {
  const clientId = tokenClientId();

  const isRecording = payload.recordingId && payload.recordingType;
  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.audioDurationMs);

  if (isRecording) {
    const voicePart =
      payload.voiceId === NoVoiceConversionType
        ? ""
        : `&voiceId=${payload.voiceId}&voiceType=${payload.voiceType}`;

    const url = apiUrl(
      `clients/${clientId}/lipsync-models/${payload.modelId}/ttv?inputType=recording&recordingId=${payload.recordingId}&recordingType=${payload.recordingType}${voicePart}&consent=true`
    );
    const response = await coreClient.post(url, null, config);

    return response.data;
  } else {
    const formData = new FormData();

    if (payload.audio) {
      formData.append("file", payload.audio);
    }

    const voicePart =
      payload.voiceId === NoVoiceConversionType
        ? ""
        : `&voiceId=${payload.voiceId}&voiceType=${payload.voiceType}`;

    const url = apiUrl(
      `clients/${clientId}/lipsync-models/${payload.modelId}/ttv?inputType=audio${voicePart}&consent=true`
    );

    const response = await coreClient.post(
      url,
      isRecording ? null : formData,
      config
    );

    return response.data;
  }
}

export async function deleteLipsyncModel(portraitId: number): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/lipsync-models/${portraitId}`);
  const response = await coreClient.delete(url);

  return response.data;
}

export async function updateLipsyncName(
  lipsyncId: number,
  newName: string
): Promise<SyntheticModel> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/lipsync-models/${lipsyncId}`);

  const response = await coreClient.patch(url, {
    name: newName,
  });

  return response.data;
}

export async function updateLipsyncVoice(payload: {
  lipsyncId: number;
  voiceId: number | string;
  cloned: boolean;
}): Promise<SyntheticModel> {
  const clientId = tokenClientId();

  const url = apiUrl(
    `clients/${clientId}/lipsync-models/${payload.lipsyncId}`
  );

  const response = await coreClient.patch(url, {
    voiceId: payload.voiceId,
    voiceType: payload.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
  });

  return response.data;
}

export async function getLipsyncModel(
  lipsyncId: number
): Promise<SyntheticModel> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/lipsync-models/${lipsyncId}`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function getLipsyncModels(): Promise<Array<SyntheticModel>> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/lipsync-models?limit=500`);

  const response = await coreClient.get(url);

  return response.data?.items.filter(
    (el: SyntheticModel) => (el.status !== LipsyncStatus.ERROR && el.type === ModelType.NORMAL) ||
      (el.type === ModelType.ZEROSHOT && el.status !== LipsyncStatus.ERROR)
  );
}

export async function getLipsyncVideos(
  lipsyncId: number | string
): Promise<Array<SyntheticVideo>> {
  const clientId = tokenClientId();

  const url = apiV2Url(
    `clients/${clientId}/lipsync-models/${lipsyncId}/videos`
  );

  const response = await coreClient.get(url);

  return response.data || [];
}

const EarlyAccess = (): Promise<RouteComponent> => import("../pages/EarlyAccess.vue");
const FreeCredits = (): Promise<RouteComponent> => import("../pages/FreeCredits.vue");
const HomeQuestionnaire = (): Promise<RouteComponent> => import("../pages/HomeQuestionnaire.vue");

import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.OFFERS.name,
    path: Routes.OFFERS.path,
    component: HomeQuestionnaire,
    meta: { requiresAuth: true },
  },
  {
    name: Routes.OFFERS.children.EARLY_ACCESS.name,
    path: Routes.OFFERS.children.EARLY_ACCESS.path,
    component: EarlyAccess,
    meta: { requiresAuth: true, smallerTopMargin: true },
  },
  {
    name: Routes.OFFERS.children.CREDITS.name,
    path: Routes.OFFERS.children.CREDITS.path,
    component: FreeCredits,
    meta: { requiresAuth: true, smallerTopMargin: true },
  },
];

export const enum PlayerState {
  Idle = "idle",
  Playing = "playing",
  Paused = "paused",
  Stopped = "stopped",
  Error = "error",
  Loading = "loading",
}

export type LoadedAudioFile = {
  id: string;
  file: Blob;
  url: string;
  time: number;
  name: string;
  createdAt?: string;
};

export type LoadedVideoFile = {
  url: string;
  type: string;
  file: Blob;
  name: string;
  id: string;
  extension: string;
  duration: number;
};

export type ApiRecording = {
  id: number;
  apiKeyId: number;
  balanceAfter: number;
  clientId: number;
  cost: number;
  createdAt: string;
  inputTextLength: number;
  inputTextWordCount: number;
  outputAudioLength: number;
  outputAudioSize: number;
  recordingUrl: string;
  streamId: number;
  uuid: string;
  voiceId: number;
  recordingName: string;
  file?: Blob;
};

export enum RecordingTypes {
  TTV = "ttv",
  VTV = "vtv",
  TTV_RAW = "ttv_raw",
  ZERO_SHOT = "ttv_0shot",
  DUBBING = "dubbing",
}

export type LocalRecording = {
  id?: string;
  type: string;
  file: Blob;
  time: number;
  videoDurationMs?: number;
  name: string;
  createdAt: string;
  status?: string;
  url?: string;
  voiceType: string;
  voiceId: number | string;
};

export enum RECORDING_TABS {
  VIDEO = "video",
  AUDIO = "audio",
}

<template>
  <div class="mt-4 pa-4">
    <div class="align-center grid mt-0 pa-0 w-100">
      <div class="align-center d-flex justify-center" @click="clickBackBtn">
        <c-icon
          class="pointer"
          color="rgba(var(--v-theme-copy-primary))"
          height="20"
          icon="ph:caret-left"
          size="x-small"
          width="20"
        />
      </div>
      <div class="align-center d-flex justify-center pr-5">
        <c-typography color-class="copy-primary" variant="subtitle-2-600">
          {{ pageTitle }}</c-typography
        >
      </div>
    </div>
    <router-view
      :recording="data.recording"
      :shared-data="data.sharedData"
      @update-share-id="updateShareId"
      @update-shared-data="updateSharedData"
      @update-sharing-value="updateActiveSharingValue"
    />
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import { getClientVideo } from "@/core/services/synthetics.service";
  import { getSharedVideoData } from "@/core/services/share.service";
  import { Routes } from "@/core/routes/core.guard";
  import type { SyntheticVideo } from "@/core/types/video.types";
  import type { VideoShareData } from "../types";

  //@ts-ignore
  import { CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  import { computed, onBeforeMount, reactive, ref } from "vue";

  import { useRouter } from "vue-router";
  import { useDrawerStore } from "../../../core/components/RightDrawer/store";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const drawerStore = useDrawerStore();

  const router = useRouter();

  const clickBackBtn = () => {
    router.go(-1);
  };

  const data = reactive({
    recording: null as SyntheticVideo | null,
    sharedData: null as VideoShareData | null,
    loading: false,
  });

  const route = computed(() => {
    return router.currentRoute.value.name;
  });

  const isSettingsRoute = computed(() => {
    return route.value === Routes.SHARE.children.SETTINGS.name;
  });

  const isPreviewRoute = computed(() => {
    return route.value === Routes.SHARE.children.PREVIEW.name;
  });

  const pageTitle = computed(() => {
    if (isSettingsRoute.value) {
      return "Share Video";
    } else if (isPreviewRoute.value) {
      return "Link preview";
    } else {
      return "Default Title";
    }
  });

  const loadRecordingData = async () => {
    const recordingId = router.currentRoute.value.params.id;

    try {
      if (recordingId) {
        data.recording = await getClientVideo(Number(recordingId));
      } else return;
    } catch {
      showSnackbar(
        "An error occurred while checking recording data",
        AlertModes.ERROR
      );
    }
  };

  const loadSharedData = async () => {
    try {
      if (data.recording?.shareId) {
        const response = await getSharedVideoData(data.recording.shareId);

        data.sharedData = {
          title: response.title,
          description: response.description,
        };
      } else {
        return;
      }
    } catch (error) {
      showSnackbar(
        "An error occurred while loading shared data",
        AlertModes.ERROR
      );
    }
  };

  const updateShareId = (val: string) => {
    if (data.recording) {
      data.recording.shareId = val;
      updateVideoDataInVideosList("shareId", val);
    }
  };

  const updateActiveSharingValue = (val: boolean) => {

    if (data.recording) {
      data.recording.sharingActive = val;
      updateVideoDataInVideosList("sharingActive", val);
    };

  };

  const updateSharedData = async(payload: VideoShareData) => {
    data.sharedData = payload;
  };

  const updateVideoDataInVideosList = (field: "shareId" | "sharingActive", val: any) => {

    const type = ((data.recording as SyntheticVideo).type) as string;
    let listType;

    if (type !== "vtv") {
      listType = "videoSynthesis";
    } else {
      listType = "vtv";
    }

    if (drawerStore.recordings[listType]?.length) {

      drawerStore.recordings[listType].find(el => el.id === (data.recording as SyntheticVideo).id)[field] = val;

    }
  };

  onBeforeMount(async () => {
    await loadRecordingData();
    await loadSharedData();
  });
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 20px auto;
}

.pointer {
  cursor: pointer;
  z-index: 10;
}
</style>

<template>
  <template v-if="showComponent">
    <error-dialog
      v-model="data.loadingError"
      :close-option="false"
      confirm-variant="secondary-web"
      :errors="data.loadingErrorMessage.description"
      :title="data.loadingErrorMessage.title"
    />
    <template v-if="!isVideoCreatorRoute && !gemeloToVideoTextRoute">
      <playground-consent-checkbox
        v-if="isVtvRoute"
        v-model="playgroundStore.consent[PLAYGROUND_FEATURES.VTV.name]"
        class="mb-2"
      />
      <playground-consent-checkbox
        v-else
        v-model="
          playgroundStore.consent[PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]
        "
        class="mb-2"
      />
    </template>
    <template v-else>
      <div class="d-flex flex-column justify-space-between mb-4 w-100">
        <div></div>
      </div>
    </template>
    <c-button
      block
      data-testid="video-creator-action-button"
      :disabled="
        !isConvertAllowed ||
          isRecordingsLimitReached ||
          isNoVoiceSelectedForDubbing
      "
      :loading="isCreatingVideo"
      :max-height="48"
      :price="price"
      @click="runConvertAction"
    >{{
      isVideoCreatorMultiTextRoute ? multiTextActionName : "Create Video"
    }}</c-button
    >
    <c-typography
      class="d-block mt-2"
      color-class="copy-secondary"
      variant="body-3-400"
    >
      By submitting to this form I accept
      <router-link class="link" :to="Routes.PRIVACY_POLICY.path" @click.stop>
        Gemelo AI Privacy Policy</router-link
      >.
    </c-typography>
  </template>
</template>

<script lang="ts" setup>
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { fetchVC } from "@/core/services/playground.service";
  import type { LoadedVideoFile } from "@/core/types/recording.types";
  import PlaygroundConsentCheckbox from "@/modules/playground/components/PlaygroundConsentCheckbox.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { storeToRefs } from "pinia";
  import { UNKNOWN_PORTRAIT_ID } from "@/core/types/other.types";
  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import useStreaming from "../../playground/composables/useStreaming";
  import { useUserStore } from "@/core/store/userStore";
  import { useWebsocket } from "@/core/composables/useWebsocket";
  import { VideoStatus } from "@/core/types/video.types";
  import { warningActionsTooltips } from "../data/buildFeatures";
  import { analytics, VIDEO, VIDEO_CREATOR } from "@/core/utils/analytics";
  import {
    AUDIO_UPLOADING_FILE,
    CONVERTING_TEXT_TO_AUDIO,
    fileProcessing,
    INITIAL_STATE,
    VIDEO_UPLOADING_FILE,
  } from "@/core/types/file-processing.types";
  import { computed, onBeforeUnmount, reactive, toRefs, watch } from "vue";
  import {
    createDubbingVideo,
    createPortraitTtvVideo,
    createVtvVideo,
  } from "@/core/services/synthetics.service";
  import {
    createLipsyncVideo,
    createZeroShotVideo,
  } from "@/core/services/lipsync.service";
  import {
    NoVoiceConversionType,
    type Voice,
    VoiceType,
  } from "@/core/types/voice.types";
  import { useRouter } from "vue-router";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";
  import {
    ModelType,
    type SyntheticModel,
    type SyntheticPortrait,
  } from "@/core/types/synthetic.types";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSdkStore } from "@/core/store/useSdkStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  const props = defineProps({
    routeName: {
      type: String,
      default: "",
    },
  });

  //STORE
  const progressStore = useProgressStore();
  const { dataProcessingState, dataProcessingPercentage } = toRefs(progressStore);
  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);
  const playgroundStore = usePlaygroundStore();
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();

  const { videoExpressCostPerUnit, videoHQCostPerUnit, billableUnitDurationS } =
    storeToRefs(userStore);

  const sdkStore = useSdkStore();
  const { sdkInitialized } = toRefs(sdkStore);

  const videoCreatorStore = useVideoCreatorStore();

  const {
    userSelectedModel,
    userSelectedPicture,
    selectedVideo,
    selectedDubbingVideo,
    multiText,
    videoCreatorCurrentlySelectedAudioRecording,
    videoSynthesisInputText,
    videoSynthesisInputAudio,
    isCreatingVideoFromAudio,
    isCreatingVideoFromText,
    isCreatingVideo,
    isCreatingVideoFromVideo,
    isCreatingDubbingVideo,
    isCreatingMultipleVideosFromMultiText,
    isCreatingVideoFromRecording,
    skipVoiceConversion,
    voiceSelectionDialogOpened,
    isCreateVideoButtonClicked,
  } = storeToRefs(videoCreatorStore);

  const { stopAllLoaders, updateMultiTextData, clearMultiTextData } =
    videoCreatorStore;

  //ROUTER
  const router = useRouter();

  const data = reactive({
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
  });

  const webSocket = useWebsocket();
  const { message } = storeToRefs(webSocket);

  const isLipsyncModel = computed(() => router.currentRoute.value.path.includes("model")
  );

  const price = computed(() => {
    const basePrice = Number(
      isLipsyncModel.value
        ? videoHQCostPerUnit.value
        : videoExpressCostPerUnit.value
    );
    const c = Number(billableUnitDurationS.value);

    if (isTTVRoute.value) {
      return Math.ceil((videoSynthesisInputText.value.length / 450) * 2);
    } else if (isVideoCreatorVideoRoute.value) {
      return selectedVideo.value
        ? Math.ceil(selectedVideo.value.duration / c) * basePrice
        : "";
    } else {
      return videoSynthesisInputAudio.value
        ? Math.ceil(videoSynthesisInputAudio.value.time / c) * basePrice
        : "";
    }
  });

  watch(
    () => voiceSelectionDialogOpened.value,
    async (newVal) => {
      if (!newVal) {
        if (
          isTTVRoute.value &&
          userSelectedVoice.value[playgroundMode.value] &&
          isCreateVideoButtonClicked.value
        ) {
          await runConvertAction();
        }
        isCreateVideoButtonClicked.value = false;
      }
    }
  );

  watch(
    () => message.value,
    async (val: any) => {
      if (val.payload) {
        const status = val.payload?.status || "";
        const id = val.payload?.["object_id"] || "";
        const mode = PLAYGROUND_FEATURES.VTV.name as PlaygroundFeature["name"];

        if (status === VideoStatus.READY && !props.routeName) {
          if (!props.routeName) {
            openVideoPreview(id, mode);
          }
        }
      }
    }
  );

  const multiTextActionName = computed(() => {
    const numOfAllItems = multiText.value.numOfAllItems;

    return numOfAllItems === 1
      ? "Create Video"
      : `Create ${numOfAllItems} Videos`;
  });

  const isRecordingsLimitReached = computed(() => {
    if (userStore.isUnlimitedVideoAccess) return false;

    return (
      drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit
    );
  });

  const isNoVoiceSelectedForDubbing = computed(() => {
    const selectedVoice =
      userSelectedVoice.value[PLAYGROUND_FEATURES.VIDEO_CREATOR.name];

    if (
      selectedVoice &&
      selectedVoice.id === NoVoiceConversionType &&
      isVideoCreatorDubbingRoute.value
    ) {
      return true;
    }
    return false;
  });

  const isNoVoiceSelectedForTextToVideo = computed(() => {
    if (
      router.currentRoute.value.name ===
      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
        .children?.TEXT.name ||
      router.currentRoute.value.name ===
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR
        .children?.TEXT.name ||
      router.currentRoute.value.name ===
      Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.name
    ) {
      const selectedVoice =
        userSelectedVoice.value[PLAYGROUND_FEATURES.VIDEO_CREATOR.name];

      if (selectedVoice && selectedVoice.id === NoVoiceConversionType) {
        return true;
      }
    }
    return false;
  });

  const playgroundMode = computed(() => {
    if (isVideoCreatorRoute.value) {
      return PLAYGROUND_FEATURES.VIDEO_CREATOR.name;
    }
    if (isVideoSynthesisAudioInput.value || isVideoSynthesisTextInput.value) {
      return PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name;
    } else if (isVtvRoute.value) {
      return PLAYGROUND_FEATURES.VTV.name;
    }
    return PLAYGROUND_FEATURES.VTV.name;
  });

  const showComponent = computed(() => {
    if (
      props.routeName ===
      Routes.VIDEO_CREATOR.children.MAIN.children?.RECORDING.name
    ) {
      return videoCreatorCurrentlySelectedAudioRecording.value;
    } else {
      return true;
    }
  });

  const isVtvRoute = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_TO_VIDEO.children.CREATE.name
  );

  const isTTVRoute = computed(() => router.currentRoute.value.path.endsWith("text")
  );

  const isVideoCreatorRoute = computed(() => router.currentRoute.value.path.includes("video-creator")
  );

  const gemeloToVideoTextRoute = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name
  );

  const isVideoCreatorVideoRoute = computed(
    () => router.currentRoute.value.name ===
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR
        .children?.VIDEO.name
  );

  const isVideoCreatorDubbingRoute = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_CREATOR.children.MAIN.children?.DUBBING.name
  );

  const isVideoCreatorMultiTextRoute = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.name
  );

  const isVideoSynthesisTextInput = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name ||
      props.routeName ===
      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
        .children?.TEXT.name ||
      props.routeName ===
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR
        .children?.TEXT.name
  );

  const isVideoSynthesisAudioInput = computed(
    () => router.currentRoute.value.name ===
      Routes.VIDEO_SYNTHESIS.children.CREATE_AUDIO.name ||
      props.routeName ===
      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
        .children?.AUDIO.name ||
      props.routeName ===
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children?.VIDEO_CREATOR
        .children?.AUDIO.name
  );

  const isConvertAllowed = computed(() => {
    return userSelectedInput.value && !isInputEmpty.value;
  });

  const userSelectedInput = computed(() => {
    if (isVideoCreatorDubbingRoute.value) return true;
    return (
      userSelectedModel.value || userSelectedPicture.value[playgroundMode.value]
    );
  });

  const isInputEmpty = computed(() => {
    if (isVideoCreatorMultiTextRoute.value) return !multiText.value.data.length;
    if (isVideoCreatorVideoRoute.value) {
      return !selectedVideo.value;
    }

    if (isVideoCreatorDubbingRoute.value) {
      return !selectedDubbingVideo.value;
    }

    if (isVideoSynthesisAudioInput.value && isVideoCreatorRoute.value) {
      return (
        !videoSynthesisInputAudio.value ||
        !playgroundStore.consent[PLAYGROUND_FEATURES.VIDEO_CREATOR.name]
      );
    } else if (playgroundMode.value === PLAYGROUND_FEATURES.VTV.name) {
      return !selectedVideo.value;
    } else if (isVideoSynthesisTextInput.value) {
      return (
        !videoSynthesisInputText.value ||
        videoSynthesisInputText.value.length >
        userStore.ttsCharactersLimitInTextToVideoMaxLength
      );
    } else if (isVideoSynthesisAudioInput.value) {
      return (
        !videoSynthesisInputAudio.value ||
        !playgroundStore.consent[PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name]
      );
    }
    return false;
  });

  onBeforeUnmount(() => {
    stopAllLoaders();
    clearMultiTextData();
  });

  const generateVtvSyntheticVideo = async (
    voice: Voice,
    portrait: SyntheticPortrait,
    video: LoadedVideoFile
  ) => {
    const dataToSend = {
      video: video.file,
      portraitId: portrait.id,
      voiceId: skipVoiceConversion.value ? NoVoiceConversionType : voice.id,
      voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
      videoDurationMs: video.duration * 1000 || 0,
    };

    return await createVtvVideo(dataToSend);
  };

  const streaming = useStreaming();

  const generateTtsAudio = async (
    voice: Voice,
    voiceType: VoiceType,
    text: string,
    format = "mp3"
  ) => {
    if (!sdkInitialized.value) {
      await streaming.createApiKeyWithSDKInit(true);
    }
    const result = await streaming.convertStream(
      Number(voice.id),
      voiceType,
      text,
      format,
      false
    );

    return new Blob([...result]);
  };

  const generateVideoFromAudio = async (
    voice: Voice,
    portrait: SyntheticPortrait | null,
    model: SyntheticModel | null,
    audio: Blob | null | undefined,
    audioDurationMs: number
  ) => {
    if (model && audio) {
      if (model.type === ModelType.ZEROSHOT) {
        return await createZeroShotVideo({
          audio: audio,
          voiceId: skipVoiceConversion.value ? NoVoiceConversionType : voice.id,
          voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
          modelId: model.id,
          audioDurationMs,
        });
      } else {
        return await createLipsyncVideo({
          audio: audio,
          voiceId: skipVoiceConversion.value ? NoVoiceConversionType : voice.id,
          voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
          modelId: model.id,
          audioDurationMs,
        });
      }
    } else if (portrait && audio) {
      return await createPortraitTtvVideo({
        portraitId: portrait.id,
        audio: audio,
        voiceId: skipVoiceConversion.value ? NoVoiceConversionType : voice.id,
        voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
        audioDurationMs,
      });
    }
    return null;
  };

  const openVideoPreview = (id: number, mode: PlaygroundFeature["name"]) => {
    router.push({
      name:
        mode === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name
          ? Routes.VIDEO_SYNTHESIS.children.PREVIEW.name
          : Routes.VIDEO_TO_VIDEO.children.PREVIEW.name,
      params: { id },
    });
  };

  const convertVtvVideo = async (playgroundFeature: PlaygroundFeature) => {
    try {
      const voice =
        (userSelectedVoice.value[playgroundFeature.name] as Voice) ||
        skipVoiceConversion.value;

      const portrait = userSelectedPicture.value[playgroundFeature.name];
      const video = selectedVideo.value;

      if (voice && portrait && video) {
        dataProcessingState.value = VIDEO_UPLOADING_FILE;
        isCreatingVideoFromVideo.value = true;

        drawerStore.prependMockedVideo(portrait.id, true);

        analytics.sendEvent("vtv", VIDEO.actions.START_CONVERSION);
        const result = await generateVtvSyntheticVideo(voice, portrait, video);

        userStore.updateBallance();

        drawerStore.prependVideo(result, PLAYGROUND_FEATURES.VTV.name);
        drawerStore.prependVideo(result, "twin");

        analytics.sendEvent("vtv", VIDEO.actions.CONVERSION_SUCCESS);
        selectedVideo.value = null;
      }
    } catch (e: any) {
      console.error(e);
      displayErrorDialog(
        e.code === "ERR_NETWORK"
          ? e.message
          : e.response?.data.message || "Error occured"
      );

      drawerStore.deleteMockedVideo();
      dataProcessingState.value = INITIAL_STATE;
    } finally {
      isCreatingVideoFromVideo.value = false;
    }
  };

  const getAudio = async (voice: Voice, text?: string) => {
    if (isVideoSynthesisAudioInput.value) {
      isCreatingVideoFromAudio.value = true;
      dataProcessingState.value = AUDIO_UPLOADING_FILE;

      if (videoSynthesisInputAudio.value && !skipVoiceConversion.value) {
        const resp = await fetchVC(
          voice.id,
          videoSynthesisInputAudio.value?.file,
          voice?.cloned,
          false
        );

        return resp.data;
      }
      return videoSynthesisInputAudio.value?.file;
    } else if (
      isVideoSynthesisTextInput.value ||
      isVideoCreatorMultiTextRoute.value
    ) {
      const voiceType = voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM;

      isCreatingVideoFromText.value = true;
      dataProcessingState.value = CONVERTING_TEXT_TO_AUDIO;
      const result = await generateTtsAudio(
        voice,
        voiceType,
        text || videoSynthesisInputText.value,
        "wav"
      );

      return result;
    }
    return null;
  };

  const getAudioDurationMs = (
    sourceAudio: Blob | undefined | null
  ): Promise<number> => {
    if (sourceAudio) {
      return new Promise(function (resolve) {
        const audio = new Audio();

        audio.src = URL.createObjectURL(sourceAudio);
        audio.onloadedmetadata = () => {
          resolve(audio.duration * 1000);
        };
        audio.onerror = () => {
          resolve(0);
        };
      });
    } else {
      return Promise.resolve(0);
    }
  };

  const convertDubbing = async () => {
    const selectedVoice =
      userSelectedVoice.value[PLAYGROUND_FEATURES.VIDEO_CREATOR.name];
    const videoForDubbing = selectedDubbingVideo.value;

    try {
      if (selectedVoice && videoForDubbing) {
        isCreatingDubbingVideo.value = true;
        drawerStore.prependMockedVideo(UNKNOWN_PORTRAIT_ID, false);

        const payload = {
          video: videoForDubbing.file,
          voiceId: selectedVoice.id,
          voiceType: selectedVoice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
          videoDurationMs: videoForDubbing.duration * 1000 || 0,
        };

        const result = await createDubbingVideo(payload);

        if (result) {
          userStore.updateBallance();

          drawerStore.prependVideo(
            result,
            PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name
          );
        } else {
          throw "Something went wrong";
        }
      }
    } catch (error: any) {
      data.loadingErrorMessage = {
        title: "Oops, something went wrong!",
        description: error.message,
      };
      data.loadingError = true;

      drawerStore.deleteMockedVideo();
    } finally {
      isCreatingDubbingVideo.value = false;
    }
  };

  const convertTtvSyntheticVideo = async (
    playgroundFeature: PlaygroundFeature,
    text?: string
  ) => {
    try {
      const voice = userSelectedVoice.value[playgroundFeature.name] as Voice;

      const portrait = userSelectedPicture.value[playgroundFeature.name];

      drawerStore.prependMockedVideo(
        portrait ? portrait.id : userSelectedModel.value!.id,
        Boolean(portrait)
      );

      const audio = await getAudio(voice, text);

      const audioDurationMs = await getAudioDurationMs(audio);

      const result = await generateVideoFromAudio(
        voice,
        portrait,
        userSelectedModel.value,
        audio,
        audioDurationMs
      );

      if (result) {
        userStore.updateBallance();

        analytics.sendEvent(
          "video-creator",
          VIDEO_CREATOR.actions.CREATE_VIDEO_SUCCESS
        );

        drawerStore.prependVideo(result, "twin");
        videoSynthesisInputAudio.value = null;

        return result;
      } else {
        throw "Something went wrong";
      }
    } catch (error: any) {
      if (error.response?.status === 402) {
        data.loadingErrorMessage = {
          title: "Oops, something went wrong!",
          description: "Your account balance is too small to use Video Creator",
        };
        data.loadingError = true;
        drawerStore.deleteMockedVideo();

        analytics.sendEvent(
          "video-creator",
          VIDEO_CREATOR.actions.CREATE_VIDEO_NO_BALLANCE
        );
        return;
      }

      if (error.message) {
        console.error(error.message);
        //sdk error pattern Error(`${response.status}: ${response.statusText}`);
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [status, statusText] = error.message.split(":");

        if (statusText) {
          const trimmedStatusText = statusText.trim();

          const capitalizedStatusText =
            trimmedStatusText.charAt(0).toUpperCase() +
            trimmedStatusText.slice(1);

          data.loadingErrorMessage = {
            title: "Oops, something went wrong!",
            description: capitalizedStatusText,
          };
        } else {
          data.loadingErrorMessage = {
            title: "Oops, something went wrong!",
            description: error.message,
          };
        }
      } else {
        data.loadingErrorMessage = {
          title: "Oops, something went wrong!",
          description: error.message,
        };
      }

      analytics.sendEvent(
        "video-creator",
        VIDEO_CREATOR.actions.CREATE_VIDEO_ERROR
      );
      data.loadingError = true;
      drawerStore.deleteMockedVideo();
    } finally {
      isCreatingVideoFromAudio.value = false;
      isCreatingVideoFromText.value = false;
    }
  };

  /* eslint-disable no-await-in-loop */
  const convertMultiTextVideos = async (playgroundFeature: PlaygroundFeature) => {
    isCreatingMultipleVideosFromMultiText.value = true;

    const texts = [...multiText.value.data];

    for (let i = 0; i < texts.length; i++) {
      const { 0: id, 1: text } = texts[i];
      const result = await convertTtvSyntheticVideo(playgroundFeature, text);

      if (result && result.id) {
        updateMultiTextData({ id, videoId: result.id });
        multiText.value.numOfGeneratedVideos = i + 1;
      }
    }
    isCreatingMultipleVideosFromMultiText.value = false;
  };

  const convertVideoFromRecording = async (
    playgroundFeature: PlaygroundFeature
  ) => {
    const recording = videoCreatorCurrentlySelectedAudioRecording.value;
    const portrait = userSelectedPicture.value[playgroundFeature.name];
    const voice = userSelectedVoice.value[playgroundFeature.name] as Voice;

    try {
      let result = null;

      isCreatingVideoFromRecording.value = true;

      drawerStore.prependMockedVideo(
        portrait ? portrait.id : userSelectedModel.value!.id,
        Boolean(portrait)
      );

      if (userSelectedModel.value) {
        if (userSelectedModel.value.type === ModelType.ZEROSHOT) {
          result = await createZeroShotVideo({
            recordingId: recording.id,
            recordingType: recording.mode,
            modelId: userSelectedModel.value.id,
            audioDurationMs: recording.outputAudioLength,
            voiceId: voice.id,
            voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
          });
        } else {
          result = await createLipsyncVideo({
            recordingId: recording.id,
            recordingType: recording.mode,
            modelId: userSelectedModel.value.id,
            audioDurationMs: recording.outputAudioLength,
            voiceId: voice.id,
            voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
          });
        }
      } else if (portrait) {
        result = await createPortraitTtvVideo({
          portraitId: portrait.id,
          recordingId: recording.id,
          recordingType: recording.mode,
          audioDurationMs: recording.outputAudioLength,
          voiceId: voice.id,
          voiceType: voice?.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
        });
      }

      if (result) {
        userStore.updateBallance();

        drawerStore.prependVideo(
          result,
          PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name
        );
      } else {
        throw "Something went wrong";
      }
    } catch (error: any) {
      data.loadingErrorMessage = {
        title: "Oops, something went wrong!",
        description: error.message,
      };
      data.loadingError = true;

      drawerStore.deleteMockedVideo();
    } finally {
      isCreatingVideoFromRecording.value = false;
    }

  //@todo finish when backend integration ready (see andther conversions)
  //implement this function similar to another functions
  };

  const runConversionBasedOnRouteNameProp = async (nameOfTheRoute: string) => {
    switch (nameOfTheRoute) {
    case Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
      .children?.TEXT.name:
    case Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
      ?.VIDEO_CREATOR.children?.TEXT.name:
    case Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children?.VIDEO_CREATOR
      .children?.AUDIO.name:
    case Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
      ?.VIDEO_CREATOR.children?.AUDIO.name:
      await convertTtvSyntheticVideo(PLAYGROUND_FEATURES.VIDEO_CREATOR);
      break;
    case Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
      ?.VIDEO_CREATOR.children?.DUBBING.name:
      await convertDubbing();
      break;
    case Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
      ?.VIDEO_CREATOR.children?.VIDEO.name:
      await convertVtvVideo(PLAYGROUND_FEATURES.VIDEO_CREATOR);
      break;
    case Routes.VIDEO_CREATOR.children.MAIN.children?.RECORDING.name:
      await convertVideoFromRecording(PLAYGROUND_FEATURES.VIDEO_CREATOR);
      break;
    case Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.name:
      await convertMultiTextVideos(PLAYGROUND_FEATURES.VIDEO_CREATOR);
    }
  };

  const runConvertAction = async () => {
    if (isTTVRoute.value && !userSelectedVoice.value[playgroundMode.value]) {
      voiceSelectionDialogOpened.value = true;
      isCreateVideoButtonClicked.value = true;
      return;
    }
    if (props.routeName) {
      runConversionBasedOnRouteNameProp(props.routeName);
    } else {
      if (playgroundMode.value === PLAYGROUND_FEATURES.VTV.name) {
        await convertVtvVideo(PLAYGROUND_FEATURES.VTV);
      } else if (
        playgroundMode.value === PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name
      ) {
        await convertTtvSyntheticVideo(PLAYGROUND_FEATURES.VIDEO_SYNTHESIS);
      }
    }
  };

  const displayErrorDialog = (message: string) => {
    data.loadingErrorMessage = {
      title: "Oops, something went wrong!",
      description: message,
    };
    data.loadingError = true;
    analytics.sendEvent("vtv", VIDEO.actions.CONVERSION_ERROR);
  };
</script>

import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
import { Routes } from "@/core/routes/core.guard";

//Removed dynamic import as this is a initial page from route
import VoicesLib from "../pages/VoicesLib.vue";

export default [
  {
    path: Routes.VOICES.path,
    redirect: Routes.VOICES.children.SYSTEM.path,
    children: [
      {
        name: Routes.VOICES.children.SYSTEM.name,
        path: Routes.VOICES.children.SYSTEM.path,
        component: VoicesLib,
        meta: {
          requiresAuth: true,
          lighterBg: true,
          smallerTopMargin: true,
          mode: "system",
        },
      },
      {
        name: Routes.VOICES.children.CLONED.name,
        path: Routes.VOICES.children.CLONED.path,
        component: VoicesLib,
        meta: {
          requiresAuth: true,
          lighterBg: true,
          smallerTopMargin: true,
          mode: PLAYGROUND_FEATURES.VOICE_CLONING.name,
        },
      },

    ],
  },
];

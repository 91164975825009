import type { Ref } from "vue";
import { useUserMedia } from "@vueuse/core";

type CanvasLibSettings = {
  stream: Ref<MediaStream | undefined>,
  startCanvas(): void;
  stopCanvas(): void;
};

export default function useLibraryCanvas(): CanvasLibSettings {

  const { stream, enabled, stop } = useUserMedia({
    videoDeviceId: false,
  });

  function stopCanvas() {
    enabled.value = false;
    stop();
  }

  function startCanvas() {
    enabled.value = true;
  }

  return {
    stream,
    startCanvas,
    stopCanvas,
  };
}

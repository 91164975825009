//Removed dynamic import as this is a initial page from route
import Share from "../pages/Share.vue";

//Dynamically imported components
const Settings = (): Promise<RouteComponent> => import("../components/Settings.vue");
const Preview = (): Promise<RouteComponent> => import("../components/Preview.vue");

import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

export default [
  {
    name: Routes.SHARE.name,
    path: Routes.SHARE.path,
    component: Share,
    meta: {
      requiresAuth: true,
      rightDrawer: false,
      isMobileFullScreenView: true,
      fullWidth: true,
    },
    redirect: Routes.SHARE.children.SETTINGS.path,
    children: [
      {
        name: Routes.SHARE.children.SETTINGS.name,
        path: Routes.SHARE.children.SETTINGS.path,
        component: Settings,
        meta: {
          requiresAuth: true,
          rightDrawer: false,
          isMobileFullScreenView: true,
          isDarkerMobile: true,
          fullWidth: true,
        },
      },
      {
        name: Routes.SHARE.children.PREVIEW.name,
        path: Routes.SHARE.children.PREVIEW.path,
        component: Preview,
        meta: {
          requiresAuth: true,
          rightDrawer: false,
          isMobileFullScreenView: true,
          fullWidth: true,
        },
      },
    ],
  },
];

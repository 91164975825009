<template>
  <right-drawer-player
    ref="rightDrawerPlayerRef"
    :active="props.active"
    :bottom-border="bottomBorder"
    :bottom-mobile-player="props.bottomMobilePlayer"
    :chip="props.chip"
    :disabled="props.disabled"
    :hide-details="props.hideDetails"
    :mode="props.recordingMode"
    :name="props.recording.recordingName"
    :player-state="data.playerState"
    :recording="recording"
    :selectable="props.selectable"
    :time="toSeconds(props.recording.outputAudioLength)"
    :top-border="topBorder"
    @download-recording="downloadRecording"
    @edit-name="editName"
    @open-delete-dialog="data.deleteDialog = true"
    @open-edit-dialog="data.editDialog = true"
    @select-item="emit('selectRecording')"
    @stop-media="audioStop"
    @toggle="toggle"
  >
    <template #dialogs>
      <edit-name-dialog
        v-if="data.editDialog"
        v-model="data.editDialog"
        :initial-name="props.recording.recordingName"
        @set-new-name="editName"
      />
      <delete-item-dialog
        v-model="data.deleteDialog"
        @delete-item="deleteApiRecording"
      />
    </template>
  </right-drawer-player>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import DeleteItemDialog from "../Dialogs/DeleteItemDialog.vue";
  import { downloadFromUrl } from "@/core/utils/download";
  import EditNameDialog from "../Dialogs/EditNameDialog.vue";
  import RightDrawerPlayer from "./RightDrawerPlayer.vue";
  import useAudioPlayer from "@/core/composables/useAudioPlayer";
  import { useMobileRecentRecordingStore } from "@/core/store/useMobileRecentRecordingStore";
  import { useDrawerStore } from "../../store";
  import { useStore } from "@/core/store";
  import { analytics, RECORDING } from "@/core/utils/analytics";
  import {
    type ApiRecording,
    PlayerState,
  } from "../../../../types/recording.types";
  import {
    type Component,
    computed,
    nextTick,
    type PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import {
    deleteRecording,
    updateRecordingName,
  } from "@/core/services/playground.service";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";

  const props = defineProps({
    recording: {
      type: Object as PropType<ApiRecording>,
      default: null,
    },
    recordingMode: {
      type: String,
      default: "",
    },
    chip: {
      type: String,
      default: "",
    },
    topBorder: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    bottomMobilePlayer: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      defult: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });
  //PROPS & EMITS
  const emit = defineEmits(["selectRecording"]);

  //STORE
  const store = useStore();
  const drawerStore = useDrawerStore();
  const { showSnackbar } = useSnackbarStore();
  const mobileRecentRecording = useMobileRecentRecordingStore();
  const { $reset, setRecordingName } = mobileRecentRecording;
  const progressStore = useProgressStore();
  const { stopAllVideos, stopAllAudio } = toRefs(progressStore);

  const { audio, stopAudio, toggleRecording } = useAudioPlayer();

  const rightDrawerPlayerRef = ref<null | Component>(null);

  const data = reactive({
    playerState: PlayerState.Idle,
    editDialog: false,
    deleteDialog: false,
  });

  const player = computed(() => (rightDrawerPlayerRef.value as any).$refs.player);

  const appDrawersState = useAppDrawersStateStore();
  const { rightPanelPlayingRecordingId } = toRefs(appDrawersState);

  watch(
    () => rightPanelPlayingRecordingId.value,
    (val) => {
      if (audio.isPlaying && val !== String(props.recording.uuid)) {
        player.value.stopPlayer();
        data.playerState = PlayerState.Idle;
      }
      if (
        data.playerState === PlayerState.Loading &&
        val !== props.recording.uuid
      ) {
        audioStop();
      }
    }
  );

  watch(
    () => stopAllAudio.value,
    () => {
      if (data.playerState === PlayerState.Playing) {
        stopAudio();
        data.playerState = PlayerState.Idle;
      }
      nextTick(() => {
        stopAllAudio.value = false;
      });
    }
  );

  const toSeconds = (time: number) => {
    return time / 1000;
  };

  const audioPlay = async () => {
    stopAllVideos.value = true;
    data.playerState = PlayerState.Loading;
    rightPanelPlayingRecordingId.value = props.recording.uuid;

    try {
      analytics.sendEvent("recording", RECORDING.actions.PLAY_AUDIO);
      await toggleRecording(
        props.recording.uuid,
        props.recording.recordingUrl,
        "wav"
      );
      data.playerState = PlayerState.Playing;
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    }
  };

  const audioStop = () => {
    stopAudio();
    data.playerState = PlayerState.Idle;
    analytics.sendEvent("recording", RECORDING.actions.STOP_AUDIO);
  };

  const toggle = () => {
    if (props.selectable) {
      emit("selectRecording");
    }

    if (data.playerState === PlayerState.Playing) {
      audioStop();
    } else {
      audioPlay();
    }
  };

  const downloadRecording = async () => {
    try {
      downloadFromUrl(
        props.recording.recordingUrl,
        `${props.recording.recordingName}.wav`,
        "_blank"
      );
      showSnackbar("File downloaded to your device", AlertModes.SUCCESS);
      analytics.sendEvent("recording", RECORDING.actions.DOWNLOAD_AUDIO);
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    }
  };

  const deleteApiRecording = async () => {
    try {
      await deleteRecording(props.recording.id, props.recordingMode);
      showSnackbar("The recording has been deleted.", AlertModes.SUCCESS);

      drawerStore.deleteRecording(props.recordingMode, props.recording.id);

      if (props.bottomMobilePlayer) {
        $reset();
      }
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.deleteDialog = false;
    }
  };

  const editName = async (newName: string) => {
    try {
      const resp = await updateRecordingName(props.recording.id, newName, props.recordingMode);

      showSnackbar(
        "The recording name has been successfully changed.",
        AlertModes.SUCCESS
      );

      drawerStore.updateRecordingName(props.recordingMode, props.recording.id, newName, resp.recordingUrl);

      if (props.bottomMobilePlayer) {
        setRecordingName(newName);
      }

    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      data.editDialog = false;
    }
  };
</script>

<template>
  <div class="align-center d-flex h-100 parent w-100" style="">
    <div ref="header" class="w-100">
      <slot name="title" />
      <slot name="streaming" />

      <slot name="top-buttons" />
      <slot name="voice" />
    </div>

    <div class="h-100 w-100">
      <slot name="playground" />
    </div>
    <div v-if="!hideExtraSlot" ref="bottomPlayer" class="bottom w-100">
      <div class="pb-3 px-4">
        <slot name="action"> </slot>
      </div>

      <div v-if="mobileRecentAudioRecording">
        <div
          class="align-center d-flex justify-end player-toolbar px-2"
        >
          <c-icon
            color="rgba(var(--v-theme-copy-secondary))"
            icon="ph:x"
            @click="clearRecordings"
          />
        </div>
        <audio-recording-player
          v-if="mobileRecentAudioRecording"
          bottom-mobile-player
          :recording="mobileRecentAudioRecording"
          :recording-mode="props.variant.name"
          :top-border="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  // @ts-ignore
  import { CIcon } from "@charactr/wooper-ui/atoms";

  import AudioRecordingPlayer from "@/core/components/RightDrawer/components/Players/AudioRecordingPlayer.vue";
  import type { PlaygroundFeature } from "@/core/data/playgroundFeatures";
  import { useElementSize } from "@vueuse/core";
  import { useMobileRecentRecordingStore } from "@/core/store/useMobileRecentRecordingStore";
  import { useStore } from "@/core/store";
  import { type PropType, ref, toRefs } from "vue";

  const props = defineProps({
    variant: {
      type: Object as PropType<PlaygroundFeature>,
      default: () => ({}),
    },
    isRecording: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    hideExtraSlot: {
      type: Boolean,
      default: false,
    },
  });
  const mobileRecentRecording = useMobileRecentRecordingStore();
  const { mobileRecentAudioRecording } = toRefs(mobileRecentRecording);
  const { $reset } = mobileRecentRecording;

  const store = useStore();

  const header = ref();
  const bottomPlayer = ref();

  const clearRecordings = () => {
    $reset();
  };

  const { height: headerHeight } = useElementSize(header);
  const { height: bottomPlayerHeight } = useElementSize(bottomPlayer);

  defineExpose({
    headerHeight,
    bottomPlayerHeight,
  });
</script>

<style lang="scss" scoped>
:deep(.c-icon-button) {
  color: rgba(var(--v-theme-copy-primary)) !important;
}

.parent {
  flex-direction: column;
}

.player-toolbar {
  background-color: rgb(var(--v-theme-aphla-bg));
  border-bottom: 1px solid rgb(var(--v-theme-light-frame));
  box-shadow: 0 -2px 15px 0 rgb(0 0 0 / 8%);
  height: 24px;
}

.bottom {
  margin-top: auto;
}
</style>

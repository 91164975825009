import { Routes } from "@/core/routes/core.guard";

//Removed dynamic import as this is a initial page from route
import Account from "../pages/Account.vue";

export default [
  {
    name: Routes.ACCOUNT.name,
    path: Routes.ACCOUNT.path,
    component: Account,
    meta: { requiresAuth: true },
  },
];

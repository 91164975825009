<template>
  <div :class="[{ 'px-4': isMobile }]">
    <cloning-title title="Voice successfully cloned!">
      <template #append>
        <div class="w-100">
          <voice-details
            v-if="currentlyActiveClonedVoice"
            cloned
            mode="single"
            :voice="currentlyActiveClonedVoice"
            wrapper-class="py-2 pr-4 pl-2"
          />
        </div>
      </template>
    </cloning-title>
    <c-typography class="d-block mb-4 text-center" variant="body-3-400">
      Bring your new voice to life - try converting text into audio or go to
      speech-to-speech!
    </c-typography>
    <div :class="['d-flex gap-16 justify-space-between', {'flex-column align-center': isMobile}]">
      <feature-card
        v-for="feature in voiceCloningFeaturesToDisplay"
        :key="feature.testId"
        :action="feature.action ? true : false"
        :action-button="feature.actionButton"
        :data-testid="`whats_new_${feature.testId}`"
        :img="feature.imgLight"
        :img-alt="feature.imgAlt"
        :name="feature.name"
        :route-path="feature.routePath"
        :test-id="feature.testId"
        :title="feature.title"
        @run-action="runFeatureAction(feature)"
      />
    </div>
    <c-typography class="d-block mt-4 text-center" variant="body-3-400">
      Feel like giving it another shot?
      <c-typography
        color-class="highlight-color"
        pointer
        variant="body-3-600"
        @click="openCreateDialog"
      >Clone a new voice</c-typography
      >
      whenever you’re ready.
    </c-typography>
  </div>
</template>

<script setup lang="ts">
  // @ts-ignore
  import {  CTypography } from "@charactr/wooper-ui/atoms";

  import { buildVoiceCloningOptions } from "@/core/data/appFeatures";
  import CloningTitle from "../components/voiceCloning/CloningTitle.vue";
  import FeatureCard from "@/core/components/FeatureCard.vue";
  import { isMobile } from "@/core/utils/mobile";
  import {  RightPanelTabs } from "@/core/types/other.types";
  import { Routes } from "@/core/routes/core.guard";
  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import useRecorder from "@/core/composables/useRecorder";
  import { useRouter } from "vue-router";
  import useTimer from "@/modules/voicesPlayground/composables/useTimer";
  import { useUserStore } from "@/core/store/userStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import VoiceDetails from "@/core/components/Voice/VoiceDetails.vue";
  import {
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    toRefs,
    watch,
  } from "vue";

  const emit = defineEmits(["openNewDialog"]);

  const voiceCloningFeaturesToDisplay = buildVoiceCloningOptions();

  const { updateVoice } = useVoicesStore();

  const runFeatureAction = (feat: any) => {
    updateVoice(currentlyActiveClonedVoice.value, feat.mode.name);

    router.push({ name: feat.routeName });
  };

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab, closeRightDrawerTab } = appDrawersState;
  const userStore = useUserStore();

  const { clonedVoices, currentlyActiveClonedVoice } = toRefs(
    useVoicesListStore()
  );
  const noAccess = ref(false);
  const isCheckingMicrophoneAccess = ref(false);
  const recorder = useRecorder();

  const timer = useTimer({ maxSecondsDuration: 60 });
  const router = useRouter();

  const openCreateDialog = () => {
    if (isVoiceCloningLimitReached.value) {
      router.push({ name: Routes.VOICE_CLONING.name });
      return;
    }
    emit("openNewDialog");
  };

  onMounted(() => {
    if (!currentlyActiveClonedVoice.value) {
      router.push({ name: Routes.VOICE_CLONING.name });
    }
    if (isMobile.value) {
      closeRightDrawerTab();
    } else {
      openRightPanelTab(RightPanelTabs.VOICES);
    }
  });

  onBeforeUnmount(() => {
    removeListeners();
  });

  watch(
    () => recorder.accessDenied,
    (val) => {
      if (val.value) {
        noAccess.value = true;
      }
    },
    { deep: true }
  );

  watch(
    () => recorder.isCheckingAccess,
    (val) => {
      isCheckingMicrophoneAccess.value = val.value;
    },
    { deep: true }
  );

  const removeListeners = () => {
    if (timer) timer.unlistenAll();

    if (recorder) {
      recorder.stop();
      recorder.unlistenAll();
    }
  };

  const isVoiceCloningLimitReached = computed(() => {
    if (userStore.isUnlimitedVoiceCloningAccess) return false;
    return clonedVoices.value.length >= userStore.clonedVoicesLimit;
  });
</script>

<style scoped lang="scss">
.recording {
  align-items: center;
  display: flex;
  width: 100%;

  &__canvas {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

.hidden {
  display: none;
}

.error {
  color: var(--vt-c-red);
}

.gap-16 {
  gap: 16px;
}
</style>

import { downloadMobile, isWeb } from "./capacitor";

export function downloadFile(blob: Blob, fileName: string): void {
  if (blob) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;

    if (isWeb()) {
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } else {
      downloadMobile(fileName, blob);
    }
  }
}
export function downloadFromUrl(url: string, fileName: string, target="_blank"): void {
  const link = document.createElement("a");

  link.href = url;
  link.download = `${fileName}`;

  link.target = target;
  document.body.appendChild(link);

  link.addEventListener("click", () => {
      link.removeAttribute("target");
  });

  link.addEventListener("click", () => {
      setTimeout(() => {
          document.body.removeChild(link);
      }, 100);
  });

  link.click();
}


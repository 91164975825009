import { useGtm } from "@gtm-support/vue-gtm";

const CATEGORIES = {
  APP_BAR: {
    actions: {
      PRICING_GUIDE: "show_pricing_guide",
      OPEN_ACCOUNT: "open_account_settings",
      OPEN_BILLING: "open_billing_settings",
      LOGOUT: "logout",
    },
  },
  LOGIN: {
    actions: {
      EMAIL: "login",
      RESET_PASSWORD_EMAIL: "reset_password_email",
      RESET_PASSWORD_NEW: "reset_password_new",
    },
  },
  REGISTER: {
    actions: {
      GOOGLE: "register_google",
      SEND_REGISTER_EMAIL: "send_email",
      SEND_REGISTER_PASSWORD: "send_password",
    },
  },
  PAYMENTS: {
    actions: {
      BUY_MINUTES: "buy_minutes",
      ADD_PROMO_CODE: "add_promo_code",
      BUY_SUCCESS: "buy_minutes_success",
      BUY_ERROR: "buy_minutes_error",
      BUY_CANCEL: "buy_minutes_cancel",
    },
  },

  VIDEO: {
    actions: {
      PLAY_VIDEO: "play_video",
      STOP_VIDEO: "stop_video",
      EDIT_VIDEO_NAME: "edit_video_name",
      DOWNLOAD_VIDEO: "download_video",
      DELETE_VIDEO: "delete_video",
      EDIT_VIDEO_NAME_ERROR: "edit_video_name_err",
      DOWNLOAD_VIDEO_ERROR: "download_video_err",
      DELETE_VIDEO_ERROR: "delete_video_err",
      SOURCE_VIDEO_LOAD_ERROR: "video_file_load_err",
      SOURCE_VIDEO_LOAD_SUCCESS: "video_file_load_success",
      START_CONVERSION: "conversion_start",
      CONVERSION_ERROR: "conversion_error",
      CONVERSION_SUCCESS: "conversion_success",
    },
  },
  TTS: {
    actions: {
      START: "tts_streaming_start",
      SUCCESS: "tts_streaming_success",
      ERROR: "tts_streaming_error",
      NO_BALANCE_ERROR: "tts_streaming_no_ballance_error",
      SDK_INIT_ERROR: "tts_streaming_sdk_loadind_error",
      LOADING_FILE_ERROR: "tts_streaming_loading_file_error",
    },
  },
  VC: {
    actions: {
      START_RECORDING: "vc_start_recording",
      START_CONVERSION: "vc_start_conversion",
      SUCCESS: "vc_conversion_success",
      ERROR: "vc_conversion_error",
      NO_BALANCE_ERROR: "vc_no_ballance_error",
    },
  },
  VIDEO_CONVERTER: {
    actions: {
      DRAG_AND_DROP_FILE_LOADED: "drag_and_drop_file_loaded",
      INPUT_FILE_LOADED: "input_file_loaded",
      FILE_LOAD_ERROR: "file_load_error",
      SUCCESS: "generate_video_success",
      ERROR: "generate_video_error",
      NO_BALLANCE_ERROR: "generate_video_error",
      DOWNLOAD: "download_video",
    },
  },
  VOICE_CLONING: {
    actions: {
      START_RECORDING: "voice_cloning_start_recording",
      START_CONVERSION: "voice_cloning_start_conversion",
      SUCCESS: "voice_cloning_conversion_success",
      ERROR: "voice_cloning_conversion_error",
      NO_BALANCE_ERROR: "voice_cloning_no_ballance_error",
      FILE_UPLOADED_SUCCESS: "voice_cloning_file_uploaded_success",
      FILE_UPLOADED_ERROR: "voice_cloning_file_uploaded_error",
      VOICE_DELETE: "voice_cloning_delete",
      VOICE_EDIT: "voice_cloning_edited",
    },
  },
  RECORDING: {
    actions: {
      PLAY_AUDIO: "play_recording",
      STOP_AUDIO: "stop_recording",
      PREVIEW_VIDEO: "preview_video",
      DOWNLOAD_AUDIO: "download_audio",
      DOWNLOAD_VIDED: "download_video",
      DELETE: "delete_recording",
    },
  },
  ACCOUNT: {
    actions: {
      EDIT: "account_edit_dialog",
      CHANGE_PASSWORD: "change_password_confirm",
      CHANGE_APPEARANCE: "change_appearance",
      CHANGE_COOKIE_SETTINGS: "account_change_cookie_settings",
      LOGOUT_ALL: "logout_all_enter",
      LOGOUT_ALL_CONFIRM: "logout_all_confirm",
    },
  },
  BILLING: {
    actions: {
      RELOAD_BILLING: "reload_billing",
      GET_MORE_DETAILS: "get_more_details",
      GET_MORE_DETAILS_CANCEL: "get_more_details_cancel",
      PROMO_CODE_DIALOG: "open_promocode_dialog",
      CREATE_BILLING_ALERT_DIALOG: "create_billing_alert_dialog",
      CREATE_ALERT: "billing_alert_created",
      DELETE_ALERT: "billing_alert_deleted",
      RELOAD_BILLING_HISTORY: "reload_billing_history",
    },
  },

  CONTACT: {
    actions: {
      SEND_MESSAGE_SUCCESS: "sendMessageSuccess",
      SEND_MESSAGE_ERROR: "sendMessageError",
    },
  },
  API_KEYS: {
    actions: {
      COPY_CLIENT_KEY: "copy_client_key",
      CREATE_API_KEY: "create_api_key",
      DELETE_API_KEY: "delete_api_key",
      COPY_API_KEY: "copy_api_key",
    },
  },
  QUESTIONNAIRE: {
    actions: {
      SUCCESS: "generate_lead",
      ERROR: "error_generate_lead",
      EARLY_ACCESS_SUCCESS: "early_access_success",
      EARLY_ACCESS_ERROR: "early_access_error",
    },
  },
  STATISTICS: {
    actions: {
      CUSTOMIZE: "customize_stats",
      GENERATE_CUSTOM_STATS_RANGE: "generate_custom_stats_range",
      CLEAR_CUSTOM_STATS_RANGE: "clear_custom_stats_range",
      LOAD_TTS_STATS_HISTORY: "reload_tts_usage_history",
      LOAD_VC_STATS_HISTORY: "reload_vc_usage_history",
      LOAD_TTV_STATS_HISTORY: "reload_ttv_usage_history",
      LOAD_ATV_STATS_HISTORY: "reload_atv_usage_history",
      LOAD_VTV_STATS_HISTORY: "reload_vtv_usage_history",
      RELOAD_STATS_HISTORY_ERROR: "reload_stats_usage_history_error",
    },
  },
  VOICES: {
    actions: {
      TRY: "try_voice",
      TRY_CLONED: "try_created_cloned_voice",
      CLICK_RATING: "voice_raiting_clicked",
      PREVIEW: "preview_voice",
      SEARCH_VOICE_INPUT_SELECT: "search_voice_input_select",
      FILTER_SELECTED: "voice_filter_selected",
      CLEAR_FILTERS: "clear_voice_filters",
      CHANGE_VOICES_TAB: "change_voices_tab",
    },
  },
  SYNTHETICS_CREATOR: {
    actions: {
      SEND_SYNTHETIC_INPUTS_SUCCESS: "success_send_synthetic_inputs",
      SEND_SYNTHETIC_INPUTS_ERROR: "error_send_synthetic_inputs",
      CHECK_DRAFT_PORTRAIT_STATUS_ERROR: "error_check_draft_portrait_status",
      FINALIZE_SYNTH_PORTRAIT_SUCCESS: "success_finalize_synth_portrait",
      FINALIZE_SYNTH_PORTRAIT_ERROR: "error_finalize_synth_portrait",
      DELETE_DRAFT_SYNTH_SUCCESS: "success_delete_synth_draft",
      DELETE_DRAFT_SYNTH_ERROR: "error_delete_synth_draft",
    },
  },
  SYNTHETICS_GALLERY: {
    actions: {
      EDIT_SYNTH_PIC_NAME_SUCCESS: "success_edit_name",
      EDIT_SYNTH_PIC_NAME_ERROR: "error_edit_name",
      DELETE_SYNTH_PIC_SUCCESS: "success_delete_synth_picture",
      DELETE_SYNTH_PIC_ERROR: "error_delete_synth_picture",
      SHOW_SYNTH_PIC_PREVIEW: "show_synth_pic_preview",
      GO_TO_VTV: "go_to_vtv",
      GO_TO_VIDEO_CREATOR_PICTURE: "go_to_video_with_picture",
      GO_TO_VIDEO_CREATOR_MODEL: "go_to_video_with_model",
      CONTINUE_SYNTH_GENERATION_ERROR: "error_synth_generation",
      CONTINUE_SYNTH_GENERATION: "continue_synth_generation",
      SEARCH_PIC_INPUT_SELECT: "search_pic_input_select",
      DOWNLOAD_SYNTH_PICTURE: "download_sync_picture",
      CREATE_NEW_SYNTH: "create_new_synth",
    },
  },

  VIDEO_CREATOR: {
    actions: {
      SEND_REQUEST_PHOTO_TWIN_PRO_SUCCESS: "send_request_photo_twin_pro_success",
      SEND_REQUEST_PHOTO_TWIN_PRO_ERROR: "send_request_photo_twin_pro_error",
      SEND_REQUEST_TWIN_PRO_SUCCESS: "send_request_twin_pro_success",
      SEND_REQUEST_TWIN_PRO_ERROR: "send_request_twin_pro_error",
      SEND_REQUEST_DESIGNED_VOICE_SUCCESS: "send_request_designed_voice_success",
      SEND_REQUEST_DESIGNED_VOICE_ERROR: "send_request_designed_voice_error",
      SEND_REQUEST_AI_TWIN_SUCCESS: "send_request_ai_twin_success",
      SEND_REQUEST_AI_TWIN_ERROR: "send_request_ai_twin_error",
      SEND_REQUEST_BUNDLE_VIDEO_TWIN_SUCCESS: "send_request_bundle_video_twin_success",
      SEND_REQUEST_BUNDLE_VIDEO_TWIN_ERROR: "send_request_bundle_video_twin_error",
      SEND_REQUEST_BUNDLE_PHOTO_TWIN_SUCCESS: "send_request_bundle_photo_twin_success",
      SEND_REQUEST_BUNDLE_PHOTO_TWIN_ERROR: "send_request_bundle_photo_twin_error",

      OPEN_VISUAL_CHOICE: "open_visual_choice",
      OPEN_VOICE_CHOICE: "open_voice_choice",

      VISUAL_CHOICE_UPLOAD_PHOTO: "visual_choice_upload_photo",
      VISUAL_CHOICE_GENERATE_TWIN: "visual_choice_generate_twin",
      VISUAL_CHOICE_PHOTO_TWIN_PRO: "visual_choice_photo_twin_pro",
      VISUAL_CHOICE_VIDEO_TWIN_PRO: "visual_choice_video_twin_pro",

      VOICE_CHOICE_VOICE_CLONING: "voice_choice_voice_cloning",
      VOICE_CHOICE_CLONED_VOICE_PRO: "voice_choice_cloned_voice_pro",

      OPEN_UPLOAD_VIDEO: "open_upload_video",
      OPEN_UPLOAD_AUDIO: "open_upload_audio",
      OPEN_TYPE_TEXT: "open_type_text",
      OPEN_DUBBING: "open_dubbing",
      OPEN_IMPORT_RECORDING: "open_import_recording",
      OPEN_VIDEO_GUIDE: "open_video_guide",
      OPEN_MULTI_TEXT: "open_multi_text",

      CREATE_VIDEO_SUCCESS: "create_video_success",
      CREATE_VIDEO_ERROR: "create_video_error",
      CREATE_VIDEO_NO_BALLANCE: "create_video_no_ballance",
    },
  },

  RECORDINGS_HISTORY: {
    actions: {
      SEARCH_RECORDING_INPUT_SELECT: "search_recording_input_select",
    },
  },

  CLONES: {
    actions: {
      ERROR_LOAD_CLIENT_CLONES: "error_load_client_clones",
      SUCCESS_LOAD_CLIENT_CLONES: "success_load_client_clones",
      ERROR_LOAD_CLIENT_CLONES_VIDEO: "error_load_client_clones_videos",
      SUCCESS_LOAD_CLIENT_CLONES_VIDEO: "success_load_client_clones_videos",
      CLONE_GALLLERY_SHOW_RESTORE_DIALOG:
        "clone_gallery_show_restore_clone_dialog",
      CLONE_GALLERY_CREATE_NEW_CLONE: "clone_gallery_go_to_create_new",
      CLONE_GALLERY_LOAD_CLONE_SUCCESS: "clone_gallery_load_clone_success",
      CLONE_GALLLERY_LOAD_CLONE_VIDEOS_ERROR:
        "clone_gallery_load_clone_videos_error",
      CLONE_GALLLERY_LOAD_CLONE_VIDEOS_SUCCESS:
        "clone_gallery_load_clone_videos_success",
      CLONE_GALLERY_LOAD_CLONE_ERROR: "clone_gallery_load_clone_error",
      CLONE_STUDIO_CHOOSE_VOICE_LOAD_VOICES_ERROR:
        "clone_studio_choose_voice_load_voices_error",
      CLONE_STUDIO_CHOOSE_VOICE_CHANGE_VOICES_TAB:
        "clone_studio_choose_voice_change_voices_tab",
      CLONE_STUDIO_CHOOSE_VOICE_CHANGE_FILTERS:
        "clone_studio_choose_voice_change_filters",
      CLONE_STUDIO_UPLOAD_PHOTO_UNSUPPORTED_EXTENSION:
        "clone_studio_upload_photo_unsupported_extension",
      CLONE_STUDIO_UPLOAD_PHOTO_VALIDATION_SUCCESS:
        "clone_studio_upload_photo_validation_success",
      CLONE_STUDIO_UPLOAD_PHOTO_VALIDATION_FAILED:
        "clone_studio_upload_photo_validation_failed",
      CLONE_STUDIO_UPLOAD_PHOTO_TWIN_GENERATION_SUCCESS:
        "clone_studio_upload_photo_twin_generation_success",
      CLONE_STUDIO_UPLOAD_PHOTO_TWIN_GENERATION_ERROR:
        "clone_studio_upload_photo_twin_generation_error",
      CLONE_STUDIO_CHOOSE_LOOK_SELECT_PICTURE:
        "clone_studio_choose_look_select_picture",
      CLONE_STUDIO_CHOOSE_LOOK_GENERATE_MORE_ERROR:
        "clone_studio_choose_look_generate_more_error",
      CLONE_STUDIO_CHOOSE_LOOK_GENERATE_MORE_SUCCESS:
        "clone_studio_choose_look_generate_more_success",
      CLONE_STUDIO_CHOOSE_LOOK_CHECK_CLONE_READY_ERROR:
        "clone_studio_choose_look_check_clone_ready_error",
      CLONE_STUDIO_CHOOSE_NAME_GENERATE_TWIN_SUCCESS:
        "clone_studio_choose_name_generate_twin_success",
      CLONE_STUDIO_CHOOSE_NAME_GENERATE_TWIN_ERROR:
        "clone_studio_choose_name_generate_twin_error",
      CLONE_STUDIO_TWIN_PREVIEW_LOADING_ERROR:
        "clone_studio_twin_preview_loading_error",
      CLONE_STUDIO_TWIN_PREVIEW_LOADING_SUCCESS:
        "clone_studio_twin_preview_loading_success",
    },
  },
};

export function sendEvent(
  category: string,
  action: string,
  extraParams?: Record<string, any>
): void {
  useGtm()?.trackEvent({
    event: `${action}`,
    category: `${category}`,
    source: "frontend",
    software: "GA4",
    ...extraParams,
  });
}

export const analytics = {
  sendEvent,
};

export const LOGIN = CATEGORIES.LOGIN;
export const REGISTER = CATEGORIES.REGISTER;
export const PAYMENTS = CATEGORIES.PAYMENTS;
export const TTS = CATEGORIES.TTS;
export const VC = CATEGORIES.VC;
export const VIDEO = CATEGORIES.VIDEO;
export const RECORDING = CATEGORIES.RECORDING;
export const VIDEO_CONVERTER = CATEGORIES.VIDEO_CONVERTER;
export const VOICE_CLONING = CATEGORIES.VOICE_CLONING;
export const ACCOUNT = CATEGORIES.ACCOUNT;
export const BILLING = CATEGORIES.BILLING;
export const API_KEYS = CATEGORIES.API_KEYS;
export const QUESTIONNAIRE = CATEGORIES.QUESTIONNAIRE;
export const STATISTICS = CATEGORIES.STATISTICS;
export const APP_BAR = CATEGORIES.APP_BAR;
export const VOICES = CATEGORIES.VOICES;
export const CONTACT = CATEGORIES.CONTACT;
export const CLONES = CATEGORIES.CLONES;
export const SYNTHETICS_GALLERY = CATEGORIES.SYNTHETICS_GALLERY;
export const SYNTHETICS_CREATOR = CATEGORIES.SYNTHETICS_CREATOR;
export const RECORDINGS_HISTORY = CATEGORIES.RECORDINGS_HISTORY;
export const VIDEO_CREATOR = CATEGORIES.VIDEO_CREATOR;

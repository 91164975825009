<template>
  <component
    :is="!props.action ? 'div' : 'router-link'"
    class="align-end d-flex route-link"
    :to="{ name: props.routeName }"
    @click="runAction"
  >
    <frame-card :border="title ? 1 : 0" color="button-primary" :title="title">
      <template #content>
        <section class="feature">
          <div class="feature__container">
            <c-custom-card
              id="mainCard"
              :class="[
                'feature__card d-flex align-center',
                [isMobile ? 'feature__card--mobile pa-4' : 'px-4 py-6'],
                {
                  'feature__card--extended': props.extended && !isMobile,
                  'feature__card--mobile--extended': isMobile && props.extended,
                  'feature__card--expired': props.expired,
                },
              ]"
              color="player-bg"
              type="primary"
            >
              <template #content>
                <slot name="custom">
                  <div
                    class="align-center d-flex flex-column justify-center text-center"
                  >
                    <c-skeleton
                      v-if="!imageLoaded"
                      :class="[
                        'loader',
                        { 'loader--mobile': isMobile },
                        { 'loader--extended': extended },
                        { 'loader--extended--mobile': extended && isMobile },
                      ]"
                      type="image"
                    >
                    </c-skeleton>

                    <div
                      :class="[
                        'd-flex align-center',
                        props.extended ? 'gap-6' : 'flex-column',
                        {
                          'loader--hidden': !imageLoaded,
                        },
                      ]"
                    >
                      <img
                        v-if="props.img"
                        :alt="props.imgAlt"
                        :class="isMobile ? 'img--mobile' : 'img'"
                        :data-testid="`whats-new-img-${testId}`"
                        :src="props.img"
                        @load="loaded"
                      />
                      <div
                        :class="[
                          'd-flex',
                          props.extended
                            ? 'flex-column align-start text-start'
                            : 'flex-column align-center justify-center',
                          { 'align-center': props.extended && isMobile },
                        ]"
                      >
                        <c-typography
                          :class="[isMobile ? 'mt-3' : 'mt-4']"
                          :data-testid="`whats-new-title-${testId}`"
                          pointer
                          variant="body-2-600"
                        >
                          {{ props.name }}
                          <c-tag
                            v-if="props.chip"
                            class="ml-2 tag"
                            :mode="props.expired ? 'pink' : 'blue'"
                          >{{ props.chip }}</c-tag
                          >
                        </c-typography>
                        <c-typography
                          class="pt-2"
                          color-class="copy-secondary"
                          :data-testid="`whats-new-text-${testId}`"
                          pointer
                          variant="body-3-400"
                        >{{ props.desc }}</c-typography
                        >
                        <c-button
                          v-if="props.actionButton"
                          class="mt-4"
                          :disabled="props.expired"
                          :to="props.routePath"
                        >{{ props.actionButton }}</c-button
                        >
                      </div>
                    </div>
                  </div>
                </slot>
              </template>
            </c-custom-card>
          </div>
        </section>
      </template>
    </frame-card>
  </component>
</template>

<script setup lang="ts">
  import FrameCard from "./FrameCard.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { ref } from "vue";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CSkeleton, CTag, CTypography } from "@charactr/wooper-ui/atoms";
  // @ts-ignore
  // prettier-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";

  const props = defineProps({
    routeName: {
      type: String,
      default: "",
    },
    routePath: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    imgAlt: {
      type: String,
      default: "",
    },
    extended: {
      type: Boolean,
      default: false,
    },
    expired: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    chip: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    actionButton: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    testId: {
      type: String,
      default: "",
    },
  });

  const emit = defineEmits(["runAction"]);

  const imageLoaded = ref(false);

  const loaded = () => {
    imageLoaded.value = true;
  };

  const runAction = () => {
    emit("runAction");
  };
</script>

<style scoped lang="scss">
.route-link {
  text-decoration: none;
}

.img {
  height: 86px;

  &--mobile {
    height: 80px;
  }
}

.gap-6 {
  gap: 24px;
}

.justify-between {
  justify-content: space-between;
}

.loader {
  background: transparent;
  border-radius: 7px;
  height: 212px;
  width: 266px;

  &--mobile {
    height: 183px;
    width: 180px;
  }

  &--extended {
    height: 212px;
    width: 464px;

    &--mobile {
      height: 183px;
      width: 350px;
    }
  }

  &--hidden {
    opacity: 0%;
  }
}

.feature {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  &__card {
    border-radius: 12px !important;
    box-shadow: 0 0 0 1px rgb(var(--v-theme-light-frame)), 0 0 0 2px transparent !important;
    height: 212px !important;
    width: 266px;

    &--extended {
      width: 464px;

      .img {
        height: 142px;
      }
    }

    &--mobile {
      height: 183px !important;
      width: 180px !important;

      &--extended {
        height: auto !important;
        width: 350 !important;
      }
    }

    &--expired {
      cursor: not-allowed;
      filter: grayscale(100%);
      opacity: 66%;

      * {
        pointer-events: none;
      }
    }

    &:not(&--expired):hover {
      box-shadow: 0 0 0 1px rgb(var(--v-theme-button-primary)),
        0 0 0 2px rgb(var(--v-theme-button-primary)) !important;
    }
  }

  &__container {
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
}

:deep(.v-skeleton-loader__bone) {
  background: rgb(var(--v-theme-player-bg));
  border-radius: 7px;
  height: 100%;
  margin-top: -52px;
}
</style>

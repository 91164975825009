<template>
  <circle-loader v-if="data.loading" title="Loading twin data" />
  <template v-else>
    <div v-if="twinData" class="d-flex justify-start mb-4 w-100">
      <c-button
        mode="highlight-plain"
        no-padding
        prepend-icon="ph:caret-left"
        size="x-small"
        @click="navigateToGallery"
      >
        Video Twin Studio
      </c-button>
    </div>
    <div
      :class="[
        'd-flex gap-32 twin-page-wrapper',
        { 'flex-column gap-16': isMobile },
        { 'justify-center': !twinData && !data.loading },
      ]"
    >
      <template v-if="twinData">
        <twin-profile-card
          v-if="twinData"
          v-model:delete-dialog="data.deleteDialog"
          v-model:edit-dialog="data.editDialog"
          :photo-img="
            isSyntheticPortrait(twinData) ? twinData.url : twinData.coverUrl
          "
          :twin-data="twinData"
          @delete="deletePicture"
          @edit="editName"
        >
          <template #bottom-action>
            <voice-chooser
              :hide-info-details="true"
              :variant="PLAYGROUND_FEATURES.VIDEO_CREATOR"
            />
          </template>
        </twin-profile-card>

        <div class="d-flex flex-column w-100">
          <router-view />
        </div>
      </template>
      <template v-else>
        <div class="align-center d-flex flex-column">
          <img src="/images/404.svg" />
          <div class="align-center d-flex flex-column gap-16 mt-4">
            <c-typography variant="h3">
              {{ `${isLipsyncModel ? "Twin" : "Synthetic"} not found` }}
            </c-typography>
            <c-typography color-class="copy-secondary" variant="body-1-400">
              {{
                `It looks like we can't find a ${
                  isLipsyncModel ? "twin" : "synthetic"
                } with id = ${twinId}.`
              }}
            </c-typography>
            <c-button class="mt-4" :href="Routes.AI_TWIN_STUDIO.path"
            >See available twins</c-button
            >
          </div>
        </div>
      </template>
    </div>
    <template v-if="!data.loadingVideos && twinData">
      <c-typography
        class="d-block mb-3 mt-4 text-start"
        color-class="copy-primary"
        variant="subtitle-1-600"
      >{{ twinData.name }} videos</c-typography
      >
      <div
        v-if="!drawerStore.twinRecordings.length"
        class="align-center d-flex gap-24 justify-center no-video-wrapper text-center"
      >
        <div class="d-flex flex-column">
          <c-typography
            class=""
            color-class="copy-secondary"
            variant="body-2-600"
          >Videos created with
            <c-typography
              class=""
              color-class="button-primary"
              variant="body-2-600"
            >{{ twinData?.name }}</c-typography
            >
            model will be displayed here</c-typography
          >
        </div>
        <img height="108" src="/images/spaceCat.svg" />
      </div>
    </template>
  </template>
  <div
    :class="[{ hidden: !drawerStore.twinRecordings.length || !twinData?.name }]"
  >
    <twin-recordings :recordings="drawerStore.twinRecordings" />
  </div>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import { Routes } from "@/core/routes/core.guard";
  import { storeToRefs } from "pinia";
  import TwinProfileCard from "@/core/components/TwinProfileCard.vue";
  import TwinRecordings from "../components/TwinRecordings.vue";
  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";
  import VideoCreatorVoiceSelection from "@/modules/videoCreator/components/voice/VideoCreatorVoiceSelection.vue";
  import VoiceChooser from "@/core/components/Voice/VoiceChooser.vue";
  import { VoiceType } from "../../../core/types/voice.types";
  import { computed, reactive, watch } from "vue";
  import {
    deleteUserPortrait,
    updateUserPortraitName,
  } from "@/core/services/synthetics.service";
  import { deleteLipsyncModel, updateLipsyncName } from "@/core/services/lipsync.service";
  import {
    ModelType,
    type SyntheticModel,
    type SyntheticPortrait,
  } from "@/core/types/synthetic.types";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CCheckbox, CIcon, CTooltip, CTypography } from "@charactr/wooper-ui/atoms";

  const data = reactive({
    error: false,
    loading: true,
    loadingVideos: true,
    voiceSelectionOpened: false,
    editDialog: false,
    deleteDialog: false,
    skipVoiceConversion: false,
  });

  const router = useRouter();
  const currRouteName = computed(() => router.currentRoute.value.name);
  const twinId = computed(() => router.currentRoute.value.params.id);

  const userStore = useUserStore();
  const videoCreatorStore = useVideoCreatorStore();
  const drawerStore = useDrawerStore();
  const { showSnackbar } = useSnackbarStore();
  const { listOfSyntheticModels, listOfGalleryPictures } = storeToRefs(userStore);
  const { updateModel, updatePicture } = videoCreatorStore;
  const voicesListStore = useVoicesListStore();
  const { clonedVoices, systemVoices } = storeToRefs(voicesListStore);
  const userVoices = useVoicesStore();
  const { updateVoice } = userVoices;

  const { userSelectedPicture, userSelectedModel } =
    storeToRefs(videoCreatorStore);
  const { replaceGalleryPicture, updateLipsyncModel } = userStore;

  const isLipsyncModel = computed(() => {
    return !(currRouteName.value as string).includes(
      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.name
    );
  });

  const isSyntheticPortrait = (
    img: SyntheticPortrait | SyntheticModel
  ): img is SyntheticPortrait => {
    return (img as SyntheticPortrait).url !== undefined;
  };

  const twinData = computed(() => {
    if (isLipsyncModel.value) {
      return listOfSyntheticModels.value.find(
        (el) => String(el.id) === String(twinId.value)
      );
    } else {
      return listOfGalleryPictures.value.find(
        (el) => String(el.id) === String(twinId.value)
      );
    }
  });

  const twinsListToWatch = computed(() => {
    if (isLipsyncModel.value) {
      return listOfSyntheticModels.value;
    } else {
      return listOfGalleryPictures.value;
    }
  });

  const loadVideos = async () => {
    await drawerStore.loadTwinVideos(
      twinId.value as string,
      isLipsyncModel.value
    );

    data.loadingVideos = false;
  };

  watch(
    () => twinsListToWatch.value,
    (newVal) => {
      if (newVal.length) {
        data.loading = false;
        if (isLipsyncModel.value) {
          updateModel(twinData.value as SyntheticModel);
        } else {
          updatePicture(
            twinData.value as SyntheticPortrait,
            PLAYGROUND_FEATURES.VIDEO_CREATOR
          );
        }
        if (twinData.value) {
          loadVideos();
        } else {
          data.error = true;
        }
      }
    },
    { immediate: true }
  );

  watch(
    () => [systemVoices.value, clonedVoices.value],
    (newVal) => {
      const [systemVoicesLength, clonedVoicesLength] = newVal;

      if (systemVoicesLength && clonedVoicesLength) {
        const voice =
          twinData.value?.voiceType === VoiceType.SYSTEM
            ? systemVoices.value.find((el) => el.id === twinData.value?.voiceId)
            : clonedVoices.value.find((el) => el.id === twinData.value?.voiceId);

        if (voice) {
          updateVoice(voice, "videoCreator");
        } else {
          updateVoice(undefined, "videoCreator");
        }
      }
    },
    {
      immediate: true,
    }
  );

  const editName = async (newName: string) => {
    try {
      if (
        twinData.value?.type === ModelType.ZEROSHOT ||
        twinData.value?.type === ModelType.NORMAL
      ) {
        const updatedModel = await updateLipsyncName(twinData.value!.id, newName);

        updateLipsyncModel(updatedModel);
      } else {
        const updatedPortrait = await updateUserPortraitName(
          twinData.value!.id,
          newName
        );

        replaceGalleryPicture(updatedPortrait);
      }

      showSnackbar(
        "The twin name has been successfully changed.",
        AlertModes.SUCCESS
      );
    } catch (e: any) {
      console.error(e);
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.editDialog = false;
    }
  };

  const deletePicture = async () => {
    try {
      if (
        twinData.value?.type === ModelType.ZEROSHOT ||
        twinData.value?.type === ModelType.NORMAL
      ) {
        await deleteLipsyncModel(twinData.value?.id as number);
        navigateToGallery();
        listOfSyntheticModels.value = listOfSyntheticModels.value.filter(
          (picture) => picture.id !== twinData.value?.id
        );
      } else {
        await deleteUserPortrait(twinData.value?.id as number);
        navigateToGallery();
        listOfGalleryPictures.value = listOfGalleryPictures.value.filter(
          (picture) => picture.id !== twinData.value?.id
        );
      }

      showSnackbar("The portrait has been deleted.", AlertModes.SUCCESS);
    } catch (e: any) {
      console.error(e);
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.deleteDialog = false;
    }
  };

  const navigateToGallery = () => {
    router.push({
      name: !isLipsyncModel.value
        ? Routes.AI_TWIN_STUDIO.children.SYNTHETICS.name
        : twinData.value?.type === ModelType.ZEROSHOT
          ? Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name
          : Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
    });
  };
</script>

<style lang="scss" scoped>
.gap-32 {
  gap: 32px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.no-video-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-dark-frame));
  border-radius: 12px;
  height: 196px;
}

.hidden {
  visibility: hidden;
}
</style>

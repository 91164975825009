<template>
  <div
    v-if="data.availableFilters.length"
    :class="[
      'align-center d-flex filter-wrapper mb-2',
      { 'filter-wrapper--scroll': filtersWithScroll },
      extraClass,
    ]"
  >
    <c-dropdown
      v-for="filter in data.availableFilters"
      :key="filter.name"
      v-model="data.selectedMenuFilters[filter.name]"
      class="select"
      :clearable="false"
      density="compact"
      :items="Array.from(filter.items)"
      :label="filter.label"
      :mobile="isMobile"
      mode="outlined"
      multiple
      return-object
      @update:model-value="updateselectedMenuFilters(filter.name)"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props">
          <template #prepend>
            <c-icon
              :class="[
                'mr-2',
                'check-icon',
                {
                  'check-icon--checked':
                    selectedFilters.map((el) => el.label).includes(item.raw) &&
                    selectedFilters
                      .map((el) => el.category)
                      .includes(filter.name),
                },
              ]"
              height="20"
              :icon="
                selectedFilters.map((el) => el.label).includes(item.raw) &&
                  selectedFilters.map((el) => el.category).includes(filter.name)
                  ? 'mdi:checkbox-marked'
                  : 'mdi:checkbox-blank-outline'
              "
            />
          </template>
          <template #title>
            <c-typography variant="body-2-400">
              {{ item?.raw }}
            </c-typography>
          </template>
        </v-list-item>
      </template>
    </c-dropdown>
  </div>
  <div
    v-if="selectedFilters.length"
    :class="['mb-2 pa-0 d-flex flex-wrap', { 'selected-filters': filtersWithScroll }]"
  >
    <c-button
      plain
      size="small"
      text-color="copy-tertiary"
      @click="clearAllFilters"
    >Clear</c-button
    >
    <div
      v-for="(item, idx) in selectedFilters"
      :key="item.category"
      class="align-center cursor-pointer d-flex justify-center mx-3 my-1 selected-filter"
      @click="removeFilter(item)"
      @mouseenter="hoveredItem = idx + item.label"
      @mouseleave="hoveredItem = ''"
    >
      <c-typography
        :color-class="
          hoveredItem === idx + item.label ? 'copy-primary' : 'copy-secondary'
        "
        pointer
        variant="body-3-400"
      >
        {{ item.label }}
      </c-typography>
      <c-icon class="ml-3 x-icon" height="20" icon="ph:x" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { createFilters } from "../utils";
  import { isMobile } from "@/core/utils/mobile";
  import { useUserStore } from "@/core/store/userStore";
  import { useWindowSize } from "@vueuse/core";
  import { analytics, VOICES } from "@/core/utils/analytics";
  import { computed, type PropType, reactive, ref, watch } from "vue";
  import type {
    FilterItem,
    Label,
    SelectedFilter,
    Voice,
  }  from "@/core/types/voice.types";

  // @ts-ignore
  import { CButton, CDropdown, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const properties = defineProps({
    voices: {
      type: Array as PropType<Voice[]>,
      default: () => [],
    },
    extraClass: {
      type: String,
      default: "",
    },
    twinStudio: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["updateFilters", "countFiltersSelected"]);

  const userStore = useUserStore();
  const { width } = useWindowSize();

  const hoveredItem = ref("");

  const data = reactive({
    availableFilters: [] as Array<FilterItem>,
    selectedMenuFilters: {} as SelectedFilter,
  });

  const filtersWithScroll = computed(() => width.value < 1200);

  const getFilters = (filterCategory: string, availableFilters = true) => {
    const availableFilter = data.availableFilters.find(
      (filter) => filter.name === filterCategory
    );

    if (!availableFilter || !userStore.ui_local_data.selectedMenuFilters[filterCategory]) {
      return [];
    }

    return userStore.ui_local_data.selectedMenuFilters[filterCategory].filter((filter: string) => availableFilters
      ? availableFilter.items.has(filter)
      : !availableFilter.items.has(filter)
    );
  };

  watch(
    () => properties.voices,
    (voices: Array<Voice>) => {
      data.availableFilters = createFilters(voices);
      if (!properties.twinStudio) {
        Object.keys(userStore.ui_local_data.selectedMenuFilters).forEach((filterCategory) => {
          data.selectedMenuFilters[filterCategory] = getFilters(filterCategory);
        });
      }
    },
    {
      immediate: true,
    }
  );

  const updateselectedMenuFilters = (filterCategory: string) => {
    userStore.ui_local_data.selectedMenuFilters[filterCategory] = getFilters(
      filterCategory,
      false
    ).concat(Object.values(data.selectedMenuFilters[filterCategory]));
    analytics.sendEvent("voices", VOICES.actions.FILTER_SELECTED);
  };

  const clearAllFilters = () => {
    Object.keys(userStore.ui_local_data.selectedMenuFilters).forEach((filterCategory) => {
      if (
        data.availableFilters.find((filter) => filter.name === filterCategory)
      ) {
        data.selectedMenuFilters[filterCategory] = [];
        userStore.ui_local_data.selectedMenuFilters[filterCategory] = getFilters(
          filterCategory,
          false
        );
      }
    });
    analytics.sendEvent("voices", VOICES.actions.CLEAR_FILTERS);
  };

  const removeFilter = (item: Label) => {
    userStore.ui_local_data.selectedMenuFilters[item.category] = userStore.ui_local_data.selectedMenuFilters[
      item.category
    ].filter((el: string) => el !== item.label);
    data.selectedMenuFilters[item.category] = data.selectedMenuFilters[
      item.category
    ].filter((el) => el !== item.label);
  };

  const filterVoices = (): Array<Voice> => {
    const voicesMatchFilters: Array<Voice> = [];
    const addedVoiceIds = new Set<number | string>();
    const selectedFilterCategories = Object.keys(data.selectedMenuFilters);

    properties.voices.forEach((voice) => {
      const { labels = [], id } = voice;
      let match = true;

      selectedFilterCategories.forEach((filterCategory) => {
        const categoryFilters = getFilters(filterCategory);

        const categoryLabels = labels.filter(
          (label) => label.category === filterCategory
        );

        const labelMatches =
          categoryLabels.some((label) => categoryFilters.includes(label.label)) ||
          !categoryFilters.length;

        if (!labelMatches) {
          match = false;
        }
      });

      if (match && !addedVoiceIds.has(id)) {
        voicesMatchFilters.push(voice);
        addedVoiceIds.add(id);
      }
    });

    return voicesMatchFilters;
  };

  const selectedFilters = computed(() => {
    let allFilters: Array<Label> = [];

    Object.keys(userStore.ui_local_data.selectedMenuFilters).forEach((filterCategory) => {
      if (!data.selectedMenuFilters[filterCategory as keyof SelectedFilter]) {
        return;
      }

      const filters = getFilters(filterCategory);

      allFilters = [
        ...allFilters,
        ...filters.map((filter: SelectedFilter) => {
          return { label: filter, category: filterCategory };
        }),
      ];
    });

    emit("updateFilters", filterVoices());
    emit("countFiltersSelected", allFilters.length);

    return allFilters;
  });

  watch(
    () => selectedFilters.value.length,
    (newVal) => {
      emit("countFiltersSelected", newVal);
    }
  );
</script>

<style scoped lang="scss">
  .selected {
    font-size: 10px !important;
  }

  :deep(.v-field-label) {
    color: rgb(var(--v-theme-copy-primary));
    font-size: 12px;
    top: 12px;
  }

  :deep(.v-input__details) {
    display: none;
  }

  .filter-wrapper {
    gap: 8px;

    &--scroll {
      overflow: scroll;
    }
  }

  .filter-wrapper::-webkit-scrollbar {
    display: none;
  }

  .selected-filters {
    overflow: scroll;
    white-space: nowrap;
  }

  .selected-filters::-webkit-scrollbar {
    display: none;
  }

  .select {
    min-width: 130px;
  }

  .check-icon {
    color: rgb(var(--v-theme-universal-gray)) !important;

    &--checked {
      color: rgb(var(--v-theme-button-primary)) !important;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .selected-filter {
    svg {
      color: rgb(var(--v-theme-copy-tertiary)) !important;
    }

    &:hover {
      svg {
        color: rgb(var(--v-theme-button-primary)) !important;
      }
    }
  }

  .filters {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    width: 414px;
  }
</style>

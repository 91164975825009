<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <c-dialog
    :cancel-option="extended"
    :confirm-option="extended"
    min-width="300"
    :model-value="modelValue"
    variant="secondary"
    width="auto"
    @cancel="$emit('update:modelValue', false)"
    @confirm="emit('confirm')"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>
      {{ avatarName }}
    </template>
    <template #content>
      <slot name="description" />
      <div v-show="!isLoading" :key="reloadKey" class="align-center d-flex">
        <c-button
          v-if="showArrows"
          class="pr-2"
          data-testid="recent_videos_prev"
          height="24"
          icon="ph:arrow-left"
          icon-color="rgb(var(--v-theme-copy-secondary))"
          icon-height="24"
          plain
          size="x-small"
          width="24"
          @click="prev"
        ></c-button>
        <div class="position-relative wrapper">
          <img
            :class="['avatar-preview', { 'avatar-preview--mobile': isMobile }]"
            :src="url"
            @load="handleImageLoad"
          />
          <c-skeleton v-if="isLoading" :class="['loader']" type="image">
          </c-skeleton>
        </div>
        <c-button
          v-if="showArrows"
          data-testid="recent_videos_next"
          height="24"
          icon="ph:arrow-right"
          icon-color="rgb(var(--v-theme-copy-secondary))"
          icon-height="24"
          plain
          size="x-small"
          width="24"
          @click="next"
        ></c-button>
      </div>
      <slot name="buttons" />
    </template>
    <template #confirm-label>
      {{ props.extended ? "Create Video" : "" }}
    </template>
    <template #cancel-label>{{ props.cancelLabel }}</template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { onMounted, onUnmounted, ref, watch } from "vue";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";

  // @ts-ignore
  import { CButton, CSkeleton } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    extended: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: "back",
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits([
    "update:modelValue",
    "confirm",
    "showPrev",
    "showNext",
  ]);

  const isLoading = ref(true);
  const reloadKey = ref(0);

  watch(
    () => props.modelValue,
    (val) => {
      if (val) {
        isLoading.value = true;
      }
    }
  );

  watch(
    () => props.url,
    () => {
      isLoading.value = true;
      reloadKey.value++;
    }
  );

  const handleImageLoad = () => {
    isLoading.value = false;
  };

  const prev = () => {
    emit("showPrev");
  };

  const next = () => {
    emit("showNext");
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      prev();
    } else if (event.key === "ArrowRight") {
      next();
    }
  };

  onMounted(() => {
    if (props.showArrows) {
      window.addEventListener("keydown", handleKeyDown);
    }
  });

  onUnmounted(() => {
    if (props.showArrows) {
      window.removeEventListener("keydown", handleKeyDown);
    }
  });
</script>

<style lang="scss" scoped>
.avatar-preview {
  border-radius: 16px;
  max-height: 100%;
  max-width: 600px;
  object-fit: contain;

  &--mobile {
    max-width: 100%;
  }
}

.wrapper {
  height: 600px;
  width: 600px;
}

.loader {
  border-radius: 16px;
  height: 600px;
  opacity: 85%;
  position: absolute;
  top: 0;
  width: 600px;
  z-index: 1;

  :deep(.v-skeleton-loader__bone) {
    border-radius: 16px;
    height: 600px;
    width: 600px;
  }
}
</style>

<template>
  <div
    v-if="!props.mobile"
    class="align-center d-flex justify-center ma-0 pa-0 w-100"
  >
    <c-button v-if="!hideDetails" class="ma-0 pa-0" plain size="small" @click="openInfoModal">
      <div class="align-center d-flex justify-center">
        <c-typography pointer variant="subtitle-2-600">
          {{ headerTitle }}
        </c-typography>
        <c-typography v-if="headerExtra" class="ml-2" pointer variant="subtitle-2-400">
          {{ headerExtra }}
        </c-typography>
        <c-icon v-if="!props.miniTitle" class="ml-2" height="25" icon="ph:info" />
      </div>
    </c-button>
  </div>

  <div v-else-if="!hideDetails" :class="['align-center d-flex pt-3 w-100', hideDetails ? 'justify-end': 'justify-space-between']">
    <div>
      <div
        class="align-center d-flex justify-center pl-4"
        @click="clickBackBtn"
      >
        <c-icon
          class="d-flex"
          color="rgba(var(--v-theme-copy-primary))"
          height="16"
          icon="ph:caret-left"
        />
      </div>
    </div>
    <div v-if="!hideDetails" class="align-center d-flex justify-center w-100">
      <c-button class="ma-0 pa-0" plain size="small" @click="openInfoModal">
        <div class="align-center d-flex justify-center">
          <c-typography pointer variant="subtitle-2-600">
            {{ headerTitle }}
          </c-typography>
          <c-typography class="ml-2" pointer variant="subtitle-2-400">
            {{ headerExtra }}
          </c-typography>
          <c-icon v-if="!props.miniTitle" class="ml-2" height="25" icon="ph:info" />
        </div>
      </c-button>
    </div>

    <div>
      <a
        v-if="!props.miniTitle"
        :class="[
          'special-button',
          { 'special-button--opened': props.openRecordings },
        ]"
        @click="openRightPanelTab(RightPanelTabs.RECORDINGS)"
      >
        <div class="icon">
          <c-icon :icon="props.variant.name === PLAYGROUND_FEATURES.VOICE_CLONING.name ? 'mdi:sheep' : 'ph:list-plus'" width="22"></c-icon>
        </div>

        <span class="special-text"
        ><c-typography color-class="cta" variant="body-1-600"
        >Recordings</c-typography
        ></span
        >
      </a>
    </div>
  </div>

  <info-dialog
    v-if="infoModal"
    v-model="infoModal"
    :confirm-label="infoDialogConfirmLabel"
    :description="infoDialogDescription"
    :title="infoDialogTitle"
    @confirm="runConfirmAction"
  />
</template>

<script lang="ts" setup>
  import InfoDialog from "@/core/components/Dialogs/InfoDialog.vue";
  import { RightPanelTabs } from "@/core/types/other.types";
  import { useRouter } from "vue-router";
  import { computed, type PropType, ref, toRefs } from "vue";
  import { PLAYGROUND_FEATURES, type PlaygroundFeature } from "@/core/data/playgroundFeatures";

  // @ts-ignore
  import { CButton, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  import { isMobile } from "@/core/utils/mobile";

  import { storeToRefs } from "pinia";
  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";
  import { useDialogsStore } from "@/core/store/useDialogsStore";

  const props = defineProps({
    variant: {
      type: Object as PropType<PlaygroundFeature>,
      default: () => ({}),
    },
    openRecordings: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    miniTitle: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  });

  //STORE
  const appDrawersState = useAppDrawersStateStore();
  const { openRightPanelTab } = appDrawersState;
  const dialogStore = useDialogsStore();
  const { videoCreatorMobileGuide } = storeToRefs(dialogStore);

  const router = useRouter();

  const infoModal = ref(false);

  const infoDialogConfirmLabel = computed(() => props.variant.dialog.confirmLabel || "OK");

  const infoDialogTitle = computed(() =>  props.variant.dialog.title);

  const infoDialogDescription = computed(
    () => props.variant.dialog.description
  );

  const headerTitle = computed(() => props.variant.label);
  const headerExtra = computed(() => props.variant.dialog.labelExtra);

  const clickBackBtn = () => {
    router.go(-1);
  };

  const runConfirmAction = () => {
    if (props.variant === PLAYGROUND_FEATURES.VIDEO_CREATOR && isMobile.value) {
      videoCreatorMobileGuide.value = true;
    }
  };

  const openInfoModal = () => {
    if (!props.miniTitle) {
      infoModal.value = true;
    }
  };
</script>

<style scoped lang="scss">
.special-button {
  align-items: center;
  background-color: rgba(var(--v-theme-highlight-color));
  border-radius: 22px 0 0 22px;
  color: white;
  display: inline-flex;
  height: 44px;
  max-width: 54px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  overflow: hidden;
  text-decoration: none;
  transition: max-width 1s;
  width: auto;

  &--opened {
    max-width: 300px;
  }
}

.special-text {
  padding-right: 15px;
  white-space: nowrap;
}

.parent {
  flex-direction: column;
  height: 44px;

  &-container {
    justify-content: space-between;
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  padding: 0 0 0 16px;
}
</style>

import { apiUrl, apiV3Url } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";
import type {
  PhotoTwinModel,
  PhotoTwinPhoto,
} from "@/core/types/synthetic.types";

export async function createNewPhotoTwinModel(payload: {
  files: Array<Blob>;
  name: string;
  gender: string;
}): Promise<PhotoTwinModel> {
  const clientId = tokenClientId();
  const formData = new FormData();

  const url = apiUrl(`clients/${clientId}/photo-twin-models/train`);

  formData.append("name", payload.name);
  formData.append("gender", payload.gender);
  payload.files.forEach((file) => {
    formData.append("files[]", file);
  });

  const response = await coreClient.post(url, formData);

  return response.data;
}

export async function deletePhotoTwinModel(modelId: number): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/photo-twin-models/${modelId}`);
  const response = await coreClient.delete(url);

  return response.data;
}

export async function updatePhotoTwinModelName(
  modelId: number,
  newName: string
): Promise<PhotoTwinModel> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/photo-twin-models/${modelId}`);

  const response = await coreClient.patch(url, {
    name: newName,
  });

  return response.data;
}

export async function updatePhotoTwinModelCover(
  modelId: number,
  coverId: number
): Promise<PhotoTwinModel> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/photo-twin-models/${modelId}`);

  const response = await coreClient.patch(url, {
    coverPhotoId: coverId,
  });

  return response.data;
}

export async function getAllPhotoTwinModels(): Promise<Array<PhotoTwinModel>> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/photo-twin-models?limit=500`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function getPhotoTwinModelData(
  modelId: number
): Promise<PhotoTwinModel> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/photo-twin-models/${modelId}`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function getPhotoTwinPhotos(
  modelId: number
): Promise<Array<PhotoTwinPhoto>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/photo-twin-models/${modelId}/photos`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function getPromptGalleryItems(): Promise<Array<PhotoTwinPhoto>> {
  const clientId = tokenClientId();

  // const url = apiUrl(`clients/${clientId}/photo-twin-models/prompts`);
  const url = apiV3Url("/prompts");

  const response = await coreClient.get(url);

  return response.data;
}

export async function createNewPhotoTwinPhotos(payload: {
  modelId: number;
  prompt: string;
  numPhotos: number;
}): Promise<PhotoTwinPhoto> {
  const clientId = tokenClientId();

  const url = apiUrl(
    `clients/${clientId}/photo-twin-models/${payload.modelId}/generate-photos`
  );

  const response = await coreClient.post(url, {
    prompt: payload.prompt,
    numPhotos: payload.numPhotos,
  });

  return response.data;
}

export async function getPhotoTwinPhotoData(
  twinId: number
): Promise<PhotoTwinPhoto> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/photo-twin-photos/${twinId}`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function deletePhotoTwinPhoto(
  twinId: number
): Promise<PhotoTwinPhoto> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/photo-twin-photos/${twinId}`);

  const response = await coreClient.delete(url);

  return response.data;
}

export async function updatePhotoTwinPhotoName(
  twinId: number,
  newName: string
): Promise<PhotoTwinPhoto> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/photo-twin-photos/${twinId}`);

  const response = await coreClient.patch(url, {
    name: newName,
  });

  return response.data;
}

import type { AppFeatures } from "@/core/types/other.types";
import { RECORDING_TABS } from "@/core/types/recording.types";
import { Routes } from "@/core/routes/core.guard";

import { isMobile } from "@/core/utils/mobile";
import { useUserStore } from "@/core/store/userStore";

import { computed } from "vue";
export interface Item {
  label: string;
  id?: string;
  url?: string;
  redirect?: string;
  alt?: string;
  icon?: string;
  rightIcon?: string;
  mobileOnly?: boolean;
  expanded?: boolean;
  show?: boolean;
  footer?: boolean;
  disabled?: boolean;
  info?: boolean;
  logout?: boolean;
  childItems?: Array<Item>;
  special?: boolean;
  rightPaddingDisabled?: boolean;
  action?(): void;
}

export interface MenuItem {
  category: string;
  items: Item[];
}

const homeCategory: MenuItem = {
  category: "",
  items: [
    {
      id: Routes.HOME.name,
      label: "Home",
      show: true,
      alt: "Go to homepage",
      url: Routes.HOME.path,
      icon: "ph:house-simple-fill",
    },
  ],
};

function addVideoStudio(show: boolean): MenuItem {
  return {
    category: "Video Twin Studio",
    items: [
      {
        id: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
        label: "Express Twins",
        show: show,
        alt: "Go to Express Twins",
        url: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.path,
        icon: "mdi:fast-forward",
      },
      {
        id: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
        label: "High Quality Twins",
        show: show,
        alt: "Go to High Quality Twins",
        url: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.path,
        icon: "ph:diamonds-four-fill",
      },
      {
        id: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.name,
        label: "Image-based twins",
        show: show,
        alt: "Go to Image based twins",
        url: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.path,
        icon: "ph:user-square-fill",
      },
      {
        id: Routes.RECORDINGS_HISTORY.children.VIDEO.name,
        alt: "Show video creations",
        show: true,
        label: "Video Creations",
        icon: "material-symbols:video-library-outline-rounded",
        url: Routes.RECORDINGS_HISTORY.children.VIDEO.path,
      },
    ],
  };
}

function addPhotoStudio(show: boolean): MenuItem {
  const modelsGalleryUrl = "/photo-twin-studio/models-gallery";

  return {
    category: "Photo Twin Studio",
    items: [
      {
        id: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
        label: "Photo Twin Gallery",
        show: show,
        alt: "Go to Twin Gallery",
        url: modelsGalleryUrl,
        icon: "ph:camera-fill",
      },
      {
        id: Routes.PHOTO_TWIN_STUDIO.children.PROMPT_GALLERY.name,
        label: "Prompt Gallery",
        show: show,
        special: true,
        alt: "Go to Photo Prompt gallery",
        url: Routes.PHOTO_TWIN_STUDIO.children.PROMPT_GALLERY.path,
        icon: "ph:note-pencil-fill",
      },
    ],
  };
}

function addAiTools(show: boolean): MenuItem {
  return {
    category: "Audio Studio",
    items: [
      {
        id: Routes.TEXT_TO_SPEECH.name,
        label: "Text To Speech",
        show: true,
        alt: "",
        icon: "ph:text-t",
        url: Routes.TEXT_TO_SPEECH.path,
      },
      {
        id: Routes.SPEECH_TO_SPEECH.name,
        label: "Speech to Speech",
        show: true,
        alt: "",
        icon: "ph:microphone-fill",
        url: Routes.SPEECH_TO_SPEECH.path,
      },
      {
        id: Routes.VOICES.children.SYSTEM.name,
        show: true,
        label: "Voice Libraries",
        alt: "Go to voices",
        icon: "ph:microphone-stage-fill",
        url: Routes.VOICES.children.SYSTEM.path,
      },
      {
        id: Routes.VOICE_CLONING.name,
        label: "Voice Cloning",
        show: true,
        alt: "",
        icon: "mdi:sheep",
        url: Routes.VOICE_CLONING.path,
      },
      {
        id: Routes.RECORDINGS_HISTORY.children.AUDIO.name,
        alt: "Show audio creations",
        show: true,
        label: "Audio Creations",
        icon: "ph:radio-fill",
        url: Routes.RECORDINGS_HISTORY.children.AUDIO.path,
      },
    ],
  };
}

const forDevelopersCategory = {
  category: "For developers",
  items: [
    {
      id: Routes.APIKEYS.name,
      label: "API keys",
      alt: "Go to API keys",
      show: true,
      url: Routes.APIKEYS.path,
      icon: "ph:key-fill",
    },
    {
      id: "Documentation",
      label: "API Documentation",
      rightPaddingDisabled: true,
      show: true,
      alt: "",
      icon: "ph:file-code-fill",
      rightIcon: "ph:arrow-square-out",
      redirect: "https://docs.api.gemelo.ai/reference/about",
    },
    {
      id: "Github",
      label: "Github",
      show: true,
      alt: "",
      icon: "ph:github-logo-fill",
      rightIcon: "ph:arrow-square-out",
      redirect: "https://github.com/charactr-platform",
    },
  ],
};

export function buildMenuItems(features: AppFeatures): Array<MenuItem> {
  const aiTools = addAiTools(features.portraitStudio);

  const photoStudioItems = addPhotoStudio(features.portraitStudio);

  const videoStudioItems = addVideoStudio(features.portraitStudio);

  return [
    homeCategory,
    photoStudioItems,
    videoStudioItems,
    aiTools,
    forDevelopersCategory,
  ];
}

<template>
  <c-tabs :mobile="isMobile" :tabs="tabs" @update-route="changeTab" />

  <div class="align-center d-flex justify-center my-2 w-100">
    <c-input
      v-model="data.search"
      density="compact"
      mode="search"
      @click.stop
      @focus="searchFocus"
    />
    <c-button
      class="ml-2"
      :disabled="areFiltersDisabled"
      :icon="
        showFilters && !areFiltersDisabled ? 'ph:sliders-fill' : 'ph:sliders'
      "
      :icon-color="
        areFiltersDisabled
          ? 'rgb(var(--v-theme-copy-secondary-lock))'
          : 'rgb(var(--v-theme-copy-primary))'
      "
      mode="outline"
      :open="showFilters && !areFiltersDisabled"
      @click="showFilters = !showFilters"
    ></c-button>
  </div>
  <div class="align-end d-flex">
    <div
      v-show="showFilters && !areFiltersDisabled"
      ref="filtersWrapper"
      :class="[
        'filters-wrapper',
        { 'filters-wrapper--collapsed': !showFilters && !isMobile },
        { 'mb-2': filtersCounter },
      ]"
      :style="{ maxHeight: !isMobile ? maxHeight + 'px' : 'auto' }"
    >
      <voice-filters
        :extra-class="filterClass"
        :voices="currentTabVoices"
        @count-filters-selected="updateFiltersCounter"
        @update-filters="updateListWithFilters"
      />
    </div>
  </div>
  <template v-if="currentTabVoices.length && !data.isLoading">
    <c-custom-card
      :class="[
        [isMobile ? 'voices-card--mobile' : 'voices-card--desktop flex-grow'],
      ]"
      color="player-bg"
      draggable
      type="primary"
    >
      <template #content>
        <slot name="prepend" />
        <div
          v-if="data.filteredVoices.length"
          ref="voicesWrapper"
          :class="[
            'voices-wrapper',
            {
              'voices-wrapper--scroll': data.filteredVoices.length,
            },
            { 'voices-wrapper--mobile': isMobile },
          ]"
        >
          <div class="w-100">
            <v-virtual-scroll
              ref="scroller"
              :height="getHeight"
              :item-height="50"
              :items="voicesToDisplay"
            >
              <template #default="{ item: voice, index: idx }">
                <c-feature-select
                  v-if="
                    idx === 0 &&
                      props.mode === PLAYGROUND_FEATURES.VTV.name &&
                      data.activeTab === 'all'
                  "
                  :border-radius="12"
                  :class="[
                    'extra-container',
                    {
                      'extra-container--not-checked': !isChecked(
                        noVoiceConversionOption.id
                      ),
                    },
                    {
                      'extra-container--checked': isChecked(
                        noVoiceConversionOption.id
                      ),
                    },
                  ]"
                  name="No Voice Conversion"
                  no-icon
                  wrapper-class="py-3 pl-2 pr-3"
                  @click="checkVoice(noVoiceConversionOption)"
                >
                  <template #img>
                    <microphone-img />
                  </template>
                  <template #prepend>
                    <c-button
                      disabled
                      icon="ph:prohibit"
                      icon-height="20"
                      mode="default"
                      size="small"
                    />
                  </template>
                  <template #content>
                    <c-typography
                      color-class="copy-secondary"
                      variant="body-3-400"
                    >
                      Keep orginal audio
                    </c-typography>
                  </template>
                  <template #append>
                    <c-checkbox-button
                      :checked="isChecked(noVoiceConversionOption.id)"
                      radio
                    />
                  </template>
                </c-feature-select>
                <voice-details
                  :key="componentKey + voice.id"
                  :available="Boolean(!voice.disabled)"
                  :border-bottom="
                    idx > voicesCount - 1
                      ? idx !== voicesToDisplay.length - 1
                      : true
                  "
                  :checked="isChecked(voice.id)"
                  :cloned="voice.cloned"
                  :hide-cloned-actions="
                    currMode !== PLAYGROUND_FEATURES.VOICE_CLONING.name ||
                      (isMobile ? detailsMode === MODES.MAIN_LIST : false)
                  "
                  :mode="detailsMode"
                  :send-mounted="idx === 0"
                  :voice="voice"
                  :wrapper-class="wrapperClass"
                  @choose-voice="checkVoice(voice)"
                >
                  <template #append>
                    <slot :idx="idx" name="append" :voice="voice">
                      <div class="align-center d-flex justify-center">
                        <rating-star
                          v-if="voice.rating"
                          :id="voice.id"
                          :class="[isMobile ? 'mr-2' : 'mr-4']"
                          clear-margin
                          :rating="voice.rating"
                        />
                        <div
                          v-if="detailsMode === MODES.MAIN_LIST"
                          :class="[
                            'lock-icon d-flex mr-3',
                            { 'lock-icon--hidden': !isVoiceDisabled(voice) },
                          ]"
                        >
                          <lock
                            v-if="!voice.cloned && isVoiceDisabled(voice)"
                          />
                        </div>
                        <lock
                          v-else-if="
                            detailsMode === MODES.PANEL_LIST &&
                              isVoiceDisabled(voice)
                          "
                        />
                        <slot
                          v-if="!isVoiceDisabled(voice)"
                          name="action"
                          :voice="voice"
                        >
                          <c-checkbox-button
                            v-if="props.detailsMode === MODES.PANEL_LIST"
                            :checked="isChecked(voice.id)"
                            radio
                            @click="checkVoice(voice)"
                          />
                        </slot>
                      </div> </slot
                    ></template>
                </voice-details>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
      </template>
    </c-custom-card>
  </template>
  <empty-list
    v-if="
      !data.isLoading &&
        !data.filteredVoices.length &&
        (filtersCounter || data.search)
    "
  />

  <empty-list
    v-if="
      !data.isLoading &&
        !data.filteredVoices.length &&
        !data.search &&
        !filtersCounter
    "
    message="The voices list is empty."
  />

  <circle-loader v-if="data.isLoading" height="380" title="Loading voices" />
</template>

<script setup lang="ts">
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import EmptyList from "@/core/components/Info/EmptyList.vue";
  import { isMobile } from "@/core/utils/mobile";
  import Lock from "@/core/components/Voice/Lock.vue";
  import MicrophoneImg from "@/core/components/Voice/MicrophoneImg.vue";
  import { MODES } from "./voice.types";
  import { noVoiceConversionOption } from "@/core/types/voice.types";
  import RatingStar from "@/modules/voicesPlayground/components/RatingStar.vue";
  import { sortByPropertyName } from "@/core/utils/sorting";
  import { useRouter } from "vue-router";
  import VoiceDetails from "@/core/components/Voice/VoiceDetails.vue";
  import VoiceFilters from "@/modules/voicesPlayground/components/VoiceFilters.vue";

  import { useUserStore } from "@/core/store/userStore";
  import { useWindowSize } from "@vueuse/core";
  import { analytics, VOICES } from "@/core/utils/analytics";
  import {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    type PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import { type Voice, VoiceTabs } from "@/core/types/voice.types";
  import {
    PLAYGROUND_FEATURES,
    type PlaygroundFeature,
  } from "@/core/data/playgroundFeatures";

  // @ts-ignore
  // prettier-ignore
  import { CCustomCard, CFeatureSelect, CTabs } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  // prettier-ignore
  import { CButton,  CCheckboxButton, CInput,  CTypography } from "@charactr/wooper-ui/atoms";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";

  //PROPS & EMITS
  const props = defineProps({
    selectedVoiceId: {
      type: [Number, String],
      default: 0,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: "",
    },
    detailsMode: {
      type: String as PropType<MODES>,
      default: MODES.PANEL_LIST,
    },
  });

  const emit = defineEmits(["update:modelValue", "selectVoice", "checkVoice"]);

  //STORE
  const userStore = useUserStore();
  const voicesListStore = useVoicesListStore();
  const { fetchClonedVoices, fetchSystemVoices } = voicesListStore;
  const { clonedVoices, systemVoices } = toRefs(voicesListStore);

  const router = useRouter();
  const { height } = useWindowSize();

  const showFilters = ref(false);
  const maxHeight = ref(56);
  const filtersWrapper = ref<HTMLElement | null>(null);
  const voicesWrapper = ref<HTMLElement | null>(null);
  const voicesContainerHeight = ref(0);
  const filtersCounter = ref(0);
  const componentKey = ref(0);

  const data = reactive({
    activeTab:
      props.detailsMode === MODES.MAIN_LIST ? VoiceTabs.SYSTEM : VoiceTabs.ALL,
    isLoading: false,
    search: "",
    filteredVoices: [] as Array<Voice>,
  });

  onMounted(async () => {
    await getVoices();

    const el = document.querySelector(".voice__container");

    if (el) {
      voicesContainerHeight.value = (
        el as Element
      ).getBoundingClientRect().height;
    }

    window.addEventListener("resize", handleResize);
    handleResize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
  });

  const voiceItemHeight = 69;

  const getHeight = computed(() => {
    if (props.detailsMode === MODES.MAIN_LIST) {
      //add "One extra voiceItemHeight due to No Voice Conversion option"
      const multipleRowsHeight =
        voiceItemHeight + voiceItemHeight * voicesToDisplay.value.length;

      return multipleRowsHeight < 2000 ? multipleRowsHeight : 2000;
    }
    return 500;
  });

  const voicesCount = computed(() => {
    let containerHeight = 0;

    if (voicesContainerHeight.value && voicesWrapper.value) {
      containerHeight = (
        voicesWrapper.value as HTMLElement
      ).getBoundingClientRect().height;
    }

    const voiceHeight = voicesContainerHeight.value;

    const count = Math.floor(containerHeight / voiceHeight);

    return count;
  });

  const filterClass = ref("");

  const currMode = computed(() => {
    return router.currentRoute.value.meta.mode;
  });

  const handleResize = () => {
    filterClass.value =
      props.detailsMode === MODES.PANEL_LIST && window.innerWidth < 1280
        ? "filters"
        : "";
  };

  const currentVoicesList = computed(() => systemVoices.value);

  const currentTabVoices = computed(() => {
    if (props.detailsMode === MODES.MAIN_LIST) {
      if (currMode.value === PLAYGROUND_FEATURES.VOICE_CLONING.name) {
        return getFilteredVoices(clonedVoices.value, true);
      }
      return getFilteredVoices(systemVoices.value);
    }
    if (data.activeTab === VoiceTabs.ALL) {
      return [
        ...getFilteredVoices(clonedVoices.value, true),
        ...getFilteredVoices(currentVoicesList.value),
      ];
    } else if (data.activeTab === VoiceTabs.SYSTEM) {
      return getFilteredVoices(currentVoicesList.value);
    } else {
      return getFilteredVoices(clonedVoices.value, true);
    }
  });

  const voicesHeight = computed(() => {
    // if (props.mode !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name) {
    //   if (height.value < 650) {
    //     return "500px";
    //   }
    //   return showFilters.value ? "calc(100vh - 621px)" : "calc(100vh - 556px)";
    // }

    if (props.detailsMode === MODES.PANEL_LIST) {
      return "380px";
    } else {
      return showFilters.value ? "calc(100vh - 288px)" : "calc(100vh - 240px)";
    }
  });

  const voicesToDisplay = computed(() => data.filteredVoices);

  const areFiltersDisabled = computed(
    () => data.activeTab === VoiceTabs.CLONED ||
      currMode.value === PLAYGROUND_FEATURES.VOICE_CLONING.name
  );

  const tabs = computed(() => {
    // if (props.mode !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name) {
    //   return [
    //     {
    //       label: "Gemelo Voices",
    //       active: data.activeTab === VoiceTabs.SYSTEM,
    //       href: VoiceTabs.SYSTEM,
    //     },
    //     {
    //       label: isMobile.value ? "Cloned" : "Cloned Voices",
    //       href: VoiceTabs.CLONED,
    //       active: data.activeTab === VoiceTabs.CLONED,
    //     },
    //   ];
    // }

    if (props.detailsMode !== MODES.MAIN_LIST) {
      return [
        {
          label: "All",
          active: data.activeTab === VoiceTabs.ALL,
          href: VoiceTabs.ALL,
        },
        {
          label: "Gemelo Voices",
          active: data.activeTab === VoiceTabs.SYSTEM,
          href: VoiceTabs.SYSTEM,
        },
        {
          label: "Cloned voices",
          active: data.activeTab === VoiceTabs.CLONED,
          href: VoiceTabs.CLONED,
        },
      ];
    } else {
      return [
        {
          label: isMobile.value ? "Gemelo" : "Gemelo Voices",
          active: currMode.value === VoiceTabs.SYSTEM,
          href: VoiceTabs.SYSTEM,
        },
        {
          label: isMobile.value ? "Cloned" : "Cloned voices",
          active:
            currMode.value === VoiceTabs.CLONED ||
            currMode.value === PLAYGROUND_FEATURES.VOICE_CLONING.name,
          href: VoiceTabs.CLONED,
        },
      ];
    }
  });

  watch(
    () => showFilters.value,
    (newVal) => {
      if (newVal) {
        updateHeight();
      }
    }
  );

  watch(
    () => data.activeTab,
    async () => {
      componentKey.value++;
      if (props.detailsMode !== MODES.MAIN_LIST) {
        await getVoices();
      }
    }
  );

  watch(
    () => currMode.value,
    async (val: any) => {
      if (val) {
        if (props.detailsMode === MODES.MAIN_LIST) {
          await getVoices();
        }
      }
    }
  );

  const updateListWithFilters = (filteredVoices: Array<Voice>) => {
    data.filteredVoices = filteredVoices;
  };

  const getVoices = async () => {
    const fetchVoicesWithFunction = async (
      voiceArray: Array<Voice>,
      fetchFunction: () => void
    ) => {
      if (!voiceArray.length) {
        data.isLoading = true;
        await fetchFunction();
        data.isLoading = false;
      }
    };

    if (data.activeTab !== VoiceTabs.SYSTEM) {
      await fetchVoicesWithFunction(clonedVoices.value, fetchClonedVoices);
    } else {
      await fetchVoicesWithFunction(systemVoices.value, fetchSystemVoices);
    }
  };

  const updateHeight = () => {
    nextTick(() => {
      if ((filtersWrapper.value as HTMLElement)?.scrollHeight !== 0) {
        maxHeight.value = (filtersWrapper.value as HTMLElement)?.scrollHeight + 8;
      }
    });
  };

  const updateFiltersCounter = (val: number) => {
    const beforeVal = filtersCounter.value;

    filtersCounter.value = val;
    if (val > beforeVal) {
      analytics.sendEvent("voices", VOICES.actions.FILTER_SELECTED);
      showFilters.value = true;
      updateHeight();
    }
  };

  const getFilteredVoices = (voiceList: Voice[], cloned = false) => {
    let filteredVoices = voiceList;
    const enabledVoices = [];
    const newVoices = [];
    const otherVoices = [];

    if (data.search) {
      const searchTerms = data.search.toLowerCase().split(",");

      for (const index in searchTerms) {
        const searchTerm = searchTerms[index].trim();

        filteredVoices = filteredVoices.filter(({ name, labels = [] }) => {
          const tags = labels.map((el) => el.label);

          return [name, tags.join(", "), ...tags].some((el) => el.trim().toLowerCase().includes(searchTerm)
          );
        });
      }
    }

    for (const voice of filteredVoices) {
      if (voice.new === true && userStore.subscriptionOption) {
        newVoices.push(voice);
      } else if (voice.disabled !== true) {
        enabledVoices.push(voice);
      } else if (voice.new === true) {
        newVoices.push(voice);
      } else {
        otherVoices.push(voice);
      }
    }

    const enabledVoicesSortedByName = sortByPropertyName(
      enabledVoices,
      "name",
      "asc"
    );

    const enabledVoicesSorted = sortByPropertyName(
      enabledVoicesSortedByName,
      cloned ? "createdAt" : "rating",
      "desc"
    );

    const newVoicesSortedByName = sortByPropertyName(newVoices, "name", "asc");

    const newVoicesSorted = sortByPropertyName(
      newVoicesSortedByName,
      cloned ? "createdAt" : "rating",
      "desc"
    );

    const otherVoicesSortedByName = sortByPropertyName(
      otherVoices,
      "name",
      "asc"
    );

    const otherVoicesSorted = sortByPropertyName(
      otherVoicesSortedByName,
      cloned ? "createdAt" : "rating",
      "desc"
    );

    const premiumVoices = [...newVoicesSorted, ...enabledVoicesSorted];

    const freeVoices = [
      ...enabledVoicesSorted,
      ...newVoicesSorted,
      ...otherVoicesSorted,
    ];

    return userStore.subscriptionOption ? premiumVoices : freeVoices;
  };

  const scroller = ref();

  const changeTab = (val: VoiceTabs) => {
    componentKey.value++;
    if (props.detailsMode === MODES.MAIN_LIST) {
      router.push(val);
      return;
    }

    //in case when tab is changed go to top of the list
    if (scroller.value) scroller.value.scrollToIndex(0);

    analytics.sendEvent("voices", VOICES.actions.CHANGE_VOICES_TAB);
    data.activeTab = val;
  };

  const isVoiceDisabled = (voice: Voice) => {
    return voice.disabled;
  };

  const isChecked = (voiceId: number | string) => {
    return props.selectedVoiceId === voiceId;
  };

  const checkVoice = (voice: Voice) => {
    if (isVoiceDisabled(voice) || isChecked(voice.id)) {
      return;
    }

    if (voice) {
      emit("checkVoice", voice);
    }
  };

  const searchFocus = () => {
    analytics.sendEvent("voices", VOICES.actions.SEARCH_VOICE_INPUT_SELECT);
  };

  const wrapperClass = computed(
    () => `${isMobile.value ? "pl-2 pr-3 py-2" : "py-3 pl-2 pr-3"}`
  );
</script>

<style scoped lang="scss">
.filters-wrapper {
  transition: max-height 0.5s ease;
  width: 100%;

  &--collapsed {
    max-height: 56px !important;
  }

  &--all {
    max-height: 120px !important;
  }
}

:deep(.v-field) {
  height: 40px !important;
}

.voices-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
  height: v-bind(voicesHeight);

  &--scroll {
    border-radius: 8px;
    overflow: auto;
  }
}

:deep(.v-card-text) {
  display: block;
}

.voices-card {
  &--mobile {
    max-width: 100%;
  }
}

.lock-icon {
  width: 20px;

  &--hidden {
    visibility: hidden;
  }
}

.icon-wrapper {
  padding: 8px;
}

.extra-container {
  cursor: pointer;

  &:hover {
    background: rgb(var(--v-theme-light-frame));
  }

  &--not-checked {
    background: rgb(var(--v-theme-player-bg));
    border: 2px transparent solid !important;
    border-bottom: 1px solid rgb(var(--v-theme-light-frame)) !important;
    border-radius: 0 !important;
  }

  &--checked {
    border: 2px solid rgb(var(--v-theme-button-primary));
    border-radius: 8px !important;
  }
}

:deep(.tabs-wrapper) {
  width: 100%;
}

.voices-wrapper {
  :deep(.feature-wrapper--hovered) {
    border: none !important;
  }

  :deep(.feature-wrapper--selected) {
    background: transparent !important;
    border: none !important;
  }
}

.flex-grow {
  flex-grow: 1;
}
</style>

import { apiUrl } from "@/core/utils/api";
import type { SyntheticVideo } from "@/core/types/video.types";
import { toRefs } from "vue";
import { useProgressStore } from "../store/useProgressStore";
import { useUserStore } from "../store/userStore";
import { VIDEO_PROCESSING } from "../types/file-processing.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";
import { NoVoiceConversionType, VoiceType } from "@/core/types/voice.types";
import {
  type RandomizedInputs,
  type SyntheticModel,
  type SyntheticPortrait,
  SyntheticStatus,
} from "@/core/types/synthetic.types";

function defineUploadConfig() {
  const progressStore = useProgressStore();

  const { dataProcessingState, dataProcessingPercentage } =
    toRefs(progressStore);

  dataProcessingPercentage.value = 0;

  return {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      dataProcessingPercentage.value = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (dataProcessingPercentage.value === 100) {
        dataProcessingState.value = VIDEO_PROCESSING;
      }
    },
    headers: {
      "X-File-Ms": 0,
    },
  };
}

export async function createDubbingVideo(payload: {
  video: any;
  voiceId: number | string;
  voiceType: string;
  videoDurationMs: number;
}): Promise<SyntheticVideo> {
  const clientId = tokenClientId();
  const formData = new FormData();

  formData.append("file", payload.video);

  const url = apiUrl(
    `clients/${clientId}/videos/dubbing?voiceId=${payload.voiceId}&voiceType=${payload.voiceType}&consent=true`
  );
  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.videoDurationMs);

  const response = await coreClient.post(url, formData, config);

  return response.data;
}

export async function createVtvVideo(payload: {
  video: any;
  portraitId: number;
  voiceId: number | string;
  voiceType: string;
  videoDurationMs: number;
}): Promise<SyntheticVideo> {
  const clientId = tokenClientId();
  const formData = new FormData();

  formData.append("file", payload.video);

  let query = "";

  if (payload.voiceId !== NoVoiceConversionType) {
    query = `?voiceId=${payload.voiceId}&voiceType=${payload.voiceType}&consent=true`;
  } else {
    query = "?consent=true";
  }
  const url = apiUrl(
    `clients/${clientId}/portraits/${payload.portraitId}/videos${query}`
  );
  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.videoDurationMs);

  const response = await coreClient.post(url, formData, config);

  return response.data;
}

export async function createPortraitTtvVideo(payload: {
  portraitId: number;
  audio?: Blob;
  voiceId?: number | string;
  voiceType?: string;
  recordingId?: number;
  recordingType?: string;
  audioDurationMs: number;
}): Promise<SyntheticVideo> {
  const clientId = tokenClientId();

  const isRecording = payload.recordingId && payload.recordingType;

  const config = defineUploadConfig();

  config.headers["X-File-Ms"] = Math.ceil(payload.audioDurationMs);
  const voicePart =
    payload.voiceId === NoVoiceConversionType
      ? ""
      : `&voiceId=${payload.voiceId}&voiceType=${payload.voiceType}`;

  if (isRecording) {
    const url = apiUrl(
      `clients/${clientId}/portraits/${payload.portraitId}/ttv?inputType=recording&recordingId=${payload.recordingId}&recordingType=${payload.recordingType}${voicePart}&consent=true`
    );
    const response = await coreClient.post(url, null, config);

    return response.data;
  } else {
    const formData = new FormData();

    if (payload.audio) {
      formData.append("file", payload.audio);
    }

    const url = apiUrl(
      `clients/${clientId}/portraits/${payload.portraitId}/ttv?inputType=audio${voicePart}&consent=true`
    );

    const response = await coreClient.post(url, formData, config);

    return response.data;
  }
}

const fiveMinutesInMiliSeconds = 5 * 60 * 1000;
let cachedPortraits: SyntheticPortrait[];

export async function deleteUserPortrait(portraitId: number): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/portraits/${portraitId}`);
  const response = await coreClient.delete(url);

  return response.data;
}

export async function updateUserPortraitName(
  portraitId: number,
  newName: string
): Promise<SyntheticPortrait> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/portraits/${portraitId}`);

  const response = await coreClient.patch(url, {
    name: newName,
  });

  return response.data;
}

export async function updateSyntheticVoice(payload: {
  lipsyncId: number;
  voiceId: number | string;
  cloned: boolean;
}): Promise<SyntheticModel> {
  const clientId = tokenClientId();

  const url = apiUrl(
    `clients/${clientId}/portraits/${payload.lipsyncId}`
  );

  const response = await coreClient.patch(url, {
    voiceId: payload.voiceId,
    voiceType: payload.cloned ? VoiceType.CLONED : VoiceType.SYSTEM,
  });

  return response.data;
}

export async function getClientPortraits(): Promise<Array<SyntheticPortrait>> {
  const clientId = tokenClientId();
  const now = new Date();

  const userStore = useUserStore();

  const url = apiUrl(`clients/${clientId}/portraits?limit=500`);

  if (
    !userStore.portraitsLastUpdatedAt ||
    now.getTime() - userStore.portraitsLastUpdatedAt.getTime() >
      fiveMinutesInMiliSeconds
  ) {
    const response = await coreClient.get(url);

    cachedPortraits =
      response.data?.items.filter(
        (el: SyntheticPortrait) => el.status !== SyntheticStatus.ERROR
      ) || [];
    userStore.portraitsLastUpdatedAt = new Date();
  }
  return cachedPortraits;
}

export async function getClientVideo(videoId: number): Promise<SyntheticVideo> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/videos/${videoId}`);
  const response = await coreClient.get(url);

  return response.data;
}

export async function getPortrait(
  portraitId: number
): Promise<SyntheticPortrait> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/portraits/${portraitId}`);

  const response = await coreClient.get(url);

  return response.data;
}

export async function finalizeSyntheticPortrait(
  portraitId: number,
  variants: Array<any>
): Promise<Array<SyntheticPortrait>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/portraits/${portraitId}`);

  const response = await coreClient.post(url, { imageIds: variants });

  return response.data;
}

export async function getAllSyntheticVideos(): Promise<Array<SyntheticVideo>> {
  const clientId = tokenClientId();
  const url = apiUrl(`clients/${clientId}/videos?limit=500`);

  const response = await coreClient.get(url);

  return response.data?.items || [];
}

export async function updateVideoName(
  videoId: number,
  newName: string
): Promise<void> {
  //add ttv option
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/videos/${videoId}`);

  await coreClient.patch(url, {
    name: newName,
  });
}

export async function deleteVideo(videoId: number): Promise<void> {
  //add ttv option
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/videos/${videoId}`);
  const response = await coreClient.delete(url);

  return response.data;
}

export async function sendSyntheticFormData(
  data: any
): Promise<SyntheticPortrait> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/portraits/synthetic`);

  const response = await coreClient.post(url, data);

  return response.data;
}

export async function uploadPortrait(payload: {
  image: any;
}): Promise<SyntheticPortrait> {
  const clientId = tokenClientId();

  const formData = new FormData();

  formData.append("file", payload.image);

  const url = apiUrl(`clients/${clientId}/portraits/upload?consent=true`);

  const response = await coreClient.post(url, formData);

  return response.data;
}

export async function randomizeSyntheticInputs(): Promise<RandomizedInputs> {
  const url = apiUrl("portraits/randomize-feats");

  const response = await coreClient.get(url);

  return response.data;
}

export async function getSyntheticVideos(
  syntheticId: number | string
): Promise<Array<SyntheticVideo>> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/portraits/${syntheticId}/videos`);

  const response = await coreClient.get(url);

  return response.data.items || [];
}

import { computed } from "vue";

// @ts-ignore
import { getDisplayDetails } from "@charactr/wooper-ui";

export const isMobile = computed(() => {
  const { mobile, platform } = getDisplayDetails();

  return mobile.value || platform.value.android || platform.value.ios;
});

export const isIOS = computed(() => {
  const { platform } = getDisplayDetails();

  return platform.value.ios;

});

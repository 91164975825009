import { secondsToTime } from "@/core/utils";
import useListener from "@/core/composables/useListener";
import { computed, type ComputedRef, reactive } from "vue";

type TimerEvent = "onTimeIsUp";

interface TimerOptions {
  maxSecondsDuration?: number;
  setTimeoutInterval?: number;
}

interface AppTimer {
  currentTime: ComputedRef<string>;
  remainingTimeInSeconds: ComputedRef<number | null>;
  remainingTime: ComputedRef<string | null>;
  currentTimeInSeconds: ComputedRef<number>;
  start(): void;
  stop(): void;
  listen(arg0: string, arg1: () => void): void;
  unlisten(arg0: string): void;
  unlistenAll(): void;
  dispatchToListener(arg0: string, arg1?: any): void;
}

export default function useTimer(
  { maxSecondsDuration, setTimeoutInterval }: TimerOptions = {} as TimerOptions
): AppTimer {
  setTimeoutInterval = setTimeoutInterval || 1000;
  const data = reactive({
    duration: 0,
    miliseconds: 0,
    interval: 0,
  });

  const { listen, unlisten, unlistenAll, dispatchToListener } =
    useListener<TimerEvent>();

  const currentTime = computed(() => secondsToTime(data.duration));

  const currentTimeInSeconds = computed(() => data.duration);

  const remainingTime = computed(() => {
    if (!maxSecondsDuration) {
      return null;
    }

    return secondsToTime(
      maxSecondsDuration - data.duration > 0
        ? maxSecondsDuration - data.duration
        : 0
    );
  });

  const remainingTimeInSeconds = computed(() => {
    if (!maxSecondsDuration) {
      return null;
    }

    return maxSecondsDuration - data.duration > 0
      ? maxSecondsDuration - data.duration
      : 0;
  });

  const start = () => {
    data.duration = 0;

    const minTimeValue = setTimeoutInterval === 1000 ? 1 : 0;

    data.interval = window.setInterval(() => {
      if (
        maxSecondsDuration != null &&
        maxSecondsDuration - data.duration <= minTimeValue
      ) {
        dispatchToListener("onTimeIsUp");
        stop();
      }

      if (setTimeoutInterval) {
        const step =
          setTimeoutInterval === 1000 ? 1 : setTimeoutInterval / 1000;

        data.duration += step;
      }
    }, setTimeoutInterval);
  };

  const stop = async () => {
    clearInterval(data.interval);
  };

  return {
    currentTime,
    currentTimeInSeconds,
    remainingTime,
    remainingTimeInSeconds,
    start,
    stop,
    listen,
    unlisten,
    unlistenAll,
    dispatchToListener,
  };
}

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <c-dialog
    :cancel-option="true"
    :confirm-disabled="!storePrompt"
    :confirm-loading="data.loading"
    :model-value="modelValue"
    persistent
    :price="storePrompt ? Number(photoTwinPhotoCost) * data.numberPhotos : ''"
    variant="secondary"
    width="600"
    @cancel="emit('update:modelValue', false)"
    @confirm="confirm"
    @keydown.enter="confirm"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Create new Photo</template>

    <template #content>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <div class="align-center d-flex">
            <c-typography v-if="!data.showPromptGenerator" variant="body-2-600"
            >Need help?</c-typography
            >
            <c-button
              :append-icon="
                data.showPromptGenerator ? 'mdi:chevron-up' : 'mdi:chevron-down'
              "
              class="generator-btn ml-2"
              mode="highlight-plain"
              no-padding
              size="x-small"
              @click="data.showPromptGenerator = !data.showPromptGenerator"
            >{{
              !data.showPromptGenerator ? "Show prompt generator" : "Hide"
            }}</c-button
            >
          </div>
          <c-button
            v-if="!data.showPromptGenerator"
            class="ml-2"
            :disabled="data.loading"
            mode="highlight-plain"
            no-padding
            prepend-icon="fluent:sparkle-16-filled"
            size="x-small"
            @click="randomize"
          >
            Inspire Me
          </c-button>
        </div>
        <div
          :class="[
            'd-flex flex-column wrapper',
            { 'wrapper--open': data.showPromptGenerator },
          ]"
        >
          <div
            ref="promptGeneratorContainer"
            class="slide-content"
            :class="{ 'slide-content--show': data.showPromptGenerator }"
          >
            <div class="pa-4 slide-wrapper">
              <div class="d-flex justify-space-between mb-2">
                <div>
                  <c-typography
                    class="d-block"
                    :color-class="
                      data.loading ? 'copy-secondary-lock' : 'copy-primary'
                    "
                    variant="body-2-600"
                  >Prompt Generator</c-typography
                  >
                  <c-typography
                    class="d-block"
                    :color-class="
                      data.loading ? 'copy-secondary-lock' : 'copy-secondary'
                    "
                    variant="body-3-400"
                  >
                    Specify the prompt according to your own wishes
                  </c-typography>
                </div>
              </div>
              <div :class="['form-grid my-4', {'form-grid--mobile': isMobile}]">
                <form-dropdown
                  v-for="field in data.form"
                  :key="field.key"
                  v-model="field.value"
                  :disabled="data.loading"
                  :field="field"
                />
              </div>
              <div class="d-flex justify-space-between">
                <c-button
                  :disabled="data.loading"
                  mode="highlight-plain"
                  no-padding
                  @click="clearForm"
                >Clear All</c-button
                >
                <c-button
                  :disabled="data.loading"
                  min-width="150"
                  @click="applyPrompt"
                >Apply</c-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <c-textarea
        v-model="storePrompt"
        class="mx-1"
        clearable
        :disabled="data.loading"
        height="200px"
        :label="`A photo of ${name}`"
        :max="1000"
        max-height="calc(100vh - 500px)"
        mode="outlined"
        placeholder="Type here a detailed description of what you want to see in your artwork"
        test-id="new-photo-twin-from-photo-model"
      />
      <div class="mb-2 mt-3">
        <c-typography
          :color-class="data.loading ? 'copy-secondary-lock' : 'copy-secondary'"
          variant="body-2-600"
        >Select number of output photos:</c-typography
        >
      </div>

      <div class="d-flex gap-12">
        <select-image-card
          v-for="el in data.numberOptions"
          :key="el.title"
          :checked="el.title.toLocaleLowerCase() === String(data.numberPhotos)"
          :disabled="data.loading"
          :title="el.title"
          @check-gender="setNumberPhotos"
        />
      </div>
      <c-typography
        class="d-block mt-4"
        color-class="copy-secondary"
        pointer
        variant="body-3-400"
      >
        By submitting to this form I accept Gemelo AI
        <router-link class="link" :to="Routes.PRIVACY_POLICY.path" @click.stop>
          Privacy policy</router-link
        >.
      </c-typography>
      <!-- <div class="align-center d-flex justify-center">
        <c-icon
          class="mr-2"
          color="rgb(var(--v-theme-copy-secondary))"
          height="16"
          icon="ph:info"
        />
        <c-typography color-class="copy-secondary" variant="body-3-400"
        >AI outputs can be misleading or wrong</c-typography
        >
      </div> -->
    </template>

    <template #confirm-label> Generate </template>
    <template #cancel-label> Cancel </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import FormDropdown from "@/core/components/Forms/FormDropdown.vue";
  import SelectImageCard from "./SelectImageCard.vue";
  import { AlertModes } from "@/core/types/other.types";
  import { Routes } from "@/core/routes/core.guard";
  import { computed, reactive, ref } from "vue";
  import { createNewPhotoTwinPhotos } from "@/core/services/photoTwins.service";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  // prettier-ignore
  import {  CButton, CDropdown, CIcon, CInput, CTextarea, CTypography } from "@charactr/wooper-ui/atoms";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useDrawerStore } from "../../../core/components/RightDrawer/store";
  import { isMobile } from "@/core/utils/mobile";
  import { usePhotoTwinStudioStore } from "../store/index";
  import { storeToRefs } from "pinia";
  import { useUserStore } from "../../../core/store/userStore";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
  });
  const emit = defineEmits(["update:modelValue"]);

  const { showSnackbar } = useSnackbarStore();
  const drawerStore = useDrawerStore();
  const userStore = useUserStore();
  const photoTwinStudioStore = usePhotoTwinStudioStore();
  const { prompt: storePrompt } = storeToRefs(photoTwinStudioStore);
  const { photoTwinPhotoCost, promptItems } = storeToRefs(userStore);

  const inspirePrompts = computed(() => {
    return promptItems.value.map(el => el.prompt);
  });

  const promptGeneratorContainer = ref<HTMLElement | null>(null);

  const data = reactive({
    showPromptGenerator: false,
    loading: false,
    name: "",
    minPhotos: 10,
    maxPhotos: 20,
    images: [],
    numberPhotos: 1,
    currentPromptIndex: 0,
    numberOptions: [
      {
        title: "1",
      },
      {
        title: "2",
      },
      {
        title: "3",
      },
      {
        title: "4",
      },
    ],
    form: [
      {
        key: "age",
        placeholder: "Age",
        values: ["Teen", "Young Adult", "Adult", "Middle-aged", "Senior"],
        descriptions: [
          "as a teenager aged 16 to 19 years old.",
          "as a person in their 20s or early 30s",
          "as a mature individual between 30 and 50 years old",
          "as a person in their 50s or early 60s",
          "as an elderly individual over 65 years old",
        ],
        value: null,
      },
      {
        key: "bodyType",
        placeholder: "Body type",
        values: ["Slim", "Fit", "Athletic", "Muscular", "Normal"],
        descriptions: [
          "With a lean body with minimal fat and minimal muscle",
          "With a healthy, well-maintained body with moderate muscle tone and low body fat",
          "With a toned, muscular build",
          "With well-defined muscles and a strong physique",
          "With a balanced, average body type, showing a natural, proportional physique",
        ],
        value: null,
      },
      {
        key: "outfit",
        placeholder: "Outfit",
        values: [
          "Casual",
          "Formal",
          "Business",
          "Sporty",
          "Streetwear",
          "Summer",
          "Winter",
          "Party",
          "Boho",
          "Vintage",
        ],
        descriptions: [
          "Dressed casually in blue jeans, a plain white t-shirt, and clean white sneakers",
          "Dressed in a smart, elegant outfit suitable for special events or formal settings",
          "Wearing a gray tailored suit, light blue button-down shirt, black leather shoes, and a slim black tie",
          "Dressed in activewear designed for comfort and movement during exercise",
          "Wearing an oversized black hoodie, distressed ripped jeans, and high-top white sneakers",
          "Dressed in light and airy clothing, perfect for warm weather and outdoor activities",
          "Wearing a long beige wool coat, knitted scarf, black gloves, and brown leather boots",
          "Dressed in a stylish outfit for social gatherings, suitable for making a statement",
          "Wearing loose-fitting beige linen pants, a white flowy shirt, and brown leather sandals",
          "Dressed in retro-inspired clothing that evokes a classic, timeless look",
        ],
        value: null,
      },
      {
        key: "hairType",
        placeholder: "Hair type",
        values: ["Straight", "Wavy", "Curly", "Coily", "Fine", "Thick", "Frizzy"],
        descriptions: [
          "Having smooth, sleek hair with no natural curl",
          "Having hair with a gentle, flowing wave pattern",
          "Having hair with defined curls that form loops or spirals",
          "Having tight, springy curls that form small, compact spirals",
          "Having thin strands of hair that are soft and delicate",
          "Having dense hair with a lot of volume and fullness",
          "Having hair that is prone to flyaways and lacks smoothness",
        ],
        value: null,
      },
      {
        key: "hairColor",
        placeholder: "Hair color",
        values: [
          "Black",
          "Dark Brown",
          "Light Brown",
          "Blonde",
          "Platinum Blonde",
          "Ginger",
          "Gray",
          "White",
        ],
        descriptions: [
          "With deep, dark-colored hair",
          "With rich, dark brown hair",
          "With softer, lighter shade of brown",
          "With light-colored hair with pale and golden tones",
          "With very light, almost white shade of blonde",
          "With ginger-colored hair with natural warm, reddish-orange tones",
          "With hair with shades of gray",
          "With bright white hair",
        ],
        value: null,
      },
      {
        key: "background",
        placeholder: "Background",
        values: [
          "Urban Cityscape",
          "Tropical Beach",
          "Forest Path",
          "Modern Office",
          "Countryside Field",
          "Historic European Town",
          "Mountain Range",
          "Traditional Market",
          "Street Art Wall",
          "Gym",
          "AI Conference",
        ],
        descriptions: [
          "In the midst of a bustling city street with tall buildings and busy sidewalks",
          "On a serene beach with white sand, palm trees, and clear blue waters",
          "Standing in a peaceful forest with tall trees, lush green leaves, and a narrow dirt path.",
          "Inside a sleek office environment with glass windows, desks, and computers",
          "On a vast, open field with rolling green hills and clear skies",
          "In the middle of cobblestone streets lined with old stone buildings and traditional European charm",
          "In front of a landscape with majestic mountains in the background with a clear sky or low clouds",
          "Amidst a lively market scene with colorful stalls, vendors, and people",
          "In front of a vibrant urban environment with a large mural or graffiti on a city wall",
          "Inside a modern fitness center with exercise equipment, weights, and mirrors",
          "At a high-tech venue with large screens, digital displays, and people networking at an artificial intelligence event",
        ],
        value: null,
      },
    ],
  });

  const setNumberPhotos = (val: string) => {
    data.numberPhotos = Number(val);
  };

  const confirm = async () => {
    data.loading = true;

    try {
      const response = await createNewPhotoTwinPhotos({
        modelId: props.id,
        prompt: storePrompt.value,
        numPhotos: data.numberPhotos,
      });

      response.twinIds.forEach((el) => {
        const formattedData = { ...response, id: el };

        drawerStore.prependModelPhoto(formattedData);
      });

      emit("update:modelValue", false);
    } catch (e: any) {
      showSnackbar(e.response?.data.message || "Error occured", AlertModes.ERROR);
    } finally {
      data.loading = false;
    }
  };

  const randomize = () => {

    storePrompt.value = inspirePrompts.value[data.currentPromptIndex];
    data.currentPromptIndex =
      (data.currentPromptIndex + 1) % inspirePrompts.value.length;
  };

  const applyPrompt = () => {
    let generatedPrompt = "";

    data.form.forEach((field) => {
      if (field.value) {
        const index = field.values.indexOf(field.value);

        if (index !== -1) {
          const description = field.descriptions[index];

          generatedPrompt += `${description}. `;
        }
      }
    });

    storePrompt.value = generatedPrompt.trim();
  };

  const clearForm = () => {
    data.form.forEach((field) => {
      field.value = null;
    });
  };
</script>

<style lang="scss" scoped>
.gap-12 {
  gap: 12px;
}

.form-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
}

.wrapper {
  gap: 16px;

  &--open {
    height: auto;
    margin-bottom: 20px;
  }
}

.slide-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
}

.slide-wrapper {
  // border: 1px solid rgb(var(--v-theme-dark-frame));
  background: rgb(var(--v-theme-light-frame-secondary));
  border-radius: 12px;
}

.slide-content--show {
  max-height: 240px;
}

.generator-btn {
  :deep(.v-btn__prepend) {
    display: none !important;
  }
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";
import { INITIAL_STATE } from "../types/file-processing.types";

export const useProgressStore = defineStore("progressStore", () => {
  const dataProcessingState = ref(INITIAL_STATE);
  const dataProcessingPercentage = ref(0);
  const clientUploadFileProgress = ref(0);
  const loadingCloneStudio = ref(false);
  const isConverting = ref(false);
  const isRecording = ref(false);
  const stopAllVideos = ref(false);
  const stopAllAudio = ref(false);

  const startLoadingFileProgress = () => {
    clientUploadFileProgress.value = 0;
  };

  const successLoadingFileProgress = () => {
    clientUploadFileProgress.value = 100;
  };

  const errorLoadingFileProgress = () => {
    clientUploadFileProgress.value = 0;
  };

  return {
    dataProcessingState,
    dataProcessingPercentage,
    clientUploadFileProgress,
    loadingCloneStudio,
    isConverting,
    isRecording,
    stopAllVideos,
    stopAllAudio,

    startLoadingFileProgress,
    successLoadingFileProgress,
    errorLoadingFileProgress,
  };
});

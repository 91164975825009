<template>
  <component
    :is="isMobile ? MobileWrapper : DesktopWrapper"
    ref="wrapper"
    :hide-extra-slot="hideExtraSlot"
    :padding="padding"
    :variant="props.variant"
  >
    <template #title>
      <wrapper-title
        :hide-details="props.hideDetails"
        :mini-title="props.miniTitle"
        :mobile="isMobile"
        :open-recordings="showExtendedRecordings"
        :variant="props.variant"
      />
    </template>

    <template #top-buttons>
      <slot name="top-buttons" />
    </template>

    <template #streaming>
      <slot name="streaming" />
    </template>
    <template #voice>
      <voice-chooser v-if="!miniTitle && showVoice" :hide-info-details="!Boolean(userSelectedVoice[variant.name])" :variant="variant" />
      <slot name="visual" />
    </template>

    <template #playground>
      <slot name="playground"></slot>
    </template>

    <template #action>
      <slot name="action"></slot>
    </template>
  </component>

</template>

<script setup lang="ts">

  import DesktopWrapper from "./DesktopWrapper.vue";
  import { isMobile } from "@/core/utils/mobile";
  import MobileWrapper from "./MobileWrapper.vue";
  import { useStore } from "@/core/store";
  import VoiceChooser from "@/core/components/Voice/VoiceChooser.vue";
  import WrapperTitle from "@/core/components/Wrapper/WrapperTitle.vue";

  import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";

  import { computed, type PropType, ref, toRefs, watch } from "vue";

  import type {  PlaygroundFeature } from "@/core/data/playgroundFeatures";
  import type { Voice } from "@/core/types/voice.types";
  import { useMobileRecentRecordingStore } from "@/core/store/useMobileRecentRecordingStore";
  import { useVoicesStore } from "@/core/store/useSelectedVoicesStore";

  const props = defineProps({
    variant: {
      type: Object as PropType<PlaygroundFeature>,
      default: () => ({}),
    },
    isRecording: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    showVoice: {
      type: Boolean,
      default: true,
    },
    miniTitle: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 6,
    },
    hideExtraSlot: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["clearForm", "clearRecent"]);
  const appDrawersState = useAppDrawersStateStore();
  const { showRightDrawer } = toRefs(appDrawersState);
  const mobileRecentRecording = useMobileRecentRecordingStore();
  const { mobileRecentAudioRecording } = toRefs(mobileRecentRecording);

  const userVoices = useVoicesStore();
  const { userSelectedVoice } = toRefs(userVoices);

  const wrapper = ref();

  const store = useStore();

  const disabledVoice: Voice = {
    createdAt: "",
    id: 267,
    description: "",
    previewUrls: [],
    labels: [
      {
        category: "otherFeatures",
        label: "Explanatory",
      },
    ],
    name: "Voice Name",
  };

  const showExtendedRecordings = computed(
    () => Boolean(mobileRecentAudioRecording)
  );

  const selectedVoice = computed(
    () => userSelectedVoice.value[props.variant.name] || disabledVoice
  );

  watch(selectedVoice, () => {
    emit("clearForm");
    if (props.mobile) {
      showRightDrawer.value = false;
    }
  });

  defineExpose({
    childRef: wrapper,
  });

</script>

<style lang="scss" scoped>
:deep(.c-typography) {
  text-align: initial;
}

:deep(.v-container) {
  padding: 0 !important;
}

.main-container {
  display: flex;
  justify-content: center;
}

:deep(.v-card) {
  overflow: initial;
}

:deep(.v-card-text) {
  justify-content: center;
  width: 100%;
}

.wrapper {
  height: 150px !important;

  &--opacity {
    opacity: 30%;
    pointer-events: none;
  }
}

.playground-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
}

</style>

<template>
  <error-dialog
    v-model="data.loadingError"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />

  <input
    id="source_video"
    ref="videoFileSelector"
    accept="video/*"
    class="d-none"
    type="file"
    @change="loadTargetVideoFile"
  />
  <div
    :class="[
      'd-flex flex-column dnd-upload-container',
      { 'flex-grow': !showTitle, 'h-100 px-4': isMobile, 'pt-3': !showTitle },
    ]"
  >
    <div
      v-if="props.showTitle"
      :class="['d-flex justify-space-between', [isMobile ? 'mt-3' : 'mt-4']]"
    >
      <c-typography color-class="copy-primary" variant="subtitle-2-600">{{
        selectedVideo ? "" : "Upload a video"
      }}</c-typography>

      <c-button
        :disabled="isCreatingVideoFromVideo"
        mode="highlight-plain"
        no-padding
        size="x-small"
        @click="data.showVideoGuide = true"
      >
        Video Guide
      </c-button>

    </div>
    <div
      v-if="!selectedVideo && !isCreatingVideoFromVideo"
      :class="[
        'align-center d-flex flex-wrap justify-center drag-and-drop-wrapper',
        {
          'mt-4': !isMobile && showTitle,
          'h-100 pb-4 pt-2': isMobile,
          'h-100': !showTitle,
        },
      ]"
    >
      <drag-and-drop
        :class="['mb-4', { 'h-100': isMobile || !showTitle }]"
        :disabled="isRecordingsLimitReached"
        :supported-formats="VIDEO_FORMATS"
        test-id="dnd-video-creator-video"
        @choose-file="chooseFile"
        @drop-file="loadTargetVideoFile($event, true)"
      />
    </div>
    <Video
      v-else
      id="player"
      :delete="!mini"
      :file="selectedVideo || {}"
      :hide-consent="hideVideoConsent"
      :loading="isCreatingVideoFromVideo"
      :max-height="maxHeight"
      :mobile="isMobile"
      :show-details="!mini"
      :video-creator="!showTitle"
      @delete="cleanUp"
    />
  </div>
  <video-to-video-guide v-model="data.showVideoGuide" />
  <div
    v-if="!twinCreation"
    :class="['align-center d-flex justify-start w-100 mt-2']"
  >
    <c-checkbox
      v-model="skipVoiceConversion"
      :disabled="isCreatingVideo"
      :gap="8"
      :height="22"
      test-id="playground-consent"
      typography="body-2-400"
    />

    <div class="align-center d-flex">
      <c-typography
        :color-class="isCreatingVideo ? 'copy-secondary-lock' : 'copy-secondary'"
        data-testid="playground-consent-text"
        pointer
        variant="body-3-400"
        @click="isCreatingVideo ? null : skipVoiceConversion = !skipVoiceConversion"
      >
        Keep original audio
      </c-typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import Video from "../../../playground/components/videoToVideo/Video.vue";
  import VideoToVideoGuide from "@/core/components/Dialogs/VideoToVideoGuide.vue";
  import { analytics, VIDEO } from "@/core/utils/analytics";
  import { computed, reactive, ref, toRefs, watch } from "vue";

  // @ts-ignore
  import { CButton, CCheckbox, CTypography } from "@charactr/wooper-ui/atoms";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { useProgressStore } from "@/core/store/useProgressStore";

  import { useUserStore } from "@/core/store/userStore";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { VIDEO_FORMATS } from "@/core/types/file.types";
  import { storeToRefs } from "pinia";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
  import { ModelType } from "@/core/types/synthetic.types";
  import { useRouter } from "vue-router";

  const props = defineProps({
    showTitle: {
      type: Boolean,
      default: true,
    },
    hideVideoConsent: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    twinCreation: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "calc(100vh - 623px)",
    },
  });

  //STORE
  const drawerStore = useDrawerStore();
  const progressStore = useProgressStore();
  const { isConverting } = toRefs(progressStore);
  const userStore = useUserStore();

  const {
    isCreatingVideoFromVideo,
    selectedVideo,
    openVideoSelector,
    skipVoiceConversion,
    isCreatingVideo,
  } = storeToRefs(useVideoCreatorStore());

  const { loadVideoFile } = useMediaFileLoader();
  const videoFileSelector = ref();
  const router = useRouter();

  const data = reactive({
    showVideoGuide: false,
    showPreview: false,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
    videoName: "",
    loadedVideoSize: 0,
    agreeRules: false,
  });

  const isRecordingsLimitReached = computed(() => {
    if (userStore.isUnlimitedVideoAccess) return false;

    return (
      drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit
    );
  });

  const cleanUp = () => {
    selectedVideo.value = null;
  };

  const toMb = (size: number) => {
    if (size) {
      return `${Number(size / 10e5).toFixed(2)} MB`;
    }
  };

  const chooseFile = () => {
    videoFileSelector.value.type = "text";
    videoFileSelector.value.type = "file";
    videoFileSelector.value.click();
  };

  watch(
    () => openVideoSelector.value,
    (val: boolean) => {
      if (val) {
        chooseFile();
        openVideoSelector.value = false;
      }
    }
  );

  const isLipsyncUpload = computed(() => {
    return router.currentRoute.value.params.type === "normal";
  }); ;

  const loadTargetVideoFile = async (event: any, isDropped = false) => {
    isConverting.value = true;

    const files = isDropped ? event.dataTransfer?.files : event.target.files;

    data.videoName = files.length ? files[0].name : "";
    const videoFile = await loadVideoFile(files, {
      minDuration: props.twinCreation
        ? isLipsyncUpload.value ? 60 : userStore.zeroShotCreationMin
        : 0,
      maxDuration: props.twinCreation ? isLipsyncUpload.value ? 180 : userStore.zeroShotCreationMax: userStore.videoSecondsMaxLength,
      maxMegabytes: !isLipsyncUpload.value ? 1500 : userStore.uploadedVideoFileSizeMb,
      supportedExtensions: isLipsyncUpload.value ? ["mp4", "mov"] : VIDEO_FORMATS,
    }).catch((err) => {
      data.loadingErrorMessage = err;
      data.loadingError = true;
      analytics.sendEvent("vtv", VIDEO.actions.SOURCE_VIDEO_LOAD_ERROR);
      return;
    });

    if (videoFile) {
      cleanUp();
      selectedVideo.value = videoFile;
      analytics.sendEvent("vtv", VIDEO.actions.SOURCE_VIDEO_LOAD_SUCCESS);
    }
    isConverting.value = false;
  };

</script>

<style scoped lang="scss">
.file-wrapper {
  background: transparent;
  border: 1px solid rgba(var(--v-theme-dark-frame)) !important;
  border-radius: 8px;
  box-sizing: border-box;
}

.flex-grow {
  flex: 1;
}
</style>

<template>
  <div class="view">
    <div v-if="$slots.header" class="view__header">
      <slot name="header" />
    </div>
    <div v-if="$slots.content" class="view-slot view__content">
      <slot name="content" />
    </div>
    <div v-if="$slots.footer" class="view-slot view__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .view {
    display: flex;
    flex-direction: column;
    margin: auto;

    &__content{
      display: block;
      flex-direction: column;
      gap: 24px;
    }

    &__header {
      display: flex;
      justify-content: left;
      margin-bottom: 40px;
      text-align: flex-start;
    }

    &-slot {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    :deep(.v-container) {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 0;
    }

    :deep(.content-container) {
      display: flex;
      flex-direction: column;
    }
  }
</style>

import { defineStore } from "pinia";
import { ref } from "vue";
import { USER_DEFAULT_VOICES, type Voice } from "../types/voice.types";
import { PLAYGROUND_FEATURES, type PlaygroundFeature } from "../data/playgroundFeatures";

export const useVoicesStore = defineStore("userSelectedVoicesStore", () => {
  const userSelectedVoice = ref(structuredClone(USER_DEFAULT_VOICES));

  const $reset = () => {
    userSelectedVoice.value = structuredClone(USER_DEFAULT_VOICES);
  };

  const clonedVoicePostRemoveCleanUp = (voiceId: number) => {
    const features = [
      PLAYGROUND_FEATURES.STS.name,
      PLAYGROUND_FEATURES.TTS.name,
      PLAYGROUND_FEATURES.VIDEO_CREATOR.name,
    ];

    features.forEach((featureName) => {
      const currentVoice = userSelectedVoice.value[featureName];

      if (currentVoice && currentVoice.id === voiceId) {
        userSelectedVoice.value[featureName] = undefined;
      }
    });
  };

  const updateVoice = (voice: Voice | undefined, variant: PlaygroundFeature["name"]) => {
    userSelectedVoice.value[variant] = voice;
  };

  return {
    userSelectedVoice,
    clonedVoicePostRemoveCleanUp,
    updateVoice,
    $reset,
  };
});

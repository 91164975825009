<template>
  <c-dialog :confirm-option="false" width="500">
    <template #title>Are you sure?</template>
    <template #content>
      <c-form ref="formWrapper" v-model="formValid">
        <div class="justify-center mb-6">
          <c-typography class="d-block text-center" color-class="copy-secondary" variant="body-2-400">
            You will be logged out of your devices within couple of minutes.
          </c-typography>
        </div>
        <c-input
          id="la"
          v-model="data.userPass"
          :append-inner-icon="data.showPass ? 'mdi:eye' : 'mdi:eye-off'"
          autofocus
          class="mb-6"
          density="compact"
          :disabled="data.isLoading"
          mode="outlined"
          no-padding
          placeholder="Current password"
          :required="rulesOn ? true : false"
          :rules="rulesOn ? [...inputRules().password] : []"
          :type="data.showPass ? 'text' : 'password'"
          @click:append-inner="data.showPass = !data.showPass"
          @keydown.enter.stop="confirmLogoutAll"
          @update:model-value="clearError"
        />
        <c-button
          id="laconf"
          block
          elevation="0"
          :loading="data.isLoading"
          @click="confirmLogoutAll"
        >Log out</c-button
        >
        <div v-if="data.error" class="mt-2">
          <c-typography class="text-center text-error" variant="body-2-500">{{
            data.error
          }}</c-typography>
        </div>
      </c-form>
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { inputRules } from "@/core/utils/validators";
  import router from "@/router";
  import { Routes } from "@/core/routes/core.guard";
  import { useStore } from "@/core/store";
  import { ACCOUNT, analytics } from "@/core/utils/analytics";
  import { logout, logoutAll } from "@/core/services/login.service";
  import { nextTick, reactive, ref } from "vue";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CForm, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  const { logout: storeLogout } = useStore();

  const data = reactive({
    error: "",
    isLoading: false,
    showPass: false,
    userPass: "",
  });

  const rulesOn = ref(false);
  const formValid = ref(false);
  const componentKey = ref(1);
  const formWrapper = ref<null | HTMLFormElement>(null);

  const clearError = () => {
    data.error = "";
  };

  const confirmLogoutAll = async () => {
    rulesOn.value = true;
    componentKey.value++;
    nextTick(() => {
      (formWrapper.value as HTMLFormElement).validate().then(() => {
        if (!formValid.value) {
          return;
        }
        data.isLoading = true;
        logoutAll(data.userPass)
          .then(async () => {
            await logout();
            console.log("inside confirmLogoutAll");
            storeLogout();
            analytics.sendEvent("account", ACCOUNT.actions.LOGOUT_ALL_CONFIRM);
            nextTick(() => {
              router.push({ name: Routes.LOGIN.name });
            });
          })
          .catch((e: any) => {
            data.error = e.response?.data.message;
          })
          .finally(() => {
            data.isLoading = false;
          });
      });
    });
  };
</script>

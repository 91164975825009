export const secondsToTime = (value: number): string => {
  const time: number = Math.round(value) ?? 0;

  const hours: string = addLeadingZero(Math.floor(time / 3600));

  const minutes: string = addLeadingZero(
    Math.floor((time - Number(hours) * 3600) / 60)
  );

  const seconds: string = addLeadingZero(
    Math.floor(time - Number(hours) * 3600) - Number(minutes) * 60,
    2
  );

  if (Number(hours) > 0) {
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
};

export const addLeadingZero = (value: number, count = 1): string => {
  return value.toString().padStart(count, "0");
};

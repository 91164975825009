<template>
  <error-dialog
    v-model="data.loadingError"
    confirm-variant="secondary-web"
    :errors="data.loadingErrorMessage.description"
    :title="data.loadingErrorMessage.title"
  />
  <input
    id="target_file"
    ref="fileSelector"
    :accept="isMobile ? '*' : 'audio/*'"
    class="hidden"
    type="file"
    @change="loadUserAudioFile"
  />
  <div
    v-if="!audioIsRecordingPanelVisible && !videoSynthesisInputAudio"
    :class="[
      'd-flex flex-wrap',
      { 'pt-3': isMobile, 'mt-3': !isMobile  , 'pb-4': mode !== PLAYGROUND_FEATURES.VIDEO_CREATOR.name && !isMobile, 'h-100': mode === PLAYGROUND_FEATURES.VIDEO_CREATOR.name},
    ]"
    :style="{ height: maxHeight, minHeight: '168px' }"
  >
    <drag-and-drop
      :disabled="isRecordingsLimitReached"
      height="auto"
      :supported-formats="AUDIO_FORMATS"
      test-id="dnd-video-creator-audio"
      @choose-file="chooseFile"
      @drop-file="loadUserAudioFile($event, true)"
    />
  </div>
  <template v-else>
    <template
      v-if="
        !isCheckingMicrophoneAccess &&
          !noMicAccess &&
          audioInputMode === VoiceInputSource.MIC
      "
    >
      <div :class="{ 'pa-4': isMobile }">
        <div
          ref="element"
          :class="['recording', { 'recording--mobile': isMobile }]"
        >
          <c-button
            :disabled="isConverting"
            icon="ph:trash-simple"
            mode="outline"
            size="small"
            @click="cancelRecording"
          />

          <div class="recording__canvas">
            <AVMedia
              v-if="waveComponent"
              :bar-width="2"
              :canv-width="waveWidth"
              frequ-direction="mo"
              :frequ-lnum="waveWidth / 6"
              height="70"
              line-color="#A8A8AA"
              :media="stream"
              type="frequ"
            />
          </div>

          <div class="recording__remaining_time">
            <c-typography color-class="copy-primary" variant="body-3-400">
              {{ data.timer ? unref(data.timer.remainingTime) : "" }}
            </c-typography>
          </div>
        </div>

        <c-button
          block
          class="mt-4"
          :loading="isConverting"
          size="large"
          warning
          @click="toggleRecording"
        >Stop Recording
        </c-button>
      </div>
    </template>

    <div
      v-if="
        audioInputMode === VoiceInputSource.FILE &&
          videoSynthesisInputAudio && !isCreatingVideoFromAudio
      "
      :style="{ height: maxHeight}"
    >
      <local-audio-player
        audio-label="Selected file"
        :bottom-border="false"
        :class="[isMobile ? 'ma-4' : 'my-4']"
        hide-details
        :recording="videoSynthesisInputAudio"
        :style="{ height: isMobile ? maxHeight  : 'auto'}"
        :video-creator="mode === PLAYGROUND_FEATURES.VIDEO_CREATOR.name"
        wrapped
        @delete="clearFileSelection"
      />
    </div>

    <circle-loader
      v-if="isCreatingVideoFromAudio"
      :subtitle="getSubtitle(dataProcessingState)"
      :title="getTitle(dataProcessingState)"
    />

    <div
      v-if="!isCheckingMicrophoneAccess && noMicAccess"
      class="align-center d-flex justify-center"
      style="height: 32px; margin-top: 28px"
    >
      <c-icon class="error mr-3" icon="mdi:alert-outline" width="21px" />
      <c-typography class="align-center d-flex" error variant="body-2-500">
        Microphone not authorized. Please check your media permissions
        settings.</c-typography
      >
    </div>
    <div v-if="isCheckingMicrophoneAccess" class="d-flex justify-center mt-7">
      <c-progress-circular class="loader mr-2" indeterminate />
      <c-typography class="align-center d-flex" variant="body-2-500"
      >Loading...</c-typography
      >
    </div>
  </template>
  <div
    :class="['align-center d-flex justify-start w-100 mt-2']"
  >
    <c-checkbox
      v-model="skipVoiceConversion"
      :disabled="isCreatingVideo"
      :gap="8"
      :height="22"
      test-id="playground-consent"
      typography="body-2-400"
    />

    <div class="align-center d-flex">
      <c-typography
        :color-class="isCreatingVideo ? 'copy-secondary-lock' : 'copy-secondary'"
        data-testid="playground-consent-text"
        pointer
        variant="body-3-400"
        @click="isCreatingVideo ? null : skipVoiceConversion = !skipVoiceConversion"
      >
        Keep original audio
      </c-typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { AVMedia } from "vue-audio-visual";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import DragAndDrop from "@/core/components/DragAndDrop.vue";
  import ErrorDialog from "@/core/components/Dialogs/ErrorDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import LocalAudioPlayer from "@/core/components/LocalAudioPlayer.vue";
  import { useElementSize } from "@vueuse/core";
  import useLibraryCanvas from "@/core/composables/useLibraryCanvas";
  import useMediaFileLoader from "@/core/composables/useMediaFileLoader";
  import useRecorder from "@/core/composables/useRecorder";
  import { VoiceInputSource } from "@/core/types/playground.types";

  import {
    computed,
    onBeforeMount,
    type PropType,
    reactive,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";

  // @ts-ignore
  import { CButton, CCheckbox, CIcon, CProgressCircular, CTypography } from "@charactr/wooper-ui/atoms";

  import { fileProcessing, type FileProcessingStage } from "@/core/types/file-processing.types";
  import  { PLAYGROUND_FEATURES, type PlaygroundFeature } from "@/core/data/playgroundFeatures";

  import { useDrawerStore } from "@/core/components/RightDrawer/store";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import { useUserStore } from "@/core/store/userStore";

  import { AUDIO_FORMATS } from "@/core/types/file.types";
  import { storeToRefs } from "pinia";
  import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";

  //PROPS & EMITS
  const props = defineProps({
    headerHeight: {
      type: Number,
      default: 0,
    },
    bottomPlayerHeight: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String as PropType<PlaygroundFeature["name"]>,
      default: PLAYGROUND_FEATURES.VIDEO_SYNTHESIS.name,
    },
  });

  //STORE
  const progressStore = useProgressStore();
  const { dataProcessingState, dataProcessingPercentage, isConverting } = toRefs(progressStore);
  const userStore = useUserStore();
  const drawerStore = useDrawerStore();
  const { isCreatingVideoFromAudio, isCreatingVideo, skipVoiceConversion, videoSynthesisInputAudio, noMicAccess, isCheckingMicrophoneAccess, audioInputMode, audioIsRecordingPanelVisible } = storeToRefs(useVideoCreatorStore());

  const getTitle = (currentState: FileProcessingStage) => {
    return currentState.title;
  };

  const getSubtitle = (currentState: FileProcessingStage) => {
    if (fileProcessing.isUploadingFile(currentState)) {
      return `${dataProcessingPercentage.value}%`;
    }
    return "please wait";
  };

  const toggleRecording = () => {
    recorder.toggle();
  };

  const clearFileSelection = () => {
    //reset selected FileList solution
    fileSelector.value.type = "text";
    fileSelector.value.type = "file";
    videoSynthesisInputAudio.value = null;
  };

  const { stream, stopCanvas } = useLibraryCanvas();

  const recorder = useRecorder();

  const { loadAudioFile } = useMediaFileLoader();

  const fileSelector = ref();

  const waveComponent = ref(true);

  const chooseFile = () => {
    fileSelector.value.click();
  };

  const maxHeight = computed(() => {
    if (isMobile.value) {
      const tabsHeight = 58;

      return `calc(100% - ${tabsHeight}px)`;
    } else {
      return isCreatingVideoFromAudio.value ? "144px" : props.mode === PLAYGROUND_FEATURES.VIDEO_CREATOR.name ? "100%" : "297px";
    }
  });

  const isRecordingsLimitReached = computed(() => {

    if (userStore.isUnlimitedVideoAccess) return false;

    return drawerStore.videoCreatorRecordings.length >= userStore.videoRecordingsLimit;
  });

  onBeforeMount(async () => {
    if (!userStore.stsAudioSecondsMaxLength) {
      await userStore.getSubscriptionLimits();
    }
  });

  const data = reactive({
    timer: null as any,
    loadingError: false,
    loadingErrorMessage: { title: "", description: "" },
  });

  const element = ref(null);

  const { width } = useElementSize(element);

  const waveWidth = computed(() => {
    //minus width of delete button and remaing time width
    return width.value - 120;
  });

  watch(
    () => recorder.accessDenied,
    (val) => {
      if (val.value) {
        noMicAccess.value = true;
      }
    },
    { deep: true }
  );

  watch(
    () => recorder.isCheckingAccess,
    (val) => {
      isCheckingMicrophoneAccess.value = val.value;
    },
    { deep: true }
  );

  const cancelRecording = () => {
    removeListeners();
    audioIsRecordingPanelVisible.value = false;
  };

  const removeListeners = () => {
    if (data.timer) data.timer.unlistenAll();

    if (recorder) {
      recorder.stop();
      recorder.unlistenAll();
      stopCanvas();
    }
  };

  const loadUserAudioFile = async (event: any, isDropped = false) => {
    const files = isDropped ? event.dataTransfer?.files : event.target.files;

    const audioFile = await loadAudioFile(files, {
      maxDuration: userStore.stsAudioSecondsMaxLength,
      maxMegabytes: userStore.uploadedAudioFileSize,
      supportedExtensions: AUDIO_FORMATS,
    }).catch((err) => {
      data.loadingErrorMessage = err;
      data.loadingError = true;
    });

    if (audioFile) {
      videoSynthesisInputAudio.value = {
        ...audioFile,
        type: PLAYGROUND_FEATURES.STS.name,
        createdAt: new Date().toISOString(),
        voiceId: 0,
        voiceType: "other",
      };
      audioInputMode.value = VoiceInputSource.FILE;
      isCheckingMicrophoneAccess.value = false;
    }
  };
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>

<template>
  <div :class="{ 'px-4': isMobile }">
    <cloning-title
      :disabled="isCreatingVoice"
      subtitle="Read the sentence below or say anything for 10-60 seconds to clone a voice"
      title="Record your sample"
    >
      <template #append>
        <div class="cloning-wrapper pa-4">
          <div class="w-100">
            <c-typography variant="body-1-400"
            >Once upon a time, a very long time ago now, about last Friday,
              Winnie-the-Pooh lived in a forest all by himself under the name of
              Sanders. "What does under the name mean?" asked Christopher Robin.
              It means he had the name over the door in gold letters, and lived
              under it.</c-typography
            >
          </div>
          <div              class="w-100">
            <div ref="recordingContainer" class="d-flex flex-column recording">
              <div v-if="!cloning.isRecording(cloningCurrentState)" class="my-5 w-100 wave" />
              <div v-else class="recording__canvas">
                <AVMedia
                  v-if="waveComponent && !noAccess"
                  :bar-width="1"
                  :canv-height="42"
                  :canv-width="waveWidth"
                  frequ-direction="mo"
                  :frequ-line-cap="true"
                  :frequ-lnum="100"
                  line-color="#A8A8AA"
                  :media="stream"
                  type="frequ"
                />
              </div>
              <c-typography
                v-if="noAccess"
                error
                variant="body-2-500">
                Microphone access denied. Please check the permissions in your browser or device settings.
              </c-typography>
              <c-typography
                v-if="!noAccess"
                class="ml-2"
                :color-class="cloning.isRecording(cloningCurrentState) ? 'copy-secondary' : 'copy-tertiary'"
                variant="body-2-400"
              >{{ timer ? unref(timer.remainingTime) : "" }}</c-typography
              >
            </div>
          </div>
        </div>
      </template>
    </cloning-title>
    <cloning-consent-checkbox
      v-if="!noAccess"
      v-model:consent-confirmed="data.consentConfirmed"
      :disabled="isRecording || isCreatingVoice"
    />
    <c-button
      v-if="cloning.isBeforeRecording(cloningCurrentState)"
      block
      description="Record"
      :disabled="!data.consentConfirmed"
      size="large"
      @click="record"
    >
      Start Recording
    </c-button>
    <div
      v-if="
        cloning.isRecording(cloningCurrentState) &&
          !isCheckingMicrophoneAccess &&
          !noAccess
      "
      class="d-flex gap-16 mt-4"
    >
      <c-button
        class="flex-grow-1"
        :disabled="isCreatingVoice"
        size="large"
        :warning="!isCreatingVoice"
        @click="cancelRecording"
      >
        Cancel
      </c-button>
      <c-button
        class="flex-grow-1"
        :disabled="timer.currentTimeInSeconds.value < 10 "
        :loading="isCreatingVoice"
        size="large"
        @click="toggleRecording"
      >
        Finish & Clone
      </c-button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { AVMedia } from "vue-audio-visual";
  import CloningConsentCheckbox from "../components/voiceCloning/CloningConsentCheckbox.vue";
  import CloningTitle from "../components/voiceCloning/CloningTitle.vue";
  import { createClonedVoice } from "@/core/services/playground.service";
  import { isMobile } from "@/core/utils/mobile";
  import type { LocalRecording } from "@/core/types/recording.types";
  import { Routes } from "@/core/routes/core.guard";
  import { useElementSize } from "@vueuse/core";
  import useLibraryCanvas from "@/core/composables/useLibraryCanvas";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import useRecorder from "@/core/composables/useRecorder";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import useTimer from "@/modules/voicesPlayground/composables/useTimer";
  import { useUserStore } from "@/core/store/userStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";
  import { VoiceCloningInputModes } from "@/core/types/playground.types";
  import { analytics, VOICE_CLONING } from "@/core/utils/analytics";
  import { cloning, CloningSteps } from "../types/cloning-state.type";
  import {
    computed,
    nextTick,
    onBeforeUnmount,
    reactive,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";

  // @ts-ignore
  // prettier-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();
  const { addClonedVoice } = useVoicesListStore();

  const { clonedVoices } = toRefs(
    useVoicesListStore()
  );
  const voicesListStore = useVoicesListStore();

  const { setCurrentlyActiveClonedVoice } =
    voicesListStore;
  const progressStore = useProgressStore();
  const { isRecording } = toRefs(progressStore);

  const recordingContainer = ref(null);
  const noAccess = ref(false);
  const isCheckingMicrophoneAccess = ref(false);
  const isCreatingVoice = ref(false);
  const waveComponent = ref(true);
  const recorder = useRecorder();

  const timer = useTimer({ maxSecondsDuration: 60 });
  const router = useRouter();
  const { width } = useElementSize(recordingContainer);
  const { stream, startCanvas, stopCanvas } = useLibraryCanvas();

  const data = reactive({
    cloningState: CloningSteps.BEFORE_MIC_RECORDING,
    cloningInfoDialog: false,
    tryNowSelectionDialog: false,
    voiceCloningInputMode: VoiceCloningInputModes.MIC,
    fileRecording: null as LocalRecording | null,
    consentConfirmed: false,
    confirmConsentDialog: false,
  });

  onBeforeUnmount(() => {
    removeListeners();
  });

  const cloningCurrentState = computed(() => {
    return data.cloningState;
  });

  const isVoiceCloningLimitReached = computed(() => {
    if (userStore.isUnlimitedVoiceCloningAccess) return false;
    return clonedVoices.value.length >= userStore.clonedVoicesLimit;
  });

  const waveWidth = computed(() => {
    return width.value;
  });

  watch(
    () => recorder.accessDenied,
    (val) => {
      if (val.value) {
        noAccess.value = true;
      }
    },
    { deep: true }
  );

  watch(
    () => recorder.isCheckingAccess,
    (val) => {
      isCheckingMicrophoneAccess.value = val.value;
    },
    { deep: true }
  );

  watch(waveWidth, () => {
    forceRenderWaveComponent();
  });

  //this is workaround to re-render canvas component of sound wave
  const forceRenderWaveComponent = async () => {
    waveComponent.value = false;
    await nextTick();
    waveComponent.value = true;
  };

  const record = () => {
    setTimeout(() => {
      recorder.start();
      isRecording.value = true;
      analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.START_RECORDING);
      data.cloningState = CloningSteps.MIC_RECORDING;
    }, 200);

    recorder.listen("onStart", () => {
      startCanvas();
      timer.start();
    });

    recorder.listen("onStop", () => {
      timer.stop();
      stopCanvas();
      isRecording.value = false;
    });

    recorder.listen("onSend", function (blob: Blob): void {
      generateRecording(blob);
    });

    timer.listen("onTimeIsUp", () => {
      if (recorder.isRecording) {
        recorder.stop();
        stopCanvas();
        isRecording.value = false;
      }
    });
  };

  const toggleRecording = () => {
    if (timer.currentTimeInSeconds.value < 10) {
      showSnackbar(
        "Recording must be at least 10 seconds long",
        AlertModes.ERROR
      );
      recorder.stop({ isCancelled: true });
      isRecording.value = false;
      data.cloningState = CloningSteps.BEFORE_MIC_RECORDING;
      recorder.unlistenAll();
    } else {
      recorder.toggle();
    }
  };

  const generateRecording = async (recording: Blob) => {
    try {
      analytics.sendEvent(
        "voice_cloning",
        VOICE_CLONING.actions.START_CONVERSION
      );

      isCreatingVoice.value = true;

      const resp = await createClonedVoice("Cloned Voice", recording);

      resp.cloned = true;
      addClonedVoice(resp);
      setCurrentlyActiveClonedVoice({ ...resp, description: "" });
      router.push({ name: Routes.VOICE_CLONING.children.SUCCESS.name });

      analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.SUCCESS);
      data.cloningState = CloningSteps.CLONE_READY;
    } catch (e: any) {
      if (e.response?.status === 402) {
        showSnackbar(
          "Your account balance is too small to use voice cloning",
          AlertModes.ERROR
        );
        analytics.sendEvent(
          "voice_cloning",
          VOICE_CLONING.actions.NO_BALANCE_ERROR
        );
        data.cloningState = CloningSteps.BEFORE_MIC_RECORDING;
      } else {
        showSnackbar(
          e.response?.data?.message || "Something went wrong",
          AlertModes.ERROR
        );
        analytics.sendEvent("voice_cloning", VOICE_CLONING.actions.ERROR);
      }
    } finally {
      isCreatingVoice.value = false;
      removeListeners();
    }
  };

  const removeListeners = () => {
    if (timer) timer.unlistenAll();

    if (recorder) {
      recorder.stop();
      recorder.unlistenAll();
      stopCanvas();
    }
  };

  const cancelRecording = () => {
    removeListeners();
    isRecording.value = false;
    data.cloningState = CloningSteps.BEFORE_MIC_RECORDING;
  };

</script>

<style scoped lang="scss">
.recording {
  align-items: center;
  display: flex;
  height: 60px;
  width: 100%;

  &__canvas {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

.hidden {
  display: none;
}

.error {
  color: var(--vt-c-red);
}

.wave {
  border: 1px dashed rgb(var(--v-theme-copy-tertiary)) !important;
  height: 1px;

}

.cloning-wrapper {
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;
}

.gap-16 {
    gap: 16px;
}
</style>

<template>
  <div
    :class="[
      'align-center d-flex flex-column h-100 justify-center pa-4 w-100',
      { wrapped: showDetails },
    ]"
  >
    <div
      v-if="showDetails || props.edit || props.delete"
      class="align-center d-flex justify-start mb-2 w-100"
    >
      <div
        :class="[
          'd-flex w-100 wrapped-container',
          { 'wrapped-container--hidden': loading },
        ]"
      >
        <div v-if="showDetails" class="d-flex flex-column">
          <c-typography
            class="mb-2"
            color-class="highlight-color"
            variant="body-3-400"
          >{{ props.fileOrigin }}</c-typography
          >
          <c-typography variant="body-2-600">{{
            file?.name || ""
          }}</c-typography>
        </div>
        <div>
          <c-button
            v-if="props.edit"
            class="mr-3"
            icon="ph:pencil-line-fill"
            mode="outline"
            @click="emit('edit')"
          />
          <c-button
            v-if="props.delete"
            icon="ph:trash-simple"
            mode="outline"
            @click="emit('delete')"
          />
        </div>
      </div>
    </div>
    <circle-loader
      v-if="props.loading"
      :subtitle="loadingSubtitle || getSubtitle(dataProcessingState)"
      :title="loadingTitle || getTitle(dataProcessingState)"
    />

    <div v-else class="align-center d-flex flex-grow">
      <video
        v-show="videoReady"
        id="playerReady"
        :class="['video', { 'video--mobile': props.mobile }]"
        controls
        preload="metadata"
        :src="props.file?.url || ''"
        :style="{ maxHeight: maxHeight }"
        @loadedmetadata="handleVideoLoadMeta"
      />
    </div>
    <circle-loader
      v-if="!videoReady"
      subtitle="please wait..."
      title="Loading your video"
    />
  </div>
</template>

<script setup lang="ts">
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
  import PlaygroundConsentCheckbox from "../PlaygroundConsentCheckbox.vue";
  import { usePlaygroundStore } from "@/core/store/playgroundStore";
  import { useProgressStore } from "@/core/store/useProgressStore";
  import {
    fileProcessing,
    type FileProcessingStage,
  } from "@/core/types/file-processing.types";
  import { ref, toRefs } from "vue";

  // @ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  //PROPS & EMITS
  const props = defineProps({
    edit: {
      type: Boolean,
      default: false,
    },
    delete: {
      type: Boolean,
      default: true,
    },
    file: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    fileOrigin: {
      type: String,
      default: "Selected file",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    hideConsent: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    videoCreator: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "",
    },
    loadingTitle: {
      type: String,
      default: "",
    },
    loadingSubtitle: {
      type: String,
      default: "",
    },
  });

  const emit = defineEmits(["delete", "edit"]);

  //STORE
  const progressStore = useProgressStore();
  const { dataProcessingState, dataProcessingPercentage } = toRefs(progressStore);
  const playgroundStore = usePlaygroundStore();

  const videoReady = ref(false);

  const handleVideoLoadMeta = () => {
    videoReady.value = true;
  };

  const getTitle = (currentState: FileProcessingStage) => {
    return currentState.title;
  };

  const getSubtitle = (currentState: FileProcessingStage) => {
    if (fileProcessing.isUploadingFile(currentState)) {
      return `${dataProcessingPercentage.value}%`;
    }
    return "please wait";
  };
</script>

<style lang="scss" scoped>
.wrapped {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 8px;

  &-container {
    justify-content: space-between;

    &--hidden {
      visibility: hidden;
    }
  }
}

.video {
  border-radius: 16px;
  max-width: 100%;

  &--mobile {
    height: unset;
    max-width: 100%;
  }
}

.flex-grow {
  flex-grow: 1;
}

.margin-top-auto {
  margin-top: auto;
}
</style>

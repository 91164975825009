<template>
  <c-dialog
    :confirm-option="false"
    :height="294"
    :mobile="isMobile"
    :model-value="modelValue"
    width="500"
    @keydown.enter="confirmDelete"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #title>Are you sure?</template>
    <template #content>
      <div class="align-center justify-center mb-6 text-center">
        <c-typography
          class="d-block mb-6"
          color-class="copy-secondary"
          variant="body-2-400"
        >
          Please provide password for account delete confirmation.
        </c-typography>
        <c-input
          v-model="data.userPass"
          :append-inner-icon="data.showUserPass ? 'mdi:eye' : 'mdi:eye-off'"
          density="compact"
          :disabled="form.isLoading(state)"
          mode="outlined"
          placeholder="Current password"
          required
          :rules="[...inputRules().password]"
          :type="data.showUserPass ? 'text' : 'password'"
          @click:append-inner="data.showUserPass = !data.showUserPass"
        />
      </div>
      <c-button
        block
        :disabled="form.isLoading(state) || !isPasswordValid(data.userPass)"
        :loading="form.isLoading(state)"
        @click="confirmDelete"
      >Confirm</c-button
      >
      <div v-if="form.isErrored(state)" class="ma-1 text-center text-error">
        {{ state.message }}
      </div>
    </template>
  </c-dialog>
</template>

<script setup lang="ts">
  import { deleteUserAccount } from "@/core/api/slowpoke.auth.api";
  import { isMobile } from "@/core/utils/mobile";
  import { logout } from "@/core/services/login.service";
  import { Routes } from "@/core/routes/core.guard";
  import useFormState from "@/core/composables/useFormState";
  import { useRouter } from "vue-router";
  import { useStore } from "@/core/store";
  import { computed, reactive, watch } from "vue";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";
  import { inputRules, isPasswordValid } from "@/core/utils/validators";

  // @ts-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  //PROPS & EMITS
  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  //STORE
  const { logout: storeLogout } = useStore();

  //ROUTER
  const router = useRouter();

  const data = reactive({
    userPass: "",
    showUserPass: false,
  });

  const deleteWithEmailForm = useFormState(deleteUserAccount, FORM_INITIAL_STATE);

  const confirmDelete = () => {
    deleteWithEmailForm.submit({ password: data.userPass }).then(async () => {

      if (form.isSuccess(state.value)) {
        await logout();
        console.log("inside confirmDelete logout");
        storeLogout();
        router.push({ name: Routes.LOGIN.name });
      }
    });
  };

  watch(
    () => props.modelValue,
    (open: boolean) => {
      if (open) {
        data.userPass = "";
        deleteWithEmailForm.state.value = FORM_INITIAL_STATE;
      }
    }
  );

  const state = computed(() => {
    return deleteWithEmailForm.state.value;
  });
</script>

<template>
  <view-layout>
    <template #content>
      <div class="d-flex flex-column w-100">
        <home-title :features="titleFeaturesToDisplay" />
        <recent-twins
          class="mt-4"
          subtitle="Create a new Photo Twin or choose from available options"
          title="AI Photo Twins"
          :twins="
            [...userPhotoTwinModels, ...systemPhotoTwinModels].slice(0, 10)
          "
        />
        <recent-twins
          class="my-4"
          subtitle="Create a new Video Twin or select from available twins"
          title="AI Video Twins"
          :twins="[...userZeroShotModels, ...systemLipsyncModels].slice(0, 10)"
          video
        />
        <templates :features="templates" />
      </div>
    </template>
  </view-layout>

  <audio-tools-dialog v-model="openCreateAudio" />
</template>

<script setup lang="ts">
  import AudioToolsDialog from "../components/Home/AudioToolsDialog.vue";
  import HomeTitle from "../components/Home/Title.vue";
  import RecentTwins from "../components/Home/RecentTwins.vue";
  import Templates from "../components/Home/Templates.vue";
  import ViewLayout from "@/core/layouts/ViewLayout.vue";

  import { useRouter } from "vue-router";
  import { useUserStore } from "../store/userStore";

  import { PLAYGROUND_FEATURES } from "../data/playgroundFeatures";
  import { Routes } from "../routes/core.guard";
  import type { TitleFeature } from "../types/other.types";

  import { computed, ref } from "vue";
  import { storeToRefs } from "pinia";

  const router = useRouter();
  const userStore = useUserStore();

  const {
    userPhotoTwinModels,
    systemPhotoTwinModels,
    systemZeroShotModels,
    userZeroShotModels,
    systemLipsyncModels,
  } = storeToRefs(userStore);

  const openCreateAudio = ref(false);

  const videoCreator: TitleFeature = {
    name: "Video Twin Studio",
    testId: "video",
    title: "Video Twin Studio",
    desc: "Upload your input and explore the comprehensive studio for creating your AI generated Twin. Choose a way to create content tailored to your needs",
    tags: ["TEXT TO VIDEO", "AUDIO TO VIDEO"],
    image: "/images/home/videoTwin.webp",
    hidden: false,
    soon: false,
    routeName: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
    actionIcon: "ph:users-fill",
    actionLabel: "Explore",
    infoDesc: PLAYGROUND_FEATURES.VIDEO_CREATOR.dialog.description,
  };

  const videoCreatorWaitlist: TitleFeature = {
    name: "aiTwinWaitlist",
    title: "Video Twin Studio",
    testId: "waitlist",
    desc: "Upload your input and explore the comprehensive studio for creating your AI generated Twin. Choose a way to create content tailored to your needs",
    tags: ["TEXT TO VIDEO", "AUDIO TO VIDEO"],
    image: "/images/home/videoTwin.webp",
    hidden: false,
    soon: false,
    routeName: Routes.OFFERS.children.EARLY_ACCESS.name,
    actionIcon: "ph:users-fill",
    actionLabel: "AI Twin Waitlist",
    infoDesc: PLAYGROUND_FEATURES.VIDEO_CREATOR.dialog.description,
  };

  const photoTwinStudio: TitleFeature = {
    name: "photoTwinStudio",
    title: "Photo Twin Studio",
    testId: "photoTwinStudio",
    desc: "Take advantage of our Photo Twin Studio to discover the possibilities of the Flux engine and transform yourself into any stylized character!",
    tags: ["TEXT TO PHOTO", "PHOTO TO PHOTO"],
    image: "/images/home/photoTwin.webp",
    hidden: false,
    soon: false,
    routeName: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
    actionIcon: "ph:aperture-fill",
    actionLabel: "Explore",
    infoDesc: PLAYGROUND_FEATURES.VIDEO_CREATOR.dialog.description,
  };

  const promptGallery: TitleFeature = {
    name: "Prompt Gallery",
    testId: "promptGallery",
    title: "Prompt Gallery",
    desc: "See yourself as a superhero, a medieval warrior, a pirate ship captain and others!",
    tags: [],
    image: "/images/home/templates/prompt_big2.jpg",
    images: [
      "/images/home/templates/prompt5.jpg",
      "/images/home/templates/prompt4.jpg",
      "/images/home/templates/prompt6.jpg",
    ],
    hidden: false,
    soon: false,
    routeName: Routes.PHOTO_TWIN_STUDIO.children.PROMPT_GALLERY.name,
    actionIcon: "ph:browsers-simple-fill",
    actionLabel: "Browse Prompt Gallery",
    infoDesc: "",
  };

  const videoSystemTwins: TitleFeature = {
    name: "Video Twin Gallery",
    testId: "videoSystemTwins",
    title: "Video Twin Gallery",
    desc: "Use our system AI Video Twins to get a feel for how the Gemelo platform works.",
    tags: [],
    image: "/images/home/templates/system_big.jpg",
    images: [
      "/images/home/templates/system1_2.jpg",
      "/images/home/templates/system2_2.jpg",
      "/images/home/templates/system3_2.jpg",
    ],
    hidden: false,
    soon: false,
    routeName: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
    actionIcon: "material-symbols:video-library-outline-rounded",
    actionLabel: "Browse Video Twins",
    infoDesc: "",
  };

  const audioCreator: TitleFeature = {
    name: "audioCreator",
    title: "Audio Creator",
    testId: "audio",
    soon: false,
    desc: "Browse our Voice Library and pick the ideal voice for your creation and transform your written or spoken message into MP3 speech audio",
    tags: ["SPEECH TO SPEECH", "TEXT TO SPEECH"],
    hidden: false,
    image: "/images/home/createAudio.png",
    action: () => {
      openCreateAudio.value = true;
    },
    actionIcon: "ph:microphone-fill",
    actionLabel: "Create Audio",
    infoDesc: [
      PLAYGROUND_FEATURES.TTS.dialog.description,
      PLAYGROUND_FEATURES.STS.dialog.description,
      PLAYGROUND_FEATURES.VOICE_CLONING.dialog.description,
    ].join(" "),
  };

  const titleFeaturesToDisplay = computed(() => userStore.ui_local_data.portraitStudioAccess
    ? [photoTwinStudio, videoCreator]
    : [videoCreatorWaitlist, photoTwinStudio]
  );

  const templates = computed(() => {
    return [promptGallery, videoSystemTwins];
  });
</script>

<style scoped lang="scss">
.home {
  display: flex;
  gap: 24px;
  justify-content: flex-start;

  &--mobile {
    gap: 8px;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .home {
    grid-template-columns: min-content min-content min-content;
  }
}

:deep(.v-slide-group__prev) {
  display: none;
}

:deep(.v-slide-group__next) {
  display: none;
}
</style>

import { Routes } from "@/core/routes/core.guard";

//Removed dynamic import as this is a initial page from route
import Contact from "../pages/Contact.vue";

export default [
  {
    name: Routes.CONTACT.name,
    path: Routes.CONTACT.path,
    component: Contact,
    meta: { requiresAuth: true },
  },
];

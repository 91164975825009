function addPartOfTime(
  value: number,
  text: string,
  shortUnits?: boolean
): string {
  if (value === 0) {
    return "";
  }
  return !shortUnits && value > 1 ? ` ${value} ${text}s` : ` ${value} ${text}`;
}

export function convertToCurrency(cents: number, currency: string): string {
  if (currency === "PROMOTION") {
    return "CODE";
  }

  return cents <= 0
    ? ""
    : `${parseFloat(String(cents / 100)).toFixed(
        2
      )}  ${currency.toUpperCase()}`;
}

export function convertToMinutes(timeInMiliseconds: number): string {
  const seconds = timeInMiliseconds / 1000;
  const minutes = Math.floor(seconds / 60);

  const remainingSeconds = String(Math.round(seconds - minutes * 60)).padStart(
    2,
    "0"
  );

  return `${minutes}:${remainingSeconds} MIN`;
}

export function convertToTime(time: number, shortUnits?: boolean): string {
  const hourUnit = shortUnits ? "h" : "hour";
  const minuteUnit = shortUnits ? "m" : "minute";
  const secondUnit = shortUnits ? "s" : "second";

  if (time < 60) {
    return `${addPartOfTime(time, secondUnit, shortUnits)}`;
  }

  const minutes = Math.floor(time / 60);
  const seconds = (time - minutes * 60).toFixed(2);

  if (minutes < 60) {
    return `${addPartOfTime(minutes, minuteUnit, shortUnits)} ${addPartOfTime(
      Number(seconds),
      secondUnit,
      shortUnits
    )}`;
  }

  const hours = Math.floor(time / 3600);
  const rminutes = Math.floor(time / 60) - hours * 60;
  const rseconds = time - hours * 3600 - rminutes * 60;

  return `${addPartOfTime(hours, hourUnit, shortUnits)} ${addPartOfTime(
    rminutes,
    minuteUnit,
    shortUnits
  )} ${addPartOfTime(rseconds, secondUnit, shortUnits)}`;
}

export function convertMiliseconds(time: number, shortUnits?: boolean): string {
  return convertToTime(parseFloat((time / 1000).toFixed(2)), shortUnits);
}

export function convertBytes(bytes: number, decimals = 2): string {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatDate(date: string): string {
  const dateToFormat = new Date(date);

  const options: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return dateToFormat.toLocaleString("en-US", options);
}

export function numberWithSpaces(x: any) : string {
  if (Number.isInteger(x)) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return String(x);
}

export const formatCreateDate = (val: string): string => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(val);
  const formattedDate = date.toLocaleString("en-US", optionsDate);

  return formattedDate;
};

const MONTHS = [
  { full: "January", abbrev: "Jan." },
  { full: "February", abbrev: "Feb." },
  { full: "March", abbrev: "March" },
  { full: "April", abbrev: "April" },
  { full: "May", abbrev: "May" },
  { full: "June", abbrev: "June" },
  { full: "July", abbrev: "July" },
  { full: "August", abbrev: "Aug." },
  { full: "September", abbrev: "Sept." },
  { full: "October", abbrev: "Oct." },
  { full: "November", abbrev: "Nov." },
  { full: "December", abbrev: "Dec." },
];

export const convertDate = (date : string, fullMonth = true): string => {
  const sDate = new Date(date);

  const day = sDate.getDate();
  const month = sDate.getMonth();
  const year = sDate.getFullYear();

  const monthPart = fullMonth ? MONTHS[month].full : MONTHS[month].abbrev;

  if (fullMonth) {
    return day + " " + monthPart;
  }
  else {
    return `${monthPart} ${day}, ${year}`;
  }
};

export const convertShareDate = (date: string) : string => {
  const sDate = new Date(date);

  const day = sDate.getDate();
  const month = sDate.getMonth();
  const year = sDate.getFullYear();
  const hour = sDate.getHours();
  const minutes = sDate.getMinutes();
  const monthPart =  MONTHS[month].abbrev;

  return `${monthPart} ${day}, ${year} at ${hour}:${minutes}`;
};


export const billingHistoryHeaders = [
  {
    title: "Quantity",
    key: "formatedQuantity",
  },
  {
    title: "Cost",
    key: "formattedCost",
  },
  {
    title: "Date",
    key: "boughtAt",
  },
];

<template>
  <c-typography
    v-if="showMainNav"
    class="d-block mb-7 text-center"
    data-testid="ai-twin-studio-title"
    variant="subtitle-2-600"
  >Video Twin Studio
  </c-typography>
  <div v-if="showMainNav" :class="['d-flex gap-16', {'gap-8': isMobile}]">
    <c-info-card
      v-for="tab in tabs"
      :id="tab.href"
      :key="tab.href"
      :active="isActive(tab.routeName)"
      class="tab w-100"
      height="auto"
      :icon="tab.icon"
      :is-mini="false"
      :test-id="`twin-studio-tab-${tab.href}`"
      :text-cut="false"
      variant="feature"
      @click="navigateTo(tab)"
    >
      <template #title>{{ tab.label }}</template>
      <template #subtitle>{{ tab.description }}</template>
    </c-info-card>
  </div>
  <router-view :active-main-tab="data.activeTab" />
</template>

<script lang="ts" setup>
//@ts-ignore
  import { CTypography } from "@charactr/wooper-ui/atoms";
  //@ts-ignore
  import { CInfoCard } from "@charactr/wooper-ui/molecules";
  import { isMobile } from "@/core/utils/mobile";
  import { computed, onMounted, reactive, watch } from "vue";
  import { TWIN_STUDIO_TABS } from "../types/index";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";

  const data = reactive({
    activeTab: TWIN_STUDIO_TABS.ZERO_SHOT,
  });

  const router = useRouter();

  const routeName = computed(() => {
    return router.currentRoute.value.name;
  });

  const showMainNav = computed(() => {
    const extractBasePath = (path: string): string => path.split("/")[2];

    const currentPath = router.currentRoute.value.path;
    const lipsyncPath = extractBasePath(Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.path);
    const portraitPath = extractBasePath(Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.path);

    return !currentPath.includes(lipsyncPath) && !currentPath.includes(portraitPath);
  });

  const tabs = computed(() => [
    {
      label: TWIN_STUDIO_TABS.ZERO_SHOT,
      active: data.activeTab === TWIN_STUDIO_TABS.ZERO_SHOT,
      href: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.path,
      routeName: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
      icon: "mdi:fast-forward",
      description: "Don't have much time and want to generate a slightly lower quality but still cool AI Twin? Try this one!",
    },
    {
      label: TWIN_STUDIO_TABS.LIPSYNC_MODELS,
      active: data.activeTab === TWIN_STUDIO_TABS.LIPSYNC_MODELS,
      href: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.path,
      routeName: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
      icon: "ph:diamonds-four-fill",
      description: "Generate your AI Twin in the best possible quality. It will take time, but the result will be truly amazing!",
    },
    {
      label: TWIN_STUDIO_TABS.SYNTHETICS,
      active: data.activeTab === TWIN_STUDIO_TABS.SYNTHETICS,
      href: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.path,
      routeName: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.name,
      icon: "ph:user-square-fill",
      description: "Don't have a video of yourself? Discover the feature where you can generate an AI avatar from your photo!",
    },
  ]);

  watch(() => routeName.value, (newVal) => {
    data.activeTab = tabs.value.find(el => el.routeName === newVal)?.label || TWIN_STUDIO_TABS.ZERO_SHOT;
  }, {
    immediate: true,
  });

  const navigateTo = (val: any) => {
    data.activeTab = val.label;
    router.push({ name: val.routeName });
  };

  const isActive = (name: string | undefined) => {
    return routeName.value === name;
  };

  onMounted(() => {
    const currentTab = tabs.value.find((el) => el.routeName === routeName.value);

    if (currentTab) {
      data.activeTab = currentTab.label;
    }
  });
</script>

<style lang="scss" scoped>
.tab {
  overflow: hidden;
}

.gap {
  &-16 {
    gap: 16px
  }

  &-8 {
    gap: 8px;
  }

}
</style>

<template>
  <c-container :class="['container', { container__outlined: outlined }]">
    <div class="container--content">
      <div v-if="$slots.title" class="header" :style="{ gap: gap + 'px' }">
        <div :class="[{ 'w-100': fullTitle }]">
          <slot name="title">
            <c-typography variant="subtitle-2-400">
              {{ label }}
            </c-typography>
          </slot>
        </div>
        <slot name="search" />
        <slot name="title-action">
          <c-button
            v-if="refresh"
            icon="mdi:refresh"
            @click="emit('refresh')"
          />
        </slot>
      </div>
      <slot v-if="!isError && !loading && !noData" />
      <slot v-if="noData" name="noData" />
      <div v-if="isError" class="container--wrapper text-error">
        <c-icon icon="mdi:alert-circle-outline" />
      </div>
      <div v-if="loading" class="container--wrapper">
        <v-progress-circular color="#A67DFF" indeterminate />
      </div>
    </div>
  </c-container>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  // @ts-ignore
  import { CButton, CContainer, CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    error: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    noDivider: {
      type: Boolean,
      default: false,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    mainPaddingClass: {
      type: String,
      default: "pa-10",
    },
    gap: {
      type: [String, Number],
      default: 0,
    },
    fullTitle: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["refresh"]);

  const isError = computed(() => {
    return props.error !== "";
  });
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.container {
  &__outlined {
    border: 1px solid rgba(var(--v-theme-light-frame));
  }

  &--content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    text-align: left;
    width: 100%;
  }

  &--wrapper {
    font-size: 40px;
    margin-top: 10px;
    text-align: center;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  :deep(.c-icon-button) {
    height: 24px !important;
    width: 24px !important;
  }
}
</style>

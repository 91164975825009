<template>
  <c-dialog
    :cancel-option="false"
    :class="[{ 'dialog-desktop': !isMobile }]"
    :close-option="false"
    :confirm-option="false"
    hide-title
    min-width="300"
    :model-value="modelValue"
    reset-paddings
    variant="secondary"
    :width="isMobile ? '100%' : 'auto'"
    @cancel="$emit('update:modelValue', false)"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #content>
      <div :class="['d-flex', { 'flex-column mt-2': isMobile }]">
        <div
          v-show="!isLoading"
          :key="reloadKey"
          class="align-center d-flex justify-center"
        >
          <div
            :class="[
              'position-relative wrapper',
              { 'wrapper--mobile': isMobile },
            ]"
          >
            <c-icon
              v-if="!isLoading"
              class="arrow arrow-left"
              data-testid="recent_videos_prev"
              height="32"
              icon="ph:arrow-left"
              icon-color="rgb(var(--v-theme-copy-secondary))"
              width="32"
              @click="prev"
            />
            <img
              :class="[
                'avatar-preview',
                { 'avatar-preview--mobile': isMobile },
              ]"
              :src="photoEl?.url"
              @load="handleImageLoad"
            />
            <c-skeleton
              v-if="isLoading || !photoEl?.url"
              :class="['loader', { 'loader--mobile': isMobile }]"
              type="image"
            ></c-skeleton>
            <div
              v-if="!promptDialog"
              :class="[
                'bottom-img-actions px-3 py-2',
                { 'bottom-img-actions--mobile': isMobile },
              ]"
            >
              <slot name="actions-container" />
            </div>
            <c-icon
              v-if="!isLoading"
              class="arrow arrow-right"
              data-testid="recent_videos_next"
              height="32"
              icon="ph:arrow-right"
              icon-color="rgb(var(--v-theme-copy-secondary))"
              size="x-small"
              width="32"
              @click="next"
            />
          </div>
        </div>
        <div class="align-space-between d-flex flex-column flex-grow-1 pa-4">
          <div
            v-if="!isGridVisible && !isAnimating"
            :class="[
              'd-flex justify-space-between mb-2',
              [promptDialog ? 'align-start' : 'align-center'],
            ]"
          >
            <div class="align-center d-flex">
              <c-typography
                class="mr-1"
                color-class="highlight-color"
                variant="body-3-400"
              >Created on</c-typography
              >
              <c-typography color-class="copy-primary" variant="body-3-400">
                {{ formatCreateDate(photoEl.createdAt) }}</c-typography
              >
            </div>

            <c-button
              v-if="!isMobile"
              class="close-btn"
              data-testid="close-btn"
              height="24"
              icon="ph:x"
              icon-height="24"
              mode="secondary"
              width="24"
              @click="$emit('update:modelValue', false)"
            />
          </div>

          <div
            v-if="!isGridVisible && !isAnimating"
            :class="[
              'prompt-wrapper px-3 py-2',
              { 'prompt-wrapper--mobile': isMobile },
            ]"
          >
            <c-typography color-class="copy-secondary" variant="body-3-400">
              {{ photoEl?.prompt }}
            </c-typography>
          </div>
          <div
            v-if="!isGridVisible && !isAnimating"
            class="actions d-flex gap-16 mt-3 w-100"
          >
            <c-button
              class="flex-grow-1"
              mode="secondary"
              prepend-icon="ph:copy-fill"
              @click="() => copyPrompt(photoEl?.prompt)"
            >Copy Prompt</c-button
            >
            <!-- <c-button
                class="flex-grow-1"
                mode="secondary"
                prepend-icon="ph:heart-fill"
              >Add to favorites</c-button
              > -->
          </div>
          <slot name="buttons">
            <div
              v-if="promptDialog"
              :class="[
                'actions align-bottom d-flex flex-column flex-grow-1 w-100',
                { 'mt-2': isMobile },
              ]"
            >
              <c-typography
                v-if="!isGridVisible && !isAnimating"
                class="align-end d-flex flex-grow-1 margin-top-auto mb-4"
                variant="body-3-400"
              >
                or select one of the Photo Twins to use the prompt with it!
              </c-typography>
              <div
                :class="[
                  'd-flex flex-column pa-2 selected-twin-wrapper',
                  { 'selected-twin-wrapper--mobile': isMobile },
                  { expanded: isGridVisible },
                ]"
              >
                <div
                  :class="[
                    'grid-twins',
                    { 'grid-twins--mobile': isMobile },
                    { expanded: isGridVisible },
                  ]"
                >
                  <div
                    v-for="(el, idx) in [
                      ...listOfPhotoTwinModels.filter(
                        (el) => el.status === 'ready'
                      ),
                    ]"
                    :key="idx"
                    class="position-relative"
                  >
                    <div v-if="el.id === selectedTwin.id" class="gradient" />
                    <img
                      :class="[
                        'cursor-pointer grid-twin',
                        { 'grid-twin--selected': el.id === selectedTwin.id },
                        { 'grid-twin--mobile': isMobile },
                      ]"
                      :src="el.thumbnailUrl"
                      @click="() => setSelectedTwin(el)"
                    />
                  </div>
                </div>
                <div :class="['align-center d-flex justify-space-between']">
                  <div class="align-center d-flex">
                    <img
                      class="img-twin mr-4"
                      :src="selectedTwin.thumbnailUrl"
                    />
                    <c-typography variant="body-2-600">{{
                      selectedTwin.name
                    }}</c-typography>
                  </div>
                  <c-button
                    v-if="!isGridVisible"
                    mode="highlight-plain"
                    no-padding
                    @click="toggleGrid"
                  >Change</c-button
                  >
                  <c-icon
                    v-else
                    class="cursor-pointer mr-2"
                    color="rgb(var(--v-theme-copy-secondary))"
                    height="20"
                    icon="mdi:chevron-down"
                    @click="toggleGrid"
                  />
                </div>
              </div>
              <c-button
                class="mt-4"
                d-block
                height="40"
                mode="primary"
                @click="usePromptWithTwin"
              >{{
                isGridVisible ? "Select Twin" : "Use with selected Photo Twin"
              }}</c-button
              >
            </div>
          </slot>
        </div>
      </div>
    </template>
  </c-dialog>
</template>

<script lang="ts" setup>
  import { AlertModes } from "@/core/types/other.types";
  import { formatCreateDate } from "@/core/utils/formatters";
  import { isMobile } from "@/core/utils/mobile";
  import type { PhotoTwinModel } from "@/core/types/synthetic.types";
  import { Routes } from "@/core/routes/core.guard";
  import { storeToRefs } from "pinia";
  import { usePhotoTwinStudioStore } from "../store";
  import { useRouter } from "vue-router";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { onMounted, onUnmounted, ref, watch } from "vue";

  // @ts-ignore
  // prettier-ignore
  import { CDialog } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  // prettier-ignore
  import { CButton, CIcon, CSkeleton, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    modelValue: { type: Boolean, required: true },
    photoEl: { type: Object, default: () => ({}) },
    promptDialog: {
      type: Boolean,
      default: true,
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits([
    "update:modelValue",
    "showPrev",
    "showNext",
    "updateClickedItem",
  ]);

  const prev = () => {
    emit("showPrev");
  };

  const next = () => {
    emit("showNext");
  };

  const router = useRouter();
  const userStore = useUserStore();
  const { listOfPhotoTwinModels } = storeToRefs(userStore);

  const photoTwinStudioStore = usePhotoTwinStudioStore();
  const { prompt: storePrompt } = storeToRefs(photoTwinStudioStore);

  const isLoading = ref(true);
  const reloadKey = ref(0);
  const isGridVisible = ref(false);
  const isAnimating = ref(false);
  const selectedTwin = ref(listOfPhotoTwinModels.value[0] as PhotoTwinModel);
  const { showSnackbar } = useSnackbarStore();

  watch(
    () => props.modelValue,
    (val) => {
      if (val) {
        isLoading.value = true;
      }
    }
  );

  watch(
    () => props.photoEl?.url,
    () => {
      isLoading.value = true;
      reloadKey.value++;
    }
  );

  watch(isGridVisible, () => {
    isAnimating.value = true;
    setTimeout(() => {
      isAnimating.value = false;
    }, 200);
  });

  const handleImageLoad = () => {
    isLoading.value = false;
  };

  const toggleGrid = () => {
    isGridVisible.value = !isGridVisible.value;
  };

  const setSelectedTwin = (twin: PhotoTwinModel) => {
    selectedTwin.value = twin;
  };

  const copyPrompt = (val: string) => {
    navigator.clipboard.writeText(val);
    showSnackbar("Prompt copied.", AlertModes.SUCCESS);
  };

  const usePromptWithTwin = () => {
    if (isGridVisible.value) {
      toggleGrid();
      return;
    }
    storePrompt.value = props.photoEl.prompt;

    router.push({
      name: Routes.PHOTO_TWIN_STUDIO.children.MODEL.name,
      params: { id: selectedTwin.value.id, openDialog: 1 },
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      prev();
    } else if (event.key === "ArrowRight") {
      next();
    }
  };

  onMounted(() => {
    window.addEventListener("keydown", handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener("keydown", handleKeyDown);
  });
</script>

<style lang="scss" scoped>
.gap-16 {
  gap: 16px;
}

.gap-8 {
  gap: 8px;
}

.avatar-preview {
  border-radius: 14px 0 0 14px;
  max-height: 100%;
  max-width: 550px;
  object-fit: contain;

  &--mobile {
    border-radius: 16px;
    max-width: 100%;
  }
}

.wrapper {
  height: 550px;
  width: 550px;

  &--mobile {
    height: 320px;
    width: 320px;
  }
}

.loader {
  border-radius: 14px 0 0 14px;
  height: 550px;
  opacity: 85%;
  position: absolute;
  top: 0;
  width: 550px;
  z-index: 1;

  :deep(.v-skeleton-loader__bone) {
    border-radius: 14px 0 0 14px;
    height: 550px;
    width: 550px;
  }

  &--mobile {
    height: 320px;
    width: 320px;

    :deep(.v-skeleton-loader__bone) {
      border-radius: 14px 0 0 14px;
      height: 320px;
      width: 320px;
    }
  }
}

.prompt-wrapper {
  border: 1px solid rgba(var(--v-theme-light-frame));
  border-radius: 12px;
  max-height: 160px;
  min-height: 160px;
  overflow: scroll;
  width: 361px;

  &--mobile {
    width: 100%;
  }
}

.selected-twin-wrapper {
  background: rgba(var(--v-theme-highlight-aphla));
  border: 2px solid rgba(var(--v-theme-light-frame));
  border-radius: 12px;
  height: 60px;
  margin-top: auto;
  overflow: hidden;
  position: relative;
  transform-origin: bottom;
  transition: height 0.4s ease, transform 0.4s ease;
  width: 361px;

  &.expanded {
    height: 462px;
  }

  &--mobile {
    width: 100%;
  }
}

.img-twin {
  border-radius: 8px;
  height: 40px;
}

.margin-top-auto {
  margin-top: auto;
}

.grid-twins {
  display: grid;
  gap: 12px;
  grid-auto-rows: 104px;
  grid-template-columns: repeat(3, 104px);
  height: 0;
  justify-content: center;
  max-height: 450px;
  opacity: 0%;
  overflow: hidden;
  transition: height 0.4s ease, opacity 0.4s ease;

  &--mobile {
    grid-auto-rows: 90px;
    grid-template-columns: repeat(3, 90px);
  }
}

.grid-twins.expanded {
  height: 450px;
  opacity: 100%;
  overflow: scroll;
}

.grid-twin {
  border: 2px solid transparent;
  border-radius: 12px;
  height: 104px;

  &:hover {
    border-color: rgba(var(--v-theme-button-primary));
  }

  &--selected {
    border-color: rgba(var(--v-theme-button-primary));
  }

  &--mobile {
    height: 90px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  height: 8px !important;
}

.arrow {
  background: rgb(0 0 0 / 70%);
  border-radius: 16px;
  color: rgba(var(--v-theme-cta));
  cursor: pointer;
  padding: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  &-left {
    left: 8px;
  }

  &-right {
    right: 8px;
  }
}

.gradient {
  background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 55%,
      rgb(71 65 154 / 20%) 75%,
      rgb(71 65 154 / 40%) 85%,
      rgb(71 65 154 / 55%) 95%,
      rgb(71 65 154 / 70%) 100%
    )
    no-repeat;
  border: 2px solid transparent;
  border-radius: 0 0 12px 12px;
  border-radius: 16px;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.bottom-img-actions {
  background: rgb(0 0 0 / 70%);
  border-radius: 0 0 0 14px;
  bottom: 0;
  position: absolute;
  width: 100%;

  &--mobile {
    border-radius: 0 0 14px 14px;
  }
}
</style>

<style lang="scss">
.dialog-desktop {
  .dialog__content {
    overflow-y: hidden !important;
  }
}
</style>

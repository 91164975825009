import { apiV2Url } from "@/core/utils/api";
import { TOKEN } from "@/core/types/login.types";
import { useS3UploadStore } from "../store/useS3UploadStore";
import coreClient, { tokenClientId } from "@/core/api/core.api";

type UploadResponseType = {
  coverUrl: null | string,
  createdAt: string,
  id: number,
  name: string,
  status: string,
  isBuiltIn: boolean,
  uploadUrl: string,
  uuid: string
}

export async function uploadModelVideo(payload: {
  name: string,
  contentMd5: string,
  contentLength: string | number,
  contentType: string,
}): Promise<UploadResponseType> {
  const clientId = tokenClientId();
  const token = localStorage.getItem(TOKEN);

  const url = apiV2Url(`clients/${clientId}/lipsync-models?consent=true`);

  const response = await coreClient.post(url, payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  });

  return response.data;
}

export function S3Upload(payload: { url: string, video: File }): Promise<any> {

  const S3UploadStore = useS3UploadStore();

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = false;

    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;

        S3UploadStore.percentage = Math.round(percentComplete);
      }
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Error uploading file"));
        }
      }
    };

    xhr.open("PUT", payload.url);
    xhr.send(payload.video);
  });
}

export async function verifyModel(lipsyncId: number): Promise<any> {
  const token = localStorage.getItem(TOKEN);
  const clientId = tokenClientId();

  const url = apiV2Url(`clients/${clientId}/lipsync-models/${lipsyncId}/verify-upload`);

  const response = await coreClient.post(url, null, {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  });

  return response.data;
}

import { apiUrl } from "@/core/utils/api";
import coreClient, { tokenClientId } from "@/core/api/core.api";
import type {
  FullVideoShareData,
  StatusData,
  VideoShareData,
} from "@/modules/share/types";

//url (shareId) is not ready yet, so we have to create it with request below
export async function activateSharing(
  videoId: number,
  shareData: VideoShareData
): Promise<FullVideoShareData> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/videos/${videoId}/share`);

  const response = await coreClient.post(url, shareData);

  return response.data;
}

//update link status - shared/disabled
export async function toggleShareActive(
  shareId: string,
  status: StatusData
): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`/clients/${clientId}/share/${shareId}/change-status`);

  const response = await coreClient.patch(url, {
    status,
  });

  return response.data;
}

export async function updateSharedVideoData(
  shareId: string,
  shareData: VideoShareData
): Promise<void> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/share/${shareId}`);

  const response = await coreClient.patch(url, shareData);

  return response.data;
}

export async function getSharedVideoData(
  shareId: string
): Promise<FullVideoShareData> {
  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/share/${shareId}`);

  const response = await coreClient.get(url);

  return response.data;
}

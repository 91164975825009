import TwinsSection from "../pages/TwinsSection.vue";
import TwinPage from "../pages/TwinPage.vue";
import AITwinStudio from "../pages/AITwinStudio.vue";
import { Routes } from "@/core/routes/core.guard";

import VideoCreatorUseAudio from "@/modules/videoCreator/components/audio/VideoCreatorUseAudio.vue";
import VideoCreatorUseVideo from "@/modules/videoCreator/components/video/VideoCreatorUseVideo.vue";
import VideoCreatorUseText from "@/modules/videoCreator/components/text/VideoCreatorUseText.vue";
import VideoCreatorUseDubbing from "@/modules/videoCreator/components/dubbing/VideoCreatorUseDubbing.vue";
import ConfigureAction from "@/modules/videoCreator/components/ConfigureAction.vue";
import VideoCreatorWelcomeContent from "@/modules/videoCreator/components/welcome/WelcomeContent.vue";

export default [
  {
    name: Routes.AI_TWIN_STUDIO.name,
    path: Routes.AI_TWIN_STUDIO.path,
    component: AITwinStudio,
    redirect: { name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name },
    meta: { requiresAuth: true },
    children: [
      {
        name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.name,
        path: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODELS.path,
        component: TwinsSection,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
      },
      {
        name: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.name,
        path: Routes.AI_TWIN_STUDIO.children.ZERO_SHOT.path,
        component: TwinsSection,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
      },
      {
        name: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.name,
        path: Routes.AI_TWIN_STUDIO.children.SYNTHETICS.path,
        component: TwinsSection,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
      },
      {
        name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.name,
        path: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.path,
        component: TwinPage,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
        redirect: {
          name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
          ?.VIDEO_CREATOR.children?.TEXT.name,
        },
        children: [
          {
            name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
              ?.VIDEO_CREATOR.name,
            path: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
              ?.VIDEO_CREATOR.path,
            component: VideoCreatorWelcomeContent,
            redirect: {
              name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
              ?.VIDEO_CREATOR.children?.TEXT.name,
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
            children: [
              {
                name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                  ?.VIDEO_CREATOR.children?.AUDIO.name,
                path: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                  ?.VIDEO_CREATOR.children?.AUDIO.path,
                components: {
                  content: VideoCreatorUseAudio,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                        ?.VIDEO_CREATOR.children?.AUDIO.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
              {
                name: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                  ?.VIDEO_CREATOR.children?.TEXT.name,
                path: Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                  ?.VIDEO_CREATOR.children?.TEXT.path,
                components: {
                  content: VideoCreatorUseText,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.LIPSYNC_MODEL.children
                        ?.VIDEO_CREATOR.children?.TEXT.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
            ],
          },
        ],
      },
      {
        name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.name,
        path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.path,
        component: TwinPage,
        meta: {
          requiresAuth: true,
          requiresPortraitStudioFeature: true,
        },
        redirect: {
          name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
          ?.VIDEO_CREATOR.children?.TEXT.name,
        },
        children: [
          {
            name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
              ?.VIDEO_CREATOR.name,
            path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
              ?.VIDEO_CREATOR.path,
            component: VideoCreatorWelcomeContent,
            redirect: {
              name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
              ?.VIDEO_CREATOR.children?.TEXT.name,
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
            children: [
              {
                name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.AUDIO.name,
                path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.AUDIO.path,
                components: {
                  content: VideoCreatorUseAudio,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                        ?.VIDEO_CREATOR.children?.AUDIO.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
              {
                name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.VIDEO.name,
                path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.VIDEO.path,
                components: {
                  content: VideoCreatorUseVideo,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                        ?.VIDEO_CREATOR.children?.VIDEO.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
              {
                name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.TEXT.name,
                path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.TEXT.path,
                components: {
                  content: VideoCreatorUseText,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                        ?.VIDEO_CREATOR.children?.TEXT.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
              {
                name: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.DUBBING.name,
                path: Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                  ?.VIDEO_CREATOR.children?.DUBBING.path,
                components: {
                  content: VideoCreatorUseDubbing,
                  action: ConfigureAction,
                },
                props: {
                  action: {
                    routeName:
                      Routes.AI_TWIN_STUDIO.children.SYNTHETIC_PORTRAIT.children
                        ?.VIDEO_CREATOR.children?.DUBBING.name,
                  },
                },
                meta: {
                  requiresAuth: true,
                  lighterBg: true,
                  requiresPortraitStudioFeature: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

<template>
  <div>
    <create-new-two-options-dialog
      v-model="data.newVoiceDialog"
      voice-cloning
    />
    <confirm-consent-dialog
      v-if="data.confirmConsentDialog"
      v-model="data.confirmConsentDialog"
      @accept="confirmConsent"
    >
      <template #title>
        Do you have consent from the individual whose voice is being cloned?
      </template>
      <template #description>
        By uploading the individual's voice sample, you confirm that you have
        obtained their explicit consent for the digital replication of their
        voice. You also acknowledge that you are solely responsible for ensuring
        that you have the legal right to use and manipulate the uploaded audio
        sample.
      </template>
    </confirm-consent-dialog>
    <div
      :class="['d-flex align-end text-center', [isMobile ? 'my-4' : 'mb-7']]"
    >
      <c-button
        v-if="!isMainCloningView"
        mode="highlight-plain"
        no-padding
        prepend-icon="ph:caret-left"
        size="x-small"
        @click="goBack"
      >
        Back
      </c-button>
      <c-typography
        :class="['w-100', { 'extra-right-padding': !isMainCloningView }]"
        variant="subtitle-2-600"
      >
        Voice Cloning
      </c-typography>
    </div>

    <div
      v-if="isRecordingView"
      class="align-center d-flex frame justify-space-between mb-4 pa-2 pl-4 pr-0 w-100"
    >
      <c-typography color-class="copy-primary" variant="body-2-400"
      >Before recording, read and follow our audio guide</c-typography
      >
      <c-button
        mode="highlight-plain"
        prepend-icon="ph:circle-wavy-check-fill"
        size="small"
        @click="data.cloningInfoDialog = true"
      >Audio Guide</c-button
      >
    </div>
    <div
      :class="[
        'card-wrapper',
        { 'pa-6': !isMobile },
        { 'pa-4': isMobile && isMainCloningView },
        { 'card-wrapper--mobile': isMobile },
      ]"
    >
      <template v-if="isMainCloningView">
        <div
          v-if="isVoiceCloningLimitReached"
          class="d-flex flex-column limits px-4 py-3"
        >
          <c-typography color-class="copy-primary" variant="body-2-600"
          >Limit Reached</c-typography
          >
          <div class="pt-3">
            <c-typography color-class="copy-secondary" variant="body-3-400">
              You have reached the limit of
              {{ userStore.clonedVoicesLimitNumber }} cloned voices in Voices
              Library. Upgrade to unlock the full potential.
            </c-typography>
          </div>

          <div class="pt-4">
            <c-button
              height="40"
              mode="special"
              :to="{ name: Routes.SHOP.name }"
              width="125"
            >Upgrade</c-button
            >
          </div>
        </div>
        <div
          v-else
          :class="['align-center d-flex', { 'flex-column': isMobile }]"
        >
          <create-new-card @click="openCreateNewDialog" />
          <div class="d-flex flex-grow-1 justify-center">
            <c-typography
              :class="[
                'button-secondary d-block text-center',
                { 'mt-4': isMobile },
              ]"
              color-class="button-secondary"
              variant="subtitle-2-600"
            >
              Start the process of creating your perfect Voice Clone
            </c-typography>
          </div>
        </div>
        <c-typography
          class="d-block mb-3 mt-5"
          color-class="copy-primary"
          variant="subtitle-1-600"
        >
          Cloned voices
        </c-typography>
        <div
          :class="[
            ' list-wrapper',
            {'d-flex align-center justify-center': data.loading || !clonedVoices.length }
          ]"
        >
          <circle-loader
            v-if="data.loading"
            no-padding
            title="Loading voices..."
          />
          <template v-else-if="clonedVoices.length">
            <voice-details
              v-for="(voice, idx) in clonedVoices"
              :key="voice.id"
              :border-bottom="idx !== clonedVoices.length - 1 || idx === 0"
              cloned
              mode="panel-list"
              :voice="voice"
              :wrapper-class="wrapperClass"
            />
          </template>
          <template v-else>
            <div class="d-flex flex-column">
              <c-typography
                class=""
                color-class="copy-secondary"
                variant="body-2-600"
              >Cloned voices will be displayed here</c-typography
              >
              <c-typography
                class=""
                color-class="copy-secondary"
                variant="body-4-400"
              >Clone your first voice</c-typography
              >
            </div>
            <img height="120" src="/images/spaceCat.svg" />
          </template>
        </div>
      </template>
      <router-view v-else @open-new-dialog="openCreateNewDialog" />
    </div>
  </div>
  <recording-tips-dialog v-model="data.cloningInfoDialog" />
</template>

<script lang="ts" setup>
// @ts-ignore
// prettier-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";
  import CircleLoader from "@/core/components/Container/CircleLoader.vue";
  import ConfirmConsentDialog from "@/core/components/Dialogs/ConfirmConsentDialog.vue";
  import CreateNewCard from "@/core/components/CreateNewCard.vue";
  import CreateNewTwoOptionsDialog from "@/core/components/Dialogs/CreateNewTwoOptionsDialog.vue";
  import { isMobile } from "@/core/utils/mobile";
  import RecordingTipsDialog from "../components/dialogs/RecordingTipsDialog.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { useUserStore } from "@/core/store/userStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";
  import VoiceDetails from "@/core/components/Voice/VoiceDetails.vue";
  import { computed, onBeforeMount, reactive, toRefs, watch } from "vue";

  const voicesListStore = useVoicesListStore();

  const { fetchClonedVoices } = voicesListStore;
  const { clonedVoices } = toRefs(voicesListStore);
  const userStore = useUserStore();

  const router = useRouter();

  const data = reactive({
    loading: false,
    newVoiceDialog: false,
    cloningInfoDialog: false,
    confirmConsentDialog: false,
    consentConfirmed: false,
  });

  onBeforeMount(async () => {
    if (!clonedVoices.value.length) {
      data.loading = true;
      await fetchClonedVoices();
      data.loading = false;
    }
  });

  const wrapperClass = computed(
    () => `${isMobile.value ? "pl-2 pr-3 py-2" : "py-3 pl-2 pr-3"}`
  );

  watch(
    () => router.currentRoute.value.name,
    () => {
      data.newVoiceDialog = false;
    }
  );

  const openCreateNewDialog = () => {
    data.newVoiceDialog = true;
  };

  const isMainCloningView = computed(() => {
    return router.currentRoute.value.name === Routes.VOICE_CLONING.name;
  });

  const isRecordingView = computed(() => {
    return (
      router.currentRoute.value.name === Routes.VOICE_CLONING.children.RECORD.name
    );
  });

  const confirmConsent = () => {
    data.consentConfirmed = true;
    data.confirmConsentDialog = false;
  };

  const isVoiceCloningLimitReached = computed(() => {
    if (userStore.isUnlimitedVoiceCloningAccess) return false;
    return clonedVoices.value.length >= userStore.clonedVoicesLimit;
  });

  const goBack = () => {
    router.go(-1);
  };
</script>

<style lang="scss" scoped>
.ml-10 {
  margin-left: 10px;
}

.card-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-light-frame));
  border-radius: 16px;
  width: 600px;

  &--mobile {
    border-color: transparent;
    width: auto;
  }
}

.gallery-add-new {
  border: 1px dashed rgb(var(--v-theme-dark-frame));
  border-radius: 12px !important;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
  cursor: pointer;

  &:hover {
    background: rgb(var(--v-theme-highlight-aphla)) !important;
    border: 1px solid rgb(var(--v-theme-button-primary));
  }
}

.button-secondary {
  color: rgb(var(--v-theme-button-secondary-hover)) !important;
  max-width: 280px;
}

.list-wrapper {
  background: rgb(var(--v-theme-aphla-bg));
  border: 1px solid rgb(var(--v-theme-dark-frame));
  border-radius: 12px;
  max-height: 308px;
  min-height: 196px;
  overflow-y: scroll;
}

.frame {
  background: rgb(var(--v-theme-aphla-bg));
  border-radius: 12px;
}

.extra-right-padding {
  padding-right: 87px;
}
</style>


//Removed dynamic import as this is a initial page from route
import SpeechToSpeech from "../pages/SpeechToSpeech.vue";
import TextToSpeech from "../pages/TextToSpeech.vue";
import VideoToVideo from "../pages/VideoToVideo.vue";
import VoiceCloning from "../pages/VoiceCloning.vue";
import VoiceCloningRecord from "../pages/VoiceCloningRecord.vue";
import VoiceCloningSuccess from "../pages/VoiceCloningSuccess.vue";
import VoiceCloningUpload from "../pages/VoiceCloningUpload.vue";

//Dynamically imported components
const ConfigureAction = (): Promise<RouteComponent> => import("@/modules/videoCreator/components/ConfigureAction.vue");
const ConfigureAudio = (): Promise<RouteComponent> => import("@/modules/videoCreator/components/audio/ConfigureAudio.vue");
const ConfigurePicSelector = (): Promise<RouteComponent> => import("@/core/components/PictureSelection/ConfigurePicSelector.vue");
const ConfigureTextArea = (): Promise<RouteComponent> => import("@/modules/videoCreator/components/text/ConfigureTextArea.vue");
const ConfigureVideoUploader = (): Promise<RouteComponent> => import("@/modules/videoCreator/components/video/ConfigureVideoUploader.vue");
const SyntheticVideo =  (): Promise<RouteComponent> => import("../pages/SyntheticVideo.vue");
const PreviewVideo = (): Promise<RouteComponent> => import("../components/videoToVideo/Preview/PreviewVideo.vue");
const PreviewVideoAction = (): Promise<RouteComponent> => import("../components/videoToVideo/Preview/PreviewVideoAction.vue");

import { getClientVideo } from "@/core/services/synthetics.service";
import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
import { Routes } from "@/core/routes/core.guard";
import type { NavigationGuardNext, RouteComponent, RouteLocation } from "vue-router";
import { useVideoCreatorStore } from "@/core/store/useVideoCreatorStore";
import { storeToRefs } from "pinia";

export default [
  {
    name: Routes.TEXT_TO_SPEECH.name,
    path: Routes.TEXT_TO_SPEECH.path,
    component: TextToSpeech,
    meta: {
      requiresAuth: true,
      rightDrawer: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
    },
  },
  {
    name: Routes.SPEECH_TO_SPEECH.name,
    path: Routes.SPEECH_TO_SPEECH.path,
    component: SpeechToSpeech,
    meta: {
      requiresAuth: true,
      rightDrawer: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
    },
  },

  {
    name: Routes.VIDEO_TO_VIDEO.name,
    path: Routes.VIDEO_TO_VIDEO.path,
    component: VideoToVideo,
    meta: {
      requiresAuth: true,
      rightDrawer: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
      requiresPortraitStudioFeature: true,
    },
    redirect: { name: Routes.VIDEO_TO_VIDEO.children.CREATE.name },
    children: [
      {
        name: Routes.VIDEO_TO_VIDEO.children.CREATE.name,
        path: Routes.VIDEO_TO_VIDEO.children.CREATE.path,
        meta: {
          requiresAuth: true,
          rightDrawer: true,
          isConsumingBallance: true,
          isMobileFullScreenView: true,
          playgroundMode: PLAYGROUND_FEATURES.VTV,
          requiresPortraitStudioFeature: true,
        },
        components: {
          default: ConfigureVideoUploader,
          picSelector: ConfigurePicSelector,
          action: ConfigureAction,
        },
      },
      {
        name: Routes.VIDEO_TO_VIDEO.children.PREVIEW.name,
        path: Routes.VIDEO_TO_VIDEO.children.PREVIEW.path,
        meta: {
          requiresAuth: true,
          rightDrawer: true,
          isConsumingBallance: true,
          isMobileFullScreenView: true,
          requiresPortraitStudioFeature: true,
          playgroundMode: PLAYGROUND_FEATURES.VTV,
        },
        components: {
          default: PreviewVideo,
          action: PreviewVideoAction,
        },
        beforeEnter: async (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext
        ) : Promise<void> => {
          const { videoPreview, isLoadingVideoPreview } = storeToRefs(useVideoCreatorStore());

         try {
          isLoadingVideoPreview.value= true;
          videoPreview.value = await getClientVideo(Number(to.params.id));
         }
         catch (e: any) {
          videoPreview.value = null;
         }
         finally {
          isLoadingVideoPreview.value = false;
          next();
         }

        },
      },

    ],
  },
  {
    name: Routes.VIDEO_SYNTHESIS.name,
    path: Routes.VIDEO_SYNTHESIS.path,
    component: SyntheticVideo,
    meta: {
      requiresAuth: true,
      rightDrawer: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
    },
    redirect: { name: Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name },
    children: [
      {
        name: Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.name,
        path: Routes.VIDEO_SYNTHESIS.children.CREATE_TEXT.path,
        meta: {
          requiresAuth: true,
          rightDrawer: true,
          isConsumingBallance: true,
          isMobileFullScreenView: true,
          playgroundMode: PLAYGROUND_FEATURES.VIDEO_SYNTHESIS,
        },
        components: {
          picSelector: ConfigurePicSelector,
          inputArea: ConfigureTextArea,
          action: ConfigureAction,
        },
      },
      {
        name: Routes.VIDEO_SYNTHESIS.children.CREATE_AUDIO.name,
        path: Routes.VIDEO_SYNTHESIS.children.CREATE_AUDIO.path,
        meta: {
          requiresAuth: true,
          rightDrawer: true,
          isConsumingBallance: true,
          isMobileFullScreenView: true,
          playgroundMode: PLAYGROUND_FEATURES.VIDEO_SYNTHESIS,
        },
        components: {
          picSelector: ConfigurePicSelector,
          inputArea: ConfigureAudio,
          action: ConfigureAction,
        },
      },
      {
        name: Routes.VIDEO_SYNTHESIS.children.PREVIEW.name,
        path: Routes.VIDEO_SYNTHESIS.children.PREVIEW.path,
        meta: {
          requiresAuth: true,
          rightDrawer: true,
          isConsumingBallance: true,
          isMobileFullScreenView: true,
          playgroundMode: PLAYGROUND_FEATURES.VIDEO_SYNTHESIS,
        },
        components: {
          default: PreviewVideo,
          action: PreviewVideoAction,
        },
        beforeEnter: async (
          to: RouteLocation,
          from: RouteLocation,
          next: NavigationGuardNext
        ) : Promise<void> => {
          const { videoPreview, isLoadingVideoPreview } = storeToRefs(useVideoCreatorStore());

         try {
          isLoadingVideoPreview.value = true;
          videoPreview.value = await getClientVideo(Number(to.params.id));
         }
         catch (e: any) {
          videoPreview.value = null;
         }
         finally {
          isLoadingVideoPreview.value = false;
          next();
         }

        },
      },
 ],
  },
  {
    name: Routes.VOICE_CLONING.name,
    path: Routes.VOICE_CLONING.path,
    component: VoiceCloning,
    meta: {
      requiresAuth: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
      centered: true,
    },
    children: [
      {
        name: Routes.VOICE_CLONING.children.UPLOAD.name,
        path: Routes.VOICE_CLONING.children.UPLOAD.path,
        component: VoiceCloningUpload,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: Routes.VOICE_CLONING.children.RECORD.name,
        path: Routes.VOICE_CLONING.children.RECORD.path,
        component: VoiceCloningRecord,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: Routes.VOICE_CLONING.children.SUCCESS.name,
        path: Routes.VOICE_CLONING.children.SUCCESS.path,
        component: VoiceCloningSuccess,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

import { PLAYGROUND_FEATURES } from "@/core/data/playgroundFeatures";
import type { RouteComponent } from "vue-router";
import { Routes } from "@/core/routes/core.guard";

import VideoCreator from "../pages/VideoCreator.vue";
import VideoCreatorWelcomeContent from "../components/welcome/WelcomeContent.vue";
import { useAppDrawersStateStore } from "@/core/store/useAppDrawersStateStore";

//Dynamically imported components
const VideoCreatorVoiceAndVisualSelection = (): Promise<RouteComponent> => import("../components/VideoCreatorVoiceAndVisualSelection.vue");

const VideoCreatorVisualSelection = (): Promise<RouteComponent> => import("../components/visual/VideoCreatorVisualSelection.vue");

const VideoCreatorVoiceSelection = (): Promise<RouteComponent> => import("../components/voice/VideoCreatorVoiceSelection.vue");

const VideoCreatorUseAudio = (): Promise<RouteComponent> => import("../components/audio/VideoCreatorUseAudio.vue");

const VideoCreatorUseDubbing = (): Promise<RouteComponent> => import("../components/dubbing/VideoCreatorUseDubbing.vue");

const VideoCreatorUseMultiText = (): Promise<RouteComponent> => import("../components/multiText/VideoCreatorUseMultiText.vue");

const VideoCreatorUseVideo = (): Promise<RouteComponent> => import("../components/video/VideoCreatorUseVideo.vue");

const VideoCreatorUseText = (): Promise<RouteComponent> => import("../components/text/VideoCreatorUseText.vue");

const VideoCreatorUseRecording = (): Promise<RouteComponent> => import("../components/recording/VideoCreatorUseRecording.vue");

const ConfigureAction = (): Promise<RouteComponent> => import("../components/ConfigureAction.vue");

export default [
  {
    name: Routes.VIDEO_CREATOR.name,
    path: Routes.VIDEO_CREATOR.path,
    component: VideoCreator,
    redirect: { name: Routes.VIDEO_CREATOR.children.MAIN.name },
    meta: {
      requiresAuth: true,
      rightDrawer: true,
      isConsumingBallance: true,
      isMobileFullScreenView: true,
      playgroundMode: PLAYGROUND_FEATURES.VIDEO_CREATOR,
      fullWidth: true,
    },
    children: [
      {
        name: Routes.VIDEO_CREATOR.children.MAIN.name,
        path: Routes.VIDEO_CREATOR.children.MAIN.path,
        components: {
          selection: VideoCreatorVoiceAndVisualSelection,
          content: VideoCreatorWelcomeContent,
        },
        meta: {
          requiresAuth: true,
          lighterBg: true,
          requiresPortraitStudioFeature: true,
        },
        children: [
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.AUDIO.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.AUDIO.path,
            components: {
              content: VideoCreatorUseAudio,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.AUDIO.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.VIDEO.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.VIDEO.path,
            components: {
              content: VideoCreatorUseVideo,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.VIDEO.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.TEXT.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.TEXT.path,
            components: {
              content: VideoCreatorUseText,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.TEXT.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.DUBBING.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.DUBBING.path,
            components: {
              content: VideoCreatorUseDubbing,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.DUBBING.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.path,
            components: {
              content: VideoCreatorUseMultiText,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.MULTI_TEXT.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
          {
            name: Routes.VIDEO_CREATOR.children.MAIN.children?.RECORDING.name,
            path: Routes.VIDEO_CREATOR.children.MAIN.children?.RECORDING.path,
            components: {
              content: VideoCreatorUseRecording,
              action: ConfigureAction,
            },
            props: {
              action: {
                routeName:
                  Routes.VIDEO_CREATOR.children.MAIN.children?.RECORDING.name,
              },
            },
            meta: {
              requiresAuth: true,
              lighterBg: true,
              requiresPortraitStudioFeature: true,
            },
          },
        ],
      },
      {
        name: Routes.VIDEO_CREATOR.children.VISUAL.name,
        path: Routes.VIDEO_CREATOR.children.VISUAL.path,
        components: {
          content: VideoCreatorVisualSelection,
        },
        meta: {
          requiresAuth: true,
          lighterBg: true,
          requiresPortraitStudioFeature: true,
        },
      },
      {
        name: Routes.VIDEO_CREATOR.children.VOICE.name,
        path: Routes.VIDEO_CREATOR.children.VOICE.path,
        components: {
          content: VideoCreatorVoiceSelection,
        },
        meta: {
          requiresAuth: true,
          lighterBg: true,
          requiresPortraitStudioFeature: true,
        },
      },
    ],
    beforeEnter: async (): Promise<void> => {
      const appDrawersState = useAppDrawersStateStore();
      const { setRail, setExpandOnHover } = appDrawersState;

      setRail(true);
      setExpandOnHover(false);
    },
  },
];

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div>
    <Container>
      <c-form
        v-if="!contactFormPushed"
        ref="formWrapper"
        v-model="formValid"
        @submit.prevent
      >
        <div class="d-flex flex-column">
          <c-typography class="pb-3" variant="subtitle-2-600"
          >Contact us</c-typography
          >
          <c-typography
            class="pb-3"
            color-class="copy-secondary"
            variant="body-2-400"
          >Questions, concerns, requests? Fill the form and we will be back to
            you.</c-typography
          >
        </div>

        <div>
          <c-typography color-class="copy-secondary" variant="body-2-600"
          >Personal Information</c-typography
          >
        </div>

        <div class="mt-2">
          <div class="d-flex flex-row" style="gap: 8px">
            <c-input
              :key="componentKey + 'a'"
              v-model="data.userName"
              density="compact"
              :disabled="form.isLoading(state)"
              maxLength="50"
              mode="outlined"
              placeholder="Name"
              :required="rulesOn"
            />
            <c-input
              :key="componentKey + 'b'"
              v-model="data.userLastName"
              density="compact"
              :disabled="form.isLoading(state)"
              maxLength="50"
              mode="outlined"
              placeholder="Last Name"
              :required="rulesOn"
            />
          </div>
        </div>

        <div class="mb-2 pt-3">
          <c-typography color-class="copy-secondary" variant="body-2-600"
          >Message</c-typography
          >
        </div>

        <c-input
          :key="componentKey + 'c'"
          v-model="data.subject"
          class="mb-2"
          density="compact"
          :disabled="form.isLoading(state)"
          maxLength="100"
          mode="outlined"
          placeholder="Subject"
          :required="rulesOn"
        />

        <c-textarea
          :key="componentKey + 'd'"
          v-model="data.message"
          :disabled="form.isLoading(state)"
          :max="500"
          mode="outlined"
          placeholder="Type your answer"
          :required="rulesOn"
          rows="2"
          test-id="contact"
        />

        <div class="align-center d-flex justify-center py-4">
          <c-checkbox
            v-model="agreeRules"
            :disabled="form.isLoading(state)"
            :gap="8"
            :height="22"
            typography="body-2-400"
          />

          <c-typography
            color-class="copy-secondary"
            pointer
            variant="body-3-400"
            @click="form.isLoading(state) ? '' : (agreeRules = !agreeRules)"
          >
            By submitting to this form I accept charactr's
            <router-link
              class="link"
              :to="Routes.TERMS_OF_SERVICE.path"
              @click.stop
            >
              Terms of Use
            </router-link>
            and
            <router-link
              class="link"
              :to="Routes.PRIVACY_POLICY.path"
              @click.stop
            >
              Privacy policy</router-link
            >.
          </c-typography>
        </div>
        <c-button
          block
          :disabled="!agreeRules || data.message.length > 500"
          :loading="form.isLoading(state)"
          :size="isMobile ? 'x-large' : 'large'"
          type="primary"
          @click="submitForm"
        >Submit</c-button
        >
        <c-typography v-if="form.isErrored(state)" error variant="body-2-400">{{
          state.message
        }}</c-typography>
      </c-form>

      <info-view
        v-if="contactFormPushed"
        button-href="/contact"
        button-label="Go Back"
        subtitle="We will get back to you soon"
        title="Thank you for contacting us"
        @action="contactFormPushed = false"
      />
    </Container>
  </div>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import Container from "@/core/components/Container/Container.vue";
  import InfoView from "@/core/components/Info/InfoView.vue";
  import { isMobile } from "@/core/utils/mobile";
  import { Routes } from "@/core/routes/core.guard";
  import { sendContactUsMessage } from "../services/contact.service";
  import { sendFormDataToHubSpot } from "@/core/services/hubspot.service";
  import useFormState from "@/core/composables/useFormState";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import { analytics, CONTACT } from "@/core/utils/analytics";
  import { computed, nextTick, reactive, ref } from "vue";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";

  // @ts-ignore
  import { CButton, CCheckbox, CForm, CInput, CTextarea, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();

  const data = reactive({
    userName: "",
    userLastName: "",
    subject: "",
    message: "",
  });

  const contactFormPushed = ref(false);
  const agreeRules = ref(true);
  const rulesOn = ref(false);
  const componentKey = ref(0);
  const formValid = ref(false);
  const formWrapper = ref<null | HTMLFormElement>(null);

  const contactForm = useFormState(sendContactUsMessage, FORM_INITIAL_STATE);

  const state = computed(() => {
    return contactForm.state.value;
  });

  const sendToHubSpot = () => {

    const hubspotData = {
      urlId: import.meta.env.VITE_HUBSPOT_FORM_SUPPORT,
      formData: {
        email: userStore.userEmail,
        firstname: data.userName,
        lastName: data.userLastName,
        message: data.subject + "\n" + data.message,
      },
      pageName: "App - Support form",
    };

    sendFormDataToHubSpot(hubspotData);
  };

  const submitForm = async () => {
    rulesOn.value = true;
    componentKey.value++;

    nextTick(() => {
      (formWrapper.value as HTMLFormElement).validate().then(() => {
        if (!formValid.value) {
          return;
        }

        const formUserData = {
          email: userStore.userEmail,
          firstName: data.userName,
          lastName: data.userName,
          subject: data.subject,
          message: data.message,
        };

        contactForm.submit(formUserData).then(() => {
          if (form.isSuccess(state.value)) {
            sendToHubSpot();
            analytics.sendEvent(
              "contactForm",
              CONTACT.actions.SEND_MESSAGE_SUCCESS
            );
            contactFormPushed.value = true;
          }
          if (form.isErrored(state.value)) {
            showSnackbar(state.value.message, AlertModes.ERROR);
            analytics.sendEvent(
              "contactForm",
              CONTACT.actions.SEND_MESSAGE_ERROR
            );
            contactFormPushed.value = false;
          }
        });
      });
    });
  };
</script>

<style scoped lang="scss">
.container {
  max-width: 496px;
}

:deep(.v-container) {
  padding: 0;
}
</style>

<template>
  <c-menu width="206" >
    <template #activator="{ props, isActive }">
      <div class="align-center d-flex justify-center" data-testid="userMenu" v-bind="props">
        <c-avatar
          v-if="userStore.userEmail && !userStore.ui_local_data.googleAvatar"
          class="avatar"
          color="rgb(var(--v-theme-id-chips))"
          size="32"
        >
          <c-typography color-class="universal-gray" variant="subtitle-1-400">{{
            firstEmailLetter(userStore.userEmail)
          }}</c-typography>
        </c-avatar>
        <c-avatar
          v-if="userStore.ui_local_data.googleAvatar"
          :image="userStore.ui_local_data.googleAvatar"
          size="32"
        />
        <c-avatar
          v-if="!userStore.userEmail && !userStore.ui_local_data.googleAvatar"
          height="32"
          image="/images/appBar/user.png"
          size="32"
        />
        <c-icon
          class="ml-2"
          :class="['menu', { 'menu--expanded': isActive }]"
          color="rgb(var(--v-theme-universal-gray))"
          height="20"
          icon="ph:caret-down"
          plain
        />
      </div>
    </template>
    <c-list class="ma-0 menu__list pa-0" density="compact">
      <c-list-item
        v-for="(item, index) in userMenuItems"
        :key="index"
        class="menu__list__item"
        :data-testid="`menu-item-${item.url.slice(1)}`"
        :target="item.disabled ? null : item.target"
        :to="item.url"
      >
        <c-typography
          class="d-flex"
          :color-class="item.disabled ? 'copy-tertiary' : 'cta'"
          pointer
          :variant="isMobile ? 'body-1-400' : 'body-2-400'"
        >{{ item.label }}</c-typography
        >

        <template #prepend>
          <c-icon
            :color="
              item.disabled
                ? 'rgba(var(--v-theme-copy-tertiary))'
                : 'rgba(var(--v-theme-cta))'
            "
            height="14"
            :icon="item.icon"
            width="14"
          />
        </template>

        <template v-if="item.appendButtons" #append>
          <div
            v-for="appendButton in item.appendButtons"
            :key="appendButton.icon"
            class="add-ballance align-center d-flex pl-2"
            @click="appendButton.action()"
          >
            <c-icon
              class="ml-1"
              :color="appendButton.iconColor"
              height="20"
              :icon="appendButton.icon"
              width="20"
            />
          </div>
        </template>
      </c-list-item>
      <div class="align-center d-flex justify-center version-wrapper">
        <c-typography
          class="app-version"
          color-class="copy-tertiary"
          variant="body-4-400"
        >{{ appVersion }}
        </c-typography>
      </div>
    </c-list>
  </c-menu>

  <pricing-dialog v-model="showPricingDialog" />

  <request-dialog-template
    :model-value="requestTwinProDialog"
    :request="mappingDialogContent.voice.label"
    @update:model-value="requestTwinProDialog = false"
  >
  </request-dialog-template>
</template>

<script lang="ts" setup>
  import { isMobile } from "@/core/utils/mobile";
  import { numberWithSpaces } from "@/core/utils/formatters";
  import PricingDialog from "../Dialogs/PricingDialog.vue";
  import { Routes } from "@/core/routes/core.guard";
  import { useRouter } from "vue-router";
  import { useStorage } from "@vueuse/core";
  import { computed, onBeforeMount, ref, toRefs } from "vue";

  import { useWebsocket } from "@/core/composables/useWebsocket";

  import { tokenClientId } from "@/core/api/core.api";

  import { mappingDialogContent } from "@/modules/videoCreator/data/form-requests.data";
  import RequestDialogTemplate from "@/modules/videoCreator/components/requestDialog/RequestDialogTemplate.vue";

  // @ts-ignore
  import { CAvatar, CIcon, CList, CListItem, CMenu, CTypography } from "@charactr/wooper-ui/atoms";

  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useUserStore } from "@/core/store/userStore";
  import { useVoicesListStore } from "@/core/store/useVoicesListStore";

  import { defaults } from "@/core/data/userStorage";

  //STORE
  const { requestTwinProDialog } = toRefs(useDialogsStore());
  const userStore = useUserStore();
  const { fetchAllVoices } = useVoicesListStore();

  //ROUTER
  const router = useRouter();

  const showPricingDialog = ref(false);

  type MobileMenuItem = {
    icon: string;
    url: string;
    target: string;
    label: string;
    disabled?: boolean;
    appendButtons?: Array<{
      icon: string;
      iconColor: string;
      action(): void;
    }>;
  };

  const staticMenuItems: Array<MobileMenuItem> = [
    {
      icon: "ph:user-circle",
      url: Routes.ACCOUNT.path,
      target: "_self",
      label: "Account",
    },
    {
      label: "Subscriptions",
      url: Routes.SHOP.path,
      icon: "ph:hourglass-high-fill",
      target: "_self",
    },
    {
      icon: "ph:money",
      url: Routes.BILLING.path,
      target: "_self",
      label: "Billing",
    },
    {
      label: "Special Offers",
      url: Routes.OFFERS.path,
      icon: "ci:wavy-check",
      target: "_self",
    },
    {
      label: "Statistics",
      url: Routes.STATISTICS.path,
      icon: "ph:chart-pie-slice-fill",
      target: "_self",
    },
    {
      icon: "ph:question",
      url: Routes.CONTACT.path,
      target: "_self",
      label: "Support",
    },
    {
      icon: "ph:sign-out",
      url: Routes.LOGOUT.path,
      target: "_self",
      label: "Log out",
    },
  ];

  const userMenuItems = computed(() => {
    if (isMobile.value) {
      const priceItem = {
        disabled: true,
        url: Routes.SHOP.name,
        icon: "ph:coins",
        appendButtons: [
          {
            icon: "ph:question",
            iconColor: "rgb(var(--v-theme-copy-tertiary))",
            action: () => {
              showPricingDialog.value = true;
            },
          },
          {
            icon: "ph:plus-circle-fill",
            iconColor: "rgb(var(--v-theme-cta))",
            action: () => {
              router.push({ name: Routes.SHOP.name });
            },
          },
        ],
        label: userStore.isLoadingAccountBallance
          ? "loading..."
          : isNaN(Number(userStore.userAccountBallance))
            ? userStore.userAccountBallance
            : numberWithSpaces(Number(userStore.userAccountBallance)),
        target: "",
      };

      return ([priceItem, ...staticMenuItems]) as Array<MobileMenuItem>;
    }
    return ([...staticMenuItems]) as Array<MobileMenuItem>;
  });

  const appVersion = import.meta.env.VITE_APP_VERSION;

  onBeforeMount(async () => {
    const clientId = tokenClientId();

    useWebsocket();

    userStore.ui_local_data = useStorage(
      `ui_data_${clientId}`,
      defaults,
      localStorage,
      { mergeDefaults: true } //new added defaults will be merged automatically with local stoarge
    );
    await userStore.loadUserDetails();

    fetchAllVoices();
  });

  const firstEmailLetter = (userEmail: string) => {
    if (userEmail) {
      return userEmail[0].toUpperCase();
    }
  };
</script>

<style lang="scss" scoped>
:deep(.v-list-item__prepend svg) {
  opacity: 100% !important;
}

.avatar {
  border: 1px solid rgb(var(--v-theme-copy-secondary-lock)) !important;
}

.pointer {
  cursor: pointer;
}

.add-ballance {
  color: rgb(var(--v-theme-aphla-bg)) !important;
  cursor: pointer;
  text-decoration: none;

  svg {
    opacity: 100% !important;
  }
}

.menu {
  cursor: pointer;
  transition: all 0.4s ease;

  &--expanded {
    transform: rotate(180deg);
  }

  &__list {
    background-color: rgb(var(--v-theme-side-panel-bg)) !important;
    border-radius: 4px !important;
    opacity: 100% !important;
    z-index: 2000;

    &__item {
      padding: 10px 16px;
    }

    &__item:not(:last-child) {
      border-bottom: 1px solid rgb(var(--v-theme-id-chips));
    }

    &__item:hover {
      background-color: rgb(var(--v-theme-highlight-side-panel));
    }
  }
}

.version-wrapper {
  height: 24px;
}

:deep(.v-list-item__prepend svg) {
  margin-inline-end: 8px !important;
}

.app-version {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<template>
  <div class="d-flex flex-column home-twins-wrapper">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column">
        <c-typography
          class="d-block mb-1"
          data-testid="photo-twin-models-dashboard-title"
          variant="subtitle-2-600"
        >{{ props.title }}</c-typography
        >
        <c-typography
          color-class="copy-secondary"
          data-testid="photo-twin-models-dashboard-subtitle"
          variant="body-2-400"
        >{{ props.subtitle }}</c-typography
        >
      </div>
      <div v-if="showArrows" :style="{ minWidth: '68px' }">
        <c-button
          class="pr-2"
          data-testid="recent_videos_prev"
          :disabled="isPrevDisabled"
          height="24"
          icon="ph:arrow-left"
          icon-color="rgb(var(--v-theme-copy-secondary))"
          icon-height="24"
          plain
          size="x-small"
          @click="goPrev"
        ></c-button>
        <c-button
          data-testid="recent_videos_next"
          :disabled="isNextDisabled"
          height="24"
          icon="ph:arrow-right"
          icon-color="rgb(var(--v-theme-copy-secondary))"
          icon-height="24"
          plain
          size="x-small"
          @click="goNext"
        ></c-button>
      </div>
    </div>

    <circle-loader
      v-if="isLoadingVideos"
      class="flex-grow-1"
      no-padding
      :size="40"
      :title="`Loading ${video ? 'video' : 'photo'} twins...`"
    />

    <v-slide-group
      v-else-if="props.twins.length && !isLoadingVideos"
      ref="videoSlideGroup"
      class="mt-2 video-slide"
      :show-arrows="false"
    >
      <v-slide-group-item>
        <create-new-card
          @click="createNewTwin"
          @mouseover="data.hoveredId = 0"/>

      </v-slide-group-item>

      <v-slide-group-item v-for="img in twins" :key="img.id">
        <twin-card
          :key="`${img.id}_${img.status}_${img.name}`"
          v-model:hoveredId="data.hoveredId"
          hide-menu
          :img="img"
          :video="video"
        />
      </v-slide-group-item>
    </v-slide-group>
    <create-new-video-twin-dialog
      v-if="data.createNewVideoTwinDialog"
      v-model="data.createNewVideoTwinDialog"
    />
  </div>
</template>

<script lang="ts" setup>
  import TwinCard from "@/modules/photoTwinStudio/components/TwinCard.vue";
  import CircleLoader from "../Container/CircleLoader.vue";
  import { Routes } from "@/core/routes/core.guard";

  //@ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  //@ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";
  import { computed, onBeforeMount, type PropType, reactive, ref } from "vue";

  import { useDrawerStore } from "../RightDrawer/store";

  import { isMobile } from "@/core/utils/mobile";
  import { useMutationObserver } from "@vueuse/core";

  import type { PhotoTwinModel } from "@/core/types/synthetic.types";
  import { useRouter } from "vue-router";
  import CreateNewVideoTwinDialog from "./CreateNewVideoTwinDialog.vue";
  import CreateNewCard from "@/core/components/CreateNewCard.vue";

  const props = defineProps({
    twins: {
      type: Array as PropType<Array<PhotoTwinModel>>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    video: {
      type: Boolean,
      default: false,
    },
  });

  const drawerStore = useDrawerStore();
  const router = useRouter();

  const isLoadingVideos = ref(false);
  const videoSlideGroup = ref();

  const showSliderBtns = ref(false);
  const showArrows = computed(() => isMobile.value || showSliderBtns.value);

  const data = reactive({
    loading: true,
    hoveredId: 0,
    search: "",
    clickedMenuParentPicture: {} as any,
    createNewVideoTwinDialog: false,
  });

  onBeforeMount(async () => {
    try {
      if (!drawerStore.videoCreatorRecordings.length) {
        isLoadingVideos.value = true;
        await drawerStore.loadVideos();
      }
    } catch (e) {
      console.error(e);
    } finally {
      isLoadingVideos.value = false;
    }
  });

  const isNextDisabled = computed(() => !props.twins.length);
  const isPrevDisabled = computed(() => !props.twins.length);

  const goNext = () => {
    videoSlideGroup.value.scrollTo("last");
  };

  const goPrev = () => {
    videoSlideGroup.value.scrollTo("prev");
  };

  const createNewTwin = () => {
    if (props.video) {
      data.createNewVideoTwinDialog = true;
    } else {
      router.push({
        name: Routes.PHOTO_TWIN_STUDIO.children.MODELS_GALLERY.name,
        params: { openDialog: 1 },
      });
    }
  };

  useMutationObserver(
    videoSlideGroup,
    (mutations) => {
      if (mutations[0]) {
        const isClassMutation = mutations[0].attributeName === "class";

        if (isClassMutation) {
          const classList = (mutations[0].target as HTMLElement).classList;

          showSliderBtns.value = Array.from(classList).includes(
            "v-slide-group--is-overflowing"
          );

          //in case when screen was resized and scroll buttons dissaperared, then scroll to first element
          if (!showSliderBtns.value) {
            videoSlideGroup.value.scrollTo("first");
          }
        }
      }
    },
    {
      attributes: true,
    }
  );
</script>

<style scoped lang="scss">
:deep(.v-slide-group__content) {
  align-content: center;
  gap: 16px;
  margin: 2px;
}

:deep(.v-slide-group__prev) {
  display: none;
}

:deep(.v-slide-group__next) {
  display: none;
}

.gallery {
  &-add-new {
    border: 1px dashed rgb(var(--v-theme-dark-frame));
    border-radius: 12px !important;
    cursor: pointer;

    &:hover {
      background: rgb(var(--v-theme-highlight-aphla)) !important;
      border: 1px solid rgb(var(--v-theme-button-primary));
    }
  }
}

.home-twins-wrapper {
  min-height: 220px !important;
}
</style>

<template>
  <view-layout>
    <template #content>
      <Container
        class="px-6 py-4 section"
        :error="billingBalance?.error"
        :loading="userStore.isLoadingAccountBallance"
        outlined
      >
        <template #title>
          <div class="align-center d-flex justify-between w-100">
            <c-typography class="mb-2" variant="subtitle-2-600"
            >Account balance</c-typography
            >
            <c-button
              v-if="!isMobile"
              no-padding
              plain
              prepend-icon="ph:arrow-clockwise"
              size="default"
              @click="reloadBillingBallance"
            >
              Reload
            </c-button>
          </div>
        </template>

        <div :class="['align-center balance d-flex', { 'py-4': isMobile }]">
          <c-typography
            class="mr-10"
            color-class="copy-secondary"
            variant="body-2-400"
          >
            Tokens
          </c-typography>
          <div>
            <c-typography data-testid="total-balance" variant="subtitle-2-600">
              {{ numberWithSpaces(userStore.accountBallance) }}
            </c-typography>
          </div>
        </div>
      </Container>
      <Container
        class="px-6 py-4 section"
        :error="billingBalance?.error"
        :loading="userStore.isLoadingAccountBallance"
        outlined
      >
        <template #title>
          <div class="align-center d-flex justify-between w-100">
            <c-typography class="mb-2" variant="subtitle-2-600"
            >Your subscriptions</c-typography
            >
            <c-button
              :disabled="!userStore.subscriptionOption"
              no-padding
              plain
              prepend-icon="ph:gear-six"
              size="default"
              @click="manageSub"
            >
              Manage
            </c-button>
          </div>
        </template>
        <div :class="['d-flex flex-column']">
          <div class="align-center d-flex">
            <c-typography
              v-if="userStore.subscriptionOption !== null"
              color-class="highlight-color"
              variant="subtitle-2-600"
            >{{ getSubscriptionTitle(userStore.subscriptionOption) }}
              {{ getSubscriptionPromo() }}</c-typography
            >
            <c-chip
              class="ml-4"
              :selected="!!userStore.subscriptionOption"
              variant="filter_static"
            >
              {{ userStore.subscriptionOption ? "Active" : "Inactive" }}
            </c-chip>
          </div>
          <div
            v-if="userStore.subscriptionPromo"
            class="align-center d-flex mb-2 mt-3 sub-price"
          >
            <c-typography
              class="sub-price__old"
              color-class="copy-secondary"
              variant="body-2-400"
            >{{
              toDollars(userStore.subscriptionPromo.amountCents)
            }}</c-typography
            >
            <c-typography color-class="copy-secondary" variant="body-2-600"
            >{{
              toDollars(userStore.subscriptionPromo.amountFinalCents)
            }}/monthly</c-typography
            >
          </div>
          <c-typography
            v-if="showRegularPrice"
            class="mt-2"
            color-class="copy-secondary"
            variant="body-2-600"
          >{{ userStore.subscriptionPriceUSD  ? `$${userStore.subscriptionPriceUSD}` : "$19" }}/monthly</c-typography
          >

          <template v-if="userStore.subscriptionOption">
            <c-typography color-class="copy-secondary" variant="body-2-400"
            >{{ userStore.subscriptionCancel ? "Expires on " : "Renews " }}
              {{ subDate }} {{ promoUntil }}
            </c-typography>
          </template>

          <div class="d-flex mt-4">
            <c-button
              v-if="!userStore.subscriptionOption"
              class="mr-4"
              size="default"
              @click="subscribe"
            >
              Subscribe Now
            </c-button>
            <div class="d-flex justify-between w-100">
              <c-button
                mode="secondary"
                size="default"
                @click="showSubscriptionDetailsDialog = true"
              >
                See Details
              </c-button>
            </div>
          </div>
        </div>
      </Container>
      <shop-purchase :mobile="isMobile" />
      <Container
        class="px-6 py-4 section"
        :error="billingBalance?.error"
        :loading="userStore.isLoadingAccountBallance"
        outlined
      >
        <template #title>
          <c-typography variant="subtitle-2-600">Gift card</c-typography>
        </template>
        <c-typography color-class="copy-secondary" variant="body-2-400"
        >Enter a promotional code below:</c-typography
        >
        <div class="align-center d-flex justify-center">
          <c-input
            v-model="data.promoCode"
            density="compact"
            :disabled="form.isLoading(promoCodeState)"
            mode="outlined"
            placeholder="Type code"
            @keydown.enter="submitPromoCode"
          />
          <c-button
            autofocus
            class="ml-3"
            :disabled="isSubmitDisabled"
            :loading="form.isLoading(promoCodeState)"
            min-width="130"
            size="medium"
            @click="submitPromoCode"
            @keydown.enter="submitPromoCode"
          >
            Redeem
          </c-button>
        </div>
      </Container>

      <Container
        class="px-6 py-4 section"
        :error="billingBalance?.error"
        :loading="userStore.isLoadingAccountBallance"
        outlined
      >
        <template #title>
          <c-typography variant="subtitle-2-600">Pricing sheet</c-typography>
        </template>
        <c-typography color-class="copy-secondary" variant="body-2-400"
        >Review our pricing for individual services.</c-typography
        >
        <div class="align-center d-flex justify-center">
          <pricing-table />
        </div>
        <div>
          <c-typography class="mx-6" color-class="copy-secondary" variant="body-3-400">*You are charged by 30-second intervals</c-typography>
        </div>
      </Container>

      <c-data-table
        class="billing-alerts"
        :headers="billingAlertsHeaders"
        :loading="billingAlertsLoading"
        :mobile="isMobile"
        no-data-message="No token alerts"
        :rows="data.billingAlertsRows"
        table-description="Set up an alert to receive an email when your token balance reaches a specified limit."
        table-name="Token alerts"
      >
        <template #right-action>
          <create-billing-alert-dialog
            v-model="data.createNewBillingAlert"
            :loading="billingAlertsLoading"
            @refresh="reloadBillingAlerts"
          />
          <div>
            <c-button
              id="createAlert"
              :disabled="billingAlertsLoading"
              mode="secondary"
              size="default"
              :style="{ alignSelf: 'flex-start' }"
              @click="openCreateAlertDialog"
            >Create Alert</c-button
            >
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <c-button
            :id="`da${item.id}`"
            height="20"
            icon="ph:trash-simple"
            mode="secondary"
            width="auto"
            @click="openDeleteDialog(item)"
          />
        </template>
      </c-data-table>
      <delete-billing-alert
        :id="data.billingIdToDelete"
        v-model="data.deleteBillingAlertDialog"
        @alert-deleted="billingAlertDeleted"
      />
      <subscription-details v-model="showSubscriptionDetailsDialog" @buy="subscribe" />
    </template>
  </view-layout>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { billingAlertsHeaders } from "../data/billing.data";
  import type { BillingAlertsRow } from "@/core/types/billing.types";
  import Container from "@/core/components/Container/Container.vue";
  import CreateBillingAlertDialog from "../components/CreateBillingAlert.vue";
  import DeleteBillingAlert from "../components/DeleteBillingAlert.vue";
  import { getSubscriptionTitle } from "@/core/utils/subscription";
  import { isMobile } from "@/core/utils/mobile";
  import PricingTable from "@/core/components/PricingTable.vue";
  import ShopPurchase from "./ShopPurchase.vue";
  import SubscriptionDetails from "../components/SubscriptionDetails.vue";
  import { SubscriptionTypes } from "@/core/types/checkout.types";
  import useFormState from "@/core/composables/useFormState";
  import ViewLayout from "@/core/layouts/ViewLayout.vue";

  import { useDialogsStore } from "@/core/store/useDialogsStore";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";

  import { analytics, BILLING, PAYMENTS } from "@/core/utils/analytics";
  import {
    checkoutNew,
    enableTrial,
    manageSubscription,
  } from "@/core/services/checkout.service";

  import { claimPromoCode, getAlerts } from "@/core/services/billing.service";
  import { computed, onMounted, reactive, ref, toRefs } from "vue";
  import {
    convertDate,
    formatDate,
    numberWithSpaces,
  } from "@/core/utils/formatters";
  import { form, FORM_INITIAL_STATE } from "@/core/types/form-state.type";

  // @ts-ignore
  import { CDataTable } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CChip, CInput, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();
  const dialogsStore= useDialogsStore();
  const { showSubscriptionDetailsDialog } = toRefs(dialogsStore);

  const billingBalance = ref();
  const billingAlertsLoading = ref(false);

  const data = reactive({
    createNewBillingAlert: false,
    isRefreshingTresholds: false,
    isLoadingPayment: false,
    error: "",
    snackbar: false,
    billingAlertsRows: [] as Array<BillingAlertsRow>,
    billingIdToDelete: 0,
    deleteBillingAlertDialog: false,
    promoCode: "",
  });

  async function loadBillingAlerts() {
    billingAlertsLoading.value = true;
    try {
      const billingAlerts = await getAlerts();

      data.billingAlertsRows = billingAlerts.map((row: BillingAlertsRow) => convertRowDateColumns(row)
      );
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      billingAlertsLoading.value = false;
    }
  }

  const billingAlertDeleted = (billingAlertId: number) => {
    data.billingAlertsRows = data.billingAlertsRows.filter(
      (row: BillingAlertsRow) => row.id !== billingAlertId
    );
    data.deleteBillingAlertDialog = false;
    showSnackbar("Billing alert deleted", AlertModes.SUCCESS);
    analytics.sendEvent("billing", BILLING.actions.DELETE_ALERT);
  };

  const reloadBillingBallance = () => {
    analytics.sendEvent("billing", BILLING.actions.RELOAD_BILLING);
    userStore.updateBallance();
  };

  const openCreateAlertDialog = () => {
    data.createNewBillingAlert = true;
    analytics.sendEvent("billing", BILLING.actions.CREATE_BILLING_ALERT_DIALOG);
  };

  const toDollars = (amount: number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(amount / 100);
  };

  const convertRowDateColumns = (row: BillingAlertsRow) => {
    const updatedRow = Object.assign({}, row);

    updatedRow.createdAt = formatDate(row.createdAt);
    updatedRow.balanceThresholdMinutes = `${updatedRow.balanceThreshold} tokens`;
    return updatedRow;
  };

  async function openDeleteDialog(row: BillingAlertsRow) {
    data.billingIdToDelete = row.id;
    data.deleteBillingAlertDialog = true;
  }

  const reloadBillingAlerts = async () => {
    await loadBillingAlerts();
    data.createNewBillingAlert = false;
  };

  const promoCodeForm = useFormState(claimPromoCode, FORM_INITIAL_STATE);

  const submitPromoCode = async () => {
    if (!isSubmitDisabled.value) {
      promoCodeForm.submit({ code: data.promoCode }).then(() => {
        if (form.isSuccess(promoCodeState.value)) {
          showSnackbar("Promotional code success", AlertModes.SUCCESS);
          analytics.sendEvent("billing", PAYMENTS.actions.ADD_PROMO_CODE);
          userStore.updateBallance();
          data.promoCode = "";
        }
        if (form.isErrored(promoCodeState.value)) {
          showSnackbar(
            promoCodeState.value.message || "Error occured",
            AlertModes.ERROR
          );
        }
      });
    }
  };

  const getSubscriptionPromo = () => {
    if (userStore.subscriptionPromo) {
      return `- ${userStore.subscriptionPromo.name}`;
    }
  };

  const promoUntil = computed(() => {
    return userStore.subscriptionPromo
      ? `(promo until ${convertDate(userStore.subscriptionPromo.end)})`
      : "";
  });

  const showRegularPrice = computed(
    () => !userStore.subscriptionOption ||
      ((userStore.subscriptionOption === SubscriptionTypes.EARLY_BIRDS ||
        userStore.subscriptionOption === SubscriptionTypes.PROFESSIONAL) &&
        !userStore.subscriptionPromo)
  );

  const subDate = computed(() => convertDate(userStore.subscriptionUntil));

  const checkoutForm = useFormState(checkoutNew, FORM_INITIAL_STATE);

  const checkoutState = computed(() => {
    return checkoutForm.state.value;
  });

  const subscribe = async () => {
    checkoutForm.submit({ subscription: true }).then((checkout: any) => {
      if (form.isSuccess(checkoutState.value) && checkout?.data.url) {
        window.location.href = checkout?.data.url;
      }
      if (form.isErrored(checkoutState.value)) {
        showSnackbar(
          checkoutState.value.message || "Error occured",
          AlertModes.ERROR
        );
      }
    });
  };

  //todo: use when designs ready
  // const enableTrialForOneMonth = async () => {
  //   try {
  //     await enableTrial();
  //   } catch (e: any) {
  //     showSnackbar(
  //       e.response?.data.message || "Error occured",
  //       AlertModes.ERROR
  //     );
  //   }
  // };

  const manageSub = async () => {
    try {
      const data = await manageSubscription();

      window.location.href = data?.url;
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    }
  };

  const promoCodeState = computed(() => {
    return promoCodeForm.state.value;
  });

  const isSubmitDisabled = computed(
    () => form.isLoading(promoCodeState.value) || !data.promoCode
  );

  onMounted(async () => {
    await loadBillingAlerts();
  });
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.section {
  background-color: rgb(var(--v-theme-aphla-bg)) !important;
  border: 1px solid rgb(var(--v-theme-light-frame)) !important;
  border-radius: 16px;
}

.balance {
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: 30% 5% 65%;
}

.justify-between {
  justify-content: space-between;
}

.sub-price {
  gap: 8px;

  &__old {
    text-decoration: line-through;
  }

  &__chip {
    background-color: rgb(var(--v-theme-button-secondary)) !important;
    border-radius: 12px;
    height: 24px;
    padding: 0 12px;
  }
}
// one sepcific table header in application, no sense to update UI Kit
.billing-alerts {
  :deep(.v-toolbar__content) {
    align-items: flex-end !important;
  }

  :deep(.v-toolbar-title) {
    max-width: 320px !important;
  }
}
</style>

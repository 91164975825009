<template>
  <view-layout>
    <template #content>
      <Container
        class="px-6 py-4 section"
        :error="billingBalance?.error"
        :loading="userStore.isLoadingAccountBallance"
        outlined
      >
        <template #title>
          <c-typography class="mb-2" variant="subtitle-2-600"
          >Billing information</c-typography
          >
        </template>

        <div
          :class="['d-flex justify-between align-end', { 'py-4': isMobile }]"
        >
          <div class="d-flex flex-column">
            <c-typography color-class="copy-secondary" variant="body-2-400">
              {{ userStore.userEmail }}
            </c-typography>
          </div>
        </div>
      </Container>
      <c-data-table
        :id="2"
        :headers="billingHistoryHeaders"
        :loading="billingHistoryLoading"
        :mobile="isMobile"
        no-data-message="No billing history"
        :rows="data.billingHistoryRows"
        table-name="Billing history"
      >
        <template #right-action>
          <c-button
            plain
            prepend-icon="ph:arrow-clockwise"
            size="default"
            @click="reloadBillingHistory"
          >
            Reload
          </c-button>
        </template></c-data-table
      >
    </template>
  </view-layout>
</template>

<script setup lang="ts">
  import { AlertModes } from "@/core/types/other.types";
  import { billingHistoryHeaders } from "../data/billing.data";
  import type { BillingHistoryRow } from "@/core/types/billing.types";
  import Container from "@/core/components/Container/Container.vue";
  import { getHistory } from "@/core/services/billing.service";
  import { isMobile } from "@/core/utils/mobile";
  import { useSnackbarStore } from "@/core/store/useSnackbarStore";
  import { useUserStore } from "@/core/store/userStore";
  import ViewLayout from "@/core/layouts/ViewLayout.vue";
  import { analytics, BILLING } from "@/core/utils/analytics";
  import {
    convertToCurrency,
    formatDate,
  } from "@/core/utils/formatters";
  import { onMounted, reactive, ref } from "vue";

  // @ts-ignore
  import { CDataTable } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CButton, CTypography } from "@charactr/wooper-ui/atoms";

  //STORE
  const { showSnackbar } = useSnackbarStore();
  const userStore = useUserStore();

  const billingBalance = ref();
  const billingHistoryLoading = ref(false);

  const data = reactive({
    createNewBillingAlert: false,
    isRefreshingTresholds: false,
    isLoadingPayment: false,
    error: "",
    snackbar: false,
    billingHistoryRows: [] as Array<BillingHistoryRow>,
    billingIdToDelete: 0,
    deleteBillingAlertDialog: false,
  });

  const reloadBillingHistory = () => {
    loadBillingHistory();
    analytics.sendEvent("billing", BILLING.actions.RELOAD_BILLING_HISTORY);
  };

  const convertBillingHistoryRow = (row: BillingHistoryRow) => {
    const updatedRow = Object.assign({}, row);

    updatedRow.formatedQuantity = updatedRow.quantity + " tokens";
    updatedRow.formattedCost = convertToCurrency(
      updatedRow.cents,
      updatedRow.currency
    );
    updatedRow.boughtAt = formatDate(row.boughtAt);
    return updatedRow;
  };

  async function loadBillingHistory() {
    try {
      billingHistoryLoading.value = true;
      const historyRows = await getHistory();

      data.billingHistoryRows = historyRows.map((row: BillingHistoryRow) => convertBillingHistoryRow(row)
      );
    } catch (e: any) {
      showSnackbar(
        e.response?.data.message || "Error occured",
        AlertModes.ERROR
      );
    } finally {
      billingHistoryLoading.value = false;
    }
  }

  onMounted(async () => {
    await loadBillingHistory();
  });
</script>

<style scoped lang="scss">
@import "@/assets/common.scss";

.section {
  background-color: rgb(var(--v-theme-aphla-bg)) !important;
  border: 1px solid rgb(var(--v-theme-light-frame)) !important;
  border-radius: 16px;
}

.balance {
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: 30% 5% 65%;
}

.justify-between {
  justify-content: space-between;
}

// one sepcific table header in application, no sense to update UI Kit
.billing-alerts {
  :deep(.v-toolbar__content) {
    align-items: flex-end !important;
  }

  :deep(.v-toolbar-title) {
    max-width: 320px !important;
  }
}
</style>

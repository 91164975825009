import type { AxiosError } from "axios";
import type { ErrorData } from "@/core/api/core.api";
import { useProgressStore } from "../store/useProgressStore";
import {
  form,
  FORM_LOADING_STATE,
  type FormState,
  type FormStateError,
  type FormStateSuccess,
} from "@/core/types/form-state.type";
import { type Ref, ref, toRefs } from "vue";

type FnToWrap<T, K> = (args: K) => Promise<T>;

export type FormStateWrappedFn<T, K> = {
  state: Ref<FormState<T>>;
  wrappedFunction: Ref<FnToWrap<T, K>>;
  submit(args: K): Promise<FormStateError | FormStateSuccess<T> | undefined>;
};

export default function useFormState<T, K>(
  fn: FnToWrap<T, K>,
  initialState?: FormState<any>
): FormStateWrappedFn<T, K> {
  const state: Ref<FormState<T>> = ref(initialState ?? FORM_LOADING_STATE);

  const wrappedFunction = ref(fn);

  const submit = async (args: K) => {

    //STORE
  const progressStore = useProgressStore();
  const { loadingCloneStudio } = toRefs(progressStore);

    try {
      if (state.value === FORM_LOADING_STATE) {
        return;
      }

      state.value = FORM_LOADING_STATE;
      loadingCloneStudio.value = true;
      state.value = form.makeSuccess(await wrappedFunction.value(args));
    } catch (e: unknown) {
      const axiosError: AxiosError<ErrorData> = e as AxiosError<ErrorData>;

      if (axiosError.code === "ERR_NETWORK") {
        state.value = form.makeError(axiosError.message, axiosError.code);
      } else {
        state.value = form.makeError(
          String(axiosError.response?.data.message ?? axiosError),
          axiosError.response?.data.code ?? 0
        );
        state.value.headers = axiosError?.response?.headers;
      }
    } finally {
      loadingCloneStudio.value = false;
    }

    return state.value;
  };

  return {
    wrappedFunction,
    state,
    submit,
  };
}

import { apiUrl } from "@/core/utils/api";
import type { RequestDataType } from "../types/form.types";
import coreClient, { tokenClientId } from "@/core/api/core.api";

export async function sendRequest(data: RequestDataType): Promise<void> {

  const clientId = tokenClientId();

  const url = apiUrl(`clients/${clientId}/surveys/3`);

   const response = await coreClient.post(url, data);

   return response.data;
  }

<template>
  <c-custom-card
    :class="[
      'dnd-upload',
      {
        'dnd-upload--dragover': data.dragover,
        'show-loader': props.displayProgress,
      },
    ]"
    color="aphla-bg"
    draggable
    :height="props.height"
    :min-height="104"
    :test-id="testId"
    type="primary"
    @click="emit('chooseFile')"
    @dragenter.prevent="data.dragover = true"
    @dragleave="data.dragover = false"
    @dragover.prevent="data.dragover = true"
    @drop.prevent="droppedFile($event)"
  >
    <template #content>
      <slot name="custom">
        <c-icon
          v-if="!data.dragover"
          class="mb-3"
          color="rgb(var(--v-theme-highlight-color))"
          height="32"
          icon="ph:file-plus-light"
        ></c-icon>
        <c-typography class="mb-1" variant="body-2-600"
        >{{
          data.dragover
            ? "Drop it like it’s hot"
            : isMobile
              ? "Tap to upload a file"
              : "Click to upload or drag and drop"
        }}
        </c-typography>
        <c-typography
          v-if="!data.dragover"
          color-class="copy-tertiary"
          variant="body-2-400"
        >{{ props.supportedFormats.join(", ").toUpperCase() }}
        </c-typography>
      </slot>
    </template>
  </c-custom-card>
</template>

<script setup lang="ts">
  import { isMobile } from "../utils/mobile";
  import { reactive } from "vue";

  // @ts-ignore
  import { CCustomCard } from "@charactr/wooper-ui/molecules";
  // @ts-ignore
  import { CIcon, CTypography } from "@charactr/wooper-ui/atoms";

  const props = defineProps({
    supportedFormats: {
      type: Array<string>,
      default: [],
    },
    height: {
      type: [Number, String],
      default: 140,
    },
    displayProgress: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: "",
    },
  });

  const emit = defineEmits(["chooseFile", "dropFile"]);

  const data = reactive({
    dragover: false,
  });

  const droppedFile = async ($event: any) => {
    data.dragover = false;

    emit("dropFile", $event);
  };
</script>

<style lang="scss" scoped>
.dnd-upload {
  border: 1px dashed rgb(var(--v-theme-light-frame));
  width: 100%;

  &:hover:not(.dnd-upload--dragover) {
    background: rgb(var(--v-theme-player-bg)) !important;
    border: 1px dashed rgb(var(--v-theme-button-primary)) !important;
  }

  &--dragover {
    background: rgb(var(--v-theme-button-primary)) !important;
    border: 2px dashed rgb(var(--v-theme-button-secondary-hover)) !important;
  }

  :deep(.v-card-text) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

:deep(.v-card__overlay) {
  display: none !important;
}

.show-loader {
  :deep(.v-card-text) {
    opacity: 100% !important;
  }
}
</style>
